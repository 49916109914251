import types from '../../mutationTypes'

export default {
  [types.CUSTOMER_SET_TOKEN](state, payload) {
    state.user.token = payload
  },
  [types.CUSTOMER_SET_INFO](state, payload) {
    state.user.info = payload
  },
  [types.CUSTOMER_LOGOUT](state, payload) {
    state.user = payload
    window.location.reload()
  }
}