<template>
  <div class="bg-white">
    <header-component v-if="!isMobile()" />
    <div v-if="isMobile()" class="text-center relative py-15">
      <h1 class="text-14">My Rewards</h1>
      <router-link :to="{name: 'ph-reward'}">
        <button class="flex items-center text-grey absolute pl-20 top-0 bottom-0 left-0">
          <em class="ml-5 icon-caret-left icon--small"></em>
        </button>
      </router-link>
      <button @click="$router.push({name: 'ph-reward-detail'})" class="flex items-center text-grey absolute pr-20 top-0 bottom-0 right-0">
        <em class="ml-5 icon-info"></em>
      </button>
    </div>
    <reward-level-section :is-auth="isAuth" :auth-user="authUser" :is-floating="false" />
    <div class="container mt-20 mb-20">
      <div class="nav">
        <router-link :to="{name: 'treasures'}" class="nav-tab" v-bind:class="{'active': this.$route.name === 'treasures'}">Treasures</router-link>
        <router-link :to="{name: 'slices'}" class="nav-tab" v-bind:class="{'active': this.$route.name === 'slices'}">Slices</router-link>
        <router-link :to="{name: 'vouchers'}" class="nav-tab" v-bind:class="{'active': this.$route.name === 'vouchers'}">Vouchers</router-link>
      </div>
    </div>
    <div class="container mt-20 mb-20">
      <router-view/>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import RewardLevelSection from "@/components/shared/RewardLevelSection";
import {mapGetters} from "vuex";
import types from "@/store/mutationTypes";
export default {
  name: "MyReward",
  components: {RewardLevelSection, HeaderComponent},
  computed: {
    ...mapGetters({
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
    }),
  }
}
</script>

<style scoped>
.nav {
  display: flex;
  flex-wrap: wrap;
  height: 32px;
}
.nav-tab {
  width: 33.33%;
  text-align: center;
  font-weight: 600;
  line-height: 20px;
  color: #9C9FA7;
  border-bottom: 1px solid #9C9FA7;
}
.nav-tab.active {
  color: #C8102E;
  border-bottom: 2px solid #C8102E;
}
</style>
