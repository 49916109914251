import app from '../main'
import types from './mutationTypes'
export default {
  [types.SET_LANG](state, payload) {
    app.$i18n.locale = payload;
    state.lang = payload;
  },
  [types.SET_MENU_CATEGORY_LIST](state, payload) {
    state.categories = payload;
  },
  [types.SET_CURRENT_CATEGORY](state, payload) {
    state.currentCategory = payload;
  },
  [types.IN_CREATE_LOADING](state) {
    state.isLoading += 1;
  },
  [types.DE_CREATE_LOADING](state) {
    state.isLoading -= 1;
    if (state.isLoading < 0) {
      state.isLoading = 0;
    }
  },
  [types.HIDE_LOADING](state) {
    state.isLoading = 0;
  },
  [types.SET_DEFAULT_CATEGORY](state, payload) {
    state.defaultCategory = payload;
  },
}