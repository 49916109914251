<template>
    <section class="app-carousel">
        <carousel v-if="items.length > 0" :perPage="3" :perPageCustom="[[100, 1], [768, 2], [1024, 3]]"  :paginationEnabled="false" :navigationEnabled="true">
            <slide class="app-item-card" v-for="(item, index) in items" :key="index" v-bind:data-index="index" v-bind:data-name="item.name" >
                <div class="item-card--full ">
                    <div class="">
                        <img :src="item.image" :alt="item.name" class="app-item-img"/>
                    </div>
                    <div class="item-card-body ">
                        <div class="item-card-heading">
                            <h2 class="item-card-title">
                                <span @click="handleSlideClick(item)" class="cursor-pointer underline">
                                    {{item.name}}
                                </span>
                            </h2>
                        </div>
                        <div class="item-card-description">{{ item.description }}</div>
                    </div>
                </div>
            </slide>
        </carousel>
    </section>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel';
  export default {
    name: "Carousel-Deals",
    props: {
      items: {
        type: Array,
        default: () => []
      },
      settings: {
        type: Object,
        default: () => {}
      }
    },
    components: {Carousel, Slide},
    data() {
      return {

      }
    },
    created() {
      this.initialise();
    },
    methods: {

      handleSlideClick (item) {
        this.$router.push({name: 'combo-detail', params: {uuid: item.uuid}});

      },
      initialise () {

      }
    }
  }
</script>

<style scoped>

    .item-card--full {
        background-color: #f5f7f9;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .app-item-card {
        display: flex !important;
        flex-direction: column;
        /*align-self: stretch;*/
        padding: 0 5px;
    }
    .item-card-title {
        font-size: 1.2rem;
        text-transform: none;
        font-weight: bold;
    }
    .item-card-body {
        background-color: #f5f7f9;
        padding: 1rem;
        text-align: left;
    }
    .app-item-img{
        vertical-align: middle;
        max-width: 100%;
        margin: auto;
    }
</style>