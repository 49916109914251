<template>
    <div v-if="isMobile()" class="bg-white fixed z-1" style="bottom: 0; box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 5px 0px; width: 100%">
        <div class="container flex flex-center justify-between relative h-full">
            <div class="footer-menu-item">
                <router-link :to="{name: 'home'}" >
                    <img v-if="this.$route.name === 'home'" :src="cdn_url + '/icons/home-active.png'" class="footer-menu-img">
                    <img
                        v-else :src="cdn_url + '/icons/home.png'" class="footer-menu-img"
                    >
                    <div class="footer-menu-text"  v-bind:class="{'active': this.$route.name == 'home'}">Home</div>
                </router-link>
            </div>
            <div class="footer-menu-item">
                <!-- <router-link v-if="isAuth" :to="{name: 'order-tracking'}" >
                    <img v-if="this.$route.name === 'order-tracking'" :src="cdn_url + '/icons/shopping-list-active.png'" class="footer-menu-img">
                    <img v-else
                         :src="cdn_url + '/icons/shopping-list.png'" class="footer-menu-img"
                    >
                    <div class="footer-menu-text" v-bind:class="{'active': this.$route.name == 'order-tracking'}">My Order</div>
                </router-link> -->
                <router-link :to="{name: 'my-order'}" >
                    <img v-if="this.$route.name === 'my-order'" :src="cdn_url + '/icons/shopping-list-active.png'" class="footer-menu-img">
                    <img v-else
                         :src="cdn_url + '/icons/shopping-list.png'" class="footer-menu-img"
                    >
                    <div class="footer-menu-text" v-bind:class="{'active': this.$route.name == 'my-order'}">My Order</div>
                </router-link>
            </div>
          <div class="footer-menu-item">
            <router-link :to="{name: 'ph-reward'}" >
              <img v-if="this.$route.name === 'ph-reward'" :src="cdn_url + '/icons/ph-rewards.gif'" class="footer-menu-img">
              <img v-else :src="cdn_url + '/icons/ph-rewards.gif'"
                   class="footer-menu-img">
              <div class="footer-menu-text" v-bind:class="{'active': this.$route.name == 'ph-reward'}">Hut Rewards</div>
            </router-link>
          </div>
            <div class="footer-menu-item">
                <router-link :to="{name: 'cart'}" >
                    <img v-if="this.$route.name === 'cart'" :src="cdn_url + '/icons/cart-active.png'" class="footer-menu-img">
                    <img v-else :src="cdn_url + '/icons/cart.png'" class="footer-menu-img">
                    <div class="footer-menu-text">Cart</div>
                </router-link>
            </div>
            <div class="footer-menu-item">
                <router-link v-if="!isAuth" :to="{name: 'login'}">
                    <img :src="cdn_url + '/icons/user.png'" class="footer-menu-img">
                    <div class="footer-menu-text">Account</div>
                </router-link>
                <router-link class="w-full" :to="{name: 'profile'}" v-else>
                    <img :src="cdn_url + '/icons/user.png'" class="footer-menu-img">
                    <div class="footer-menu-text">Account</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import types from "../store/mutationTypes";
    const CDN_URL = process.env.VUE_APP_CDN_URL
    export default {
        name: "MobileFooterComponent",
        data() {
          return {
            cdn_url: CDN_URL
          }
        },
        computed: {
            ...mapGetters({
                isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
                authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
            }),
            ...mapState({
                listCategories: 'categories'
            })
        },
        methods: {
            ...mapActions ({
                logout: 'moduleAuth/' + types.CUSTOMER_LOGOUT,
            }),
            showBasket (){
                document.body.classList.add('basket-open');
            },
        }
    }
</script>

<style scoped>

</style>
