<template>
  <div class="w-full">
    <div v-if="$route.params.slug !== $const.CATEGORY.COUPON">
      <div  v-for="(productTag, indenxPItem) in products" :key="indenxPItem">
        <h2 class="watermark cond single-tag caps xs-my-20 xs-mx-10 xs-text-center heading-hr clearfix pt-10" v-if="productTag.name || productTag.image" :class="{'margin-top-container': indenxPItem === 0  }">
          <img :src="productTag.image" v-if="productTag.image">
          <span v-else class="t24 bold">
            {{productTag.name}}
          </span>
        </h2>
        <div class="product-grid list side-menu-placeholder container list__group px-5 pb-10 flex flex-wrap items-center md:pt-5" :class="{'margin-top-container': indenxPItem === 0 && !productTag.name && !productTag.image}">
          <template v-for="(item, indexp) in productTag.products">
            <singles :product="item" :slug="$route.params.slug" :product-uuid="item.uuid" :key="indexp" v-if="!item.is_combo"></singles>
            <combos :product="item" :slug="$route.params.slug" :product-uuid="item.uuid" :key="'combo-' + indexp" v-else></combos>
          </template>
        </div>
      </div>
    </div>
    <div v-else class="product-grid margin-top-container list side-menu-placeholder container list__group px-5 pb-10 flex flex-wrap items-center md:pt-5">
      <div class="coupon-box-info w-full mb-20">
        <div class=" bg-white mt-10 p20">
          <div class="coupon-inner">
            <div class="input-box">
              <div v-if="couponMessage" class="coupon-message"> {{ couponMessage }} </div>
              <span class="mt-10">{{ $t('common.title_coupon_box') }}</span>
              <input type="text" v-model="couponCode" :placeholder="$t('common.add_coupon_place_holder')" class="form-control mt-10 mb-10"/>
              <button class="button button--red mb-10" @click="redeemCode">{{ $t('common.use') }}</button>
            </div>
          </div>
        </div>
      </div>
      <template v-for="(item, indexp) in products">
        <coupons :coupon="item" :key="indexp"></coupons>
      </template>
    </div>
    <div class="footer relative lg:hidden">
      <div class="footer__fixed bg-white shadow-up fixed bottom-0 left-0 right-0 z-100 md:z-100 side-menu-placeholder">
        <div class="container flex flex-col">
          <div class="md:hidden menus-fixed-top-position">
            <div class="bg-white relative overflow-auto text-center whitespace-no-wrap">
              <template v-for="(item, indexC) in listCategories">
                <router-link class="menu-link pb-10 capitalize bold inline-block" :key="indexC" v-if="item.slug !== $const.CATEGORY.COUPON || (isAuth)" :class="{' bg-red lg:text-white side-menu__link--active': item.slug == currentCategory.slug }" aria-current="page" :to="'/order/' + item.slug" style="padding-left: 8px; padding-right: 8px;">
                  <span>{{item.name}}</span>
                </router-link>
              </template>
            </div>
          </div>
          <div class="bg-white p-10 relative z-200">
            <button class="flex button button--red" :to="'#basket'" @click="showBasket()">
              <span class="shopping-items" :style="`background-image: url('${CDN_URL}/icons/icon-shopping-cart.png')`" v-if="orderType == $const.ORDERTYPE.DINEIN">
                <span class="count-items" v-if="cartBeforeCheckoutCount > 0">{{cartBeforeCheckoutCount}}</span>
              </span>
              <span class="shopping-items" :style="`background-image: url('${CDN_URL}/icons/icon-shopping-cart.png')`" v-else>
                <span class="count-items" v-if="cartCount > 0">{{cartCount}}</span>
              </span>
              <span class="absolute inset-0 flex-center">
                <span>{{$t('common.view_basket')}}</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
import Singles from '../components/partials/singles';
import Combos from '../components/partials/combos';
import Coupons from '../components/partials/coupons';
import VueScrollTo from 'vue-scrollto'

import { mapState, mapGetters , mapActions} from 'vuex';
import mutationTypes from "../store/mutationTypes";
import cartAPI from '../services/cart';
import menuAPI from '../services/menu';
import couponAPI from '../services/coupon';
import MobileFooterComponent from "../components/MobileFooterComponent";
import manthan from "../services/manthan";
import types from "../store/mutationTypes";
import moment from "moment-timezone";
import branch from '../services/branch';
const CDN_URL = process.env.VUE_APP_CDN_URL

export default {
  components: {
      MobileFooterComponent,
    'singles': Singles,
    'combos': Combos,
    'coupons': Coupons,
  },
  mounted () {
    if (this.$route.params.slug === this.$const.CATEGORY.COUPON && !this.isAuth) {
      this.$root.$router.push({name: 'login', query: { callback: 'order/' + this.$const.COUPON_CAT.slug }});
      return;
    }
    if (this.$route.params.coupon) {
        this.couponCode = this.$route.params.coupon
        this.redeemCode();
    }
    this.getMenuListByCategory();
    this.mobileShowCart();
  },
  data () {
    return {
        CDN_URL: CDN_URL,
        couponCode: '',
        couponMessage: '',
        products: []
     }
  },
  methods: {
    ...mapActions({
      getCartDetail: 'moduleCart/' + mutationTypes.CART_GET_CART_DETAIL,
      setCartDetail: 'moduleCart/' + mutationTypes.CART_SET_DATA,
      setCartSuggest: 'moduleCart/' + mutationTypes.CART_SET_SUGGEST,
    }),
    createCart () {
      var vm = this;
      let dataProduct = {
        "cart_uuid": this.cartUuid || '',
        "disposition": this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        "items": [],
        "now_order": this.orderNow,
        "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
        "store_uuid": this.storeUuid,
      }

      if (this.localisation.orderType === this.$const.ORDERTYPE.DINEIN) {
        dataProduct.table_number = this.localisation.tableNumber
      }

      cartAPI.addCart(dataProduct, response => {
        vm.setCartDetail({payload: response.data});
        vm._redeemCoupon(response.data.information.cart_uuid);
        vm._eventAddCart(response.data);
      }, error => {
        vm.notificationError(vm, error.message);
      })
    },
    _redeemCoupon ( uuidCart = '') {
      let params = {
        "cart_uuid": uuidCart,
        "code": this.couponCode || '',
        "vm": this
      }

      var vm = this;
      cartAPI.redeem(params, function (response) {
        if (response.data.status === 0) {
          let proUuid = ''
          let prodRequired = ''
          let isCombo = false;
          if (response.data.products && response.data.products.length > 0) {
            proUuid = response.data.products[0].uuid;
            if (response.data.products[0].is_combo == 1) {
              isCombo = true;
            }
          } else {
              prodRequired = response.data.required_product_uuids.join();
              return vm.$router.push({name: 'redeem', params: {code: vm.couponCode, listRequired: prodRequired}});
          }
          if (isCombo) {
            return  vm.$router.push({name: 'combo-detail', params: {uuid: proUuid, code: vm.couponCode}})
          }
          return vm.$router.push({name: 'redeem', params: {code: vm.couponCode, uuid: proUuid}});
        }
        vm.notificationSuccess(vm, vm.$t('message.success.redeem_coupon_success'));
        vm.getCartDetail({payload: {uuid: vm.cartUuid}});
      }, function (error) {
        vm.notificationError(vm, error.message);
      });
    },
    redeemCode () {
      if (!this.storeUuid) {
        this.$notify({
          type: 'warning',
          title: this.$t('message.error.choose_localisation_first')
        });
        let path = this.$route.fullPath.indexOf('/') !== 0 ? this.$route.fullPath : this.$route.fullPath.substr(1);
        this.$router.push({name: 'home', query: {warning: '1', callback: path}});
        return false;
      }

      if (!this.couponCode) {
        this.couponMessage = this.$t('message.error.redeem_coupon');
        return false;
      }
      if (!this.cartUuid) {
        return this.createCart();
      }
      this._redeemCoupon(this.cartUuid);
    },
    showBasket (){
      this._eventViewCart();
      document.body.classList.add('basket-open');
      this.$root.$el.querySelector('.basket-container').scrollTo(0, 2000)
    },
    getMenuListByCategory () {
      if (this.currentCategory && this.currentCategory.slug === this.$route.params.slug) {
        if (this.$route.params.slug === this.$const.CATEGORY.COUPON) {
          this._handleGetCoupons();
        } else {
          this._handleGetItems();
        }
      }
    },
    scrollToProduct (v) {
      const headHeight = document.body.querySelector('.header').clientHeight
      const categoryHeight = document.body.querySelector('.menus-fixed-top-position').clientHeight
      const offset = this.isMobile() ? -(headHeight + categoryHeight + 5) : -5
      document.body.classList.remove('basket-open')
      VueScrollTo.scrollTo(`[product-uuid="${v}"]`, 0.5, { offset: offset })
      this.$el.querySelector(`[product-uuid="${v}"]`).classList.add('highlight-upsell')
      setTimeout(() => {
        this.$el.querySelector(`[product-uuid="${v}"]`).classList.remove('highlight-upsell')
      }, 3000)
    },
    _sortTagProduct (dataProducts) {
      let _productFirst = [];
      for (let item of dataProducts) {
        if (!this.emptyVariable(item.products) && item.products.length > 0) {
          _productFirst = _productFirst.concat(item.products);
        }
      }
      this.eventProductsImpression(_productFirst, 'main-list');
      this.eventProductView(_productFirst, 'main-list');
    },
    _handleGetItems () {
      let params = {
        type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        category_uuid: this.currentCategory.uuid,
        store_uuid: this.storeUuid,
        vm: this
      };
      if (['combox', 'hot-promo'].includes(this.currentCategory.slug)) {
        params = {
          "now_order": this.orderNow,
          "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
          ...params
        }
      }
      var vm = this;
      vm.products = [];
      menuAPI.getProducts(params, function (response) {
        vm.products = response.data;
        vm._sortTagProduct(response.data);
        //eventProduct
        //vm._eventProducts(response.data);
      })
      let dataManthan = {
        "parentCategoryId": "",
        "categoryId": this.currentCategory.uuid,
      }
      manthan.execute(dataManthan,"eventCategory");
    },
    _handleGetCoupons () {
      let params = {
        store_uuid: this.storeUuid,
        vm: this
      };
      var vm = this;
      vm.products = [];
      couponAPI.getCoupons(params, function (response) {
        vm.products = response.data
      })
    },
    mobileShowCart(){
        if (this.isMobile()){
            if (this.$route.name == 'cart'){
                this.showBasket()
            }
        }
    },
    _eventAddCart(data) {
      //function for manthan
      const dataManthanCart = {
        cartId: this.cartUuid || '',
        productId: '',
        categoryId: '',
        brandId: this.localisation.storeType === this.$const.TYPE_HUT.PHD ? 'PHD' : 'PHR',
        price: '',
        quantity: 0,
        totalQuanty: 0,
        shippingCost: data.information.delivery_fee > 0 ? data.information.delivery_fee : 0,
        tax: data.information.tax
      }
      manthan.execute(dataManthanCart,"eventAddToCart")
      branch.branchLog('ADD_TO_CART',[this.product])
    },
    _eventProducts(data) {
      //function for manthan
      for (const itemProduct of data) {
        for (const item of itemProduct.products) {
          const dataManthanProduct = {
            productId: item.uuid,
            categoryId: item.category.uuid,
            manufacturer: this.localisation.storeType === this.$const.TYPE_HUT.PHD ? 'PHD' : 'PHR',
            price: item.sub_price,
            bannerHash: ''
          };

          manthan.execute(dataManthanProduct,'eventProduct');
        }
      }
    },
    _eventViewCart() {
      let cartItem = [];
      let totalDiscount = 0
      let couponCode = "";

      if (this.cartData.products.length > 0) {
        for (const [index, item] of this.cartData.products.entries()) {
          totalDiscount += item.discount
          for (const value of item.product) {
            const productId = value.uuid;
            const childProductId = value.option_group ? value.option_group.uuid : '';

            cartItem.push({
              productId: productId,
              childProductId: childProductId,
              actualProductId: childProductId != '' ? childProductId : productId,
              productPosition: index + 1,
              action: "View Cart",
              quantity: item.quantity,
              productBasePrice: item.sub_price,
              tax: item.tax,
              discount: item.discount,
              couponCode: item.promotion_code,
            });
          }
        }
      }

      if (this.cartData.promotions.length > 0) {
        for (const [index, item] of this.cartData.promotions.entries()) {
          if (index == 0) {
            couponCode = item.code;
          } else {
            couponCode += ',' + item.code;
          }
        }
      }

      const dataManthanCart = {
        totalPrice: this.cartData.information.total,
        shippingCost: this.cartData.information.delivery_fee > 0 ? this.cartData.information.sub_delivery_fee : 0,
        discount: totalDiscount,
        couponCode: couponCode,
        cartProducts: (cartItem) ? cartItem: null,
        createdDate: moment.utc(this.cartData.information.created_at).local().format('YYYY-MM-DD HH:mm:ss'),
        subTotalPrice: this.cartData.information.sub_total,
        tax: this.cartData.information.tax,
        cartId: this.cartUuid || '',
        totalQty: this.cartCount,
        itemCount: this.cartData.products.length || 0,
      };
      // eslint-disable-next-line no-undef
      manthan.execute(dataManthanCart,"eventCartView");
      branch.branchLog('VIEW_CART', this.cartData.products)
    },
  },
  watch: {
    '$route': function (v) {
      if (v.query.is_upsell === 1) {
        const waitLoad = setInterval(() => {
          if (this.$el.querySelector(`[product-uuid="${v.query.product}"]`)) {
            this.scrollToProduct(v.query.product)
            clearInterval(waitLoad)
          }
        }, 200)
      }
    },
    currentCategory: function (newValue, oldValue) {
      if (newValue && newValue.uuid != oldValue.uuid) {
       this.getMenuListByCategory();
      }
    },
    couponCode: function (newValue, oldValue) {
      this.couponMessage = '';
      if (!newValue) {
        this.couponMessage = this.$t('message.error.redeem_coupon');
      }
    }
  },

  computed: {
    ...mapGetters ({
      storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
      isAuth:  'moduleAuth/' + mutationTypes.CUSTOMER_IS_AUTH,
      cartCount: 'moduleCart/' + mutationTypes.CART_COUNT,
      cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
      cartTotal: 'moduleCart/' + mutationTypes.CART_GET_TOTAL,
      cartSuggest: 'moduleCart/' + mutationTypes.CART_GET_SUGGESTIONS,
      cartPromo: 'moduleCart/' + mutationTypes.CART_GET_PROMOTIONS,
      cartBeforeCheckoutCount: 'moduleCart/' + types.CART_BEFORE_CHECKOUT_COUNT,
      orderNow: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_NOW,
      orderTime: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_COLLECTION_TIME,
      timezone: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_TIMEZONE,
      localisation: 'moduleLocalisation/' +  mutationTypes.LOCALISATION_GET_LOCALISATION,
      defaultCategory:  mutationTypes.GET_DEFAULT_CATEGORY,
      cartData: 'moduleCart/' + types.CART_DETAIL,
    }),
    ...mapState({
      currentCategory: 'currentCategory',
      listCategories: 'categories'
    })
  }
}
</script>
<style scoped>
    .coupon-box-info .coupon-inner {
        max-width: 500px;
        margin: auto;
    }
    .p20 {
        padding: 20px;
    }
    .coupon-box-info .coupon-message {
        color: red;
        margin: 10px 0px;
    }
</style>
