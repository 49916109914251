<template>
    <div class="list-item  " :class="'list-item--' + slug" tabindex="0"  >
       <div role="button">
           <div class="list-item__image flex-center flex" style="height: 200px" ref="itemImage">
               <img :src="img_url+product.image" class="block " :alt="product.name" :title="product.name" v-if="!emptyVariable(product.image)">
           </div>
           <p class="typography-4 list-item__name flex-1 px-10 pt-10">{{product.name}}</p>
           <p class="typography-6 list-item__desc flex-1 ">{{product.description}} </p>
       </div>
        <div class="mt-auto">
            <div class="m-10">
                <div class="mb-10 " v-if="listCrusts.length > 0">
                    <div class="relative">
                        <label class=" items-center" for="dropdown_64">
                            <div class="pr-5 pb-5">
                                <span class="" v-if="!emptyVariable(selectedSizeItem)">{{$t('menus.select_crust')}}</span>
                                <span class="" v-else>{{$t('menus.select_sauce')}}</span>
                            </div>
                            <v-select v-if="listCrusts.length > 1" :options="listCrusts" v-model="selectedCrustItem" id="dropdown_64" class="w-full typography-6 bold mb-10 v-select__disable_remove" name="SelectCrust">
                            </v-select>
                            <v-select v-else :options="listCrusts" v-model="selectedCrustItem" id="dropdown_64" class="w-full typography-6 bold mb-10 v-select__disable " name="SelectCrust">
                            </v-select>
                        </label>
                    </div>
                    <div class="relative" v-if="listSizes.length > 0">
                        <label class=" items-center" for="dropdown_65">
                            <div class="pr-5 pb-5"><span class="">{{$t('menus.select_size')}}</span></div>
                            <v-select v-if="listSizes.length > 1" :options="listSizes" v-model="selectedSizeItem" id="dropdown_65" class="w-full typography-6 bold mb-10 v-select__disable_remove" name="SelectSize">
                            </v-select>
                            <v-select v-else :options="listSizes" v-model="selectedSizeItem" id="dropdown_65" class="w-full typography-6 bold mb-10 v-select__disable" name="SelectSize">
                            </v-select>
                        </label>
                    </div>
                    <button v-if="!emptyVariable(currentExtraCheese)" class="button button--md font-semibold mt-10 height-auto"
                            v-bind:class="[ !emptyVariable(hasExtraCheese) ? 'button--red' : 'button--yellow']"  @click="toggleExtraCheese">
                        <div class="w-full  whitespace-normal ">
                            {{$t('common.extra_cheese')}} (+{{$t('common.format_currency', {amount: formatCurrency(currentExtraCheese.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}})
                        </div>
                    </button>
                </div>
                <div class="mb-10 " v-else-if="!emptyVariable(currentExtraCheese)">
                    <button class="button button--md font-semibold mt-10 height-auto"
                            v-bind:class="[ !emptyVariable(hasExtraCheese) ? 'button--red' : 'button--yellow']"  @click="toggleExtraCheese">
                        <div class="w-full  whitespace-normal ">
                            {{$t('common.extra_cheese')}} (+{{$t('common.format_currency', {amount: formatCurrency(currentExtraCheese.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}})
                        </div>
                    </button>
                </div>
                <button @click="addItemIntoCart" class="button button--md button--red flex-1 font-semibold t15 mt13" v-if="product.point > 0">
                    <span>{{$t('common.add_to_redeem')}}</span>
                    <span class="w-auto" style="margin-left: 3px;">{{$t('common.format_point', {amount: formatCurrency(product.point, $const.SUFFIX_CURRENCY)})}}</span>
                </button>
                <button @click="addItemIntoCart" class="button button--md button--red flex-1 font-semibold t15 mt14" v-else>
                    <span>{{$t('common.btn.add')}}</span>
                    <span class="w-auto" style="margin-left: 3px;">{{$t('common.format_currency', {amount: formatCurrency(amount, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  /* eslint-disable */
  import { debounce } from 'lodash'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import mutationTypes from "../../store/mutationTypes";
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  import manthan from "../../services/manthan";
  import branch from '../../services/branch'

  export default {
  name: 'menu-single',
  props: ['product', 'slug', 'codeRedeem', 'isRequired'],
  mounted () {
    this.checkShowExtraCheese();
    this.initSizeCrust();
    this.calculatorAmount();
    this.addCurrentExtraCheesePasta();
    const size = this.$refs.itemImage.clientWidth
    this.img_url = this.$const.LINK_STATIC_FLY + "width=" + size + "/"
  },
  components: {
   vSelect
  },
  data () {
    return {
      amount: 0,
      listCrusts: [],
      listSizes: [],
      selectedCrustItem: {},
      selectedSizeItem: {},
      extraCheese: {},
      currentExtraCheese: {}, // cheese base on size
      hasExtraCheese: {}, // cheese base on size
      img_url:'',
    }
  },
  methods: {
    ...mapActions({
      addToCart: 'moduleCart/' + mutationTypes.CART_SAVE_ITEM,
      setStore: 'moduleLocalisation/' + mutationTypes.LOCALISATION_SAVE_STORE,
    }),
    checkShowExtraCheese() {
      if (this.product && this.product.toppings) {
          if (this.product.toppings.additional_toppings.length > 0) {
              this.extraCheese = this.product.toppings.additional_toppings[0];
          }
      }
    },
    toggleExtraCheese () {
      if (!this.emptyVariable(this.hasExtraCheese)) {
        this.hasExtraCheese = {};
      } else {
        this.hasExtraCheese = this.currentExtraCheese;
      }
      this.calculatorAmount();
    },
    calculatorAmount() {
      this.amount = this.product.sub_price;
      let _selectedCrustItem = this.selectedCrustItem ? _.cloneDeep(this.selectedCrustItem.item) : {};
      let _selectedSizeItem = this.selectedSizeItem ? _.cloneDeep(this.selectedSizeItem.item) : {};
      if (!this.emptyVariable(_selectedCrustItem)) {
        this.amount += _selectedCrustItem.sub_price;
      }
      if (!this.emptyVariable(_selectedSizeItem)) {
        this.amount += _selectedSizeItem.sub_price;
      }
      if (!this.emptyVariable(this.hasExtraCheese)) {
        this.amount += this.hasExtraCheese.sub_price;
      }
    },
    initSizeCrust () {
      if (this.product) {
        let _listCrusts = this.product.option_groups || [];
        if (_listCrusts.length > 0) {
          for (let i in this.product.option_groups) {
            this.listCrusts.push({
              label: this.product.option_groups[i].name,
              item: this.product.option_groups[i]
            })
          }
          this.selectedCrustItem = this.listCrusts[0];
        }
      }
    },
    addItemIntoCart(){
      if (!this.storeUuid) {
        this.$notify({
          type: 'warning',
          title: this.$t('message.error.choose_localisation_first')
        });
        let path = this.$route.fullPath.indexOf('/') !== 0 ? this.$route.fullPath : this.$route.fullPath.substr(1);
        this.$router.push({name: 'home', query: {warning: '1', callback: path}});
        return false;
      }
      // check point
      if (this.product.point > 0 && !this.isAuth) {
        this.$notify({
          type: 'warning',
          title: this.$t('message.error.login_to_redeem')
        });
        this.$router.push({name: 'login'});
        return false;
      }

      let plus = [];
      if (!this.emptyVariable(this.hasExtraCheese)) {
        plus.push({
          quantity: 1,
          uuid: this.extraCheese.uuid,
        })
      }
      let _selectedCrustItem = this.selectedCrustItem ? _.cloneDeep(this.selectedCrustItem.item) : {};
      let _selectedSizeItem = this.selectedSizeItem ? _.cloneDeep(this.selectedSizeItem.item) : {};
      let dataProduct = {
        "cart_uuid": this.cartUuid || '',
        "disposition": this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        "items": [
          {
            "combo_uuid": "",
            "is_point": this.product.point > 0 ? 1 : 0,
            "promotion_code": this.codeRedeem || '',
            "products": [
              {
                "category_uuid": this.product.category.uuid,
                "combo_group_uuid": "",
                "option_group_uuid": _selectedCrustItem ? _selectedCrustItem.uuid : '',
                "option_uuid": _selectedSizeItem ? _selectedSizeItem.uuid : '',
                "product_uuid": this.product.uuid,
                "quantity": 1,
                "toppings": {
                  "removal": [],
                  "addition": plus
                },
                "is_upsell": this.$route.query.is_upsell && this.$route.query.product === this.product.uuid ? 1 : 0
              }
            ],
            "quantity": 1,
            "type": "product",
            "voucher_code": ""
          }
        ],
        "now_order": this.orderNow,
        "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
        "store_uuid": this.storeUuid
      }

      // add table_number only for dine in
      if (this.localisation.orderType === this.$const.ORDERTYPE.DINEIN) {
        dataProduct.table_number = this.localisation.tableNumber
      }

      this.handleProductClick();
      this.handleEventAddToCart();

        this.addToCart({vm: this, payload: dataProduct}).then(response => {
            if (this.isRequired) {
                this.$router.push({name: 'category', params: {slug: this.$const.CATEGORY.COUPON, coupon: this.isRequired}})
            }
            if (this.codeRedeem) {
                this.$router.push({name: 'category', params: {slug: this.$const.CATEGORY.COUPON}})
            }
            this._eventAddToCart();
            this.localisation.cartUuid = this.cartUuid
            this.setStore(this.localisation)
        }).catch(error=> {
            this.$router.push({name: 'category', params: {slug: this.$const.CATEGORY.COUPON}})
        });

    },
    handleProductClick () {
      let dataPro = {
        'name': this.product.name,
        'id': this.product.uuid,
        'price': this.product.sub_price,
        'category': this.product.category && this.product.category.name ? this.product.category.name : '',
        'url': window.location.href
      };
      this.eventProductClick(dataPro);
    },
    handleEventAddToCart() {
      let variant = [];
      if (this.selectedSizeItem) {
        variant.push(this.selectedSizeItem.uuid)
      }
      if (this.selectedCrustItem) {
        variant.push(this.selectedCrustItem.uuid)
      }
      let dataPro = {
        'name': this.product.name,
        'id': this.product.uuid,
        'variant': variant.length > 0 ? variant.join('-') : '',
        'price': this.amount,
        'category': this.product.category && this.product.category.name ? this.product.category.name : '',
      };
      this.eventAddToCart(dataPro);
    },
    mappingCurrentExtraCheese () {
      if (this.emptyVariable(this.extraCheese)){
        return false;
      }
      for (let item of this.extraCheese.options) {
        if (item.uuid == this.selectedSizeItem.item.uuid) {
          this.currentExtraCheese = item;
          break;
        }
      }
    },
    addCurrentExtraCheesePasta(){
        if(this.extraCheese.options && this.extraCheese.category_type_id == this.$const.ADDITIONAL_TYPE.ADDON ){
            if(!this.emptyVariable(this.extraCheese.options[0])){
                this.currentExtraCheese = this.extraCheese.options[0];
            }
        }
      },
    _eventAddToCart() {
      //function for manthan
      const dataManthanCart = {
        cartId: this.cartUuid || '',
        productId: this.product.uuid,
        categoryId: this.product.category && this.product.category.uuid ? this.product.category.uuid : '',
        brandId: this.localisation.storeType === this.$const.TYPE_HUT.PHD ? 'PHD' : 'PHR',
        price: this.product.sub_price,
        quantity: 1,
        totalQuantity: this.cartCount,
        shippingCost: this.cartData.information.delivery_fee > 0 ? this.cartData.information.delivery_fee : 0,
        tax: this.product.tax
      };
      manthan.execute(dataManthanCart,"eventAddToCart")
      branch.branchLog('ADD_TO_CART',[this.product])
    },
  },
  watch: {
    selectedCrustItem: function(newVal) {
      if (newVal && !this.emptyVariable(newVal.item)) {
        let dataListSizes = newVal.item.options || [];
        if (dataListSizes.length) {
          this.listSizes = [];
          for (let i in dataListSizes) {
            this.listSizes.push({
              label: dataListSizes[i].name,
              item: dataListSizes[i]
            })
          }
          this.selectedSizeItem = this.listSizes[0];
        }
      }
      this.calculatorAmount();
    },
    selectedSizeItem: function() {
      // reset and mapping extra cheese
      this.hasExtraCheese = {};
      this.mappingCurrentExtraCheese();
      this.calculatorAmount();
    }
  },
  computed: {
    ...mapGetters ({
      storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      orderNow: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_NOW,
      orderTime: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_COLLECTION_TIME,
      timezone: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_TIMEZONE,
      orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
      cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
      isAuth:  'moduleAuth/' + mutationTypes.CUSTOMER_IS_AUTH,
      cartData: 'moduleCart/' + mutationTypes.CART_DETAIL,
      cartCount: 'moduleCart/' + mutationTypes.CART_COUNT,
      localisation: 'moduleLocalisation/' +  mutationTypes.LOCALISATION_GET_LOCALISATION,
    }),
    ...mapState({
      currentCategory: 'currentCategory',
      listCategories: 'categories'
    })
  }
}
</script>
<style scoped>
</style>
