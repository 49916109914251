export default {
    localisation: {
        "clientUUID": "",
        "code": "",
        "distance": 0,
        "lat": 0,
        "location": "",
        "long": 0,
        "name": "",
        "tierUUID": 0,
        "uuid": "",
        "timezone": "Asia/Jakarta",
        "businessHours": [],
        "deliveryInfo": {},
        "orderType": 0,
        "orderNow": 1,
        "collection_time": "",
        "collectionTime" : "",
        "storeType": ""
    }
}
