<template>
  <div>
    <header-component/>
    <div class=" en-GB app-bg">
      <div class="container mt-20 mb-40">
        <div class="font-open-sans-bold text-center relative mb-20">
          <h3 class="px-40">Edit Delivery Details</h3>
          <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
        </div>
        <div class="p-20 bg-white">
          <div class="md:flex">
            <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" @submit="checkForm">
              <div class="block-section">
                <div class="md:flex lg:flex">
                  <div class="form-field flex-1">
                    <input
                        id="recipient_name"
                        v-model="receipt_name"
                        placeholder="Recipient name"
                        class="input xs-mb-10"
                        ref="receipt_name"
                        name="receipt_name"
                        maxlength="255"
                        v-bind:class="{'has-error': errors.receipt_name, 'has-success': errors.receipt_name === false}"
                        type="text">
                    <span class="float-left text-red" v-if="errors.receipt_name">{{ errors.receipt_name}}</span>
                  </div>
                </div>
                <div class="form-field">
                  <div class="input-group">
                    <div class="input-group-append"><span class="input-group-text" >{{CONSTANT.PREFIX_PHONE}}</span></div>
                    <input id="receipt_phone"
                           v-model="receipt_phone"
                           placeholder="Phone number"
                           class="input xs-mb-10 form-control"
                           ref="receipt_phone"
                           name="receipt_phone"
                           maxlength="30"
                           v-bind:class="{'has-error': errors.receipt_phone, 'has-success': errors.receipt_phone === false}"
                           type="text">
                  </div>
                  <span class="float-left text-red" v-if="errors.receipt_phone">{{ errors.receipt_phone }}</span>
                </div>
                <div class="md:flex lg:flex">
                  <div class="form-field flex-1">
                    <input id="receipt_addr"
                           v-model="receipt_addr"
                           placeholder="Address"
                           class="input xs-mb-10"
                           ref="receipt_addr"
                           name="receipt_addr"
                           v-bind:class="{'has-error': errors.receipt_addr, 'has-success': errors.receipt_addr === false}"
                           type="text">
                    <span class="float-left text-red" v-if="errors.receipt_addr">{{ errors.receipt_addr}}</span>
                  </div>
                </div>
                <div class="md:flex lg:flex">
                  <div class="form-field flex-1">
                    <input id="receipt_addr_detail" v-model="receipt_addr_detail" placeholder="Additional Address Detail (Optional)" class="input xs-mb-10" ref="receipt_addr_detail" name="receipt_addr_detail" type="text">
                  </div>
                </div>
              </div>
              <div>
                <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" > Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Constant from "@/constant";
import HeaderComponent from "@/components/HeaderComponent";
import couponAPI from "@/services/coupon";

export default {
  name: "UpdateRewardDetail",
  components: {HeaderComponent},
  data() {
    return {
      CONSTANT: Constant,
      uuid: '',
      code: '',
      receipt_name: '',
      receipt_phone: '',
      receipt_addr: '',
      receipt_addr_detail: '',
      errors: {
        receipt_name: '',
        receipt_phone: '',
        receipt_addr: '',
      }
    }
  },
  watch: {
    'receipt_name': function (newValue) {
      this.errors.receipt_name = false;
      if (!newValue) {
        this.errors.receipt_name = 'Error Name'
      }
    },
    'receipt_phone': function (newValue, oldValue) {
      this.errors.receipt_phone = false;
      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.receipt_phone = oldValue
        return
      }
      if (!newValue) {
        this.errors.receipt_phone = this.$t('message.error.phone');
      } else if (newValue.length < this.$const.PHONE.MIN || newValue.length > this.$const.PHONE.MAX) {
        this.errors.receipt_phone = this.$t('message.error.invalid_phone', {
          min: this.$const.PHONE.MIN,
          max: this.$const.PHONE.MAX
        });
      }
    },
    'receipt_addr': function (newValue) {
      this.errors.receipt_addr = false;
      if (!newValue) {
        this.errors.receipt_addr = 'Error Address'
      }
    },
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      const info = this.$route.params.info
      if (info) {
        this.receipt_name = info.receipt_name
        this.receipt_phone = info.receipt_phone
        this.receipt_addr = info.receipt_addr
        this.receipt_addr_detail = info.receipt_addr_detail
        this.uuid = info.uuid
        this.code = info.order_code
      } else {
        this.$router.push({name:'error-404'})
      }
    },
    initErrorMessage () {
      this.errors = {
        receipt_name: false,
        receipt_phone: false,
        receipt_addr: false,
      }
    },
    checkForm(e) {
      e.preventDefault();
      let isValidForm = true;
      this.initErrorMessage();

      if (!this.receipt_name) {
        this.errors.receipt_name = 'Error name';
        if (isValidForm) {
          this.$refs.receipt_name.focus();
        }
        isValidForm = false;
      }
      if (!this.receipt_phone) {
        this.errors.receipt_phone = this.$t('message.error.phone');
        if (isValidForm) {
          this.$refs.receipt_phone.focus();
        }
        isValidForm = false;
      } else if (this.receipt_phone.length < this.$const.PHONE.MIN || this.receipt_phone.length > this.$const.PHONE.MAX) {
        this.errors.receipt_phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX})
        if (isValidForm) {
          this.$refs.receipt_phone.focus();
        }
        isValidForm = false;
      }
      if (!this.receipt_addr) {
        this.errors.receipt_addr = 'Error Address';
        if (isValidForm) {
          this.$refs.receipt_addr.focus();
        }
        isValidForm = false;
      }
      if (isValidForm) {
        this.updateProfile();
      }
    },
    updateProfile () {
      const vm = this;
      let payload = {
        "uuid": this.uuid,
        "receipt_name": this.receipt_name,
        "receipt_phone": this.receipt_phone,
        "receipt_addr": this.receipt_addr,
        "receipt_addr_detail": this.receipt_addr_detail,
        "vm": vm
      }
      couponAPI.updateReceipt(payload,
          function () {
            vm.$router.push({name:'detail-delivery-reward', params: {order_code: vm.code}})
          }, function (data) {
            vm.notificationError(vm, data.message);
          }
      )
    },
  }
}
</script>

<style scoped>

</style>