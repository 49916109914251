<template>
    <div>
        <div class="t24 text-center" v-html="$t('common.select_store')"></div>
        <button @click="$emit('close')" type="button" class="icon-close2 xs-p-20 fixed xs-r-20 xs-t-10"></button>
        <div  class="overflow-auto " style="max-height: calc(100vh - 150px)">
            <div class="flex justify-between items-center border-b border-grey-darkest pl-10 pr-10 pt-20 pb-20 cursor-pointer"  v-for="(item, indexS) in stores" :key="indexS" v-if="indexS < 5"
                 @click="chosenStore(item)"
                >
                <div>
                    <h4 style="display: flex; align-items:center">
                        <img :src="item.logo" class="store_logo">
                        <span class="t20 store_distance"> ({{formatKm(item.distance, 2)}} {{$t('km')}})</span>
                    </h4>
                </div>
                <button>
                    <img src="data:image/svg+xml;base64,PHN2ZwogeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiCiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIzNXB4Ij4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiAgZmlsbD0icmdiKDI1NSwgMjU1LCAyNTUpIgogZD0iTTAuNzAwLDMwLjc2NCBMMTMuOTcwLDE3LjQ5NCBMMC44NzEsNC4zOTYgQzAuMzM4LDMuOTM4IDAuMDAwLDMuMjU4IDAuMDAwLDIuNTAwIEMwLjAwMCwxLjExOSAxLjExOSwwLjAwMCAyLjUwMCwwLjAwMCBDMy4xNzEsMC4wMDAgMy43ODAsMC4yNjUgNC4yMjksMC42OTUgTDQuMjM2LDAuNjg5IEwxOS4yMzYsMTUuNjg5IEwxOS4yMzUsMTUuNjkwIEMxOS43MDYsMTYuMTQ1IDIwLjAwMCwxNi43ODIgMjAuMDAwLDE3LjQ4OSBDMjAuMDAwLDE3LjQ5MSAyMC4wMDAsMTcuNDkzIDIwLjAwMCwxNy40OTQgQzIwLjAwMCwxNy40OTYgMjAuMDAwLDE3LjQ5OCAyMC4wMDAsMTcuNTAwIEMyMC4wMDAsMTguMjA3IDE5LjcwNiwxOC44NDQgMTkuMjM1LDE5LjI5OSBMMTkuMjM2LDE5LjMwMCBMNC4yMzYsMzQuMzAwIEw0LjIzNSwzNC4yOTkgQzMuNzg1LDM0LjczMyAzLjE3NCwzNS4wMDAgMi41MDAsMzUuMDAwIEMxLjExOSwzNS4wMDAgMC4wMDAsMzMuODgxIDAuMDAwLDMyLjUwMCBDMC4wMDAsMzEuODI2IDAuMjY3LDMxLjIxNSAwLjcwMSwzMC43NjUgTDAuNzAwLDMwLjc2NCBaIi8+Cjwvc3ZnPgo=" style="height: 16px;">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ModalStoreDelivery",
        props: {
            'stores': {
                type: Array,
                default: [],
            },
            'chosenStore': {
                type: Function,
            }
        },
        data: () => {
            return {
            }
        },
        created: function () {
          if (this.stores.length > 0) {
            //this.stores.sort(this.dynamicSort("-name"));
          }
        },
        mounted () {
        },
        methods: {
          dynamicSort(property) {
            let sortOrder = 1;

            if (property[0] === "-") {
              sortOrder = -1;
              property = property.substr(1);
            }

            return function (a, b) {
              if (sortOrder === -1) {
                return b[property].localeCompare(a[property]);
              } else {
                return a[property].localeCompare(b[property]);
              }
            }
          }
        }
    }
</script>

<style scoped>
     .store_logo{
         float: left;
         width: 150px;
         height: auto;
     }
    .store_distance{
        padding-left: 10px;
    }
</style>
