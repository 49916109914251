<template>
    <div class=" en-GB app-bg">
        <div class="container mt-20 mb-40">
            <div class="font-open-sans-bold uppercase text-center relative mb-20">
                <h1 class="px-40">{{$t('common.title_fqas')}}</h1>
                <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
            </div>
            <div class="p-20 bg-white">
                <div class="md:flex">
                    <section class="p-10">
                        <h1>FAQ</h1>
                        <div id="about-container">
                            <ol>
                                <li v-for="(item, indexJ) in dataJson" :key="indexJ">
                                    <p class="question">{{item.question}}</p>
                                    <div class="answer" v-html="item.answer">
                                        <p>
                                            {{item.answer}}
                                        </p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    /* eslint-disable */
    export default {
        name: "content-content",
        components: {
        },
        data() {
            return {
                title: '',
                dataJson: [
                    {
                        question: 'Bagaimana Brand Story dari Pizza Hut Indonesia?',
                        answer: 'Cerita kami dimulai di tahun 1958, di kota Wichita. Saat itu dua orang bersaudara bernama Dan serta Frank Carney berinisiatif membuat sebuah kedai Pizza sederhana. Ternyata, Pizza yang mereka bikin disukai banyak orang hingga akhirnya menyebar ke seluruh Dunia, termasuk ke Indonesia.</br> Di Indonesia, Restoran pertama kami dibuka di gedung Djakarta Theatre pada tahun 1984. Dalam waktu cukup singkat, Pizza Hut menyebar ke seluruh Indonesia, sampai ada lebih dari 500 outlet kami dari ujung barat ke ujung timur negri. Keren kan? Nah resep rahasia di balik semua itu adalah service terbaik dan inovasi! </br> Pizza Hut Indonesia terdiri dari Pizza Hut Restaurant dan PHD dengan layanan “30 menit pasti tiba”. Karena kami mengerti, kesibukan dan terbatasnya waktu bikin kamu ingin semuanya serba cepat, jadi pesanan kamu tetap hangat dan nikmat saat tiba di tujuan.'
                    },
                    {
                        question: 'Apakah menu Pizza Hut Restaurant sama dengan yang ada di PHD?',
                        answer: 'Menu yang ditawarkan oleh Pizza Hut Restaurant dan PHD ada sedikit perbedaan untuk topping pizza, ukuran, snacks / appetizer. Tetapi untuk pelayanan dan kualitas makanan, kami selalu berikan yang terbaik buat kamu. Pilih outlet yang anda inginkan untuk melihat menu lebih detail.'
                    },
                    {
                        question: 'Apakah Pizza Hut Indonesia memiliki sertifikasi halal?',
                        answer: 'Semua bahan yang digunakan di Pizza Hut Restaurant dan PHD adalah bahan yang Halal dan sudah mendapatkan persetujuan penggunaan dari LPPOM MUI terdaftar dengan No. Sertifikasi 00160005580799.'
                    },
                    {
                        question: 'Apa keuntungan menggunakan Aplikasi dan Website Pizza Hut Indonesia?',
                        answer: 'Dengan menggunakan aplikasi dan website Pizza Hut Indonesia, kamu mendapatkan banyak keuntungan diantaranya: (1) promo menarik yang hanya akan kamu dapatkan jika kamu memesan melalui aplikasi dan website. (2) kamu juga akan mendapatkan beragam voucher menarik dari Tier kamu salah satunya voucher Happy Birthday setiap kamu berulang tahun. Tidak hanya itu, (3) setiap kali kamu melakukan pembelian dari akun member yang terdaftar di Pizza Hut Indonesia, kamu akan menghasilkan slices yang dapat ditukarkan dengan menu-menu pilihan dan kamu berkesempatan untuk memenangkan hadiah dari Treasure Hut berupa Merchandise atau voucher produk Pizza Hut. Melalui aplikasi dan website juga, (4) pesananmu dapat kamu lacak dengan fitur order tracker. (5) Jika kamu ingin memesan pesanan yang kamu pesan sebelumnya tanpa perlu repot, fitur reorder dengan satu kali klik sebagai solusi. (6) Kamu pun tanpa perlu repot mencari outlet terdekat, karena dengan aplikasi dan website Pizza Hut Indonesia, kami menghubungkan Pizza Hut Restaurant dan PHD dengan jangkauan paling dekat terhadap lokasimu. '
                    },
                    {
                        question: 'Apa itu aplikasi dan website Pizza Hut Indonesia?',
                        answer: 'Aplikasi dan website Pizza Hut Indonesia adalah layanan pemesanan pizza berbasis digital melalui desktop, handphone atau tablet. Di dalam aplikasi dan website terdapat 2 pilihan untuk dapat menikmati menu terbaik dari kami yaitu pemesanan dengan delivery tanpa perlu repot pizza sampai di depan rumah. Kedua yaitu pemesanan takeaway di outlet pilihanmu sehingga kamu dapat menyesuaikan waktu sesuai kebutuhan tanpa perlu antri. Selain itu, kamu juga bisa pilih pemesanan dari PHD dan Pizza Hut Restaurant.'
                    },
                    {
                        question: 'Apa saja ketentuan mendaftar di aplikasi dan website?',
                        answer: 'Cukup dengan memiliki alamat email dan nomor telepon, kamu bisa langsung mendaftarkan diri ke aplikasi atau website Pizza Hut Indonesia.'
                    },
                    {
                        question: 'Dimana sajakah outlet Pizza Hut Restaurant dan PHD?',
                        answer: 'Pizza Hut Indonesia saat ini terdiri dari 500 outlet yang tersebar di seluruh Indonesia.'
                    },
                    {
                        question: 'Pukul berapakah outlet di Pizza Hut Restaurant dan PHD buka dan tutup?',
                        answer: 'Jam operasional Pizza Hut Restaurant mulai pukul 10:00 s/d 23:00 WIB/WITA (last order delivery pukul 22:30 WIB/WITA) hanya sebagian restaurant aja dan sebagian Pizza Hut Restaurant tutup pukul 21:00 WIB/WITA dan 22:00 WIB/WITA. Sedangkan jam operasional untuk PHD mulai pukul 10:00 s/d 23:00 WIB/WITA (last order delivery pukul 22:30 WIB/WITA) hanya sebagian outlet saja dan sebagian outlet PHD tutup pukul 02:00 WIB (last order delivery pukul 01:30 WIB).'
                    },
                    {
                        question: 'Bagaimana cara melakukan pemesanan?',
                        answer: 'Pemesanan dapat dilakukan dengan cara takeaway atau delivery yang bisa dilakukan lewat pesan online dengan aplikasi Pizza Hut Indonesia atau website www.pizzahut.co.id ataupun telepon ke call center 1500 600.'
                    },
                    {
                        question: 'Bagaimana cara melakukan pemesanan Takeaway?',
                        answer: 'Pemesanan Takeaway dapat kamu lakukan melalui aplikasi atau website Pizza Hut Indonesia dengan memilih layanan "Takeaway" di home page aplikasi atau website Pizza Hut Indonesia kemudian masukan alamat pemesanan kamu terlebih dahulu dan pilih outlet terdekat yang ingin kamu tuju. Lalu, tentukan waktu pengambilan pesanan kamu. Pilih menu yang akan kamu pesan dan lakukan pembayaran. Proses pemesanan sudah selesai, kamu hanya perlu pergi dan mengambil pesanan di outlet yang kamu pilih di jam yang kamu tentukan tersebut.'
                    },
                    {
                        question: 'Seberapa jauh jangkauan pengantaran dan berapa biaya delivery di Pizza Hut Indonesia?',
                        answer: 'Pizza Hut Indonesia tidak ada jarak tempuh per kilometer, hanya saja untuk delivery jarak tempuh dari outlet adalah 12 menit sampai ke tempat customer agar dapat menjaga suhu dan kualitas produk tetap fresh. Saat ini untuk dapat mengetahui apakah alamat kamu termasuk ke dalam wilayah pengantaran kami, kamu dapat coba memasukan alamat lengkap kamu pada aplikasi atau website Pizza Hut Indonesia. Apabila hasil menunjukan bahwa store tidak ditemukan maka kamu akan dialihkan untuk memilih layanan takeaway di outlet Pizza Hut Indonesia terdekat. Jika berhasil, maka biaya delivery adalah sebesar Rp. 16,000,- selama alamat kamu masih terjangkau oleh outlet Pizza Hut Indonesia. Kamu juga akan mendapatkan gratis ongkos kirim apabila pesanananmu memenuhi syarat dan ketentuan yang berlaku dari voucher tersebut.'
                    },
                    {
                        question: 'Apakah PHD menjamin pesanan tiba dalam 30 menit? Bagaimana jika terlambat?',
                        answer: 'PHD menerapkan sistem SISO (Speed Inside Safety Outside ) untuk menjamin semua pesanan dapat diantarkan dalam 30 menit dalam keadaan hangat atau GRATIS 1 Pizza. Jika pesanan terlambat, customer akan diberikan 1 buah voucher pizza yang bisa ditukarkan langsung tanpa minimal pembelian dengan masa berlaku 30 hari setelah pembelian. Penukaran dapat dilakukan melalui take away atau delivery melalui aplikasi, website atau datang langsung ke outlet PHD.'
                    },
                    {
                        question: 'Mengapa area saya tidak masuk wilayah antaran Pizza Hut Indonesia?',
                        answer: 'Pizza Hut Indonesia berkomitmen untuk tetap menjaga kualitas produk dan memenuhi kecepatan waktu pengantaran sehingga membuat wilayah antar terbatas.'
                    },
                    {
                        question: 'Apakah di outlet PHD tersedia untuk fasilitas dine-in (makan di tempat)?',
                        answer: 'PHD hanya menyediakan layanan Takeaway (pesan bawa) dan Delivery (pesan antar). Jika kamu ingin Eat In (makan di tempat) bisa informasikan kepada Staff kami di outlet hanya saja PHD tidak dapat menyediakan alat makan.'
                    },
                    {
                        question: 'Bagaimana cara cek status pesanan?',
                        answer: 'Setelah kamu berhasil melakukan pemesanan melalui aplikasi atau website Pizza Hut Indonesia, pesananmu dapat kamu lacak melalui layanan Order Tracker setelah kamu berhasil melakukan pesanan, kamu bisa juga melacak pesanan kamu dengan klik Order Tracker pada home page aplikasi atau webiste. Status pesananmu akan berubah secara otomatis ke dalam preparing order, cooking time, delivery hingga sampai pada tujuan.'
                    },
                    {
                        question: 'Apa saja metode pembayaran yang dapat digunakan?',
                        answer: 'Untuk pemesanan online, kamu dapat membayar pesanan melalui dua cara yaitu Online Payment dan Payment on Delivery. Online Payment terdiri dari Credit Card, BCA Virtual Account, Shopeepay/Shopeepaylater, OVO dan GoPay. Sedangkan, Payment on Delivery yaitu pembayaran melalui Delivery Man yang mengantarkan jika kamu memilih layanan Delivery yaitu terdiri dari Swipe on Delivery melalui mesin EDC dan pembayaran secara tunai.'
                    },
                    {
                        question: 'Bagaimana cara melihat daftar voucher yang saya miliki?',
                        answer: 'Di aplikasi atau website Pizza Hut Indonesia, kamu dapat melihat daftar voucher yang kamu miliki di dihalaman Hut Rewards dan pilih “Vouchers”.'
                    },
                    {
                        question: 'Voucher apa saja yang akan saya dapatkan?',
                        answer: 'Semakin tinggi tier, semakin banyak keuntungan yg didapatkan. Berbagai voucher menarik setiap tier Fan - Lover - Mania.'
                    },
                    {
                        question: 'Apa syarat untuk bisa mendapatkan voucher tersebut?',
                        answer: 'Untuk tier Fan akan mendapatkan voucher pengguna baru/pengguna yang baru pertama kali register/daftar di aplikasi atau website Pizza Hut Indonesia serta tidak memiliki akun email dan nomor handphone yang sama dengan yang didaftarkan di aplikasi atau website Pizza Hut Indonesia. Selain itu, bisa juga didapatkan dengan melakukan transaksi melalui aplikasi dan website Pizza Hut Indonesia dalam 365 hari terakhir dengan rincian sebagai berikut: Fan (Rp0,- sampai Rp499.000,-), Lover (Rp500.000,- sampai Rp1.499.999,-) dan Mania (>=Rp1.500.000,-).'
                    },
                    {
                        question: 'Apakah voucher yang saya miliki di aplikasi Pizza Hut Indonesia dapat digunakan di website Pizza Hut Indonesia?',
                        answer: 'Voucher yang kamu miliki di aplikasi Pizza Hut Indonesia akan secara otomatis muncul di website Pizza Hut Indonesia dengan menggunakan akun email atau nomor handphone yang didaftarkan di website atau aplikasi Pizza Hut Indonesia.'
                    },
                    {
                        question: 'Bagaimana jika ingin menukarkan voucher keterlambatan jaminan 30 menit pasti tiba dengan aplikasi atau website Pizza Hut Indonesia?',
                        answer: 'Penukaran voucher keterlambatan bagi customer yang sudah terdaftar menjadi member maka E-voucher akan diberikan secara langsung melalui akun customer dan akan dikirimkan notifikasi melalui Aplikasi Pizza Hut Indonesia. Dan bagi customer yang belum terdaftar menjadi member akan diberikan berupa Unique Code melalui WhatsApp Pizza Hut Rewards.</br> E-voucher jaminan pengiriman 30 menit pasti tiba ini berlaku selama 30 hari dari tanggal penerbitan.'
                    },
                    {
                        question: 'Bagaimana cara melihat jumlah slices saya?',
                        answer: 'Di aplikasi atau website Pizza Hut Indonesia, kamu dapat melihat jumlah slices yang kamu miliki di halaman Pizza Hut Rewards pada bagian kanan atas.'
                    },
                    {
                        question: 'Bagaimana cara mendapatkan slices dari website?',
                        answer: 'Lakukan transaksi sebanyak-banyaknya melalui aplikasi atau website Pizza Hut Indonesia maka setiap kelipatan Rp10.000,- kamu akan mendapatkan 1 Slices.'
                    },
                    {
                        question: 'Bagaimana cara menukarkan slices?',
                        answer: 'Kamu dapat menukarkan slices yang kamu miliki di halaman Pizza Hut Rewards dibawah tier kamu kemudian pilih menu yang ingin kamu tukarkan, pastikan kamu memiliki jumlah slices yang sesuai dengan menu pilihan yang akan kamu tukarkan. Setelah itu, tentukan alamat pengiriman kamu jika Delivery atau pilih outlet terdekatmu jika Takeaway. Biaya delivery di luar dari menu yang ditukarkan ya, sebagai contoh jika kamu hendak menukarkan 100 Slices untuk Pan Meat Lovers maka kamu perlu membayar biaya deliverynya sesuai jarak lokasi tempat kamu menentukan pesanan. Pilih waktu pengiriman atau pengambilan pesanan, jika sesuai dengan jumlah slices yang kamu miliki maka, slices kamu akan berhasil ditukarkan pada saat checkout pembayaran.'
                    },
                    {
                        question: 'Slices saya tidak bertambah, apa yang harus dilakukan?',
                        answer: 'Kamu bisa Log out terlebih dahulu dari aplikasi atau website Pizza Hut Indonesia, dan lakukan log in ulang. Apabila slices kamu masih belum bertambah, tunjukkan kembali ke kasir atau hubungi customer service kami di 1500 600.'
                    },
                    {
                        question: 'Bagaimana perhitungan jumlah transaksi untuk menentukan Tier?',
                        answer: 'Perhitungan Tier ini adalah transaksi Anda melalui aplikasi dan website Pizza Hut Indonesia dalam 365 hari terakhir yang merupakan akumulasi dihitung selama satu tahun bukan tahun berjalan atau berdasarkan pergantian tahun.'
                    },
                    {
                        question: 'Apakah setelah pembelanjaan tier bisa langsung naik?',
                        answer: 'Tier akan otomatis naik apabila transaksi sudah memenuhi syarat dan ketentuan masing-masing tier Fan (Rp0,- sampai Rp499.000,-), Lover (Rp500.000,- sampai Rp1.499.999,-) dan Mania (>=Rp1.500.000,-). Selain itu, voucher juga akan otomatis diberikan ketika upgrade tier dan masing-masing tier akan mendapatkan voucher yang berbeda-beda, semakin tinggi tier maka semakin banyak keuntungan yang didapatkan.'
                    },
                    {
                        question: 'Apakah tier bisa turun?',
                        answer: 'Bisa, tier akan turun apabila akumulasi transaksi dalam 365 hari terakhir tidak memenuhi syarat dan ketentuan untuk tetap pada tier sebelumnya.'
                    },
                    {
                        question: 'Apakah jika tier turun lalu setelah naik akan mendapatkan voucher kembali?',
                        answer: 'Ya, jika turun ke Lover atau Fan kembali lalu melakukan transaksi dan naik tier maka kamu akan mendapatkan kembali voucher ketika naik level tier.'
                    },
                    {
                        question: 'Apakah perlu claim untuk mendapatkan voucher?',
                        answer: 'Voucher akan diinject secara otomatis ke akun kamu.'
                    },
                    {
                        question: 'Apakah ada batasan voucher atau merchandise yang didapat?',
                        answer: 'Tidak ada batasan dalam mendapatkan voucher atau merchandise dari Treasure Hut karena Treasure Hut yang didapatkan berdasarkan transaksi bukan berdasarkan banyaknya item atau nominal sales dalam 1 transaksi.'
                    },
                    {
                        question: 'Apakah ada batas waktu dalam menukarkan Treasure Hut?',
                        answer: 'Batas waktu dalam menukarkan hadiah Treasure Hut adalah 7 hari dari Treasure Hut didapatkan, jika tidak ditukarkan maka status hadiah otomatis berubah menjadi Expired.'
                    },
                    {
                        question: 'Berapa lama voucher produk yang didaparkan dari Treasure Hut?',
                        answer: 'Batas waktu penggunaan voucher 14 hari dari saat voucher ditukarkan pada Treasure Hut, jika dalam 14 hari voucher tidak ditukarkan maka voucher akan hilang dari daftar voucher.'
                    },
                    {
                        question: 'Berapa lama waktu kirim merchandise?',
                        answer: 'Merchandise akan dikirimkan dengan estimasi dalam 21 hari kerja sejak kamu melakukan klaim'
                    },
                    {
                        question: 'Berapa banyak voucher yang bisa digunakan dalam satu transaksi?',
                        answer: 'Penggunaan voucher tidak bisa digabungkan dengan voucher lain selain gratis ongkos kirim, namun jika kamu ingin menggunakan voucher gratis ongkos kirim tetap mengikuti syarat dan ketentuan minimal transaksi.'
                    },
                    {
                        question: 'Apakah dalam satu hari bisa menggunakan beberapa voucher?',
                        answer: 'Kamu dapat menggunakan beberapa voucher yang kamu miliki dalam satu hari dengan transaksi terpisah.'
                    },
                    {
                        question: 'Bagaimana jika ingin melakukan pemesanan untuk orang lain?',
                        answer: 'Jika melakukan pemesanan untuk orang lain, bisa dilakukan dengan cara pemesanan melalui aplikasi atau website Pizza Hut Indonesia dengan memasukkan alamat orang tersebut dan melakukan pembayaran secara online payment melalui Credit Card, BCA Virtual Account, Shopeepay/Shopeepaylater, OVO dan GoPay.'
                    },
                    {
                        question: 'Bagaimana jika ingin mengajukan kerjasama atau sponsorship?',
                        answer: 'Untuk penawaran kerjasama dan sponsorship dengan Pizza Hut Indonesia kamu dapat mengirimkan proposal dengan File Attachment max 2MB melalui email csc@phd.co.id atau customerservice@pizzahut.co.id.'
                    },
                    {
                        question: 'Bagaimana jika ingin bertanya seputar lowongan pekerjaan?',
                        answer: 'Untuk lowongan pekerjaan di Pizza Hut Indonesia dapat datang langsung ke outlet terdekat atau bisa kirimkan CV melalui email ke recruitment@pizzahut.co.id.'
                    }
                ]
            }
        },
        computed: {
        },
        mounted() {
        },
        methods: {
        }
    }
</script>
