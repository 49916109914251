import types from '../../mutationTypes'

export default {
  [types.CART_DETAIL]: state => state.cart,
  [types.CART_GET_CART_UUID]: state => state.cart.information.cart_uuid,
  [types.CART_GRANT_TOTAL]: state => {
    let total = state.cart.information.total || 0;
    if(state.cart.information.discount > 0) {
      total -= state.cart.information.discount * 1;
    }
    return total > 0 ? total : 0;
  },
  [types.CART_COUNT]: state => {
    let count = 0;
    if (state.cart.products) {
      for (let item of state.cart.products) {
        count += item.quantity;
      }
    }
    return count;
  },
  [types.CART_BEFORE_CHECKOUT_COUNT]: state => {
    let count = 0;
    if (state.cart.products) {
      for (let item of state.cart.products) {
        if (item.is_checkout == 0) {
          count += item.quantity;
        }
      }
    }
    return count;
  },
  [types.CART_GET_PROMOTIONS]: state => state.cart.promotions,
  [types.CART_GET_SUGGESTIONS]: state => state.suggestions,
  [types.CART_GET_TOTAL]: state => {
    let total = state.cart.information.total || 0;
    if(state.cart.information.total > 0) {
      total -= state.cart.information.delivery_fee;
    }
    return total > 0 ? total : 0;
  },

}