<template>
  <div class="container-single bg-white m-auto flex flex-center pt-20" style="max-width: 1140px; height: 100%;">
    <div class="content-slides" style="margin: 0 auto;width: 50%">
      <carousel-menu-price v-if="images"
                           :items="images"
                           :settings="carouselSettings">
      </carousel-menu-price>
    </div>
  </div>
</template>

<script>
import CarouselMenuPrice from '../components/shared/CarouselMenuPrice';


export default {
  name: "MenuPrice",
  components: {CarouselMenuPrice},
  data() {
    return {
      carouselSettings: {
        autoplay: true,
        items_per_set: "1",
        autoplay_speed: "4000",
        items_to_scroll: "1",
        infinite_scrolling: true,
        show_pagination_dots: false,
        show_pagination_arrows: true
      },
      images: [],
      defaultKey: '6B86B273FF34FCE19D6B804EFF5A3F5747ADA4EAA22F1D49C01E52DDB7875B4B',
      key: '',
      menuPrices: {
        "6B86B273FF34FCE19D6B804EFF5A3F5747ADA4EAA22F1D49C01E52DDB7875B4B": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Desktop/1.+NEO+SOHO+COMPLETE+AUG+Drinks+and+cover-03.jpg",
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Desktop/2.+NEO+SOHO+COMPLETE+AUG-01.jpg",
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Desktop/3.+NEO+SOHO+COMPLETE+AUG-02.jpg",
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Desktop/4.+NEO+SOHO+COMPLETE+AUG-03.jpg",
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Desktop/5.+NEO+SOHO+COMPLETE+AUG+Drinks+and+cover-01.jpg"
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Mobile/1.+NEO+SOHO+COMPLETE+AUG+Drinks+and+cover-03.jpg",
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Mobile/2.+NEO+SOHO+COMPLETE+AUG-01.jpg",
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Mobile/3.+NEO+SOHO+COMPLETE+AUG-02.jpg",
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Mobile/4.+NEO+SOHO+COMPLETE+AUG-03.jpg",
            this.$const.LINK_STATIC_PHR + "images/Landing/Menu/Mobile/5.+NEO+SOHO+COMPLETE+AUG+Drinks+and+cover-01.jpg"
          ]
        },
        "D4735E3A265E16EEE03F59718B9B5D03019C07D8B6C51F90DA3A666EEC13AB35": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/006jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/DESKTOP/20.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_2/MOBILE/20.jpg",
          ]
        },
        "4E07408562BEDB8B60CE05C1DECFE3AD16B72230967DE01F640B7E4729B49FCE": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/DESKTOP/20.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_3/MOBILE/20.jpg",
          ]
        },
        "4B227777D4DD1FC61C6F884F48641D02B4D121D3FD328CB08B5531FCACDABF8A": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/DESKTOP/20.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220509/QR_4/MOBILE/20.jpg",
          ]
        },
        "DD4E14B2ABF243AB1C2037C97DC716F1D6872D92C0326DB73EE693BE5C8AAE01": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/DESKTOP/13.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_5/MOBILE/13.jpg",
          ]
        },
        "D036F054970B71C0250447D551F982D4749CA716C308D6D60F5BE40357591AF5": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/DESKTOP/13.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_6/MOBILE/13.jpg",
          ]
        },
        "209077FB6DC9C443567FA0F155553BEA08D6295440D917105071CDD5F3847064": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/DESKTOP/13.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_7/MOBILE/13.jpg",
          ]
        },
        "12X5PWPTP24C6EQEZ812AAI6JF3UP4SJUVJI1HNMLHHLYZZ7GQE911LEKHO54UXL": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/DESKTOP/7.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220110/QR_8/MOBILE/7.jpg",
          ]
        },
        "KCGPN8JVEB6UOVPYNWTZSONJGCVJ50FRY5YJOCO6II76RRU54E27PJ2WQY9H5TSX": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/DESKTOP/6.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210528/QR_9/MOBILE/6.jpg",
          ]
        },
        //QR_10
        "5WCXS9DY3RMY43X2YUFDFW4WGFNVAVEK8QXM2525VZLVTQZ6QCLGCCL73G94B4BB": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/DESKTOP/001-LIMO-Alt-2-QR-Desktop-1920-x-2716.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/DESKTOP/002-LIMO-Alt-2-QR-Desktop-1920-x-2716.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/DESKTOP/2.jpg Desktop New M.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/DESKTOP/3.jpg Desktop New M.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/DESKTOP/4.jpg Desktop New M.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/DESKTOP/5.jpg Desktop New M.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/DESKTOP/6.jpg Desktop New M.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/MOBILE/001-LIMO-Alt-2-QR-Mobile-1440-x-2037.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/MOBILE/002-LIMO-Alt-2-QR-Mobile-1440-x-2037.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/MOBILE/2.jpg Mobile New M.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/MOBILE/3.jpg Mobile New M.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/MOBILE/4.jpg Mobile New M.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/MOBILE/5.jpg Mobile New M.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+M/MOBILE/6.jpg Mobile New M.jpg",
          ]
        },
        //QR_11
        "EKVM8CT2EA355REAJK7L27NQQ37E7LPMYSP7C8E9ZN2BFH2YCV3PN2RGQ8VSFB36": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/DESKTOP/001-LIMO-Alt-2-QR-Desktop-1920-x-2716.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/DESKTOP/002-LIMO-Alt-2-QR-Desktop-1920-x-2716.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/DESKTOP/2.jpg Desktop M%2B.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/DESKTOP/3.jpg Desktop M%2B.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/DESKTOP/4.jpg Desktop M%2B.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/DESKTOP/5.jpg Desktop M%2B.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/DESKTOP/6.jpg Desktop M%2B.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/MOBILE/001-LIMO-Alt-2-QR-Mobile-1440-x-2037.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/MOBILE/002-LIMO-Alt-2-QR-Mobile-1440-x-2037.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/MOBILE/2.jpg Mobile M%2B.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/MOBILE/3.jpg Mobile M%2B.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/MOBILE/4.jpg Mobile M%2B.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/MOBILE/5.jpg Mobile M%2B.jpg",
            this.$const.LINK_STATIC_PHR + "images/20210624/FCDC+MPlus/MOBILE/6.jpg Mobile M%2B.jpg",
          ]
        },

        //QR_12 FCDC M+++
        "15800D55CC995712F05DD4ED9E97DF0D4F39FA09998D0DB148DFBDE69AE139ED": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/DESKTOP/16.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/006.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220616/QR_12/MOBILE/16.jpg",
          ]
        },
        //QR_13 FCDR M+++
        "F9685AE7A74323A5FF774034F72657BB56A0DCD5E494BE5EDBF4723285CD7922": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/DESKTOP/20.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/003.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/004.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/005.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220414/QR_13/MOBILE/20.jpg",
          ]
        },
        //QR_14 R+
        "3D06D5F723C1B60A8BCAD2ACFB1503F49BC480E08070A72CA165A76FC5F1C8A2": {
          "web": [
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/20.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/21.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/22.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/23.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/DESKTOP/24.jpg",
          ],
          "mobile": [
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/00.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/001.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/002.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/1.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/2.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/3.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/4.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/5.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/6.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/7.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/8.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/9.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/10.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/11.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/12.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/13.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/14.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/15.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/16.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/17.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/18.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/19.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/20.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/21.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/22.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/23.jpg",
            this.$const.LINK_STATIC_PHR + "images/20220613/QR_14/MOBILE/24.jpg",
          ]
        }
      }
    }
  },
  mounted() {
    this.key = this.$route.params.slug || this.defaultKey
    this.getData();
  },
  methods: {
    getData() {

      var vm = this;
      vm.getmenuPricesByKey(this.key, response => {
        if (response.data && !vm.emptyVariable(response.data)) {
          vm.images = vm.isMobile() ? response.data.mobile : response.data.web;
        }
      })
    },
    getmenuPricesByKey(key, success) {
      let result = {};
      if (this.menuPrices.hasOwnProperty(key)) {
        result = this.menuPrices[key] || {};
      }
      return success({data: result});
    },
  }
}
</script>

<style>
.container-single .VueCarousel-navigation-button.VueCarousel-navigation-next, .container-single .VueCarousel-navigation-button.VueCarousel-navigation-prev {
  top: 37% !important;
  color: #fff;
  background-color: rgba(0, 0, 0, .5);
  border: 0;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  position: absolute;
  padding: 0 !important;
  -webkit-transition: all .15s;
  transition: all .15s;
}

.container-single .VueCarousel-navigation-button.VueCarousel-navigation-next {
  right: 10% !important;
}

.container-single .VueCarousel-navigation-button.VueCarousel-navigation-prev {
  left: 10% !important;
}

@media screen and (max-width: 768px) {
  .container-single .content-slides {
    width: 100% !important;
  }

}
</style>
