<template>
  <div ref="googleLoginBtn" class="btn-google" id="google-sign-in" />

</template>

<script >
import jwt_decode from "jwt-decode"
    export default {
      name: 'GoogleLoginBtn',
      created() {
        window.onload = function() {
          let frameElement = document.getElementsByTagName("iframe");
          let doc = frameElement.contentDocument;
          doc.body.innerHTML = doc.body.innerHTML + '<style> #container {display: list-item !important}   div[role="buton"] {max-width: 100%}</style>';
        }
      },
      mounted() {
        window.google.accounts.id.initialize({
          client_id: '962674846498-phjcue8s0thvjf7g9jnqkqum3qpucoj9.apps.googleusercontent.com',
          callback: this.handleCredentialResponse,
          auto_select: false,
          // allowed_parent_origin: true,
        })
        window.google.accounts.id.renderButton(
            this.$refs.googleLoginBtn, {
              text: 'signup_with', // or 'signup_with' | 'continue_with' | 'signin'
              size: 'large', // or 'small' | 'medium'
              width: '100%', // max width 400
              theme: 'outline', // or 'filled_black' |  'filled_blue'
              logo_alignment: 'center' // or 'center'
            }
        )
      },
      methods: {
         handleCredentialResponse(response) {
          const responsePayload = jwt_decode(response.credential);
          // console.log(responsePayload)
          const promData =  new Promise((resolve, reject) => {
            if (responsePayload) {
              return resolve({
                "name": responsePayload.name,
                "first_name": responsePayload.given_name,
                "last_name": responsePayload.family_name,
                "email": responsePayload.email,
                "token": response.credential,
                "keep_otp": 1
              })
            } else {
              console.log(response)
              return reject({
                message: 'Google sign in failed.'
              })
            }
          })

          this.$emit('handleUserInfo', promData)
        }
      },
    }
</script>

<style>
  .btn-google span {
    color: #da291c !important;
    font-weight: bold !important;
    font-size: 16px;
  }
  .btn-google iframe {
    width: 100% !important;
    margin: 0 !important;
  }
  div#container {
    display: list-item !important;
  }
  div[role="buton"] {
    max-width: 100%;
  }
  iframe #container {
    display: list-item !important
  }
  iframe div[role="buton"] {
    max-width: 100%
  }
</style>
