<template>
    <div class="list-item">
        <div class="list-item__image "><img :src="product.image" class="block" :alt="product.name" :title="product.name" width="285"></div>
        <p class="typography-4 list-item__name flex-1 px-10 pt-10">
            {{product.name}}
            <span class="ml-2">&nbsp;</span>
<!--            <span class=" inline-flex align-middle">-->
<!--                                                <span class="flex font-light mr-5"><i title="Vegetarian" class="icon-veg" style="height: 18px; width: 18px; background-size: contain;"></i></span>-->
<!--                                            </span>-->
        </p>
        <p class="typography-6 list-item__desc flex-1 ">{{product.description}} </p>
        <div class="mt-auto">
            <div class="m-10">
                <div class="mb-10 " v-if="listCrusts.length > 0">
                    <div class="relative">
                        <label class=" items-center" for="dropdown_64">
                            <div class="pr-5 pb-5"><span class="">{{$t('menus.select_crust')}}</span></div>
                            <v-select v-if="listCrusts.length > 1" :options="listCrusts" v-model="selectedCrustItem" id="dropdown_64" class="w-full typography-6 bold mb-10 v-select__disable_remove" name="SelectCrust">
                            </v-select>
                            <v-select v-else :options="listCrusts" v-model="selectedCrustItem" id="dropdown_64" class="w-full typography-6 bold mb-10 v-select__disable" name="SelectCrust">
                            </v-select>
                        </label>
                    </div>
                    <div class="relative" v-if="listSizes.length > 0">
                        <label class=" items-center" for="dropdown_65">
                            <div class="pr-5 pb-5"><span class="">{{$t('menus.select_size')}}</span></div>
                            <v-select v-if="listSizes.length > 1" :options="listSizes" v-model="selectedSizeItem" id="dropdown_65" class="w-full typography-6 bold mb-10 v-select__disable_remove" name="SelectSize">
                            </v-select>
                            <v-select v-else :options="listSizes" v-model="selectedSizeItem" id="dropdown_65" class="w-full typography-6 bold mb-10 v-select__disable" name="SelectSize">
                            </v-select>
                        </label>
                    </div>
                    <button v-if="!emptyVariable(currentExtraCheese) && !isSecond" class="button button--md font-semibold mt-10 height-auto"
                            v-bind:class="[ !emptyVariable(hasExtraCheese) ? 'button--red' : 'button--yellow']"  @click="toggleExtraCheese">
                        <div class="w-full  whitespace-normal ">
                            {{$t('common.extra_cheese')}} (+{{$t('common.format_currency', {amount: formatCurrency(currentExtraCheese.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}})
                        </div>
                    </button>
                </div>
                <button @click="addItemIntoCart" class="button button--md button--red flex-1 font-semibold">
                    <span>{{$t('common.btn.add')}}</span>
                    <span class="w-auto" style="margin-left: 3px;" v-if="!productPoint && amount > 0">
                      {{$t('common.format_currency', {amount: formatCurrency(amount, 2), currency: $const.CURRENCY})}}
                    </span>
                    <span class="w-auto" style="margin-left: 3px;" v-else-if="productPoint && totalPoint > 0">
                      {{$t('common.format_point', {amount: formatCurrency(totalPoint, 2), currency: $const.CURRENCY})}}
                   </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';

  export default {
  name: 'combo-detail-menu',
  props: {
    'product': {
      type: Object,
      default: {}
    },
    'productPoint': {
      type: Boolean,
      default: false
    },
    'groupIndex': {
      type: Number,
      default: -1
    },
    addToDeal: {
      type: Function
    },
    'defaultCrust': {
      type: Object,
      default: {}
    },
    'isSecond': {
      type: Boolean,
          default: false
      },
    },
  mounted () {
    this.checkShowExtraCheese();
    this.initSizeCrust();
    this.calculatorAmount();
  },
  components: {
    vSelect
  },
  data () {
    return {
      amount: 0,
      totalPoint: 0,
      listCrusts: [],
      listSizes: [],
      selectedCrustItem: {},
      selectedSizeItem: {},
      extraCheese: {},
      currentExtraCheese: {},
      hasExtraCheese: {},
    }
  },
  methods: {
    checkShowExtraCheese() {
      if (this.product && this.product.toppings  && this.product.toppings.additional_toppings && this.product.toppings.additional_toppings.length > 0) {
        this.extraCheese = this.product.toppings.additional_toppings[0];
      }
    },
    toggleExtraCheese () {
      if (!this.emptyVariable(this.hasExtraCheese)) {
        this.hasExtraCheese = {}
      } else {
        this.hasExtraCheese = this.currentExtraCheese;
      }
      this.calculatorAmount();
    },
    calculatorAmount() {
      this.amount = this.product.sub_price;
      this.totalPoint = this.product.point || 0;
      let _selectedCrustItem = this.selectedCrustItem ? _.cloneDeep(this.selectedCrustItem.item) : {};
      let _selectedSizeItem = this.selectedSizeItem ? _.cloneDeep(this.selectedSizeItem.item) : {};
      if (!this.emptyVariable(_selectedCrustItem)) {
        this.amount += _selectedCrustItem.sub_price
        this.totalPoint += _selectedCrustItem.point || 0;
      }
      if (!this.emptyVariable(_selectedSizeItem)) {
        this.amount += _selectedSizeItem.sub_price
        this.totalPoint += _selectedSizeItem.point || 0;
      }

      if (!this.emptyVariable(this.hasExtraCheese)) {
        this.amount += this.hasExtraCheese.sub_price;
        this.totalPoint += this.hasExtraCheese.point || 0;
      }
    },
    initSizeCrust (defaultCrust = {}) {
        if (!this.emptyVariable(defaultCrust)){
            this.listCrusts = []
            this.listCrusts.push({
                label: defaultCrust.name,
                item: defaultCrust
            })
            this.selectedCrustItem = this.listCrusts[0];
        } else if (this.product) {
        let _listCrusts = this.product.option_groups || [];
        if (_listCrusts.length > 0) {
          for (let i in this.product.option_groups) {
            this.listCrusts.push({
              label: this.product.option_groups[i].name,
              item: this.product.option_groups[i]
            })
          }
          this.selectedCrustItem = this.listCrusts[0];
        }
      }
    },
    addItemIntoCart() {
      let _selectedCrustItem = this.selectedCrustItem ? _.cloneDeep(this.selectedCrustItem.item) : {};
      let _selectedSizeItem = this.selectedSizeItem ? _.cloneDeep(this.selectedSizeItem.item) : {};
      let _extracheese = {};
      if (!this.emptyVariable(this.hasExtraCheese)) {
        _extracheese = this.extraCheese
      }
      let hasOption = false;
      if (this.listCrusts.length >= 2) {
         hasOption = true;
      } else if (this.listCrusts.length == 1 && this.listCrusts[0].item.options && this.listCrusts[0].item.options.length > 1 ) {
        hasOption = true;
      }
      let dataProduct = {
        "crust": _selectedCrustItem ? _selectedCrustItem : {},
        "size": _selectedSizeItem ? _selectedSizeItem : {},
        "product": this.product,
        "quantity": 1,
        "hasExtraCheese": _extracheese,
        "hasOption": hasOption,
        "total": this._.cloneDeep(this.amount),
        "totalPoint": this.totalPoint,
        "toppings": {
          "removal": [],
          "addition": []
        }
      }
      this.addToDeal(dataProduct, this.groupIndex);
    },
    mappingCurrentExtraCheese () {
      if (this.emptyVariable(this.extraCheese)){
        return false;
      }
      for (let item of this.extraCheese.options) {
        if (item.uuid == this.selectedSizeItem.item.uuid) {
          this.currentExtraCheese = item;
          break;
        }
      }
    }
  },
  watch: {
    selectedCrustItem: function(newVal) {
      if (newVal && !this.emptyVariable(newVal.item)) {
        let dataListSizes = newVal.item.options || [];
        if (dataListSizes.length) {
          this.listSizes = [];
          for (let i in dataListSizes) {
            this.listSizes.push({
              label: dataListSizes[i].name,
              item: dataListSizes[i]
            })
          }
          this.selectedSizeItem = this.listSizes[0];
        }
      }
      this.calculatorAmount();
    },
    selectedSizeItem: function() {
      // reset and mapping extra cheese
      this.hasExtraCheese = {};
      this.mappingCurrentExtraCheese();
      this.calculatorAmount();
    },
    defaultCrust: function () {
        this.initSizeCrust(this.defaultCrust)
    }
  },
  computed: {
  }
}
</script>