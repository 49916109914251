<template>
  <div id="app">
    <router-view/>
    <notifications ></notifications>
    <div class="screen-loading" v-show="isShowLoading"></div>
  </div>
</template>

<script>
import '@/assets/style.css';
import '@/assets/custom.css';
import menuAPI from './services/menu'
import types from './store/mutationTypes'
import {
  mapActions,
  mapState,
  mapGetters
} from 'vuex'
import manthan from "./services/manthan";


/* eslint-disable */
export default {
  created() {
    this.checkAuth()
    window.addEventListener('beforeunload', this.leaving)
  },
  destroyed() {
    this.$root.$off('triggerInitCategories');
  },
  mounted() {
    if (!this.$const.SCREEN_NOT_FIRE_LOAD_EVENT.includes(this.$route.name)) {
      this.eventScreenLoad();
    }
    this.hideLoading();
    this.initCategories();
    this.$root.$on('triggerInitCategories', (data) => {
      this.initCategories(data);
    });
  },
  computed: {
    ...mapGetters({
      orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
      orderLocation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
      isShowLoading:  types.IS_SHOW_LOADING,
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser: 'moduleAuth/' + types.CUSTOMER_GET_INFO,
    }),
    ...mapState({
      listCategories: 'categories'
    })
  },
  methods: {
    ...mapActions({
      menuCategoryList: types.SET_MENU_CATEGORY_LIST,
      setCurrentCategory: types.SET_CURRENT_CATEGORY,
      hideLoading: types.HIDE_LOADING,
      setDefaultCategory: types.SET_DEFAULT_CATEGORY,
      restLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
      resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
      resetOrder: 'moduleOrder/' +  types.ORDER_RESET_ITEM
    }),
    leaving() {
      if(this.orderLocation.is_qr_code){
        this.restLocalisation();
        this.resetCart();
        this.resetOrder();
      }
    },
    initCategories(params) {
      let data = {
        //type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        store_type_id: (this.orderLocation.storeType) ? this.orderLocation.storeType : this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)] ,
      }
      var vm = this
      menuAPI.categories(data, function (response) {
        let categoriesData = response.data;
        categoriesData.push(vm.$const.COUPON_CAT);
        vm.handleSetDefaultCategory(categoriesData, params);
        vm.menuCategoryList(categoriesData);
      }, function (e) {
        vm.handleSetDefaultCategory([], params);
        vm.menuCategoryList([]);
      })
    },
    handleSetDefaultCategory (data, params) {
      let slug = this.$route.params.slug;
      let _currentSlug = '';
      let defaultSlug = '';
      for (let item of data) {
        if (item.slug === slug) {
          _currentSlug = item.slug;
        }
        if (item.slug === this.$const.CATEGORY.DEAL) {
          defaultSlug = item.slug;
        }
      }

      if (slug === '' && data.length > 0) {
          slug = data[0].slug;
      }
      if (defaultSlug === '' && data.length > 0) {
        defaultSlug = data[0].slug;
      }
      this.setDefaultCategory(defaultSlug);

      if (params && params.goDeal) {
        this.$router.push({name: 'category', params: {slug: defaultSlug}});
      } else if ( params && params.callback) {
        // if (params.callback.indexOf('http') == 0) {
        //   window.location.href = params.callback
        // } else {
        //   this.$router.push(params.callback);
        // }
        let path =  params.callback.replace(/\/\/\//g, '');

        return this.redirectInternal(this, path);
      } else if ([this.$const.ROUTE.CATEGORY, this.$const.ROUTE.COMBO_DETAIL].indexOf(this.$route.name) >= 0) {
        this.setSelectedCategory(this.$route.name, _currentSlug, data);
      }
    },
    routeChanged (to, from) {
      if (!(to.name == from.name && from.name == 'home') && !this.$const.SCREEN_NOT_FIRE_LOAD_EVENT.includes(to.name)){
        this.eventScreenLoad();
      }
      this.setSelectedCategory(to.name)
    },
    setSelectedCategory (name, _currentSlug = '', categories = []) {
      if ([this.$const.ROUTE.CATEGORY, this.$const.ROUTE.COMBO_DETAIL].indexOf(name) >= 0) {
        let slug = this.$route.params.slug;
        let listCategories = categories.length > 0 ? categories : this.listCategories;
        if (this.$const.ROUTE.COMBO_DETAIL != name) {
          if (!slug && listCategories && listCategories.length > 0) {
            this.setCurrentCategory(listCategories[0]);
            this.$router.push({'name': name, 'params': {slug: listCategories[0].slug}})
            return;
          }
          if (slug && listCategories && listCategories.length > 0) {
            for (let mc in listCategories) {
              if (listCategories[mc].slug === slug) {
                this.setCurrentCategory(listCategories[mc])
                break;
              }
            }
          }
        } else {
          for (let item of listCategories) {
            if (item.slug === this.$const.CATEGORY.DEAL) {
              this.setCurrentCategory(item);
              break;
            }
          }
        }
      }
    },
    checkAuth(){ 
      if (this.authUser) {
        this.sendManthan(this.authUser, true)
      }
    },
    sendManthan(customer){
      const now = new Date().getTime();
      let age = now - (new Date(customer.birthday).getTime());
      let ageDate = Math.abs((new Date(age).getUTCFullYear()) - 1970);
      const dataManthan = {
        'customerCode': customer.customer_id,
        'email': customer.email,
        // 'firstName': customer.first_name,
        // 'lastName': customer.last_name,
        // 'gender': (customer.gender == 2 ) ? "Female" : "Male",
        'mobile': customer.phone,
        // 'age': ageDate,
        // 'joiningDate': now,
      }
      manthan.execute(dataManthan,"eventCustomerCreate")
      manthan.setCustomer(dataManthan)
    }
  },
  watch: {
    '$route':  {
      handler: 'routeChanged',
    },
  }
}
</script>
