<template>
  <div class="bg-white" >
    <localisation-box ref="localisationBox" :url="backgroundUrl"></localisation-box>
    <div class="bg-white xs-pt-100">
        <h2 class="typography-2 capitalize py-20 mx-10 text-center heading-hr"><span>{{$t('common.popular_deal')}}</span></h2>
        <div class=" container">
            <carousel-banners v-if="banners"
                              :items="banners"
                              :settings="carouselSettings">
            </carousel-banners>
        </div>
        <div class="px-10 pb-30 container">
            <button class="button button--red button--xl mb-20" @click="viewAllDeal()">
                <span class="button-ie-fix-56">
                    {{$t('common.btn.view_all_deal')}}
                </span>
            </button>
        </div>
    </div>

  </div>
</template>

<script>
import VueGoogleAutocomplete from '../components/shared/GoogleAutocompleteCustom'
import types from '../store/mutationTypes'
import CarouselBanners from '../components/shared/CarouselBanners'
import ContentApi from '../services/content';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import { mapActions, mapGetters } from 'vuex'
import LocalisationBox from "../components/shared/LocalisationBox";
import manthan from "../services/manthan";
/* eslint-disable */
export default {
  components: {
    LocalisationBox,
    'vue-google-autocomplete': VueGoogleAutocomplete,
    CarouselBanners,
    vSelect
  },
  name: 'home',
  data: function() {
    return {
      carouselSettings: {
        autoplay: true,
        items_per_set: "3",
        autoplay_speed: "4000",
        items_to_scroll: "1",
        infinite_scrolling: true,
        show_pagination_dots: false,
        show_pagination_arrows: true
      },
      banners: [],
      topBackground: [],
      backgroundUrl: '',
      store_uuid: ''
    }
  },
  created () {
    manthan.execute({}, 'eventHomePage')
  },
  mounted() {
    // this._getTopBannerPersonalize();
    this._getTopBanners();
    this.getBanners();
    // this.checkAuth();
    this.store_uuid = this.storeUuid
  },
  watch: {
      storeUuid: function(){
          this.store_uuid = this.storeUuid
      }
  },
  destroyed() {
  },
  computed: {
      ...mapGetters({
          defaultCategory:  types.GET_DEFAULT_CATEGORY,
          storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
          isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
          store: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
          authUser: 'moduleAuth/' + types.CUSTOMER_GET_INFO,
      }),
  },
  methods: {
    ...mapActions({
    }),
    // checkAuth(){
    //     // if(!this.isAuth){
    //     //     console.log('is Auth', this.isAuth)
    //     //     setTimeout(() =>{
    //     //         this.$notify({
    //     //             title: "<a href='/ph-reward' > Hai, Siap nikmati gratisannya? Join Member PH Rewards Sekarang!</a>",
    //     //             duration: 18000,
    //     //         });
    //     //     })
    //     // }
    //   if (this.authUser) {
    //     setTimeout(() => {
    //       this.sendManthan(this.authUser, true)
    //     }, 1000)
    //   }
    // },
    getBanners() {
        let vm = this;
        const store_branch = (this.store.storeBranch) ? this.store.storeBranch : '';
        ContentApi.getHomeBanner({vm: this, store_branch: store_branch}, function (response) {
            vm.banners = response.data;
        })
    },
    _getTopBanners() {
      var vm = this;
      ContentApi.getHomeTop({}, function (response) {
        vm.topBackground = response.data;
        if (vm.topBackground && vm.topBackground.length > 0) {
            const widthsize = vm.$refs.localisationBox.$refs.homeBanner.clientWidth + 200
            vm.backgroundUrl =`url('${vm.$const.LINK_STATIC_FLY}width=${widthsize}/${vm.topBackground[0].small_image}')`
            if (window.matchMedia('(min-width: 640px)').matches) {
                vm.backgroundUrl =`url('${vm.$const.LINK_STATIC_FLY}width=${widthsize}/${vm.topBackground[0].middle_image}')`
            }
            if (window.matchMedia('(min-width: 960px)').matches) {
                vm.backgroundUrl =`url('${vm.$const.LINK_STATIC_FLY}width=${widthsize}/${vm.topBackground[0].large_image}')`
            }
        }
      })
    },
    _getTopBannerPersonalize(){
      let vm = this
      const widthsize = vm.$refs.localisationBox.$refs.homeBanner.clientWidth
      let params = {
        apiKey: 'c687987e43122cd7',
        apiClientKey: 'c9691df2cc9ba842',
        sessionId: 'algtestsession884632260375',
        userId: 'algtestuser073148476646',
        ts: '1656483699142',
        vm: this,
        excludeHtml: true,
        placements: 'home_page.mobile_engage_placement_1'
      }
      if (window.matchMedia('(min-width: 640px)').matches) {
        params.placements = 'home_page.tab_engage_placement_1'
      }
      if (window.matchMedia('(min-width: 960px)').matches) {
        params.placements = 'home_page.Engage_Placement_1'
      }
      ContentApi.getPersonalizeBanner(params, function (response){
        const url = response.placements[0].creatives[0].CONTENT_IMAGE
        vm.backgroundUrl =`url('${vm.$const.LINK_STATIC_FLY}width=${widthsize}/${url}')`
      })
    },
    viewAllDeal(){
        if (this.storeUuid) {
            this.$router.push({name: 'category', params:{slug: this.defaultCategory}});
        } else {
            this.$router.push({name: 'home', query: {warning: '1', callback:'order/hot-promo'}});
            return false;
        }
    },
    // sendManthan(customer){
    //   const now = new Date().getTime();
    //   let age = now - (new Date(customer.birthday).getTime());
    //   let ageDate = Math.abs((new Date(age).getUTCFullYear()) - 1970);
    //   const dataManthan = {
    //     'customerCode': customer.customer_id,
    //     'customerEmailId': customer.email,
    //     'firstName': customer.first_name,
    //     'lastName': customer.last_name,
    //     'gender': (customer.gender == 2 ) ? "Female" : "Male",
    //     'mobileNo': customer.phone,
    //     'age': ageDate,
    //     'joiningDate': now,
    //   }
    //   manthan.execute(dataManthan,"eventCustomerCreate")
    //   manthan.setCustomer(dataManthan)
    // }
  }
}
</script>
<style>
    .vue-notification-home{
        background-color: black !important;
        opacity: 0.8;
        border-left: none !important;
    }
    .vue-notification-home > a{
        color: white !important;
    }
</style>
