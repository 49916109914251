import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './translations/en.json'
// import id from './translations/id.json'
// import constant from './constant'

Vue.use(VueI18n)
// let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
const messages = {
  en: en,
  // id: id,
}
const translation = new VueI18n({
  locale: 'en', //(locale.trim().length && locale != "/" && ['en', 'id'].indexOf(locale.trim()) >=0) ? locale : 'en',
  messages,
  fallbackLocale: 'en'
})

export default translation
