<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
              <button type="button" aria-label="Close" class="close" @click="$emit('close')"><img src="../../assets/images/icon-close--white.svg" /></button>
          </div>

          <div class="modal-body t20 text-white ">
              <div class="text-center">{{contain }}</div>
          </div>

          <div class="modal-footer text-center">
              <button type="button" class="btn button--red minw-200"  @click="$emit('close')" v-if="!(hideClose === true)">{{buttonCancel || $t('common.btn.cancel')}}</button>
              <button type="button" class="btn button--red minw-200" v-if="!(hideOK === true)">{{buttonOK || $t('common.btn.ok')}}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalNotifyBlack',
  props: {
    'hideClose': {
      type: Boolean,
      default: false,
    },
    'hideOK': {
      type: Boolean,
      default: false,
    },
    'contain': {
      type: String,
      default: '',
    },
    'buttonOK': {
      type: String,
    },
    'buttonCancel': {
      type: String,
    }
  },
  data() {
    return {

    }
  },
  watch: {},
  mounted() {

  },
  methods: {}
}
</script>
<style scoped>
    .button--red {
        background-color: #da291c;
        color: #fff;
    }
    .minw-200 {
        min-width: 150px;
    }
</style>

