<template>
    <section class="app-carousel top-banners-carousel pb-10" ref="appCarousel">
        <carousel v-if="items.length > 0" :perPage="2"
                  :perPageCustom="[[100, 1], [768, 2]]"
                  :mouse-drag="true"
                  :autoplay="false"
                  :loop="true"
                  :autoplayTimeout="2000"
                  :paginationEnabled="true"
                  :navigationEnabled="false">
            <slide class="app-item-card" v-for="(item, index) in items" :key="index" v-bind:data-index="index" v-bind:data-name="item.name" >
                <div @click="handleSlideClick(item.url)" class="cursor-pointer item-card--full relative">
                    <img  :data-src="img_url+item.middle_image" :alt="item.name" class="app-item-img block w-full lazyload"/>
                    <div class="item-card-body">
                        <div class="item-card-heading">
                            <h2 class="item-card-title">
                                <span class="cursor-pointer bold">
                                    {{item.name}}
                                </span>
                            </h2>
                        </div>
                        <div class="item-card-description regular-400" v-html="item.description"></div>
                    </div>
                    <meta v-if="item.meta_desc" name="description" v-bind:content="item.meta_desc">
                    <meta v-if="item.meta_key" name="keywords" v-bind:content="item.meta_key">
                </div>
            </slide>
        </carousel>
    </section>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel';
  import types from '../../store/mutationTypes'
  import { mapGetters } from 'vuex'
  export default {
    name: "Carousel-Banners",
    props: {
      items: {
        type: Array,
        default: () => []
      },
      settings: {
        type: Object,
        default: () => {}
      }
    },
    components: {Carousel, Slide},
    data() {
      return {
          img_url: 'https://static-cdn.pizzahut.co.id/cdn-cgi/image/quality=100,format=auto/'
      }
    },
    created() {
    },
    computed: {
      ...mapGetters({
        storeUuid: 'moduleLocalisation/' +  types.LOCALISATION_GET_STORE_UUID,
      }),
    },
    watch: {
        items: function () {
            let size = this.$refs.appCarousel.clientWidth
            if (window.matchMedia('(min-width: 640px)').matches){
                size = Math.floor(size/2); // perPage = 2
            }
            this.img_url = this.$const.LINK_STATIC_FLY + "width=" + size + "/"
        }
    },
    methods: {
      handleSlideClick (url) {
        if (this.storeUuid) {
          window.location.href = url;
        } else {
          this.$router.push({name: 'home', query: {warning: '1', callback: url}});
          return false;
        }
      }
    }
  }
</script>

<style scoped>

    .item-card--full {
        background-color: #f5f7f9;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .app-item-card {
        display: flex !important;
        flex-direction: column;
        /*align-self: stretch;*/
        padding: 0 5px;
    }
    .item-card-title {
        font-size: 15px;
        text-transform: none;
        font-weight: bold;
    }
    .item-card-body {
        background-color: #f5f7f9;
        padding: 10px;
        text-align: left;
    }
    .app-item-img{
        height: auto;
        vertical-align: middle;
        max-width: 100%;
    }
</style>