<template>
    <div class="flex">
        <div class=" relative" v-if="orderType == $const.ORDERTYPE.DELIVERY">
            <span class="icon-location mr-5 opacity-50 align-text-top"></span>
            <router-link class=" border-b text-black" :to="{name: 'home'}">
                <span class="semi-bold"><span> {{$t('orders.delivery')}} </span></span>
            </router-link><span> <span>{{$t('common.to')}} </span> <router-link class=" border-b text-black" :to="{name: 'home'}"><span class="semi-bold">{{$t('common.combine_delivery',{streetName: localisation.deliveryInfo.streetName, postalCode: localisation.deliveryInfo.postalCode, state: localisation.deliveryInfo.state})}}</span></router-link> </span>
        </div>
        <div class=" relative" v-if="orderType == $const.ORDERTYPE.CARRYOUT">
            <span class="icon-location mr-5 opacity-50 align-text-top"></span>
            <span>{{$t('common.ordering_for')}}</span>
            <router-link class=" border-b text-black" :to="{name: 'home'}">
                <span class="semi-bold"><span> {{$t('orders.collection')}} </span></span>
            </router-link><span> <span>{{$t('common.from')}} </span> <router-link class=" border-b text-black" :to="{name: 'home'}"><span class="semi-bold">{{storeName}}</span></router-link> </span>
        </div>
        <div class="flex" v-if="orderType == $const.ORDERTYPE.DINEIN">
            <div><span class="icon-location-alt mr-5 opacity-50 align-text-top"></span></div>
            <div>
              <p class="typography-7 font-open-sans pl-5 pr-5 text-grey"> {{$t('common.current_dine_in')}}</p>
              <p class="typography-7 font-open-sans-bold pl-5 pr-5 text-grey-darker font-bold">{{ localisation.name }}</p>
              <p class="typography-9 font-open-sans pl-5 pr-5 text-grey">{{ localisation.addressStore }}</p>
            </div>
        </div>
    </div>
</template>

<script>
  import {  mapGetters  } from 'vuex'
  import types from '../../store/mutationTypes'

  export default {
    name: "DeliveryAddressInfo",
    computed: {
      ...mapGetters({
        storeName: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_NAME,
        location: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCATION,
        localisation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
        orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
      })
    }
  }
</script>

<style scoped>

</style>