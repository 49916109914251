<template>
    <transition>
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header"  >
                        <h5 class="modal-title text-center  text-18 w-full">SELECT OTP CHANNEL</h5>
                        <button type="button" aria-label="Close" class="close" @click="$emit('close')">×</button>
                    </div>
                    <div class="modal-body text-center">
                        <span class="text-red">How do you want to get the OTP code?</span>
                        <button type="button" class="button button--white-red" style="margin-bottom: 10px; margin-top: 5px" @click="chosenType(1)">Use Whatsapp</button>
                        <button class="button button--red" @click="chosenType(0)">Use SMS</button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import CustomerApi from '../../services/customer';
    export default {
        name: "ModalSendOTP",
        props:{
            keyToken: {
                type: String,
                default: '',
                required: true
            },
            sendOTP: {
                type: Function,
            }
        },
        methods:{
            chosenType(type){
                this.$emit('close');
                this.sendOTP(type);
            }
        }
    }
</script>

<style scoped>

</style>