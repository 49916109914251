import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import OrderConfirmation from './views/OrderConfirmation.vue';
import PaymentStatus from './views/PaymentStatus.vue';
import PaymentStatusBCA from './views/PaymentStatusBCA.vue';
import About from './views/static/About.vue';
import OrderLayout from './layouts/OrderLayout.vue';
import MainLayout from './layouts/MainLayout.vue';
import Menus from './views/Menus';
import RedeemMenus from './views/RedeemMenus';
import ComboDetail from './views/ComboDetail';
import Checkout from './views/Checkout';
import Profile from './views/Profile';
import UpdateProfile from './views/UpdateProfile';
import Register from './views/Register';
import PhReward from './views/PhReward';
import Login from './views/Login';
import OrderTracker from './views/OrderTracker';
import Stores from './views/Stores';
import StoreDetail from './views/StoreDetail';
import VerifyEmail from './views/VerifyEmail';
import ForgotPassword from './views/ForgotPassword';
import SetNewPassword from './views/SetNewPassword';
import Faq from './views/static/Faq';
import Term from './views/static/Term';
import Privacy from './views/static/Privacy';
import MobileApp from './views/static/MobileApp';
import ContactUs from './views/static/ContactUs';
import Offer from './views/static/Offer';
import PageNotFound from './views/static/404';
import MenuPrice from "./views/MenuPrice";
import store from './store'
import types from './store/mutationTypes'
import i18n from './translation'
import constant from './constant'
import BlankLayout from "./layouts/BlankLayout";
import GiftVoucher from "./views/static/GiftVoucher";
import DigitalMenu from "./views/static/DigitalMenu";
import DineIn from "./views/DineIn";
import AllRedeem from "@/views/AllRedeem";
import PhRewardDetail from "@/views/PhRewardDetail";
import MyReward from "@/views/MyReward";
import Treasures from "@/views/MyReward/Treasures";
import Slices from "@/views/MyReward/Slices";
import Vouchers from "@/views/MyReward/Vouchers";
import ClaimGiftReward from "@/views/MyReward/ClaimGiftReward";
import updateRewardDetail from "@/views/MyReward/UpdateRewardDetail";
import QRScanner from './views/QRScanner';
import MyOrder from './views/MyOrder';
import DineInCheckout from './views/DineInCheckout';
import Status from './views/Status';
import Payment from './views/Payment';


Vue.use(Router)
 let router = new Router({
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes: [
        {
          path: '/',
          name: 'main',
          component: MainLayout,
          children: [
            {
              path: '/',
              name: 'home',
              component: Home,
            },
            {
              path: '/404',
              name: 'error-404',
              component: PageNotFound,
            },
            {
              path: '/about/:slug?',
              name: 'about',
              component: About
            },
            {
              path: '/faqs',
              name: 'faq',
              component: Faq
            },
            {
              path: '/terms',
              name: 'terms',
              component: Term
            },
            {
              path: '/privacy',
              name: 'privacy',
              component: Privacy
            },
            {
              path: '/mobileapps',
              name: 'mobileapps',
              component: MobileApp
            },
            {
              path: '/contact-us',
              name: 'contact-us',
              component: ContactUs
            },
            {
              path: '/offers/:slug?',
              name: 'offer',
              component: Offer
            },
            {
              path: '/blank',
              name: 'blank',
              component: BlankLayout
            },
            {
              path: '/gift-voucher',
              name: 'gift-voucher',
              component: GiftVoucher,
              meta: {title: 'Pizza Hut Restaurant'}
            },
            {
              path: '/dine-in',
              name: 'dine-in',
              component: DineIn,
            },
          ]
        },
        {
          path: '/digital-menu/:slug?',
          name: 'digital-menu',
          component: DigitalMenu,
          meta: {title: 'Pizza Hut Restaurant'}
        },
        {
          path: '/combo/:uuid/:code?',
          name: 'combo-detail',
          component: ComboDetail,
        },
        {
          path: '/checkout',
          name: 'checkout',
          component: Checkout,
        },
        {
          path: '/register',
          name: 'register',
          component: Register,
          meta: { requiresAuth: false}
        },
        {
          path: '/ph-reward',
          name: 'ph-reward',
          component: PhReward,
        },
        {
          path: '/ph-reward-detail',
          name: 'ph-reward-detail',
          component: PhRewardDetail,
        },
        {
          path: '/detail-delivery-reward/:order_code',
          name: 'detail-delivery-reward',
          component: ClaimGiftReward,
        },
        {
          path: '/update-reward-detail',
          name: 'update-reward-detail',
          component: updateRewardDetail,
          meta: { requiresAuth: true}
        },
        {
          path: '/my-reward',
          name: 'my-reward',
          component: MyReward,
          children: [
            {
              path: '/my-reward/treasures',
              name: 'treasures',
              component: Treasures,
              meta: { requiresAuth: true}
            },
            {
              path: '/my-reward/slices',
              name: 'slices',
              component: Slices,
              meta: { requiresAuth: true}
            },
            {
              path: '/my-reward/vouchers',
              name: 'vouchers',
              component: Vouchers,
              meta: { requiresAuth: true}
            },
          ]
        },
        {
          path: '/all-redeem',
          name: 'all-redeem',
          component: AllRedeem,
          meta: { requiresAuth: true}
        },
        {
          path: '/order-tracking',
          name: 'order-tracking',
          component: OrderTracker,
        },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: ForgotPassword,
        },
        {
          path: '/reset-password/verify-by-email/:token',
          name: 'reset-password',
          component: SetNewPassword,
        },
        {
          path: '/set-new-password/:token',
          name: 'set-new-password',
          component: SetNewPassword,
        },
        {
          path: '/verify-email/:token',
          name: 'verify-email',
          component: VerifyEmail,
        },
        {
          path: '/login',
          name: 'login',
          component: Login,
          meta: { requiresAuth: false}
        },
        {
          path: '/profile',
          name: 'profile',
          component: Profile,
          meta: { requiresAuth: true}
        },
        {
          path: '/update-profile',
          name: 'update-profile',
          component: UpdateProfile,
          meta: { requiresAuth: true}
        },
        {
          path: '/stores',
          name: 'stores',
          component: Stores,
        },
        {
          path: '/stores/:slug',
          name: 'store-detail',
          component: StoreDetail,
        },
        {
          path: '/order-confirm/:uuid',
          name: 'order-confirm',
          component: OrderConfirmation,
        },
        {
          path: '/menuprice/:slug?',
          name: 'menuprice',
          component: MenuPrice,
        },
        {
          path: '/payment-status/:uuid',
          name: 'payment-status',
          component: PaymentStatus,
        },{
          path: '/payment-status-bca/:uuid',
          name: 'payment-status-bca',
          component: PaymentStatusBCA,
        },
        {
          path: '/redirect-url', redirect: to => {
            let query = to.query;
            if (query.return_url && query.return_url.length > 0) {
              for (let i of constant.ALLOW_REDIRECT) {
                if ( query.return_url.indexOf(i) == 0) {
                 return  window.location.href = query.return_url;
                }
              }
            }
            return { path: '/', query: null };
          }
        },
        {
          path: '/order',
          name: 'order',
          component: OrderLayout,
          children: [
            {
              path: ':slug/:id?',
              name: 'category',
              component: Menus,
            },
            {
              path: '/cart',
              name: 'cart',
              component: Menus,
            }
          ]
        },
        {
          path: '/order-redeem',
          name: 'order-redeem',
          component: OrderLayout,
          children: [
            {
              path: ':code/:uuid?',
              name: 'redeem',
              component: RedeemMenus,
            }
          ]
        },
        {
          path: '/qr-scanner',
          name: 'qr-scanner',
          component: QRScanner,
        },
        {
          path: '/my-order',
          name: 'my-order',
          component: MyOrder,
        },
        {
          path: '/dine-in-checkout',
          name: 'dine-in-checkout',
          component: DineInCheckout,
        },
        {
          path: '/status',
          name: 'status',
          component: Status,
        },
        {
          path: '/payment',
          name: 'payment',
          component: Payment,
        },
    {
      path: '*',
      // redirect: {name: 'home'},
      beforeEnter: (to, from, next) => {
        const promotionPage = [
          '/en/hotpromo',
          '/id/hotpromo'
          ];

        if (promotionPage.indexOf(to.fullPath) === 0){
          return next({path: '/order/hot-promo', query: to.query})
        }

        next({path: '/404', query: to.query})
      }
    }
      // ]
    // }
  ],
  linkExactActiveClass: ''
})

router.beforeEach((to, from, next) => {


  if (to.meta.title) {
    document.title = to.meta.title;
  }

  let lang = i18n.locale || 'en';
  let messages = i18n.messages;
  let metaDescription = messages[lang].meta.description.home;
  let metakeys = messages[lang].meta.keywords.home;
  let metaTitle = messages[lang].meta.title.default;

  let routeName = to.name;
  let slug = to.params.slug || '';
  switch (routeName) {
    case "home":
      metaTitle = messages[lang].meta.title.home;
      break;
     case "faq":
      metaDescription= messages[lang].meta.description.faq;
      metakeys = messages[lang].meta.keywords.faq;
      metaTitle = messages[lang].meta.title.faq;
      break;
     case "category":
       switch (slug) {
         case 'combox':
           metaDescription= messages[lang].meta.description.deal;
           metakeys = messages[lang].meta.keywords.deal;
           metaTitle = messages[lang].meta.title.default;
           break;
         case 'hot-promo':
           metaDescription= messages[lang].meta.description.deal;
           metakeys = messages[lang].meta.keywords.deal;
           metaTitle = messages[lang].meta.title.default;
           break;
         case 'pizza':
           metaDescription= messages[lang].meta.description.pizza;
           metakeys = messages[lang].meta.keywords.pizza;
           metaTitle = messages[lang].meta.title.default;
           break;
         case 'pasta-rice':
           metaDescription= messages[lang].meta.description.pasta;
           metakeys = messages[lang].meta.keywords.pasta;
           metaTitle = messages[lang].meta.title.default;

           break;
         case 'snack':
           metaDescription= messages[lang].meta.description.snack;
           metakeys = messages[lang].meta.keywords.snack;
           metaTitle = messages[lang].meta.title.default;

           break;
         case 'drinks':
           metaDescription= messages[lang].meta.description.drinks;
           metakeys = messages[lang].meta.keywords.drinks;
           metaTitle = messages[lang].meta.title.default;
           break;
         default:
           break;
       }
      break;
    case "ph-reward":
      metaTitle = messages[lang].meta.title.ph_reward;
      metaDescription= messages[lang].meta.description.ph_reward;
      metakeys = messages[lang].meta.keywords.ph_reward;
      break;
    default:
      break;
  }

  document.title = metaTitle;

  if (document.getElementsByTagName('meta')["description"] === undefined) {
    let meta = document.createElement('meta');
    meta.setAttribute('name', 'description');
    meta.setAttribute('content', metaDescription);
    document.head.appendChild(meta);
  } else {
    let description = document.getElementsByTagName('meta')["description"];
    description.content = metaDescription;
  }

  if (document.getElementsByTagName('meta')["keywords"] === undefined) {
    let metaK = document.createElement('meta');
    metaK.setAttribute('name', 'keywords');
    metaK.setAttribute('content', metakeys);
    document.head.appendChild(metaK);
  } else {
    let keywords = document.getElementsByTagName('meta')["keywords"];
    keywords.content = metakeys;
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['moduleAuth/' + types.CUSTOMER_IS_AUTH]) {
      next()
      return
    }
    next('/login')
  } else if (to.meta.hasOwnProperty('requiresAuth')) {
    if (!store.getters['moduleAuth/' + types.CUSTOMER_IS_AUTH]) {
      next()
      return
    }
    next('/')
  }else {
    next()
  }
});

export default router;
