<template>
    <div>
        <header-component />
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{$t('common.profile')}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white">
                    <div class="md:flex">
                        <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" action="" @submit="checkForm" >
                            <div class="block-section">
                                <div class="md:flex lg:flex ">
                                    <div class="form-field flex-1 ml-5 mr-5 ">
                                        <div class="xs:flex xs:flex-center mb-5">
                                            <label class="label mb-0" for="first_name">{{$t('customer.first_name')}}
                                                <span class="text-red">{{ $t('customer.required') }}</span>
                                                <span class="float-right text-red" v-if="errors.first_name">{{errors.first_name}}</span>
                                            </label>
                                        </div>
                                        <input id="first_name" class="input xs-mb-10" ref="first_name" v-bind:class="{'has-error': errors.first_name, 'has-success': errors.first_name === false}"
                                               name="first_name" v-model="customer.first_name"
                                               maxlength="255" type="text">
                                    </div>
                                    <div class="form-field flex-1 ml-5 mr-5">
                                        <div class="xs:flex xs:flex-center mb-5">
                                            <label class="label mb-0" for="last_name">{{$t('customer.last_name')}}
                                                <span class="text-red">{{ $t('customer.required') }}</span>
                                                <span class="float-right text-red" v-if="errors.last_name">{{errors.last_name}}</span>
                                            </label>
                                        </div>
                                        <input id="last_name" class="input xs-mb-10" ref="last_name" v-bind:class="{'has-error': errors.last_name, 'has-success': errors.last_name === false}"
                                               name="last_name" v-model="customer.last_name"
                                               maxlength="255" type="text">
                                    </div>
                                </div>
                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="phone">{{$t('customer.phone')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.phone">{{errors.phone}}</span>
                                        </label>
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-append" ><span class="input-group-text" >{{$const.PREFIX_PHONE}}</span></div>
                                        <input id="phone" class="input xs-mb-10 form-control" ref="phone" v-bind:class="{'has-error': errors.phone, 'has-success': errors.phone === false}"
                                               name="phone" v-model="customer.phone"
                                               maxlength="30" type="text">
                                    </div>

                                </div>
                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="email">{{$t('customer.email')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.email">{{errors.email}}</span>
                                        </label>
                                    </div>
                                    <input id="email" class="input xs-mb-10" ref="email" disabled="disabled" v-bind:class="{'has-error': errors.email, 'has-success': errors.email === false}"
                                           name="email" v-model="customer.email"
                                           maxlength="255" type="email">
                                </div>
                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="genders">{{$t('customer.gender')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.gender">{{errors.gender}}</span>
                                        </label>
                                    </div>
                                    <v-select :options="genders" v-model="customer.gender"  class="input-transparent" ref="genders" id="genders" name="genders"></v-select>
                                </div>
                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0">{{$t('customer.birthday')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.birthday">{{errors.birthday}}</span>
                                        </label>
                                    </div>
                                    <div class="md:flex lg:flex ">
                                        <v-select :options="birthdayData.day" v-model="customer.birth_date" :placeholder="$t('common.day')"
                                                  class="input-transparent input-min-height-44 flex-1 m-5" ref="birth_day" id="birth_date" name="birth_day" :disabled="customerInfo.birthday !=='1-01-01'"
                                                  v-bind:class="{'has-error': errors.birth_date, 'has-success': errors.birth_date === false}">
                                        </v-select>
                                        <v-select :options="birthdayData.month" v-model="customer.birth_month" :placeholder="$t('common.month')"
                                                  class="input-transparent input-min-height-44 flex-1 m-5" ref="birth_month" id="birth_month" name="birth_month" :disabled="customerInfo.birthday !=='1-01-01'"
                                            v-bind:class="{'has-error': errors.birth_month, 'has-success': errors.birth_month === false}">
                                        </v-select>
                                        <v-select :options="birthdayData.year" v-model="customer.birth_year"  :placeholder="$t('common.year')"
                                                  class="input-transparent input-min-height-44 flex-1 m-5" ref="birth_year" id="birth_year" name="birth_year" :disabled="customerInfo.birthday !=='1-01-01'"
                                            v-bind:class="{'has-error': errors.birth_year, 'has-success': errors.birth_year === false}">

                                        </v-select>
                                    </div>
                                </div>
                            </div>
                            <div class="block-section">
                                <div>
                                    <button @click="changePassword(customerInfo.email)" type="button" class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" > {{$t('common.btn.change_password')}}</button>
                                </div>
                            </div>
                            <div class="block-section">
                                <div>
                                    <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" > {{$t('common.btn.update_profile')}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
  import HeaderComponent from '../components/HeaderComponent';
  import CustomerApi from '../services/customer';
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  import types from '../store/mutationTypes'
  import { createNamespacedHelpers } from 'vuex'
  import moment from "moment";
  import ModalVerifyToken from "../components/modal/ModalVerifyToken";
  const { mapActions, mapGetters } = createNamespacedHelpers('moduleAuth')

  export default {
    name: "update-profile",
    components: {
      HeaderComponent,
      vSelect
    },
    data() {
     return {
       tokenKey: '',
       oldPhone: '',
       oldEmail: '',
       genders: [],
       birthdayData: {
         day: [],
         year: [],
         month: [],
       },
       errors: {
         isValid: false,
         first_name: '',
         last_name: '',
         phone: '',
         birthday: '',
         birth_month: '',
         birth_year: '',
         birth_date: '',
         email: ''
       },
       customer: {
         first_name: '',
         last_name: '',
         phone: '',
         gender: {},
         birth_date: {},
         birth_month: {},
         birth_year: {},
         email: ''
       }
     }
    },
    computed: {
      ...mapGetters({
        customerInfo: types.CUSTOMER_GET_INFO,
      })
    },
    mounted() {
      this.initData();
      this.initCustomer();
    },
    watch: {
      customerInfo: function (newVal) {
        if (newVal) {
          // eslint-disable-next-line no-console
        }
        this.initCustomer();
      },
      'customer.phone': function (newValue) {
        this.errors.phone = false;
        if (!newValue) {
          this.errors.phone = this.$t('message.error.phone');
        }else if (newValue.length < this.$const.PHONE.MIN || newValue.length > this.$const.PHONE.MAX) {
          this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX});
        }
      },
      'customer.email': function (newValue) {
        this.errors.email = false;
        if (!newValue) {
          this.errors.email = this.$t('message.error.email');
        }else if (!this.validEmail(newValue)) {
          this.errors.email = this.$t('message.error.invalid_email');
        }
      },
      'customer.first_name': function (newValue) {
        this.errors.first_name = false;
        if (!newValue) {
          this.errors.first_name = this.$t('message.error.first_name');
        }
      },
      'customer.last_name': function (newValue) {
        this.errors.last_name = false;
        if (!newValue) {
          this.errors.last_name = this.$t('message.error.last_name');
        }
      }
    },
    methods: {
      ...mapActions({
        getProfileByToken: types.CUSTOMER_GET_INFO_BY_TOKEN,
        logout: types.CUSTOMER_LOGOUT,
      }),
      initCustomer() {
        this.customer.first_name = this.customerInfo.first_name;
        this.customer.last_name = this.customerInfo.last_name;
        this.customer.phone = this.getPhoneWithoutPrefix(this.customerInfo.phone, this.$const.PREFIX_PHONE);

        this.customer.email = this.customerInfo.email;
        this.oldPhone = this.customerInfo.phone;
        this.oldEmail = this.customerInfo.email;

        if (this.customerInfo.birthday && this.customerInfo.birthday != "1-01-01") {
          let birthday = moment(this.customerInfo.birthday, 'YYYY-MM-DD');
          this.customer.birth_date = {
            label: birthday.get('date'),
            code: birthday.get('date'),
          }
          this.customer.birth_month = {
            label: birthday.get('month') + 1,
            code: birthday.get('month') + 1,
          }
          this.customer.birth_year = {
            label: birthday.get('year'),
            code: birthday.get('year'),
          }

        }
      },
      initData () {
        let _genders = this.$const.GENDERS;
        let _gender = {};
        for (let i in _genders) {
          _genders[i].label = this.$t(_genders[i].label);
          if (_genders[i].code == this.customerInfo.gender){
            _gender = _genders[i];
          }
        }
        this.genders = _genders;
        if (!this.emptyVariable(_gender)){
          this.customer.gender = _gender;
        }else if (this.genders.length > 0) {
          this.customer.gender = this.genders[0];
        }

        this.birthdayData.day.push({
          label: this.$t('common.day'),
          code: 0,
        });
        this.customer.birth_date = this.birthdayData.day[0];
        for (let i=1; i<=31; i++) {
          this.birthdayData.day.push({
            label: i,
            code: i,
          });
        }

        this.birthdayData.month.push({
          label: this.$t('common.month'),
          code: 0,
        });
        this.customer.birth_month = this.birthdayData.month[0];
        for (let i=1; i<=12; i++) {
          this.birthdayData.month.push({
            label: i,
            code: i,
          })
        }

        this.birthdayData.year.push({
          label: this.$t('common.year'),
          code: 0,
        });
        this.customer.birth_year = this.birthdayData.year[0];
        let nowYear = (new Date()).getFullYear();
        for (let i = 1900; i <= nowYear-1; i++) {
          this.birthdayData.year.push({
            label: i,
            code: i,
          })
        }
      },
      initErrorMessage () {
        this.errors = {
          isValid: false,
          first_name: false,
          last_name: false,
          phone: false,
          birthday: false,
          birth_date: false,
          birth_month: false,
          birth_year: false,
          email: false
        }
      },
      checkForm(e) {
        e.preventDefault();
        let isValidForm = true;
        this.initErrorMessage();

        if (!this.customer.first_name) {
          this.errors.first_name = this.$t('message.error.first_name');
          if (isValidForm) {
            this.$refs.first_name.focus();
          }
          isValidForm = false;
        }
        if (!this.customer.last_name) {
          this.errors.last_name = this.$t('message.error.last_name');
          if (isValidForm) {
            this.$refs.last_name.focus();
          }
          isValidForm = false;
        }
        if (!this.customer.phone) {
          this.errors.phone = this.$t('message.error.phone');
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        } else if (this.customer.phone.length < this.$const.PHONE.MIN || this.customer.phone.length > this.$const.PHONE.MAX) {
          this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX})
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        }

        if (!this.customer.email) {
          this.errors.email = this.$t('message.error.email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        } else if (!this.validEmail(this.customer.email)) {
          this.errors.email = this.$t('message.error.invalid_email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        }

        if (this.customer.birth_date == '' || this.customer.birth_month == '' || this.customer.birth_year == '') {
          this.errors.birthday = this.$t('message.error.birthday')
          if (this.customer.birth_date === '') {
            this.errors.birth_date = true
          }
          if (this.customer.birth_month === '') {
            this.errors.birth_month = true
          }
          if (this.customer.birth_year === '') {
            this.errors.birth_year = true
          }
          if (isValidForm) {
            if ( this.errors.birth_date) {
              this.$refs.birth_date.focus()
            } else if (this.errors.birth_month) {
              this.$refs.birth_month.focus()
            } else if (this.errors.birth_year) {
              this.$refs.birth_year.focus()
            }
          }
          isValidForm = false

        } else if (!this.isValidBirthday(this.customer.birth_year.code, this.customer.birth_month.code, this.customer.birth_date.code)) {
          this.errors.birthday = this.$t('message.error.invalid_birthday')
          if (isValidForm) {
            this.$refs.birthday.focus()
          }
          isValidForm = false
        }

        if (isValidForm) {
          this.updateProfile();
        }
      },
      updateProfile () {
        var vm  = this;
        let payload = {
          "birthday": this.customer.birth_year.code + '-' + (this.customer.birth_month.code < 10 ? '0': '') + this.customer.birth_month.code + '-' + (this.customer.birth_date.code < 10 ? '0': '') + this.customer.birth_date.code,
          "email": this.customer.email,
          "first_name": this.customer.first_name,
          "gender": this.customer.gender.code,
          "last_name": this.customer.last_name,
          "phone": this.customer.phone,
          "vm": this
        }
        CustomerApi.updateProfile(payload,
          function (response) {
            vm.handleUpdateProfile(response.data.type || vm.$const.UPDATE_PROFILE_TYPE.INFO, response.data);
          }, function (data) {
            vm.notificationError(vm, data.message);
          }
        )
      },
      verifyToken () {
        var vm = this;
        this.$modal.show(ModalVerifyToken, {
            keyToken: vm.tokenKey,
            mode: vm.$const.VERIFY_TOKEN_METHOD.PROFILE_PHONE,
            callbackFunc: function (result) {
              if (result) {
                vm.getProfileByToken();
                vm.notificationSuccess(vm, vm.$t('message.success.update'));
                vm.$router.push({name: 'home'});
              }
            }
          },
          {
            'class': 'modal-register-form'
          },
          {
            'before-open': function () {
            },
            'before-close': function () {
            }
          })
      },
      handleUpdateProfile(type, params) {
        switch (type) {
          case this.$const.UPDATE_PROFILE_TYPE.PHONE:
            this.tokenKey = params.key;
            this.verifyToken();
            break;
          case this.$const.UPDATE_PROFILE_TYPE.EMAIL:
            this.logout();
            this.$router.push({name: 'login'});
            this.notificationSuccess(this, this.$t('message.success.update_profile_change_mail'));
            break
          default:
            this.getProfileByToken();
            this.$router.push({name: 'home'});
            this.notificationSuccess(this, this.$t('message.success.update'));
            break;
        }
      },
      changePassword (email) {
        var vm = this;
        CustomerApi.sendOtpViaEmail(email, {vm: this},  function (response) {
          vm.notificationSuccess(vm, vm.$t('message.success.send_change_password'));
        } ,function (error) {
          vm.notificationError(vm, error.message);
        })
      },
    }
  }
</script>
<style>

</style>
