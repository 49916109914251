<template>
    <div class="bg-white relative z-600" style="box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 5px 0px;">
      <div class="container flex flex-center justify-between relative h-full">
          <div v-if="!isMobile()" class="header-logo container py-15 pl-5 block">
            <router-link :to="'/'">
                <h1 class="logo-full screenreader-only">Order Pizza for Delivery from PHD Indonesia</h1>
            </router-link>
          </div>
          <div v-else class="relative z-500">
              <router-link :to="'/'" class="header-logo block lg:pt-15 lg:py-15 lg:pl-30 bg-white lg:bg-transparent rounded-full" >
                  <span class="logo"></span>
              </router-link>
          </div>
        <div class="menu-header flex items-center flex-wrap justify-between" :class="menuOpen ? 'block slide-left': 'hidden-1024'">
            <a class="close-sidebar lg:hidden"
               @click.prevent.stop="toggleMenu()">
                <img src="../assets/images/icon-close--white.svg"
                     class="">
            </a>
          <div class="md:pr-0 body regular-400 header-font">
              <div class="flex whitespace-no-wrap mobile-flex-column mobile-full-width">
                <div v-if="!isMobile()" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px">
                  <router-link :to="{name: 'home'}" v-bind:class="{'active': this.$route.name == 'home'}">Home</router-link>
                </div>
                <div v-if="!isMobile()" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px" >
                    <router-link :to="{name: 'order-tracking'}" v-bind:class="{'active': this.$route.name == 'order-tracking'}"> {{$t('common.order_tracker')}}</router-link>
                </div>
                <div v-if="!isMobile()" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px" >
                  <router-link :to="{name: 'ph-reward'}" v-bind:class="{'active': this.$route.name == 'ph-reward'}">My Rewards</router-link>
                </div>
                <div v-if="!isMobile()" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px" >
                    <button class="button button--red" style="min-height: 32px; font-size: 14px" v-if="!isAuth" @click="showLogin()"> Sign Up /Login</button>
                    <div v-else class="header-menu">
                        <router-link class="w-full" :to="{name: 'profile'}" v-bind:class="{'active': this.$route.name == 'profile'}">{{$t('common.my_account')}}</router-link>
                        <div class="header-menu-content">
                            <router-link class="header-menu-item" :to="'#'" >Saved address</router-link>
                            <router-link class="header-menu-item" :to="'#'" >Saved outlet</router-link>
                            <span class="header-menu-item" :to="'#'" @click="logout">{{$t('common.logout')}}</span>
                        </div>
                    </div>
                </div>
                <div v-if="!isMobile() && isAuth" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px">
                  <img src="../assets/images/hut_reward_circle.png" alt=""><div class="px-10" style="color: #3E4043;">{{authUser.point}}</div>
                </div>
              </div>
          </div>
        </div>
          <div class="overlay-bg z-700"></div>
      </div>
      <div class="block hidden absolute button-toggle-menu" style="right: 5px; top: 50%; transform: translateY(-50%);">
          <button class="flex items-center p-10">
              <router-link :to="{name: 'status'}" class="flex typography-5 header-font" v-if="order_code">
                <img src="../assets/images/icon-qr-code.png" /> Dine-in 
              </router-link>
              <router-link :to="{name: 'qr-scanner'}" class="flex typography-5 header-font" v-else>
                <img src="../assets/images/icon-qr-code.png" /> Dine-in 
              </router-link>
          </button>
      </div>
    </div>
</template>

<script>
  import types from '../store/mutationTypes'

  import { mapActions, mapGetters } from 'vuex'
  import modalLoginRegister from "@/components/modal/ModalLoginRegister.vue";
  import manthan from '../services/manthan'
  import OrderApi from '../services/order'
  export default {
  components: {
  },
  data() {
    return {
      menuOpen: false,
      store_uuid: '',
      order_code: '',
      goToConfirmPage: false
    }
  },
  destroyed() {
    document.body.classList.remove('sidebar-open')
  },
  computed: {
  ...mapGetters({
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
      storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
      defaultCategory:  types.GET_DEFAULT_CATEGORY,
      orderCode: 'moduleOrder/' + types.ORDER_GET_ORDER_CODE,
    }),
  },
    mounted() {
        this.store_uuid = this.storeUuid
        this.order_code = this.orderCode
        this.checkLatestDineInOrder()
    },
    watch: {
        storeUuid: function(){
            this.store_uuid = this.storeUuid
        },
        orderCode: function(){
            this.order_code = this.orderCode
        }
    },
    methods: {
    ...mapActions ({
      logout: 'moduleAuth/' + types.CUSTOMER_LOGOUT,
      saveOrder: 'moduleOrder/' + types.ORDER_SAVE_ITEM,
      resetOrder: 'moduleOrder/' + types.ORDER_RESET_ITEM,
      resetLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
      resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
    }),
    toggleMenu() {
      this.menuOpen = !this.menuOpen
      if (this.menuOpen){
        document.body.classList.add('sidebar-open')
      } else {
        document.body.classList.remove('sidebar-open')
      }
    },
    routeMenu(){
        if (this.storeUuid) {
            this.$router.push({name: 'category', params:{slug: this.defaultCategory}});
        } else {
            this.$router.push({name: 'home', query: {warning: '1', callback:this.defaultCategory}});
            return false;
        }
    },
      showLogin() {
        const vm = this
        this.$modal.show(
            modalLoginRegister,
            {
              resizable: true,
              clickToClose: true,
              manthanLogin: function () {
                return  vm.callManthan
              }
            }
        )
      },
      callManthan(){
        manthan.execute({}, "eventLogin")
        return true
      },
      async checkLatestDineInOrder(loader = true){
        let vm = this
        let dateNow = Date.now()
        let data = {vm}

        let payloadOrderLatestDineIn = {
          ...data,
          loader: loader,
          v: dateNow,
        }
        await OrderApi.orderLatestDineIn(payloadOrderLatestDineIn, 
        (response) => {
          this.goToConfirmPage = true
          if (response.data.order_status.code != this.$const.ORDERSTATUS.FINISHED) {
            vm.saveOrder({
              order_code: response.data.code, 
              order_uuid: response.data.uuid, 
              order_status_code: response.data.order_status.code, 
              order_status_uuid: response.data.order_status.uuid, 
              collection_time: response.data.collection_time
            })
          } else {
            vm.notificationSuccess(vm, "Your order has been finished")
            clearInterval(this.$parent.statusInterval)
            this.resetOrder()
            this.resetLocalisation()
            this.resetCart()
          }
        },
        (response) => {
          if (response.code == 6102) {
            clearInterval(this.$parent.statusInterval)
            if (this.orderCode) {
              vm.notificationSuccess(vm, "Your order has been finished")
            }

            if (!this.$parent.isDineInCheckout) {
              this.resetOrder()
              this.resetLocalisation()
              this.resetCart()
            }
          }
        })
      }
  }
}
</script>
<style>
  .header-font {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #3E4043;
  }
  .header-font .active {
    color: #C8102E;
  }
  .header-font img {
    width: 15px;
    height: 15px;
    margin: auto;
    margin-right: 3px;
  }
</style>