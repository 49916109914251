export default  {
    branchLog(eventName, productList = [], customData = {}) {
        // init content_items
        let content_items = []
        productList.forEach( p => {
            let product = p
            if (p.product && p.product.length > 0) {
                product = p.product[0]
            }
            const item = {
                "$content_schema": "COMMERCE_PRODUCT",
                "$og_title": product.name || '',
                "$og_description": product.description || '',
                "$og_image_url": product.image || '',
                "$price": product.sub_price || 0,
                "$quantity": 1,
                "$sku": product.sku || '',
                "$product_name": product.name || '',
                "product_category": product.category.name || '',
                "$keywords": product.tag || [],
            }
            content_items.push(item)
        });
        let customer_event_alias = "my custom alias";
        branch.logEvent(
            eventName,
            customData,
            content_items,
            customer_event_alias,
            function(err) {
                if(err){
                    console.log('branch error',err);
                }
            }
        );
    }
}