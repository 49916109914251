<template>
  <div>
    <qrcode-stream :paused="paused" @decode="onDecode"></qrcode-stream>
  </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import { mapActions, mapGetters } from 'vuex'
import types from '../../store/mutationTypes'
import StoreApi from '../../services/store'
import MenuApi from '../../services/menu'
import OrderApi from '../../services/order'
import Popup from '../partials/popup'
import ModalNotifyBlack from "../modal/ModalNotifyBlack";

export default {
  components: {
    QrcodeStream,
    Popup,
    ModalNotifyBlack
  },
  data() {
    return {
      scannedData: null,
      paused: false,
      storeTypeId: null,
      lat: 0,
      long: 0,
      isPopupVisible: false,
      popupText: '',
      nextPage: false,
      checkOpeningStore: false,
    }
  },
  created() {
    this.getLatLong()
  },
  computed: {
    ...mapGetters({
        defaultCategory:  types.GET_DEFAULT_CATEGORY,
        orderNow:  'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_NOW,
        storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
        isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
        store: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
        authUser: 'moduleAuth/' + types.CUSTOMER_GET_INFO,
        orderCode: 'moduleAuth/' + types.ORDER_GET_ORDER_CODE,
    }),
  },
  methods: {
    ...mapActions({
      menuCategoryList: types.SET_MENU_CATEGORY_LIST,
      setDefaultCategory: types.SET_DEFAULT_CATEGORY,
      setStore: 'moduleLocalisation/' + types.LOCALISATION_SAVE_STORE,
      resetLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
      saveOrder:  'moduleOrder/' + types.ORDER_SAVE_ITEM,
    }),
    // showPopup() {
    //   this.isPopupVisible = true;
    // },
    // closePopup() {
    //   this.isPopupVisible = false;
    // },
    getLatLong() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
        }, (error) => {
          console.log(error);
        });
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    },
    async onDecode(result, params) {
      this.paused = true
      this.scannedData = result
      let vm = this
      this.paused = true
      let storeCode = this.scannedData.split("|")[0]
      let tableNum = this.scannedData.split("|")[1]
      let data = {vm}

      if (vm.isAuth){
        
        let payloadOrderTable = {
          ...data,
          store_code: storeCode,
          table: tableNum,
          lat: vm.lat,
          long: vm.long
        }
        await OrderApi.orderTable(payloadOrderTable, 
        (response) => {
          vm.saveOrder({ order_status_code: response.data.order_status.code, order_status_uuid: response.data.order_status.uuid})
          this.nextPage = true
        }, 
        (response) => {
          if (response.code == 6111) {
            // this.showPopup()
            // this.popupText = response.message
            this.$modal.show(ModalNotifyBlack, {hideOK: true, contain: this.$t(response.message)},
                        {resizable: false, clickToClose: false,
                            classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
            this.nextPage = true
            this.resetLocalisation()
          } else {
            this.nextPage = true
          }
        })

      } else {
        let payloadOrderTable = {
          ...data,
          store_code: storeCode,
          table: tableNum,
          lat: vm.lat,
          long: vm.long
        }
        await OrderApi.orderTableGuest(
        payloadOrderTable, 
        () => {
          this.nextPage = true
        }, 
        (response) => {
          if (response.code == 6111) {
            // this.showPopup()
            this.popupText = response.message
            this.nextPage = false
            this.resetLocalisation()
          } else {
            this.nextPage = true
          }
        })

      }

      if (this.nextPage == true) {
        await StoreApi.searchByCode(storeCode, data, 
          (response) => {
              let store = response.data
              vm.checkOpeningStore = vm.checkOpeningTime(store.opening_timings, vm.$const.ORDER_TYPE_MAPPING.DINEIN, vm.$const.CHANNEL.ONLINE)
              let storeDataNew = {
                "note": store.hasOwnProperty('note') ? store.note : '',
                "clientUUID": store.client_uuid || '',
                "code": store.code || '',
                "distance": store.distance || 0,
                "lat": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.latlngDelivery.lat : store.lat,
                "location": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.deliveryAddress.replace(', Indonesia', '') : '',
                "addressStore": store.location,
                "long": this.orderType === this.$const.ORDERTYPE.DELIVERY ? this.latlngDelivery.lng : store.long,
                "name": store.name,
                "tierUUID": store.tier_uuid || null,
                "uuid": store.uuid,
                "timezone": store.timezone,
                "deliveryInfo": {},
                "businessHours": store.business_hours,
                "orderNow": this.orderNow,
                "orderType": this.$const.ORDERTYPE.DINEIN,
                "storeType": store.store_type_id,
                "storeBranch": store.store_branch,
                "tableNumber": tableNum
            }
            this.setStore(storeDataNew)
            vm.storeTypeId = response.data.store_type_id
          }
        ) 

        if (vm.checkOpeningStore) {
          data = { vm: this, store_type_id: this.storeTypeId }
          await MenuApi.categories(data, function (response) {
            let categoriesData = response.data;
            categoriesData.push(vm.$const.COUPON_CAT);
            vm.handleSetDefaultCategory(categoriesData, params);
            vm.menuCategoryList(categoriesData);
          }, function () {
            vm.handleSetDefaultCategory([], params);
            vm.menuCategoryList([]);
          })
          this.viewAllDeal()
        } else {
          this.$modal.show(ModalNotifyBlack, {hideOK: true, contain: this.$t('message.error.store_closed')},
          {resizable: false, clickToClose: false, classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'});
          this.$router.go(-1)      
        }
      } else {
        this.$router.go(-1)  
      }
      this.paused = false
    },
    viewAllDeal(){
      if (this.storeUuid) {
          this.$router.push({name: 'category', params:{slug: this.defaultCategory}});
      } else {
          this.$router.push({name: 'home', query: {warning: '1', callback:'order/hot-promo'}});
          return false;
      }
    },
    handleSetDefaultCategory (data, params) {
      let slug = this.defaultCategory;
      let _currentSlug = '';
      let defaultSlug = '';
      for (let item of data) {
        if (item.slug === slug) {
          _currentSlug = item.slug;
        }
        if (item.slug === this.$const.CATEGORY.DEAL) {
          defaultSlug = item.slug;
        }
      }

      if (slug === '' && data.length > 0) {
          slug = data[0].slug;
      }
      if (defaultSlug === '' && data.length > 0) {
        defaultSlug = data[0].slug;
      }
      this.setDefaultCategory(defaultSlug);

      if (params && params.goDeal) {
        this.$router.push({name: 'category', params: {slug: defaultSlug}});
      } else if ( params && params.callback) {
        let path =  params.callback.replace(/\/\/\//g, '');
        return this.redirectInternal(this, path);
      } else if ([this.$const.ROUTE.CATEGORY, this.$const.ROUTE.COMBO_DETAIL].indexOf(this.$route.name) >= 0) {
        this.setSelectedCategory(this.$route.name, _currentSlug, data);
      }
    },
  }
}
</script>
