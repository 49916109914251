<template>
    <div>
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{$t('common.contact_us')}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white">
                    <div class="md:flex" style="flex-direction: column; padding: 20px;" >
                        <p><span>Pertanyaan, saran atau keluhan dapat disampaikan melalui:</span></p>
                        <p><span>Customer service Line kami di <b style="color: #c8102e;">1 500 600</b> (berlaku tarif lokal) hari senin sampai minggu dari jam <b>10:00</b> sampai jam <b>22:00</b></span></p>
                        <p><span>ATAU email ke </span><b style="color: #c8102e;">customerservice@pizzahut.co.id</b></p>
                        <p><span><b>Daftar sekarang untuk menerima email dan informasi dari Pizza hut secara berkala mengenal promosi, produk baru dan penawaran gratis.</b></span></p>
                    </div>
                    <div class="md:flex">
                        <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" action="" @submit="checkForm" >
                            <div class="block-section">
                                <div class="form-field flex-1 ml-5 mr-5 ">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0">{{$t('common.subject')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.subject">{{errors.subject}}</span>
                                        </label>
                                    </div>
                                    <v-select :options="titles" v-model="subject"  class="input-transparent" ref="subject" id="subject" name="subject"></v-select>

<!--                                    <input id="subject" class="input xs-mb-10" ref="subject" v-bind:class="{'has-error': errors.subject, 'has-success': errors.subject === false}"-->
<!--                                           name="subject" v-model="subject"-->
<!--                                           maxlength="255" type="text">-->
                                </div>
                                <div class="form-field flex-1 ml-5 mr-5 ">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="name">{{$t('customer.name')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.name">{{errors.name}}</span>
                                        </label>
                                    </div>
                                    <input id="name" class="input xs-mb-10" ref="name" v-bind:class="{'has-error': errors.name, 'has-success': errors.name === false}"
                                           name="name" v-model="name"
                                           maxlength="255" type="text">
                                </div>
                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="phone">{{$t('customer.phone')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.phone">{{errors.phone}}</span>
                                        </label>
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-append" ><span class="input-group-text" >{{$const.PREFIX_PHONE}}</span></div>
                                        <input id="phone" class="input xs-mb-10 form-control" ref="phone" v-bind:class="{'has-error': errors.phone, 'has-success': errors.phone === false}"
                                               name="phone" v-model="phone"
                                               maxlength="30" type="text">
                                    </div>
                                </div>

                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="email">{{$t('customer.email')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.email">{{errors.email}}</span>
                                        </label>
                                    </div>
                                    <input id="email" class="input xs-mb-10" ref="email" v-bind:class="{'has-error': errors.email, 'has-success': errors.email === false}"
                                           name="email" v-model="email"
                                           maxlength="255" type="email">
                                </div>
                                <div class="form-field" >
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="message">{{$t('common.message')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.message">{{errors.message}}</span>
                                        </label>

                                    </div>
                                    <textarea v-model="message"
                                              id="message"
                                              ref="message" class="input xs-mb-10"
                                              name="message"
                                              v-bind:class="{'has-error': errors.message, 'has-success': errors.message === false}"
                                              rows="3"></textarea>
                                </div>
                            </div>

                            <div class="block-section">
                                <div>
                                    <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" > {{$t('common.btn.submit')}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  import ContentApi from '../../services/content';
  /* eslint-disable */
  export default {
    name: "contact-us",
    components: {
      vSelect
    },
    data() {
     return {
       titles: [],
       subject: {},
       email: '',
       name: '',
       phone: '',
       message: '',
       errors: {
         isValid: false,
         name: '',
         phone: '',
         subject: '',
         message: '',
         email: ''
       },
     }
    },
    computed: {
    },
    mounted() {
      let _titles = this.$const.CONTACT_US_TITLES;
      for (let i in _titles) {
        _titles[i].label = this.$t(_titles[i].label);
      }
      this.titles = _titles;
      if (this.titles.length > 0) {
        this.subject = this.titles[0];
      }
    },
    watch: {
      'phone': function (newValue) {
        this.errors.phone = false;
        if (!newValue) {
          this.errors.phone = this.$t('message.error.phone');
        }else if (newValue.length < this.$const.PHONE.MIN || newValue.length > this.$const.PHONE.MAX) {
          this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX});
        }
      },
      'email': function (newValue) {
        this.errors.email = false;
        if (!newValue) {
          this.errors.email = this.$t('message.error.email');
        }else if (!this.validEmail(newValue)) {
          this.errors.email = this.$t('message.error.invalid_email');
        }
      },
      'name': function (newValue) {
        this.errors.name = false;
        if (!newValue) {
          this.errors.name = this.$t('message.error.name');
        }
      },
      'subject': function (newValue) {
        this.errors.subject = false;
        if (!newValue) {
          this.errors.subject = this.$t('message.error.subject');
        }
      },
      'message': function (newValue) {
        this.errors.message = false;
        if (!newValue) {
          this.errors.message = this.$t('message.error.message');
        }
      }
    },
    methods: {
      initErrorMessage () {
        this.errors = {
          isValid: false,
          name: false,
          subject: false,
          phone: false,
          message: false,
          email: false
        }
      },
      checkForm(e) {
        e.preventDefault();
        let isValidForm = true;
        this.initErrorMessage();

        if (!this.subject) {
          this.errors.subject = this.$t('message.error.subject');
          if (isValidForm) {
            this.$refs.subject.focus();
          }
          isValidForm = false;
        }
         if (!this.name) {
              this.errors.name = this.$t('message.error.name');
              if (isValidForm) {
                this.$refs.name.focus();
              }
              isValidForm = false;
         }

        if (!this.phone) {
          this.errors.phone = this.$t('message.error.phone');
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        } else if (this.phone.length < this.$const.PHONE.MIN || this.phone.length > this.$const.PHONE.MAX) {
          this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX})
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        }

        if (!this.email) {
          this.errors.email = this.$t('message.error.email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        } else if (!this.validEmail(this.email)) {
          this.errors.email = this.$t('message.error.invalid_email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        }
        if (!this.message) {
          this.errors.message = this.$t('message.error.message');
          if (isValidForm) {
            this.$refs.message.focus();
          }
          isValidForm = false;
        }
        if (isValidForm) {
          this.submitForm();
        }
      },
      submitForm () {
        var vm  = this;
        let payload = {
          "email": this.email,
          "name": this.name,
          "subject": this.subject.code,
          "comment": this.message,
          "phone": this.phone,
          "store_type_id": this.$const.TYPE_HUT.PHD,
          "vm": this
        }
        ContentApi.sentContact(payload,
          function (response) {
            vm.notificationSuccess(vm, vm.$t('message.success.send_contact'));
            if(response.code == 200) {
                vm.$router.push({name: 'home'})
            }
          }, function (data) {
            vm.notificationError(vm, data.message);
          }
        )
      }
    }
  }
</script>
