<template>
    <div class="side-menu lg:shadow-down-1 lg:border-b lg:border-t overflow-auto regular-400">
        <div class="md:flex md:flex-col lg:flex-row container lg:justify-start lg:pl-15 ">
            <router-link :to="'/order/' + item.slug"  v-bind:class="{'text-red lg:text-white side-menu__link--active': item.slug == currentCategory.slug}" v-if="item.slug !== $const.CATEGORY.COUPON || (isAuth) "
                         class="side-menu__link side-menu__link--deals text-white lg:text-black  capitalize lg:border-r" aria-current="page" v-for="(item, indexC) in listCategories" :key="indexC">
                <span style="text-transform: uppercase !important;"><b>{{item.name}}</b></span>
            </router-link>
<!--            <div class="xl:ml-auto hidden xl:flex xl:items-center  pr-15"><a class="text-grey text-13 leading-normal underline" href="/allergens/">Allergy Information</a></div>-->
        </div>
    </div>
</template>

<script>
  import {  mapState, mapGetters } from 'vuex'
  import types from '../store/mutationTypes'
  export default {
    computed: {
      ...mapGetters({
        isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH
      }),
      ...mapState({
        listCategories: 'categories',
        currentCategory: 'currentCategory',
      })
    },
    watch: {
    }
  }
</script>
