<template>
    <div>
        <header-component />
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{$t('common.track_your_order')}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white">
                    <div class="md:flex">
                        <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" action="" @submit="checkForm">
                            <div class="block-section">
                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="orderNumber">{{$t('customer.order_number')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.orderNumber">{{errors.orderNumber}}</span>
                                        </label>
                                    </div>
                                    <input id="orderNumber" class="input " ref="orderNumber" v-bind:class="{'has-error': errors.orderNumber, 'has-success': errors.orderNumber === false}"
                                           name="orderNumber" v-model="orderNumber" type="text">
                                </div>
                            </div>
                            <div class="block-section">
                                <div>
                                    <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" > {{$t('common.btn.search')}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
        <footer-component/>
    </div>
</template>
<script>
  import HeaderComponent from '../components/HeaderComponent';
  import OrderApi from '../services/order';
  import FooterComponent from "../components/FooterComponent";

  export default {
    name: "order-tracker",
    components: {
      FooterComponent,
      HeaderComponent,
    },
    data() {
     return {
       orderNumber: '',
       errors: {
         isValid: false,
         orderNumber: '',
       }
     }
    },
    computed: {
    },
    mounted() {
    },
    watch: {
      'orderNumber': function (newValue) {
        this.errors.orderNumber = false;
        if (!newValue) {
          this.errors.orderNumber = this.$t('message.error.order_number');
        }
      }
    },
    methods: {
      initErrorMessage (mode) {
        if (mode && mode === 'inputPass') {
          this.errors = {
            isValid: false,
            orderNumber: false,
          }
        } else {
          this.errors = {
            isValid: false,
            orderNumber: false,
          }
        }
      },
      checkForm(e) {
        e.preventDefault();
        let isValidForm = true;
        this.initErrorMessage();

        if (!this.orderNumber) {
          this.errors.orderNumber = this.$t('message.error.order_number');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        }

        if (isValidForm) {
          this.searchOrderLatest(this.orderNumber);
        }
      },
      searchOrderLatest (orderNumber) {
        var vm = this;
        OrderApi.orderLatest({vm: this, order_number: orderNumber},  function (response) {
          vm.$router.push({name:'order-confirm', params: {uuid: response.data.uuid}});
        } ,function (error) {
          vm.notificationError(vm, error.message);
        })
      },
    }
  }
</script>
