<template>
    <div>
        <div class="header relative bg-white xs-shadow-down-1 z-100 lg:static lg:w-auto md:fixed md:top-0 md:w-full header--paddedLeft">
            <div class="container flex-center lg:justify-start lg:item-start relative h-full">
                <div class="relative z-1">
                    <router-link :to="'/'" class="header-logo block lg:pt-15 lg:py-15 lg:pl-30 bg-white lg:bg-transparent rounded-full" >
                      <span class="logo"></span>
                    </router-link>
                </div>
                <div class="block hidden absolute button-toggle-menu" style="right: 5px; top: 5px;">
                    <button @click="toggleMenu" class="flex items-center p-10 border-menu rounded border-gray-500 hover:text-gray-600 hover:border-gray-600">
                        <img src="../assets/images/bars.svg" alt="menu" title="menu" width="25">
                    </button>
                </div>
                <div v-on-clickaway="hideDropdown" class=" menu-header typography-6 flex pr-10 absolute top-0 left-0 bottom-0 lg:static lg:ml-auto md:ml-0 ml-15" :class="menuOpen ? 'block slide-left-menu': 'hidden-1024'">
                    <a class="close-sidebar lg:hidden"
                       @click.prevent.stop="toggleMenu()">
                        <img src="../assets/images/icon-close--white.svg"
                             class="">
                    </a>
                    <div class="flex items-center body-menu" >
                        <div class="flex items-center mobile-full-width">
                            <div class="flex whitespace-no-wrap md:pr-0 mt-2 mobile-flex-column mobile-full-width">
<!--                                <div class="flex flex-center hut-rewards&#45;&#45;flat-button mobile-side-menu" >-->
<!--                                    <router-link :to="{name: 'order-tracking'}" > {{$t('common.order_tracker')}}</router-link>-->
<!--                                </div>-->
<!--                                <div class="flex flex-center hut-rewards&#45;&#45;flat-button  mobile-side-menu " :class="{'flex-col': isAuth}">-->
<!--                                    <router-link v-if="!isAuth" :to="{name: 'login'}"> {{$t('common.login')}}</router-link>-->
<!--                                    <router-link class="w-full" :to="{name: 'profile'}" v-else> {{$t('customer.hello_name', {name: authUser.first_name})}}</router-link>-->
<!--                                    <p class="text-left w-full" v-if="isAuth">-->
<!--                                        {{$t('common.format_point', {amount: authUser.point || 0})}}-->
<!--                                    </p>-->
<!--                                </div>-->
                                <div v-if="!isMobile()" class="flex flex-center hut-rewards--flat-button mobile-side w-auto min-w-80px" >
                                    <router-link v-if="!isAuth" :to="{name: 'login'}"> {{$t('common.login')}}</router-link>
                                    <div v-else class="header-menu">
                                        <router-link class="w-full" :to="{name: 'profile'}">{{$t('common.my_account')}}</router-link>
                                        <div class="header-menu-content">
                                            <router-link class="header-menu-item" :to="'/ph-reward'" >PH reward</router-link>
                                            <router-link class="header-menu-item" :to="'/order/promotions'" >My voucher</router-link>
                                            <router-link class="header-menu-item" :to="'/order/point'" >My point</router-link>
                                            <router-link class="header-menu-item" :to="'#'" >Saved address</router-link>
                                            <router-link class="header-menu-item" :to="'#'" >Saved outlet</router-link>
                                            <span class="header-menu-item" :to="'#'" @click="logout">{{$t('common.logout')}}</span>
                                        </div>
                                    </div>
                                </div>
<!--                                <div class="flex flex-center hut-rewards&#45;&#45;flat-button mobile-side-menu">-->
<!--                                    <router-link :to="{name: 'register'}" v-if="!isAuth"> {{$t('common.register')}}</router-link>-->
<!--                                    <button type="button" @click="logout" v-else>{{$t('common.logout')}}</button>-->
<!--                                </div>-->
                            </div>
                        </div>
                        <div class="hidden lg:inline relative" v-if="orderType == $const.ORDERTYPE.DELIVERY">
                            <span class="icon-location mr-5 opacity-50 align-text-top ml-5"></span>
                            <router-link class=" border-b text-black" :to="'/'">
                                <span class="semi-bold"><span> {{$t('orders.delivery')}} </span></span>
                            </router-link><span> <span>{{$t('common.to')}} </span> <router-link class=" border-b text-black" :to="'/'"><span class="semi-bold">{{$t('common.combine_delivery',{streetName: localisation.deliveryInfo.streetName, postalCode: localisation.deliveryInfo.postalCode, state: localisation.deliveryInfo.state})}}</span></router-link> </span>
                        </div>
                        <div class="hidden lg:inline relative" v-if="orderType == $const.ORDERTYPE.CARRYOUT">
                            <span class="icon-location mr-5 opacity-50 align-text-top ml-5"></span>
                            <span>{{$t('common.ordering_for')}}</span>
                            <router-link class=" border-b text-black" :to="'/'">
                                <span class="semi-bold"><span> {{$t('orders.collection')}} </span></span>
                            </router-link><span> <span>{{$t('common.from')}} </span> <router-link class=" border-b text-black" :to="'/'"><span class="semi-bold">{{storeName}}</span></router-link> </span>
                        </div>
                        <div class="lg:border-l lg:inline md:pl-10 ml-10 mobile-full-width mobile-p10">
                            <div class="flex items-center h-full cursor-pointer" @click="showAsapPopup"><i class="icon-clock mr-5"></i>
                                <button
                                        class="border-solid border-grey-lightest border-b  border-l-0 border-t-0 border-r-0 text-black semi-bold" type="button"><span class="uppercase">{{pickedTime}} </span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="overlay-bg z-700"></div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mixin as clickaway } from 'vue-clickaway'
  import Cart from '../components/partials/cart'
  import types from '../store/mutationTypes'
  import {  mapGetters, mapActions  } from 'vuex'
  import ModalASAP from '../components/modal/ModalASAP'
  import ModalOrderTime from '../components/modal/ModalOrderTime'
  export default {
    mixins: [ clickaway ],
    components: {
      'cart': Cart,
    },
      data(){
        return {
          pickedTime : '',
          menuOpen: false,
          isVisible: false
        }
      },
      mounted(){
          this.$root.$on('collectionTimeUpdate', () => {
              this.pickedTime = this.getTime(this);
              if (this.menuOpen) {
                this.toggleMenu();
              }
          });
          this.pickedTime = this.getTime(this)
      },
    destroyed () {
      this.$root.$off('collectionTimeUpdate');
      document.body.classList.remove('sidebar-open')
    },
    computed: {
      ...mapGetters({
        storeName: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_NAME,
        orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
        cartData: 'moduleCart/' + types.CART_DETAIL,
        cartCount: 'moduleCart/' + types.CART_COUNT,
        localisation : 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
        isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
        authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
      })
    },
    methods: {
      ...mapActions({
        logout: 'moduleAuth/' + types.CUSTOMER_LOGOUT,
      }),
      showAsapPopup(){
        this.$modal.show(
            ModalOrderTime,null,
            {
                classes:(this.isMobile()) ? 'modal-order-time' : ''
            }
        )
      },
      toggleMenu() {
        this.menuOpen = !this.menuOpen
        if (this.menuOpen){
          document.body.classList.add('sidebar-open')
        } else {
          document.body.classList.remove('sidebar-open')
        }
      },
      hideDropdown() {
        // this.menuOpen = false
      }
    }
  }
</script>
