import types from '../../mutationTypes'

export default {
    [types.LOCALISATION_SAVE_STORE]({commit, state}, payload) {
        commit(types.LOCALISATION_SAVE_STORE, payload)
    },
    [types.LOCALISATION_RESET_STORE]({commit, state}) {
        commit(types.LOCALISATION_SAVE_STORE, {
            "clientUUID": '',
            "code": '',
            "distance": '',
            "lat": 0,
            "location": '',
            "long": 0,
            "name": '',
            "tierUUID": '',
            "uuid": '',
            "businessHours": [],
            "orderType": '',
            "orderNow": 1,
            "collection_time": '',
            "collectionTime" : '',
            "storeType": '',
            "storeBranch": '',
        })
    },
}
