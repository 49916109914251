<template>
  <div>
    <header-component />
    <div>
      <div class=" en-GB app-bg">
        <div class="container mt-20 mb-40">
          <div class="font-open-sans-bold uppercase text-center relative mb-20">
            <h1 class="px-40">{{$t('cart.checkout')}}</h1>
            <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
          </div>
          <div class="p-20 bg-white">
            <p v-if="status === ''" class="text-yellow t16 bold pt10 text-center">{{$t('message.error.payment_checking')}}</p>
            <p v-else-if="status.toUpperCase() === $const.PAYMENT_STATUS.COMPLETED" class="text-green t16 bold pt10 text-center"> {{$t('message.success.payment')}}</p>
            <p v-else-if="status.toUpperCase() === $const.PAYMENT_STATUS.PENDING" class="text-yellow t16 bold pt10 text-center">{{$t('message.error.payment_status_pending')}}</p>
            <p v-else class="text-red t16 bold pt10 text-center">{{$t('message.error.payment_status')}}</p>
          </div>
        </div>
      </div>
    </div>
    <footer-component />

  </div>
</template>
<script>
  import HeaderComponent from '../components/HeaderComponent';
  import FooterComponent from '../components/FooterComponent';
  import OrderApi from '../services/order'
  import {mapGetters, mapActions  } from 'vuex'
  import types from '../store/mutationTypes'
  import manthan from "../services/manthan";
  import branch from "../services/branch";

  export default {
    name: 'payment-status',
    components: {
      HeaderComponent,
      FooterComponent,
    },
    beforeMount: function () {

    },
    data () {
      return {
        status: '',
        payment: {},
      }
    },
    destroyed() {
    },
    created () {
      let transaction = this.$route.params.uuid || '';
      this.checkPaymentStatus(transaction);
    },
    computed:{
      ...mapGetters({
        cartData: 'moduleCart/' + types.CART_DETAIL,
        isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
        authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
        cartObject: 'moduleCart/' + types.CART_DETAIL,
        localisation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
      }),
    },
    methods:{
      ...mapActions({
        restLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
        resetCart: 'moduleCart/' +  types.CART_RESET_DATA
      }),
      clearCart () {
        this.restLocalisation();
        this.resetCart();
      },
      checkPaymentStatus (uuid) {
        var vm = this;
        let payload ={vm: this};
        const info = {
          firstName: vm.authUser.first_name,
          lastName: vm.authUser.last_name,
          line1: vm.localisation.deliveryInfo.streetNumber || '',
          line2: vm.localisation.deliveryInfo.streetName,
          city: vm.localisation.deliveryInfo.city,
          state: vm.localisation.deliveryInfo.state,
          zip: vm.localisation.deliveryInfo.postalCode,
          country: "Indo",
          emailAddress: vm.authUser.email,
          phoneNumber: vm.authUser.phone
        };
        let totalDiscount = 0;
        let couponCode = "";
        let cartProducts = [];
        if (vm.cartObject.products.length > 0) {
          for (const [index, item] of vm.cartObject.products.entries()) {
            totalDiscount += item.discount
            for (const value of item.product) {
              cartProducts.push({
                productId: value.uuid,
                productPosition: index + 1,
                action: "Checkout",
                quantity: item.quantity,
                productBasePrice: item.sub_price,
                tax: item.tax,
                discount: item.discount,
                couponCode: item.promotion_code,
              });
            }
          }
        }
        if (vm.cartObject.promotions.length > 0) {
          for (const [index, item] of vm.cartObject.promotions.entries()) {
            if (index == 0) {
              couponCode = item.code;
            } else {
              couponCode += ',' + item.code;
            }
          }
        }
        OrderApi.paymentStatus(uuid, payload,
          function (response) {
            vm.payment = response.data;
            vm.status = vm.payment.status;
            if (response.data.status == vm.$const.PAYMENT_STATUS.COMPLETED) {
              const dataSuccess = {
                transactionId: uuid,
                referrerPageUrl: window.location.href,
                OrderId: response.data.order_uuid,
                tenderType: response.data.payment_method_name,
                checkoutType: vm.isAuth ? 'Logged in by ' + vm.authUser.email : 'Guest',
                totalPrice: response.data.amount,
                shippingCost: vm.cartObject.information.delivery_fee > 0 ? vm.cartObject.information.sub_delivery_fee : 0,
                discount: totalDiscount,
                couponCode: couponCode,
                cartProducts: cartProducts,
                billingInfo: info,
                shippingInfo: info,
              }
              // eslint-disable-next-line no-undef
              manthan.execute(dataSuccess, 'eventTransactionComplete')
              branch.branchLog('PURCHASE', vm.cartObject.products, dataSuccess)
              vm.clearCart();
              setTimeout(function () {
                vm.$router.push({ 'name': 'order-confirm', 'params': { 'uuid': vm.payment.order_uuid } })
              }, 5000)
            }else{
              const dataManthan = {
                transactionId: uuid,
                referrerPageUrl: window.location.href,
                OrderId: response.data.order_uuid,
                tenderType: response.data.payment_method_name,
                checkoutType: vm.isAuth ? 'Logged in by ' + vm.authUser.email : 'Guest',
                totalPrice: response.data.amount,
                shippingCost: vm.cartObject.information.delivery_fee > 0 ? vm.cartObject.information.sub_delivery_fee : 0,
                discount: totalDiscount,
                couponCode: couponCode,
                cartProducts: cartProducts,
                reason: "Payment Failure",
                billingInfo: info,
                shippingInfo: info,
              }
              // eslint-disable-next-line no-undef
              manthan.execute(dataManthan,"eventTransactionFailure");
            }
          }, function (error) {
            vm.status = vm.$const.PAYMENT_STATUS.FAIL;
          }
        )
      }
    }
  }

</script>

<style>

</style>
