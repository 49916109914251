<template>
  <div :style="isMobile() ? 'background-color: #D9D9D9' : ''">
    <div v-if="!isMobile()">
      <img src="../assets/images/Banner_Benefit_Web.jpg" alt="" style="width: 100%">
    </div>

    <div id="section1" class="hut-detail p-20" :style="isMobile() ? 'border-radius: 0 0 10px 10px': ''">
      <div class="container">
        <div v-if="isMobile()" class="row">
          <div class="col-lg-12 col-md-12 col-xs-12 text-center">
            <img :src="this.url_cdn +'/uploads/PH_Hut_Rewards_New_Logo-mobile.png'" class="inline-block" alt="">
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12">
            <img src="../assets/images/Banner-Welcome-Pizza-black-2.png" alt="" style="width: 100%; border-radius: 10px; height: 100px">
          </div>
        </div>

        <div class="row text-white font-bold">
          <div class="col-lg-6 col-md-6 col-xs-12">
            <div class="mt-20 mb-20" style="display: flex;">
              <div class="box-img">
                <img src="../assets/images/Mania-Red@2x.png">
              </div>
              <div class="mt-5">
                <p style="font-size: 16px;">Welcome Pizza</p>
              </div>
            </div>
            <div class="mt-20 mb-20" style="display: flex;">
              <div class="box-img">
                <img src="../assets/images/Fan_Red.png">
              </div>
              <div class="mt-5">
                <p style="font-size: 16px;">Slices</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-xs-12">
            <div class="mt-20 mb-20" style="display: flex;">
              <div class="box-img">
                <img src="../assets/images/membership voucher.png">
              </div>
              <div>
                <p style="font-size: 16px;" class="mt-5" >Membership Voucher</p>
              </div>
            </div>
            <div class="mt-20 mb-20" style="display: flex;">
              <div class="box-img">
                <img src="../assets/images/image_treasurehut.png" class="mt-5" >
              </div>
              <div>
                <p style="font-size: 16px;" class="mt-5">Treasure Hut</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- section1-->

    <div id="section2" class="bg-white p-20" :class="isMobile() ? 'mt-20': ''" :style="isMobile() ? 'border-radius: 10px': ''">
      <div class="container pt-40">
        <div class="row" style="width: 100%">
          <div class="col-lg-6 col-md-6 col-xs-12">
            <div>
              <p style="font-size: 20px;" class="text-red"><b>More Vouchers <span class="text-black">by tiers</span></b></p>
              <p class="text-grey">Make your every bite more rewarding</p>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-xs-12">
            <table class="tbl-content font-bold">
              <tr>
                <td class="mr-20 text-left">
                  <span class="text-red">Tiers</span>
                  <br/>
                  <span class="text-red">Level of Membership</span>
                </td>
                <td>
                  <img src="../assets/images/Fan-Red@2x.png" class="img-icon-small" alt="Fan">
                  <div class="text-red">Fan</div>
                </td>
                <td>
                  <img src="../assets/images/Lover-Red@2x.png" class="img-icon-small" alt="Lover">
                  <div class="text-red">Lovers</div>
                </td>
                <td>
                  <img src="../assets/images/Mania-Red@2x.png" class="img-icon-small" alt="Mania">
                  <div class="text-red">Mania</div>
                </td>
              </tr>
              <tr class="text-grey">
                <td>Purchase in 365days</td>
                <td>~499Rb</td>
                <td>~1,499Rb</td>
                <td>1,500Rb~</td>
              </tr>
              <tr class="text-grey">
                <td>Welcome Pizza</td>
                <td><img src="../assets/images/Vector.png" class="img-icon-check"></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="text-grey">
                <td>Garlic Bread</td>
                <td></td>
                <td><img src="../assets/images/Vector.png" class="img-icon-check"></td>
                <td></td>
              </tr>
              <tr class="text-grey">
                <td>Coca-cola</td>
                <td></td>
                <td><img src="../assets/images/Vector.png" class="img-icon-check"></td>
                <td></td>
              </tr>
              <tr class="text-grey">
                <td>Coca-cola (dine in)</td>
                <td></td>
                <td></td>
                <td><img src="../assets/images/Vector.png" class="img-icon-check"></td>
              </tr>
              <tr class="text-grey">
                <td>Beef Arrabbiata</td>
                <td></td>
                <td></td>
                <td><img src="../assets/images/Vector.png" class="img-icon-check"></td>
              </tr>
              <tr class="text-grey">
                <td>Birthday Pizza</td>
                <td></td>
                <td><img src="../assets/images/Vector.png" class="img-icon-check"></td>
                <td><img src="../assets/images/Vector.png" class="img-icon-check"></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div><!-- section2-->

    <div id="section3" class="bg-white p-20" :class="isMobile() ? 'mt-20': ''" :style="isMobile() ? 'border-radius: 10px': ''">
      <div class="container">
        <div class="row" style="width: 100%">
          <div class="col-lg-6 col-md-6 col-xs-12">
            <div class="pt-20">
              <p style="font-size: 20px;" class="text-black"><b>Start earning  <span class="text-red">Slices</span></b></p>
              <p style="font-size: 20px;"><b>toward a free pizza</b></p>
              <p class="text-grey">Every Rp10,000, get 1 Slice. Let`s keep it up!</p>
            </div>
          </div>

          <div class="col-lg-6 col-md-6  col-xs-12">
            <table class="tbl-content">
              <tr>
                <td style="text-align: center !important;">
                  <img src="../assets/images/Garlic-Bread.png" class="img-icon-medium" alt="">
                  <p style="font-size: 20px;"><b>25 slices</b></p>
                  <p>Free Snack</p>
                </td>
                <td>
                  <img src="../assets/images/Beef-Spaghetti 2.png" class="img-icon-medium" alt="">
                  <p style="font-size: 20px;"><b>50 slices</b></p>
                  <p>Free Pasta</p>
                </td>
                <td>
                  <img src="../assets/images/Pan-Super-Supreme.png" class="img-icon-medium" alt="">
                  <p style="font-size: 20px;"><b>100 slices</b></p>
                  <p>Free Pizza</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div><!-- section3-->

    <div id="section4" class="bg-white p-20" :class="isMobile() ? 'mt-20': ''" :style="isMobile() ? 'border-radius: 10px 10px 0 0': ''">
      <div class="container">
        <div class="row" style="width: 100%">
          <div class="col-lg-6 col-md-6 col-xs-12">
            <div class="pt-20" :class="isMobile() ? 'inline-block' : ''">
              <p style="font-size: 20px;" class="text-black"><b>Life is full of <br/><span class="text-red">Surprises!!</span></b></p>
              <p class="text-grey">Every time you make an order <br/>A treasure awaits you. </p>
            </div>
            <div v-if="isMobile()" class="inline-block">
              <img src="../assets/images/treasurehut_icon.png" style="max-width: 130px" alt="">
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-xs-12">
            <table class="tbl-content">
              <tr v-if="!isMobile()">
                <td style="text-align: center !important;">
                  <img src="../assets/images/treasurehut_icon.png" class="img-medium" alt="">
                </td>
                <td>
                  <img src="../assets/images/grey.png" class="img-medium" alt="">
                </td>
                <td>
                  <img src="../assets/images/bucket-hat.png" class="img-medium" alt="">
                </td>
                <td>
                  <img src="../assets/images/plll.png" class="img-medium" alt="">
                </td>
                <td>
                  <img src="../assets/images/Isolated_white_t-shirt_front.png" class="img-medium" alt="">
                </td>
                <td>
                  <img src="../assets/images/tumbler.png" class="img-medium" alt="">
                </td>
              </tr>
              <tr v-else>
                <td>
                  <div class="scroll-list">
                    <div class="list-container">
                      <img src="../assets/images/grey.png" alt="">
                      <img src="../assets/images/bucket-hat.png" alt="">
                      <img src="../assets/images/plll.png" alt="">
                      <img src="../assets/images/Isolated_white_t-shirt_front.png" alt="">
                      <img src="../assets/images/tumbler.png" alt="">
                    </div>
                  </div>

                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div><!-- section4-->

  </div>

</template>

<script>

const CDN_URL = process.env.VUE_APP_CDN_URL
export default {
  name: "PhRewardDetailDesktop",
  data() {
    return {
      url_cdn: CDN_URL,
      showToggle: {
        lv1 : false,
        lv2 : false,
        lv3 : false,
      }
    }
  },
  methods : {
    toggle(showToggle) {
      return this.showToggle[showToggle] = !this.showToggle[showToggle]
    }
  }
}
</script>

<style scoped>
.scroll-list{
  overflow-x: auto;
  overflow-y: hidden;
}
.list-container {
  display: flex;
  white-space: nowrap;
}
.list-container >img {
  padding-right: 10px;
  height: 70px !important;
  max-width: 100% !important;
}
.hut-detail {
  background-color: #C8102E;
}
.box-img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  background-color: #FFFFFF;
  border-radius: 4px;
}
.box-img >img {
  max-width: 100%;
  display: initial!important;
}
.img-icon-check {
  display: initial !important;
  width: 16px;
  height: 16px;
}
.img-icon-small {
  display: initial !important;
  max-width: 100%;
  height: 40px;
}
.img-icon-medium {
  display: initial !important;
}
.img-medium {
  display: initial !important;
}

.content-toggle > div {
  color: #7D8084;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 1px solid #F5F5F6;
}
.tbl-content {
  width: 100%;
}
.tbl-content tr>td {
  text-align: center;
  padding: 20px 20px 20px 0;
}
.tbl-content tr>td:first-child {
  text-align: left;
}
.tbl-content tr {
  border-bottom: 0.5px solid #2e333b54;
}
.tbl-content tr:last-child {
  border-bottom: 0px !important;
}
</style>
