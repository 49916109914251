<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="title"></slot>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button" @click="$emit('close')">
                <span aria-hidden="true">×</span>
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
  }
</script>

<style scoped>
.modal-body {
  margin-bottom: 0;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s fade;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 700px;
  min-height: 600px;
  margin: 0px auto;
  padding: 0 auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s fade;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  padding: 0;
  display: inline;
}

.modal-header h3 {
  margin-top: 0;
}

.modal-footer button {
  border: 0;
  background-color: rgba(255,255,255,.7);
  position: absolute;
  font-size: 26px;
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 30px;
  top: 170px;
  right: 550px;
  transition: .2s all ease-in-out;
  border-radius: 4px;
  color: #000;
}


.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 767.98px) {
  .modal-container {
    max-width: 325px;
    min-height: 300px;
    margin: 0px auto;
    padding: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s fade;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-footer button {
    border: 0;
      background-color: rgba(255,255,255,.7);
      position: absolute;
      right: -30px;
      top: 0;
      font-size: 26px;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 30px;
      top: 130px;
      right: 20px;
      transition: .2s all ease-in-out;
      border-radius: 4px;
      color: #000;
  }

  .modal-body {
    padding: 0 1rem;
  }
}

</style>