<template>
  <div>
    <div class=" en-GB bg-white">
      <div class="font-open-sans-bold uppercase text-center relative mb-20">
        <h3 class="px-40">CREATE AN ACCOUNT</h3>
        <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50"
                @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
      </div>
      <div class="container mt-20 mb-40">
        <div class="bg-white">
          <div class="md:flex">
            <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" action=""
                  @submit="checkForm">
              <div class="block-section">
                <div class="md:flex lg:flex ">
                  <div class="form-field">
                    <div class="xs:flex xs:flex-center mb-5">
                      <label class="label mb-0">
                        Join us and enjoy free pizza today
                      </label>
                    </div>
                    <input id="first_name" class="input xs-mb-10 form-control" ref="first_name"
                           v-bind:class="{'has-error': errors.first_name, 'has-success': errors.first_name === false}"
                           name="first_name" v-model="customer.first_name" placeholder="First name"
                           maxlength="255" type="text">
                    <span class="float-left text-red" v-if="errors.first_name">{{ errors.first_name }}</span>
                  </div>
                  <div class="form-field">
                    <input id="last_name" class="input xs-mb-10 form-control" ref="last_name"
                           v-bind:class="{'has-error': errors.last_name, 'has-success': errors.last_name === false}"
                           name="last_name" v-model="customer.last_name" placeholder="Last name"
                           maxlength="255" type="text">
                    <span class="float-left text-red" v-if="errors.last_name">{{ errors.last_name }}</span>
                  </div>
                </div>
                <div class="form-field">
                  <div class="input-group">
                    <div class="input-group-append"><span class="input-group-text">{{ $const.PREFIX_PHONE }}</span>
                    </div>
                    <input id="phone" class="input xs-mb-10 form-control" ref="phone"
                           v-bind:class="{'has-error': errors.phone, 'has-success': errors.phone === false}"
                           name="phone" v-model="customer.phone" placeholder="Phone number"
                           :maxlength="$const.PHONE.MAX" type="text">
                  </div>
                  <span class="float-left text-red" v-if="errors.phone">{{ errors.phone }}</span>
                </div>
                <div class="form-field">
                  <input id="email" class="input xs-mb-10" ref="email"
                         v-bind:class="{'has-error': errors.email, 'has-success': errors.email === false}"
                         name="email" v-model="customer.email" placeholder="Email"
                         maxlength="255" type="email">
                  <span class="float-left text-red" v-if="errors.email">{{ errors.email }}</span>
                </div>
                <div class="form-field">
                  <div class="input-group">
                    <input id="password" class="input form-control border-right-top-bottom-none xs-mb-10" ref="password"
                           v-bind:class="{'has-error': errors.password, 'has-success': errors.password === false}"
                           name="password" v-model="customer.password" placeholder="Password"
                           maxlength="255"
                           :type="showPass ? 'text' : 'password'"
                    >
                    <div class="input-group-append"><span
                        class="input-group-text border-left-top-bottom-none cursor-pointer"
                        @click="showPass = !showPass">
                                            <i class="fa " aria-hidden="true"
                                               v-bind:class="{'fa-eye-slash': !showPass, 'fa-eye': showPass}"> </i></span>
                    </div>
                  </div>
                  <span class="float-left text-red" v-if="errors.password">{{ errors.password }}</span>
                </div>
                <div class="form-field">
                  <div class="input-group">
                    <input id="passwordConfirm" class="input form-control border-right-top-bottom-none xs-mb-10"
                           ref="password_confirm"
                           v-bind:class="{'has-error': errors.passwordConfirm, 'has-success': errors.passwordConfirm === false}"
                           name="passwordConfirm" v-model="customer.passwordConfirm" placeholder="Confirm password"
                           :type="showPassConfirm ? 'text' : 'password'"
                           maxlength="255">
                    <div class="input-group-append"><span
                        class="input-group-text border-left-top-bottom-none cursor-pointer"
                        @click="showPassConfirm = !showPassConfirm">
                                            <i class="fa " aria-hidden="true"
                                               v-bind:class="{'fa-eye-slash': !showPassConfirm, 'fa-eye': showPassConfirm}"> </i></span>
                    </div>
                  </div>
                  <span class="float-left text-red"
                        v-if="errors.passwordConfirm">{{ errors.passwordConfirm }}</span>
                </div>
                <div class="form-field text-left">
                  <input id="birthday"
                         v-model="birthdayData"
                         class="input xs-mb-10"
                         ref="birthday"
                         name="birthday"
                         placeholder="Select Your Birthday"
                         maxlength="255"
                         :type="showTextBirthday ? 'date' : 'text'"
                         @click="() => {!showTextBirthday ? showTextBirthday = true: ''}">
                  <div v-if="errors.birthday"><span class="float-left text-red" >{{ errors.birthday }}</span><br></div>
                  <span class="text-grey">Enjoy free birthday pizza when level up. Once registered it cannot be updated.</span>
                </div>
                <div class="form-field text-left">
                  <div class="xs:flex xs:flex-center mb-5">
                    <label class="label mb-0" for="genders">{{ $t('customer.gender') }}
                    </label>
                  </div>
                  <div class="input-group">
                    <div class="input-tem">
                      <input id="gender_male"
                             v-model="customer.gender.code"
                             class="form-control"
                             name="gender"
                             type="radio"
                             value="1"
                      >
                      <label class="text-grey" for="gender_male">Male</label>
                    </div>
                    <div class="input-tem">
                      <input id="gender_female"
                             v-model="customer.gender.code"
                             class="form-control"
                             name="gender"
                             type="radio"
                             value="2">
                      <label class="text-grey" for="gender_female">Female</label>
                    </div>
                    <div class="input-tem">
                      <input id="gender_na"
                             v-model="customer.gender.code"
                             class="form-control"
                             name="gender"
                             type="radio"
                             value="0">
                      <label class="text-grey" for="gender_na">NA</label>
                    </div>
                  </div>
                </div>
                <button class="button button--red flex-1 rounded px-2 0"
                        style="background-color: #C8102E; min-height: 42px;">
                  Sign Up
                </button>
                <div class="text-center" style="padding-top: 20px">
                  <router-link :to="{name: 'login'}">Already Registered? <span class="text-red t15 cursor-pointer">Login</span>
                  </router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomerApi from '../services/customer';

import 'vue-select/dist/vue-select.css';
import ModalVerifyToken from '../components/modal/ModalVerifyToken'
import types from '../store/mutationTypes'
import {createNamespacedHelpers} from 'vuex'

const {mapActions} = createNamespacedHelpers('moduleAuth')

export default {
  name: "register",
  components: {
  },
  data() {
    return {
      showTextBirthday: false,
      tokenKey: '',
      showPass: false,
      showPassConfirm: false,
      genders: [],
      birthdayData: undefined,
      errors: {
        isValid: false,
        first_name: '',
        last_name: '',
        phone: '',
        birthday: '',
        birth_month: '',
        birth_year: '',
        birth_date: '',
        password: '',
        passwordConfirm: '',
        email: ''
      },
      customer: {
        first_name: '',
        last_name: '',
        phone: '',
        gender: {},
        birth_date: {},
        birth_month: {},
        birth_year: {},
        password: '',
        passwordConfirm: '',
        email: '',
        term: 0
      }
    }
  },
  computed: {},
  mounted() {
    this.initData();
  },
  watch: {
    'customer.phone': function (newValue, oldValue) {
      this.errors.phone = false;

      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.$set(this.customer, 'phone', oldValue)
        return
      }

      if (!newValue) {
        this.errors.phone = this.$t('message.error.phone');
      } else if (newValue.length < this.$const.PHONE.MIN || newValue.length > this.$const.PHONE.MAX) {
        this.errors.phone = this.$t('message.error.invalid_phone', {
          min: this.$const.PHONE.MIN,
          max: this.$const.PHONE.MAX
        });
      }
    },
    'customer.email': function (newValue) {
      this.errors.email = false;
      if (!newValue) {
        this.errors.email = this.$t('message.error.email');
      } else if (!this.validEmail(newValue)) {
        this.errors.email = this.$t('message.error.invalid_email');
      }
    },
    'customer.first_name': function (newValue) {
      this.errors.first_name = false;
      if (!newValue) {
        this.errors.first_name = this.$t('message.error.first_name');
      }
    },
    'customer.last_name': function (newValue) {
      this.errors.last_name = false;
      if (!newValue) {
        this.errors.last_name = this.$t('message.error.last_name');
      }
    },
    'customer.password': function (newValue) {
      this.errors.password = false;
      if (!newValue) {
        this.errors.password = this.$t('message.error.password');
      }
    },
    'customer.passwordConfirm': function (newValue) {
      this.errors.passwordConfirm = false;
      if (!newValue) {
        this.errors.passwordConfirm = this.$t('message.error.password_confirm');
      }
    },
  },
  methods: {
    ...mapActions({
      setToken: types.CUSTOMER_SET_TOKEN,
      getProfileByToken: types.CUSTOMER_GET_INFO_BY_TOKEN,
    }),
    initData() {
      let _genders = this.$const.GENDERS;
      for (let i in _genders) {
        _genders[i].label = this.$t(_genders[i].label);
      }
      this.genders = _genders;
      if (this.genders.length > 0) {
        this.customer.gender = this.genders[0];
      }

    },
    initErrorMessage() {
      this.errors = {
        isValid: false,
        first_name: false,
        last_name: false,
        phone: false,
        birthday: '',
        birth_date: '',
        birth_month: '',
        birth_year: '',
        password: false,
        passwordConfirm: false,
        email: false
      }
    },
    checkForm(e) {
      e.preventDefault();
      let isValidForm = true;
      this.initErrorMessage();

      if (!this.customer.first_name) {
        this.errors.first_name = this.$t('message.error.first_name');
        if (isValidForm) {
          this.$refs.first_name.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.last_name) {
        this.errors.last_name = this.$t('message.error.last_name');
        if (isValidForm) {
          this.$refs.last_name.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.phone) {
        this.errors.phone = this.$t('message.error.phone');
        if (isValidForm) {
          this.$refs.phone.focus();
        }
        isValidForm = false;
      } else if (this.customer.phone.length < this.$const.PHONE.MIN || this.customer.phone.length > this.$const.PHONE.MAX) {
        this.errors.phone = this.$t('message.error.invalid_phone', {
          min: this.$const.PHONE.MIN,
          max: this.$const.PHONE.MAX
        })
        if (isValidForm) {
          this.$refs.phone.focus();
        }
        isValidForm = false;
      }

      if (!this.customer.email) {
        this.errors.email = this.$t('message.error.email');
        if (isValidForm) {
          this.$refs.email.focus();
        }
        isValidForm = false;
      } else if (!this.validEmail(this.customer.email)) {
        this.errors.email = this.$t('message.error.invalid_email');
        if (isValidForm) {
          this.$refs.email.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.password) {
        this.errors.password = this.$t('message.error.password');
        if (isValidForm) {
          this.$refs.password.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.passwordConfirm) {
        this.errors.passwordConfirm = this.$t('message.error.password_confirm');
        if (isValidForm) {
          this.$refs.password_confirm.focus();
        }
        isValidForm = false;
      } else if (this.customer.passwordConfirm != this.customer.password) {
        this.errors.passwordConfirm = this.$t('message.error.password_confirm_not_match');
        if (isValidForm) {
          this.$refs.password_confirm.focus();
        }
        isValidForm = false;
      }
      if (isValidForm) {
        this.createNew();
      }
    },
    createNew() {
      var vm = this;
      let payload = {
        "email": this.customer.email,
        "first_name": this.customer.first_name,
        "gender": Number(this.customer.gender.code),
        "last_name": this.customer.last_name,
        "password": this.customer.password,
        "phone": this.customer.phone,
        "birthday": this.birthdayData,
        "vm": this
      }
      CustomerApi.register(payload,
          function (response) {
            vm.tokenKey = response.data.key;
            vm.verifyToken();
          }, function (data) {
            if (data.code == vm.$const.ERROR_CODE.REGISTER.EMAIL_EXIST) {
              vm.errors.email = data.message;
            }
            else if (data.code == vm.$const.ERROR_CODE.REGISTER.PASSWORD) {
              vm.errors.password = data.message;
            }
            else if (data.code == vm.$const.ERROR_CODE.REGISTER.PHONE) {
              vm.errors.phone = data.message;
            } else {
              vm.notificationError(vm, data.message)
            }
          }
      )
    },
    verifyToken() {
      var vm = this;
      this.$modal.show(ModalVerifyToken, {
            keyToken: vm.tokenKey,
            mode: vm.$const.VERIFY_TOKEN_METHOD.REGISTER,
            callbackFunc: function (xToken) {
              if (xToken) {
                vm.setToken(xToken);
                vm.getProfileByToken();
                vm.notificationSuccess(vm, vm.$t('message.success.register'));
                if (vm.$route.query && vm.$route.query.callback) {
                  return vm.redirectInternal(vm, vm.$route.query.callback);
                }
                vm.$router.push({name: 'home', params: {is_new: '1'}});
              }
            }
          },
          {
            'class': 'modal-register-form'
          },
          {
            'before-open': function () {
            },
            'before-close': function () {
            }
          })
    }
  }
}
</script>
<style>
.input-tem {
  display: flex;
  align-items: center;
  margin-right: 30px
}

.input-tem input {
  margin-right: 10px
}
</style>
