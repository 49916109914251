<template>
  <div v-if="isImg">
    <img :src="item.thumbnail" :alt="item.name" @click="setVoucher">
  </div>
  <div v-else class="item" @click="setVoucher">
    <div class="image-wrapper">
      <img v-if="item.thumbnail" :src="item.thumbnail">
    </div>
    <div class="content font-open-sans-bold"  :class="cssAdditional">
      <p>{{item.name}}</p>
      <div class="overflow-hidden" style="color: #313131">
        <p style="font-size: 12px">{{'Valid until ' + item.end_date}}</p>
        <p style="font-size: 12px">{{item.promotion_types_text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import cartAPI from "@/services/cart";
import mutationTypes from "@/store/mutationTypes";
import types from "@/store/mutationTypes";

export default {
  name: "VoucherSingles",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    isImg: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.orderTypeID = this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, 0)]
    this.storeTypeID =  this.orderLocation.storeType
    if ((this.orderTypeID > 0 && !(this.item.promotion_types.indexOf(this.orderTypeID) >=0)) ||
        (this.storeTypeID > 0 && !(this.item.store_type_ids.indexOf(this.storeTypeID) >=0))
    ) {
      this.cssAdditional = ' gray-out'
      this.isValidVoucher = false
    }
  },
  data() {
    return {
      isValidImg: true,
      storeTypeID: 0,
      orderTypeID: 0,
      isValidVoucher: true,
      cssAdditional: 'cursor-pointer'
    }
  },
  methods: {
    ...mapActions({
      getCartDetail: 'moduleCart/' + mutationTypes.CART_GET_CART_DETAIL,
      setCartDetail: 'moduleCart/' + mutationTypes.CART_SET_DATA,
    }),
    setVoucher() {
      if (!this.isValidVoucher) {
        return
      }
      if(this.storeUuid != undefined && this.storeUuid !== '') {
        if (!this.cartUuid) {
          return this.createCart();
        }
        this._redeemCoupon(this.cartUuid);
      } else {
        this.$emit('chosen-store', this.item)
      }
    },
    createCart () {
      const vm = this;
      let dataProduct = {
        "cart_uuid": this.cartUuid || '',
        "disposition": this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        "items": [],
        "now_order": this.orderNow,
        "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
        "store_uuid": this.storeUuid
      }

      cartAPI.addCart(dataProduct, response => {
        vm.setCartDetail({payload: response.data});
        vm._redeemCoupon(response.data.information.cart_uuid);
      }, error => {
        vm.notificationError(vm, error.message);
      })
    },
    _redeemCoupon ( uuidCart = '') {
      let params = {
        "cart_uuid": uuidCart,
        "code": this.item.code || '',
        "vm": this
      }
      const vm = this;
      cartAPI.redeem(params, function (response) {
        if (response.data.status === 0) {
          let proUuid = ''
          let isCombo = false;
          if (response.data.products && response.data.products.length > 0) {
            proUuid = response.data.products[0].uuid;
            if (response.data.products[0].is_combo == 1) {
              isCombo = true;
            }
          }
          if (isCombo) {
            return  vm.$router.push({name: 'combo-detail', params: {uuid: proUuid, code: vm.item.code}})
          }
          return vm.$router.push({name: 'redeem', params: {code: vm.item.code, uuid: proUuid}});
        }
        vm.notificationSuccess(vm, vm.$t('message.success.redeem_coupon_success'));
        vm.getCartDetail({payload: {uuid: vm.cartUuid}});
      }, function (error) {
        vm.notificationError(vm, error.message);
      });
    },
  },
  computed: {
    ...mapGetters({
      orderNow: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_NOW,
      orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
      storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      orderTime: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_COLLECTION_TIME,
      timezone: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_TIMEZONE,
      cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
      orderLocation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
      localisation: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
    }),
  },
}
</script>

<style scoped>
  .item {
    display: flex;
    flex-direction: column;
    max-height: 255px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 5px 0px;
    cursor: pointer;
  }
  .image-wrapper {
    width: 100%;
    min-height: 120px;
    max-height: 120px;
  }
  .image-wrapper > img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
  }
  .content {
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: 0px 12px 12px 12px;
    max-height: calc(100% - 120px);
  }
  .content > p {
    line-height: 20px;
    margin-bottom: 4px;
  }
  .content > div > p {
    max-height: 100%;
  }
  .gray-out {
    background-color: #9c9fa7;
    opacity: 0.8;
  }
</style>
