<template>
  <div class="list-item list-item--upsell upsell-item">
    <div role="button">
      <div class="list-item__image flex-center flex" ref="itemImage" @click="addItemIntoCart">
        <img :src="img_url+product.image" class="block " :alt="product.name" :title="product.name" v-if="!emptyVariable(product.image)">
      </div>
    </div>
    <div class="mt-auto upsell-name">
      <p class="typography-7 font-bold list-item__name flex-1 text-center mt-2">{{product.name}}</p>
    </div>
    <div>
      <p class="typography-7 font-semibold list-item__desc flex-1 text-center p-0 pb-5">{{$t('common.format_currency', {amount: formatCurrency(product.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</p>
    </div>
    <div>
      <div>
        <button @click="addItemIntoCart" class="button button--md button--red flex-1 font-semibold t15 mt14">
          <span>{{$t('common.btn.add')}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto'

export default {
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    amount: 0,
    img_url: '',
  }),

  mounted() {
    const size = this.$refs.itemImage.clientWidth
    this.img_url = this.$const.LINK_STATIC_FLY + "width=" + size + "/"
  },

  methods: {
    addItemIntoCart(){
      this.handleProductClick();
      if (this.$route.name !== 'category' || this.$route.params.slug !== this.product.category.name.toLowerCase() || this.$route.query.product !== this.product.uuid) {
        this.$router.push({name: 'category', params: {slug: this.product.category.name.toLowerCase()}, query: {is_upsell: 1, product: this.product.uuid}})
      } else {
        document.body.classList.remove('basket-open')
        const headHeight = document.body.querySelector('.header').clientHeight
        const categoryHeight = document.body.querySelector('.menus-fixed-top-position').clientHeight
        const offset = this.isMobile() ? -(headHeight + categoryHeight + 5) : -5
        VueScrollTo.scrollTo(`[product-uuid="${this.product.uuid}"]`, 0.5, { offset: offset })
        document.body.querySelector(`[product-uuid="${this.product.uuid}"]`).classList.add('highlight-upsell')
        setTimeout(() => {
          document.body.querySelector(`[product-uuid="${this.product.uuid}"]`).classList.remove('highlight-upsell')
        }, 3000)
      }
    },
    handleProductClick () {
      let dataPro = {
        'name': this.product.name,
        'id': this.product.uuid,
        'price': this.product.sub_price,
        'category': this.product.category && this.product.category.name ? this.product.category.name : '',
        'url': window.location.href
      };
      this.eventProductClick(dataPro);
    },
  },
}
</script>

<style scoped>
  .upsell-item {
    border: 0;
    min-width: 100px;
    margin: 11px;
    flex: 1 1 0;
    margin-top: 0;
  }
  .upsell-item .list-item__name {
    font-weight: 700 !important;
    line-height: 1.3;
    padding: 0;
  }
  .upsell-item .list-item__desc {
    font-weight: 600 !important;
    padding: 0;
    padding-bottom: 2px;
  }
  .upsell-name {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .upsell-item.list-item:hover:after {
    opacity: 0
  }
</style>

<style>
  .highlight-upsell {
    animation-duration: 2s;
    animation-name: highlighting;
  }
  
  @keyframes highlighting {
    from {
      outline: rgba(200, 16, 46, 0) solid 0px;
    }

    15% {
      outline: rgba(200, 16, 46, 1) solid 2px;
    }

    40% {
      outline: rgba(200, 16, 46, 0) solid 0px;
    }

    65% {
      outline: rgba(200, 16, 46, 1) solid 2px;
    }

    to {
      outline: rgba(200, 16, 46, 0) solid 0px;
    }
  }
</style>