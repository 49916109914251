<template>
  <div>
    <header-component/>
    <div class=" en-GB app-bg">
      <div class="container mt-20 mb-40">
        <div class="font-open-sans-bold text-center relative mb-20">
          <h2 class="px-40">Delivery Details </h2>
          <router-link class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" :to="{name: 'treasures'}"><i class="ml-5 icon-caret-left"></i></router-link>
        </div>
        <div class="p-20 bg-white ">
          <div class="contain-form m-auto w-full">
            <div class="block-section">
              <div class="flex-row flex flex-center pb-10">
                <div class="flex-1">
                  <div class="label mb-0">Recipient name</div>
                  <div>{{info.receipt_name}}</div>
                </div>
                <router-link :to="{name: 'update-reward-detail', params: {info}}" class="text-blue">{{$t('common.btn.change')}}</router-link>
              </div>
              <div class="flex-row flex flex-center pb-10">
                <div class="flex-1">
                  <div class="label mb-0">Phone number</div>
                  <div>{{info.receipt_phone}}</div>
                </div>
                <router-link :to="{name: 'update-reward-detail', params: {info}}" class="text-blue">{{$t('common.btn.change')}}</router-link>
              </div>
              <div class="flex-row flex flex-center pb-10">
                <div class="flex-1">
                  <div class="label mb-0">Address</div>
                  <div>{{info.receipt_addr}}</div>
                </div>
                <router-link :to="{name: 'update-reward-detail', params: {info}}" class="text-blue">{{$t('common.btn.change')}}</router-link>
              </div>
              <div class="flex-row flex flex-center pb-30">
                <div class="flex-1">
                  <div class="label mb-0">Additional Address Detail (Optional) </div>
                  <div>{{info.receipt_addr_detail}}</div>
                </div>
                <router-link :to="{name: 'update-reward-detail', params: {info}}" class="text-blue">{{$t('common.btn.change')}}</router-link>
              </div>
              <div class="flex-row flex flex-center pb-10">
                <div class="flex-1">
                  <div class="label mb-0">Terms and condition apply</div>
                  <div>
                    <ol style="margin-left: 20px; font-size: 10px; color: #7D8084">
                      <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non, sit quisque mi. Sit consequat placerat tellus risus lacus, id mauris varius elementum.</li>
                      <li> Tempor vel lectus facilisis neque urna mauris sapien. Sit malesuada eu maecenas sed risus, donec. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non, sit quisque mi. Sit consequat placerat tellus risus lacus, id mauris varius elementum. </li>
                      <li> Malesuada eu maecenas sed risus, donec.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non, sit quisque mi. Sit consequat placerat tellus risus lacus, id mauris varius elementum. </li>
                      <li> Eu maecenas sed risus, donec.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam non, sit quisque mi. Sit consequat placerat tellus risus lacus, id mauris varius elementum.</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" @click="claimTreasureGift()">Claim Treasure</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import couponAPI from "@/services/coupon";
export default {
  name: "ClaimGiftReward",
  components: {HeaderComponent},
  data() {
    return {
      code: '',
      info: {},
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData(){
      const vm = this;
      this.code = this.$route.params.order_code;
      let params = {
        vm: vm
      };
      vm.list = [];
      if (this.code) {
        couponAPI.getTreasureDetail(this.code, params, function (response) {
          vm.info = response.data;
        })
      } else {
        this.$router.push({name:'error-404'})
      }
    },
    claimTreasureGift(){
      this.$router.push({name:'treasures', params: {uuid: this.info.uuid}})
    }
  }
}
</script>
<style>
.group-tab-location div{
  cursor: pointer;
  color: #31708f;
  background-color: #c0d1da;
  border-color: #c0d1da;
  padding: 5px 15px;
  border-bottom: 1px solid transparent;
}
.group-tab-location div.active {
  background-color: #a2b7bb;
}
.list-item-history .info-order {
  background-color: #fff;
  border: 1px solid #e9ebee;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
  margin-bottom: 0;
  border-radius: 4px;
  margin-top: 10px;
}
.list-item-history .body-order {
  padding: 15px;
}
.list-item-history .header-order {
  cursor: pointer;
  color: #31708f;
  background-color: #e9ebee;
  border-color: #e9ebee;
  padding: 5px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.list-item-history .header-order .title-order {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

</style>