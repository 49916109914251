<template>
  <div class="bg-white">
    <div class="container mt-10 mb-10 px-15" style="text-align: center; padding-right: 15px; padding-left: 15px">
      <span>To earn points in Dine-in show this to the staff</span>
      <div v-if="authUser.phone" class="phone-box">{{authUser.phone}}</div>
    </div>
    <div class="flex-row flex flex-center px-15">
      <div class="flex-1 font-open-sans-bold">
        <div>Your History</div>
      </div>
    </div>
    <div class="mt-20 mb-20 px-15" style="display: flex; border-bottom: 1px solid #E1E2E5;" v-for="(item, index) in list" :key="index">
      <img :src="transformIcon(item.title, (item.order.order_type_code || ''))" style="width: auto; height: 64px; margin-right: 20px;">
      <div>
        <p class="text-grey" style="font-size: 14px;">{{item.store.name}}</p>
        <p>#{{item.order.code}}</p>
      </div>
      <div style="flex: 1"></div>
      <div>
        <p v-if="(item.order.total || 0) > 0">{{$t('common.format_currency', {amount: formatCurrency(item.order.total, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</p>
        <p class="text-red" style="font-size: 12px">{{mappingType[item.type]}} {{item.point}} Points</p>
        <p class="text-grey" style="font-size: 10px">{{item.created_at}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import types from "@/store/mutationTypes";
import CustomerApi from '@/services/customer';
import Constant from "./../../constant";
const CDN_URL = process.env.VUE_APP_CDN_URL

export default {
  name: "Slices",
  data() {
    return {
      list: [],
      mappingType: {
        1: '+',
        0: '-'
      },
    }
  },
  created() {
    this.ordersHistory()
  },
  methods: {
    ordersHistory () {
      const vm = this;
      CustomerApi.pointTransactions(
          {vm: this}, function (response) {
            vm.list = response.data;
          }
      )
    },
    transformIcon(title, orderType) {
      orderType = ''
      let strIcon ='/uploads/icon_delivery.png'
      if (orderType === Constant.ORDERTYPE.TAKEAWAY || title.search('Takeaway') >= 0) {
        strIcon ='/uploads/take_away_icon.png'
      }
      if (orderType ===  Constant.ORDERTYPE.DELIVERY || title.search('Delivery') >= 0) {
        strIcon = '/uploads/icon_delivery.png'
      }
      if (orderType ===Constant.ORDERTYPE.DINEIN || title.search('Dine in') >= 0) {
        strIcon ='/uploads/icon_eatin.png'
      }

      return CDN_URL + strIcon

    }
  },
  computed: {
    ...mapGetters({
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
    }),
  }
}
</script>

<style scoped>
 .phone-box {
   background: #FFEAEC;
   border: 1px dashed #C8102E;
   border-radius: 8px;
   justify-content: center;
   align-items: center;
   padding: 15px;
   font-weight: 600;
   font-size: 20px;
   line-height: 20px;
   text-align: center;
   letter-spacing: 0.15px;
   color: #C8102E;
 }
</style>
