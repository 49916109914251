const CDN_URL = process.env.VUE_APP_CDN_URL

export default {
    LOGIN_GOOGLE_KEY: '962674846498-phjcue8s0thvjf7g9jnqkqum3qpucoj9.apps.googleusercontent.com',
    TIMEZONE: 'Asia/Jakarta',
    PREFIX_PHONE: '62',
    SUFFIX_CURRENCY: 0,
    CURRENCY: 'Rp',
    CONTACT_PHONE: '1500600',
    LANGUAGE_ALLOW: ['en', 'in'],
    LOCATION: {
      LAT: -6.174760,
      LNG: 106.827070,
    },
    CHANNEL: {
      POS: 1,
      ONLINE: 2
    },
    ORDER_TYPE_MAPPING: {
        DELIVERY: 1,
        CARRYOUT: 2,
        DINEIN: 3,
    },
    ORDERTYPE: {
        DELIVERY: 'DELIVERY',
        CARRYOUT: 'CARRYOUT',
        TAKEAWAY: 'TAKEAWAY',
        DINEIN: 'DINEIN'
    },
    ORDERTYPE_MAPAPI: {
        DELIVERY: 'DELIVERY',
        CARRYOUT: 'TAKEAWAY',
        DINEIN: 'DINE IN'
    },
    COUNTRY:{
        CODE: 'id'
    },
    LANGUAGE: {
     'EN': 'en'
    },
    COUPON_CAT: {
        "uuid": "promotions-coupon-1",
        "parent_uuid": null,
        "name": "Voucher",
        "slug": "promotions",
        "sequence": 1,
        "description": "PHD Coupons",
        "main_description": null,
        "thumbnail": "",
        "image": "",
        "icon": "https://static-dev.cpos.diqit.io/uploads/products/1588927728839-hotpromo_icon.png",
        "client_uuid": "",
        "store_type_id": 0,
        "category_type": 0,
        "is_active": 1,
        "children": []
    },
    CATEGORY: {
      DEAL: 'hot-promo',
      COMBOX: 'combox',
      COUPON: 'promotions'
    },
    ROUTE: {
        CATEGORY: 'category',
        COMBO_DETAIL: 'combo-detail'
    },
    TYPE_HUT: {
        PHD: 1,
        PHR: 2,
        // DIN: 3
    },
    VERIFY_TOKEN_METHOD: {
      REGISTER: 0,
      PROFILE_PHONE: 1,
      LOGIN: 2,

    },
    LENGTH_OTP: 6,
    ALLOW_REDIRECT: [
      'pizzahutphd'
    ],
    PHONE: {
      MAX: 13,
      MIN: 9,
    },
    GENDERS: [
        {
            label: 'customer.na',
            code: 0
        },{
            label: 'customer.male',
            code: 1
        },
        {
            label: 'customer.female',
            code: 2
        }
    ],
    PAYMENT_METHOD_BCA: [
        {
            label: 'bca.key.choose',
            code: ''
        },
        {
            label: 'bca.key.atmbca',
            code: 'bca.content.atmbca'
        },
        {
            label: 'bca.key.mbca',
            code: 'bca.content.mbca'
        },
        {
            label: 'bca.key.internet_banking',
            code: 'bca.content.internet_banking'
        },
        {
            label: 'bca.key.kontor_bank',
            code: 'bca.content.kontor_bank'
        },
        // {
        //     label: 'bca.key.lain',
        //     code: 'bca.content.lain'
        // }
    ],
    CONTACT_US_TITLES: [
        {
            label: 'contact_us.inquiry',
            code: 'Inquiry'
        },{
            label: 'contact_us.suggestion',
            code: 'Suggestion'
        },
        {
            label: 'contact_us.compliment',
            code: 'Compliment'
        },
        {
            label: 'contact_us.complain',
            code: 'Complain'
        }
    ],
    /*CATEGORY_TYPE: {
        SINGLE: 1,
        PIZZA: 2,
        COMBO: 3,
        CHICKEN: 4
    },*/
    ORDERSTATUS: {
        ORDERED: 'Ordered',
        CHECKOUT: 'Checkout',
        COOKING: 'Cooking',
        COOKED: 'Cooked',
        DELIVERY: 'Delivery',
        DELIVERING: 'Delivering',
        DELIVERED: 'Delivered',
        FINISHED: 'Finished',
        EDITING: 'Finished', // not use
        PARKED: 'Parked',
        TAKEAWAY: 'Takeaway',
        CANCELLED: 'Cancelled',
        SERVED: 'Served',
        RECEIVED: 'Received',
    },
    ORDERSTATUS_MAPPING_CODE: {
        ACCEPTED: ['ORDERED' ,'CHECKOUT'],
        BAKING: ['COOKING' ,'COOKED'],
        DELIVERING: [ 'DELIVERING', 'PARKED', 'DELIVERED'],
        FINISHED: [ 'FINISHED'],
        CANCELLED: ['CANCELLED'],
    },
    ORDERSTATUS_DINEIN_MAPPING_CODE: {
        ORDERED: ['SYSTEMCONFIRMED', 'ORDERED'],
        COOKING: ['COOKING' ,'COOKED'],
        SERVED: ['CHECKOUT', 'FINISHED', 'PENDINGCREDITCARDPAYMENT'],
    },
    ORDERTYPE_UUIDS: {
        DELIVERY: '13264811-79cf-4024-9c27-b59b57042c65',
        CARRYOUT: 'ecd7f640-cdda-43c3-b626-6f436b425ba3',
        DINEIN: '28e405bf-1f57-4e2d-81b6-46da1a73e61d'
    },
    PAYMENT_STATUS: {
        COMPLETED: 'COMPLETED',
        PENDING: 'PENDING',
        FAIL : 'FAIL '
    },
    MAPPING_TRACKING_IMAGE: {
      STEP_1: {
          PAST: CDN_URL + '/icons/tracking/icon-ordered.png',
          GIFT: CDN_URL + '/icons/tracking/tracker-accepted.gif',
          ACTIVE: CDN_URL + '/icons/tracking/icon-ordered-active.png',
          INACTIVE: ''
      },
      // STEP_2: {
      //     PAST: CDN_URL + '/icons/tracking/icon-preparing.png',
      //     GIFT: CDN_URL + '/icons/tracking/tracker-preparing.gif',
      //     ACTIVE: CDN_URL + '/icons/tracking/icon-preparing-active.png',
      //     INACTIVE: CDN_URL + '/icons/tracking/icon-preparing-inactive.png'
      // },
      STEP_2: {
          PAST: CDN_URL + '/icons/tracking/icon-baking.png',
          GIFT: CDN_URL + '/icons/tracking/tracker-baking.gif',
          ACTIVE: CDN_URL + '/icons/tracking/icon-baking-active.png',
          INACTIVE: CDN_URL + '/icons/tracking/icon-baking-inactive.png'
      },
      STEP_3_D: {
          PAST: CDN_URL + '/icons/tracking/icon-delivery.png',
          GIFT: CDN_URL + '/icons/tracking/tracker-delivery.gif',
          ACTIVE: CDN_URL + '/icons/tracking/icon-delivery-active.png',
          INACTIVE: CDN_URL + '/icons/tracking/icon-delivery-inactive.png'
      },
      STEP_3_C: {
        PAST: CDN_URL + '/icons/tracking/icon-collection.png',
        GIFT: CDN_URL + '/icons/tracking/tracker-collection.gif',
        ACTIVE: CDN_URL + '/icons/tracking/icon-collection-active.png',
        INACTIVE: CDN_URL + '/icons/tracking/icon-collection-inactive.png'
      },
      STEP_4: {
          PAST: CDN_URL + '/icons/tracking/icon-recived.png',
          GIFT: CDN_URL + '/icons/tracking/icon-recived-active.png',
          ACTIVE: CDN_URL + '/icons/tracking/icon-recived-active.png',
          INACTIVE: CDN_URL + '/icons/tracking/icon-recived-inactive.png'
      }
    },
    PAYMENT_METHOD: {
      CASH: 1,
      BCA: 27,
      DOKU: 49
    },
    UPDATE_PROFILE_TYPE: {
      INFO: 0,
      PHONE: 1,
      EMAIL: 2,
    },
    ERROR_CODE: {
        CART_NOT_FOUND: '9102',
        LOGIN: {
            SOCIAL_MISSING: [7112],
            MAX_OTP: 7121,
            INCORRECT_USER: 7102,
        },
        REGISTER: {
            EMAIL_EXIST: 7106,
            PASSWORD: 7130,
            PHONE: 7107,
        }
    },
    ADDITIONAL_TYPE: {
        NORMAL: 1,
        ADDON: 2,
        DONATION: 3,
        CHILI: 4
    },
    EMAIL_SUPPORT: 'csc@phd.co.id',
    SKU_EXTRA_CHEESE: 'extra1',
    SCREEN_NOT_FIRE_LOAD_EVENT: [
      'error-404',
      'order-confirm'
    ],
    TREASURE_PRODUCT: 14,
    TREASURE_GIFT: 15,

    PROMOTION_TYPES: {
        DELIVERY: 1,
        CARRYOUT: 2,
        DINEIN: 3,
        TREASURE_PRODUCT: 14,
        TREASURE_GIFT: 15,
    },
    LINK_STATIC_FLY: CDN_URL + '/cdn-cgi/image/quality=100,format=auto,',
    LINK_STATIC_PHR: 'https://static.pizzahut.co.id/'
}
