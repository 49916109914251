<template>
  <div class="popup" v-if="showPopup" @click="closePopup">
    <div class="popup-content">
      <p>{{ popupContent }}</p>
      <button @click="$router.go(-1)">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showPopup: Boolean,
    popupContent: String,
  },
  methods: {
    closePopup() {
      this.$emit('close-popup'); // Emit event untuk menutup popup
      this.$router.go(-1)
    },
  },
};
</script>

<style scoped>
.popup {
  /* Gaya CSS untuk popup */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  /* Gaya CSS untuk konten popup */
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
</style>
