<template>
    <div style="width: 100%; height: 100vh">
        <button @click="$emit('close')" type="button" class="icon-close2 xs-p-20 fixed xs-r-20 xs-t-10"></button>
        <div class="order-time">
            <div class="t24 text-center" v-html="$t('common.what_time_would_you_like_your_order')"></div>
            <div class="order-time-select">
                <select class="button button--white-black btn-select" v-model="choiceHour">
                    <option style="color: black" v-for="(hour, indexH) in hours" :value="hour" :key="indexH">{{hour}}</option>
                </select>
            </div>
            <div class="order-time-btn">
                <button class="button button--red" @click="setTime()" >
                    <span>{{$t('common.btn.set_order_time')}}</span>
                </button>
            </div>
            <div class="order-time-btn">
                <button class="button button--white-red"
                        @click="$emit('close')">
                    <span>{{$t('common.btn.cancel')}}</span>
                </button>
            </div>

        </div>
    </div>
</template>

<script>
    import types from '../../store/mutationTypes'
    import {mapActions, mapGetters} from "vuex";
    import moment from 'moment-timezone'
    import ModalNotifyBlack from "./ModalNotifyBlack";
    import LocalizationApi from "../../services/localization";
    export default {
        name: "ModalOrderTime",
        props: {
            'businessHoursCustom': {
                type: Array,
                default: () => []
            },
            'chosenTime': {
                type: Function,
            },
            'triggerCheckLocalisationData': {
                type: Object,
                default: null
            }
        },
        data: function(){
                return {
                    hours: [],
                    businessHours: [],
                    openingDateTime : {},
                    choiceHour : 'NOW',
                    startHours: ''
                }
        },
        mounted() {
            this.orderType = this.localisation.orderType;
            if (this.businessHoursCustom && this.businessHoursCustom.length > 0) {
                this.businessHours = this.businessHoursCustom;
                this.timezone = this.timezoneHut;
                this.getTimeSlot();
            } else {
                this.getAdvanceTime();
            }
        },
        computed: {
            ...mapGetters({
                localisation: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
                cartUuid: 'moduleCart/' + types.CART_GET_CART_UUID,
            }),
        },
        methods: {
            ...mapActions({
                restLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
                resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
                saveLocalisation:  'moduleLocalisation/' + types.LOCALISATION_SAVE_STORE,
                updateCart: 'moduleCart/' + types.CART_UPDATE_CART,
            }),
            getTimeSlot(){
                let vm = this;
                let defaultDate = this.localisation.collectionTime || ''
                let storeClosedDay = 0;
                for (let op of this.businessHours){
                    let stack = op.stack;
                    if (this.emptyVariable(stack)) {
                        storeClosedDay++;
                        continue;
                    }
                    let startTimeOrg = moment(op.start_at, 'YYYY-MM-DD HH:mm:ss').add(op.waiting_time, 'minutes');
                    let oldDay = '';
                    let obTime = [];
                    for (let itemS of stack) {
                        let date = moment(itemS, 'YYYY-MM-DD HH:mm:ss');
                        obTime.push(date.format('HH:mm'));
                    }
                    this.setDateTime(
                        oldDay, //obDate,
                        obTime, //obTime,
                        startTimeOrg //startTimeOrigin
                    )
                    this.startHours = startTimeOrg.format('HH:mm')
                }

                if (storeClosedDay && storeClosedDay == this.businessHours.length) {
                    this.$emit('close');
                    this.$modal.show(ModalNotifyBlack, {hideOK: true, contain: this.$t('message.error.store_closed')},
                        {resizable: false, clickToClose: true,
                            classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'},
                        {
                            'opened': function () {
                            },
                            'before-close': function () {
                                if (vm.emptyVariable(vm.businessHoursCustom)) {
                                    vm.restLocalisation();
                                    vm.resetCart();
                                    vm.$router.push({name: 'home', query: { warning: '1'}});
                                }
                            }
                        });

                    return false;
                }
                this.setDefaultChosen(defaultDate)
            },
            setTime(){
                let pickedDay = moment().format('YYYY-MM-DD');
                if (this.startHours > this.choiceHour){
                    pickedDay = moment().add(1, 'days').format('YYYY-MM-DD');
                }
                let timepicker = {'pickedDay': pickedDay, 'pickedHours': this.choiceHour}
                this.localisation.collectionTime = timepicker;
                this.localisation.collection_time = pickedDay + ' ' + this.choiceHour;
                this.localisation.timezone = this.timezone;
                this.localisation.orderNow = 0;
                if (this.choiceHour == 'NOW') {
                    this.localisation.orderNow = 1;
                }
                this.$root.$emit('collectionTimeUpdate');
                this.saveLocalisation(this.localisation);
                if( this.triggerCheckLocalisationData) {
                    this.$root.$emit('triggerCheckLocalisation', this.triggerCheckLocalisationData);
                }
                this.updateCartTime(this.localisation.orderNow, this.localisation.collection_time);
                this.$emit('close');
            },
            setDateTime(obDate, obTime, startTimeOrigin) {
                if (!this.openingDateTime[obDate]) {
                    this.openingDateTime[obDate] = {'data': obTime}
                } else {
                    let oldData = this.openingDateTime[obDate].data
                    let newData = obTime
                    this.openingDateTime[obDate].data = oldData.concat(newData)
                }
                if (this.openingDateTime[obDate].data.length <= 0) {
                    delete this.openingDateTime[obDate]
                } else {
                    if (moment().isSameOrAfter(startTimeOrigin)) {
                        this.openingDateTime[obDate].data.unshift('NOW')
                    }
                }
            },
            setDefaultChosen(defaultDate) {
                let flag = false
                if (this.localisation.orderNow != 1) {
                    for (let date in this.openingDateTime) {
                            this.choiceDay = defaultDate.pickedDay
                            this.hours = this.openingDateTime[date].data
                            if (_.indexOf(this.openingDateTime[date].data, defaultDate.pickedHours) >= 0) {
                                this.choiceHour = defaultDate.pickedHours
                            } else {
                                this.choiceHour = this.openingDateTime[date].data[0]
                            }
                            flag = true
                            break
                    }
                }

                if (!flag) {
                    for (let date in this.openingDateTime) {
                        this.choiceDay = date
                        this.days = this.openingDateTime[date].label
                        this.hours = this.openingDateTime[date].data
                        this.choiceHour = this.openingDateTime[date].data[0]
                        flag = true
                        break
                    }
                }
            },
            updateCartTime(orderNow, collectionTime) {
                if (!this.cartUuid){
                    return;
                }
                let dataProduct = {
                    "cart_uuid": this.cartUuid,
                    "now_order": orderNow,
                    "collection_time": this.convertDateTimeFromTimezoneToUTC(this.localisation.timezone, collectionTime)
                };

                this.updateCart({payload: dataProduct});
            },
            getAdvanceTime () {
                let vm = this;
                let params = {
                    store_uuid: this.localisation.uuid ,
                    store_type_id: (this.localisation.storeType) ? this.localisation.storeType : this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)] ,
                    disposition: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)]
                }
                LocalizationApi.storeAdvanceTime(params, function (response) {
                    vm.businessHours = response.data.business_hours;
                    vm.timezone = response.data.timezone;
                    vm.getTimeSlot();
                }, function (error) {
                    vm.notificationError(vm, error.message);
                    vm.$emit('close');
                })
            },
        }
    }
</script>

<style scoped>
    .order-time{
        border-radius:4px;
        padding:10px;
        background-color: #fff;
        border: 1px solid;
    }
    .order-time-btn{
        padding:10px;
        display: flex;
    }
    .order-time-select{
        padding:10px;
        text-align-last: center;
    }
    .btn-select{
        background-image: url("data:image/svg+xml;utf8,<svg fill='red' height='30' viewBox='0 0 24 24' width='40' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 50%;
    }

</style>