<template>
  <div class="position-relative">
    <div class="position-absolute">
      <GmapAutocomplete
        placeholder=" Cari Lokasi"
        @place_changed="setPlace"
        style="width: 400px; height: 35px;"
        class="pac-input form-control"
      >
      </GmapAutocomplete> 
    </div>
    <GmapMap
      :center="myCoordinates"
      :zoom="zoom"
      style="width: 100%; height: 500px; margin: 20px auto;"
      ref="mapRef"
      :options="{
        zoomControl: true,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
        ]
      }"
      @dragend="handleDrag"
    >
      <GmapMarker
        :position="gpsCoordinates"
        :icon="mapMarker"
        :clickable="false"
        :draggable="false"
      />
    
      <template v-for="store in stores">
        <GmapMarker
          :position="{lat: store.lat, lng: store.lng}"
          :clickable="true"
          :icon="imgMarker"
          @click="openMarker(store.uuid)"
        > 
          <template v-if="store.uuid">
            <GmapInfoWindow
              :closeclick="true"
              :opened="(openedMarkerID == store.uuid) ? true : false"
              :position="myCoordinates" 
              @closeclick="openMarker(null)"
            >
              <div>
                <h4>{{ store.name }}</h4>
                <div>{{ store.location }}</div>
                <div>{{ store.phone }}</div>
                <div class="btn-container">
                  <a :href="'/stores/' + store.slug" class="btn btn-primary">Details</a>
                  <a :href="'https://www.google.com/maps?q=' + store.lat + ',' + store.lng" class="btn btn-primary">Directions</a>
                </div>
              </div>
            </GmapInfoWindow>
          </template>
        </GmapMarker>
      </template>
    </GmapMap>
  </div>
</template>


<script>

  export default {
    props: {
      stores: {
        type: Array,
        require: true,
      }
    },
    data() {
        return {
            imgMarker: require('@/assets/images/pin-logo.png'),
            mapMarker: require('@/assets/images/map_marker.png'),
            map: null,
            gpsCoordinates: {
              lat: parseFloat(0),
              lng: parseFloat(0)
            },
            myCoordinates: {
              lat: parseFloat(0),
              lng: parseFloat(0)
            },
            zoom: 7,
            openedMarkerID: null,
            place: null,
        };
    },
    created() {
      //get user's coordinates from browser request
      this.$getLocation({})
          .then(coordinates => {
          // set GPS Coordinates
          this.gpsCoordinates = coordinates;
          // set Maps Coordinates
          this.myCoordinates = coordinates;
          this.zoom = 14;
      })
          .catch(error => alert(error));
    },
    mounted() {
        //add the map to a data object
        this.$refs.mapRef.$mapPromise.then(map => this.map = map);
    },
    methods: {
      handleDrag() {
          let center = {
              lat: parseFloat(this.map.getCenter().lat()),
              lng: parseFloat(this.map.getCenter().lng())
          };
          // let zoom = this.map.getZoom();
          // localStorage.center = JSON.stringify(center);
          // localStorage.zoom = zoom;
      },

      openMarker(id) {
        this.openedMarkerID = id
      },

      setPlace(place) {
        this.place = place
        this.myCoordinates.lat = this.place.geometry.location.lat()
        this.myCoordinates.lng = this.place.geometry.location.lng()
        this.zoom = 14
      }
    },
    computed: {
      mapCoordinates() {
        if (!this.map) {
            return {
              lat: 0,
              lng: 0
            };
        }
        return {
            lat: this.map.getCenter().lat().toFixed(4),
            lng: this.map.getCenter().lng().toFixed(4),
        };
      }
    },
}
</script>

<style>
  #pac-input {
    top: 10px !important;
    left: 10px !important;
    max-width: 400px;
    width: calc(100% - 20px);
    position: absolute;
    z-index: 50;
  }

  .btn-container {
    margin-top: 8px;
  }

  .btn-container .btn {
    font-size: 13px;
    padding: 4px 10px;
    margin-right: 3px;
    border-radius: 4px;
  }

  .position-relative {
    position: relative !important;
  }

  .position-absolute {
    position: absolute !important;
    z-index: 9999;
    margin: 15px;
  }

  .search-box {
        top: 10px !important;
    left: 10px !important;
    max-width: 400px;
    width: calc(100% - 20px);
    position: absolute;
    z-index: 50;
  }
</style>