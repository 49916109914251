var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"position-absolute"},[_c('GmapAutocomplete',{staticClass:"pac-input form-control",staticStyle:{"width":"400px","height":"35px"},attrs:{"placeholder":" Cari Lokasi"},on:{"place_changed":_vm.setPlace}})],1),_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"500px","margin":"20px auto"},attrs:{"center":_vm.myCoordinates,"zoom":_vm.zoom,"options":{
      zoomControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      rotateControl: false,
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ]
    }},on:{"dragend":_vm.handleDrag}},[_c('GmapMarker',{attrs:{"position":_vm.gpsCoordinates,"icon":_vm.mapMarker,"clickable":false,"draggable":false}}),_vm._l((_vm.stores),function(store){return [_c('GmapMarker',{attrs:{"position":{lat: store.lat, lng: store.lng},"clickable":true,"icon":_vm.imgMarker},on:{"click":function($event){return _vm.openMarker(store.uuid)}}},[(store.uuid)?[_c('GmapInfoWindow',{attrs:{"closeclick":true,"opened":(_vm.openedMarkerID == store.uuid) ? true : false,"position":_vm.myCoordinates},on:{"closeclick":function($event){return _vm.openMarker(null)}}},[_c('div',[_c('h4',[_vm._v(_vm._s(store.name))]),_c('div',[_vm._v(_vm._s(store.location))]),_c('div',[_vm._v(_vm._s(store.phone))]),_c('div',{staticClass:"btn-container"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":'/stores/' + store.slug}},[_vm._v("Details")]),_c('a',{staticClass:"btn btn-primary",attrs:{"href":'https://www.google.com/maps?q=' + store.lat + ',' + store.lng}},[_vm._v("Directions")])])])])]:_vm._e()],2)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }