<template>
  <div class="bg-white">
    <div class="container input-coupon mt-10 mb-10">
      <div v-if="couponMessage" class="coupon-message"> {{ couponMessage }} </div>
      <input v-model="couponCode" type="text" class="input xs-mb-10 mr-10" :placeholder="$t('common.title_coupon_box')"/>
      <button class="button button--red" style="width: 30%" @click="redeemCode">Use</button>
    </div>
    <div class="container">
      <div class="grid-container">
        <voucher-singles v-for="(item, index) in list" :item="item" :key="index" @chosen-store="setIsOpenModal"/>
      </div>
    </div>
    <modal-voucher :is-open="isOpenModal" :voucher-data="voucherChosen" @close-modal="isOpenModal = false" />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import types from "@/store/mutationTypes";
import couponAPI from "@/services/coupon";
import ModalVoucher from '@/components/modal/ModalVoucher.vue'
import cartAPI from "@/services/cart";
import manthan from "@/services/manthan";
import moment from "moment-timezone";
import VoucherSingles from "@/components/rewards/VoucherSingles";
export default {
  name: "Vouchers",
  components: {VoucherSingles, ModalVoucher },
  data() {
    return {
      list: [],
      isOpenModal: false,
      voucherChosen: {},
      couponCode: '',
      couponMessage: '',
    }
  },
  created() {
    this.initData()
    const voucher = this.$route.params.voucher
    this.voucherChosen = voucher
  },
  computed: {
    ...mapGetters({
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
      cartUuid: 'moduleCart/' + types.CART_GET_CART_UUID,
      storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
      orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
      localisation: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
    }),
  },
  methods: {
    ...mapActions({
      getCartDetail: 'moduleCart/' + types.CART_GET_CART_DETAIL,
      setCartDetail: 'moduleCart/' + types.CART_SET_DATA,
      setCartSuggest: 'moduleCart/' + types.CART_SET_SUGGEST,
    }),
    initData(){
      const vm = this;
      let params = {
        store_uuid: this.storeUuid,
        vm: vm
      };
      vm.list = [];
      couponAPI.getCoupons(params, async (response) => {
        const data = response.data
        for (const item of data) {
          if (await this.imageExists(item.thumbnail)) {
            vm.list.push(item)
          }
        }

        if (vm.list.length > 0) {
          vm.list = vm.list.map(data => {
            data.end_date = moment(data.end_date).format('DD MMM YYYY');
            data.promotion_types_text = this.convertOrderType(data.promotion_types)
            return data
          })
        }
      })
    },
    convertOrderType(orderTypes) {
      let str = []
      if (orderTypes.indexOf(this.$const.ORDER_TYPE_MAPPING.DELIVERY) >= 0) {
        str.push('Delivery')
      }
      if ( orderTypes.indexOf(this.$const.ORDER_TYPE_MAPPING.CARRYOUT) >= 0) {
        str.push('Take away')
      }
      if (orderTypes.indexOf(this.$const.ORDER_TYPE_MAPPING.DINEIN) >= 0) {
        str.push('Dine in')
      }
      return str.join(" | ")
    },
    setIsOpenModal(voucher) {
      this.voucherChosen = voucher
      this.isOpenModal = true
    },
    setVoucher(voucher) {
      if(this.storeUuid != undefined && this.storeUuid !== '') {
        this.$router.push({name: 'category', params: {'slug' : 'promotions'}})
      } else {
        this.voucherChosen = voucher
        this.isOpenModal = true
      }
    },
    truncateTextIfTooLong(text) {
      const maxLength = this.isMobile() ? 60 : 80
      if (text.length < maxLength) {
        return text
      }
      return text.substring(0, maxLength).trim() + "...";
    },
    redeemCode () {
      if (!this.storeUuid) {
        this.$notify({
          type: 'warning',
          title: this.$t('message.error.choose_localisation_first')
        });
        let path = this.$route.fullPath.indexOf('/') !== 0 ? this.$route.fullPath : this.$route.fullPath.substr(1);
        this.$router.push({name: 'home', query: {warning: '1', callback: path}});
        return false;
      }

      if (!this.couponCode) {
        this.couponMessage = this.$t('message.error.redeem_coupon');
        return false;
      }
      if (!this.cartUuid) {
        return this.createCart();
      }
      this._redeemCoupon(this.cartUuid);
    },
    _redeemCoupon ( uuidCart = '') {
      let params = {
        "cart_uuid": uuidCart,
        "code": this.couponCode || '',
        "vm": this
      }

      const vm = this;
      cartAPI.redeem(params, function (response) {
        if (response.data.status === 0) {
          let proUuid = ''
          let prodRequired = ''
          let isCombo = false;
          if (response.data.products && response.data.products.length > 0) {
            proUuid = response.data.products[0].uuid;
            if (response.data.products[0].is_combo == 1) {
              isCombo = true;
            }
          } else {
            prodRequired = response.data.required_product_uuids.join();
            return vm.$router.push({name: 'redeem', params: {code: vm.couponCode, listRequired: prodRequired}});
          }
          if (isCombo) {
            return  vm.$router.push({name: 'combo-detail', params: {uuid: proUuid, code: vm.couponCode}})
          }
          return vm.$router.push({name: 'redeem', params: {code: vm.couponCode, uuid: proUuid}});
        }
        vm.notificationSuccess(vm, vm.$t('message.success.redeem_coupon_success'));
        vm.getCartDetail({payload: {uuid: vm.cartUuid}});
      }, function (error) {
        vm.notificationError(vm, error.message);
      });
    },
    createCart () {
      const vm = this;
      let dataProduct = {
        "cart_uuid": this.cartUuid || '',
        "disposition": this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        "items": [],
        "now_order": this.orderNow,
        "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
        "store_uuid": this.storeUuid
      }

      cartAPI.addCart(dataProduct, response => {
        vm.setCartDetail({payload: response.data});
        vm._redeemCoupon(response.data.information.cart_uuid);
        vm._eventAddCart(response.data);
      }, error => {
        vm.notificationError(vm, error.message);
      })
    },
    _eventAddCart(data) {
      //function for manthan
      const dataManthanCart = {
        cartId: this.cartUuid || '',
        productId: '',
        categoryId: '',
        brandId: this.localisation.storeType === this.$const.TYPE_HUT.PHD ? 'PHD' : 'PHR',
        price: '',
        quantity: 0,
        totalQuanty: 0,
        shippingCost: data.information.delivery_fee > 0 ? data.information.delivery_fee : 0,
        tax: data.information.tax
      }
      manthan.execute(dataManthanCart,"eventAddToCart")
    },
  },
}
</script>

<style scoped>
  .input-coupon {
    display: flex;
    text-align:left;
    max-width: 500px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
  }
  @media screen and (max-width: 800px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 16px;
      grid-gap: 12px 8px;
    }
  }
</style>
