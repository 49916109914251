<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header"  >
              <h5 class="modal-title text-center  text-18 w-full">{{$t('common.register')}}</h5>
              <button type="button" aria-label="Close" class="close" @click="$emit('close')">×</button>
          </div>
          <form novalidate="novalidate" method="post" action="" @submit="checkForm" >

            <div class="modal-body">
              <div class="md:flex">
                  <div class="contain-form m-auto w-full" >
                      <div class="block-section">
                          <div class="md:flex lg:flex ">
                              <div class="form-field flex-1 ml-5 mr-5 ">
                                  <div class="xs:flex xs:flex-center mb-5">
                                      <label class="label mb-0" for="first_name">{{$t('customer.first_name')}}
                                          <span class="text-red">{{ $t('customer.required') }}</span>
                                          <span class="float-right text-red" v-if="errors.first_name">{{errors.first_name}}</span>
                                      </label>
                                  </div>
                                  <input id="first_name" class="input xs-mb-10" ref="first_name" v-bind:class="{'has-error': errors.first_name, 'has-success': errors.first_name === false}"
                                         name="first_name" v-model="customer.first_name"
                                         maxlength="255" type="text">
                              </div>
                              <div class="form-field flex-1 ml-5 mr-5">
                                  <div class="xs:flex xs:flex-center mb-5">
                                      <label class="label mb-0" for="last_name">{{$t('customer.last_name')}}
                                          <span class="text-red">{{ $t('customer.required') }}</span>
                                          <span class="float-right text-red" v-if="errors.last_name">{{errors.last_name}}</span>
                                      </label>
                                  </div>
                                  <input id="last_name" class="input xs-mb-10" ref="last_name" v-bind:class="{'has-error': errors.last_name, 'has-success': errors.last_name === false}"
                                         name="last_name" v-model="customer.last_name"
                                         maxlength="255" type="text">
                              </div>
                          </div>
                          <div class="form-field">
                              <div class="xs:flex xs:flex-center mb-5">
                                  <label class="label mb-0" for="phone">{{$t('customer.phone')}}
                                      <span class="text-red">{{ $t('customer.required') }}</span>
                                      <span class="float-right text-red" v-if="errors.phone">{{errors.phone}}</span>
                                  </label>
                              </div>
                              <div class="input-group">
                                  <div class="input-group-append" ><span class="input-group-text" >{{$const.PREFIX_PHONE}}</span></div>
                                  <input id="phone" class="input xs-mb-10 form-control" ref="phone" v-bind:class="{'has-error': errors.phone, 'has-success': errors.phone === false}"
                                         name="phone" v-model="customer.phone"
                                         maxlength="30" type="text">
                              </div>
                          </div>
                          <div class="form-field">
                              <div class="xs:flex xs:flex-center mb-5">
                                  <label class="label mb-0" for="genders">{{$t('customer.gender')}}
                                      <span class="text-red">{{ $t('customer.required') }}</span>
                                      <span class="float-right text-red" v-if="errors.gender">{{errors.gender}}</span>
                                  </label>
                              </div>
                              <v-select :options="genders" v-model="customer.gender"  class="input-transparent" ref="genders" id="genders" name="genders"></v-select>
                          </div>
                          <div class="form-field">
                              <div class="xs:flex xs:flex-center mb-5">
                                  <label class="label mb-0" >{{$t('customer.birthday')}}
                                      <span class="text-red">{{ $t('customer.required') }}</span>
                                      <span class="float-right text-red" v-if="errors.birthday">{{errors.birthday}}</span>
                                  </label>
                              </div>
                              <div class="md:flex lg:flex ">
                                  <v-select :options="birthdayData.day" v-model="customer.birth_date" :placeholder="$t('common.day')"
                                            class="input-transparent input-min-height-44 flex-1 m-5" ref="birth_day" id="birth_date" name="birth_day">
                                      v-bind:class="{'has-error': errors.birth_date, 'has-success': errors.birth_date === false}"
                                  </v-select>
                                  <v-select :options="birthdayData.month" v-model="customer.birth_month" :placeholder="$t('common.month')"
                                            class="input-transparent input-min-height-44 flex-1 m-5" ref="birth_month" id="birth_month" name="birth_month">
                                      v-bind:class="{'has-error': errors.birth_month, 'has-success': errors.birth_month === false}"
                                  </v-select>
                                  <v-select :options="birthdayData.year" v-model="customer.birth_year"  :placeholder="$t('common.year')"
                                            class="input-transparent input-min-height-44 flex-1 m-5" ref="birth_year" id="birth_year" name="birth_year">
                                      v-bind:class="{'has-error': errors.birth_year, 'has-success': errors.birth_year === false}"
                                  </v-select>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

            <div class="modal-footer">
              <button type="button" class="button btn-secondary flex-1" @click="$emit('close')">{{$t('common.btn.cancel')}}</button>
              <button class="button button--red flex-1">{{$t('common.btn.register')}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  var VueScrollTo = require('vue-scrollto');
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';

  export default {
  name: 'ModalRegister',
  props: {
    save: {
      type: Function
    }
  },
  components: {
    vSelect
  },
  data() {
    return {
      genders: [],
      birthdayData: {
        day: [],
        year: [],
        month: [],
      },
      errors: {
        isValid: false,
        first_name: '',
        last_name: '',
        phone: '',
        birthday: '',
        birth_month: '',
        birth_year: '',
        birth_date: '',
      },
      customer: {
        first_name: '',
        last_name: '',
        phone: '',
        gender: {},
        birth_date: {},
        birth_month: {},
        birth_year: {},
      }
    }
  },
  watch: {
    'customer.phone': function (newValue) {
      this.errors.phone = false;
      if (!newValue) {
        this.errors.phone = this.$t('message.error.phone');
      }else if (newValue.length < this.$const.PHONE.MIN || newValue.length > this.$const.PHONE.MAX) {
        this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX});
      }
    },
    'customer.first_name': function (newValue) {
      this.errors.first_name = false;
      if (!newValue) {
        this.errors.first_name = this.$t('message.error.first_name');
      }
    },
    'customer.last_name': function (newValue) {
      this.errors.last_name = false;
      if (!newValue) {
        this.errors.last_name = this.$t('message.error.last_name');
      }
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData () {
      let _genders = this.$const.GENDERS;
      for (let i in _genders) {
        _genders[i].label = this.$t(_genders[i].label);
      }
      this.genders = _genders;
      if (this.genders.length > 0) {
        this.customer.gender = this.genders[0];
      }

      this.birthdayData.day.push({
        label: this.$t('common.day'),
        code: 0,
      });
      this.customer.birth_date = this.birthdayData.day[0];
      for (let i=1; i<=31; i++) {
        this.birthdayData.day.push({
          label: i,
          code: i,
        });
      }

      this.birthdayData.month.push({
        label: this.$t('common.month'),
        code: 0,
      });
      this.customer.birth_month = this.birthdayData.month[0];
      for (let i=1; i<=12; i++) {
        this.birthdayData.month.push({
          label: i,
          code: i,
        })
      }

      this.birthdayData.year.push({
        label: this.$t('common.year'),
        code: 0,
      });
      this.customer.birth_year = this.birthdayData.year[0];
      let nowYear = (new Date()).getFullYear();
      for (let i = 1900; i <= nowYear; i++) {
        this.birthdayData.year.push({
          label: i,
          code: i,
        })
      }
    },
    initErrorMessage () {
      this.errors = {
        isValid: false,
        first_name: false,
        last_name: false,
        phone: false,
        birthday: false,
        birth_date: false,
        birth_month: false,
        birth_year: false
      }
    },
    checkForm(e) {
      e.preventDefault();
      let isValidForm = true;
      this.initErrorMessage();

      if (!this.customer.first_name) {
        this.errors.first_name = this.$t('message.error.first_name');
        if (isValidForm) {
          this.$refs.first_name.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.last_name) {
        this.errors.last_name = this.$t('message.error.last_name');
        if (isValidForm) {
          this.$refs.last_name.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.phone) {
        this.errors.phone = this.$t('message.error.phone');
        if (isValidForm) {
          this.$refs.phone.focus();
        }
        isValidForm = false;
      } else if (this.customer.phone.length < this.$const.PHONE.MIN || this.customer.phone.length > this.$const.PHONE.MAX) {
        this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX})
        if (isValidForm) {
          this.$refs.phone.focus();
        }
        isValidForm = false;
      }

      if (this.customer.birth_date == '' || this.customer.birth_month == '' || this.customer.birth_year == '') {
        this.errors.birthday = this.$t('message.error.birthday')
        if (this.customer.birth_date === '') {
          this.errors.birth_date = true
        }
        if (this.customer.birth_month === '') {
          this.errors.birth_month = true
        }
        if (this.customer.birth_year === '') {
          this.errors.birth_year = true
        }
        if (isValidForm) {
          if ( this.errors.birth_date) {
            this.$refs.birth_date.focus()
          } else if (this.errors.birth_month) {
            this.$refs.birth_month.focus()
          } else if (this.errors.birth_year) {
            this.$refs.birth_year.focus()
          }
        }
        isValidForm = false

      } else if (!this.isValidBirthday(this.customer.birth_year.code, this.customer.birth_month.code, this.customer.birth_date.code)) {
        this.errors.birthday = this.$t('message.error.invalid_birthday')
        if (isValidForm) {
          this.$refs.birthday.focus()
        }
        isValidForm = false
      }

      if (isValidForm) {
        this.$emit('close');
        this.save( {
          "birthday": this.customer.birth_year.code + '-' + (this.customer.birth_month.code < 10 ? '0': '') + this.customer.birth_month.code + '-' + (this.customer.birth_date.code < 10 ? '0': '') + this.customer.birth_date.code,
          "gender": this.customer.gender.code,
          "first_name": this.customer.first_name,
          "last_name": this.customer.last_name,
          "phone": this.customer.phone
        });
      }
    },
  }
}
</script>
<style scoped>
</style>

