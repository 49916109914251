<template>
    <div id="page_404" class="text-center pb-10">
        <h3 class="mt-20">{{$t('common.title_404')}}</h3>
        <p class="mt-20" v-html="$t('common.announce_msg_404')"></p>
        <router-link
                :to="{name: 'home'}"
                class="button button--red m-auto mt-20 " style="width: 300px">{{$t('common.back_to_home')}}
        </router-link>
    </div>
</template>

<script>
  export default {
    name: "404"
  }
</script>

<style scoped>
    #page_404 h3 {
        font-size: 32px;
        font-weight: 600;
    }
    #page_404   p {
        font-size: 16px;
        font-weight: 600;
    }
</style>