import moment from 'moment-timezone'
// import { mapGetters } from 'vuex'
// import types from '../store/mutationTypes'
/* eslint-disable */
export default {
    data: function () {
        return {
            message: 'hello',
            // eventHeaderData: {
            //     hut_id: null,
            //     user_id: null,
            //     disposition: null,
            //     loyalty_member: null,
            //     customer_type: null,
            //     screen_name: null,
            //     localized: null,
            //     content_group: null
            // }
        }
    },
    created: function () {
    },
    // computed: {
    //   ...mapGetters({
    //     orderLocation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
    //     isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
    //     authUser: 'moduleAuth/' + types.CUSTOMER_GET_INFO,
    //   }),
    // },
    methods: {
        isMobile() {
            return ( window.innerWidth <= 800 );
        },
        redirectInternal (vm, path = '') {
            path =  path.replace(/\/\/\//g, '');
            if (path.charAt(0) !== '/') {
                path = '/' + path;
            }
            path = window.location.origin + path;
            // return vm.$router.push(path);
            return window.location.href = path
        },
        emptyVariable: function (param) {
            if (param == null) {
                return true
            }

            if (typeof param === 'string') {
                return param.length <= 0
            }

            if (typeof param === 'object') {
                return Object.keys(param).length <= 0
            }

            if (param instanceof Array) {
                return param.length <= 0
            }
            return false
        },
        formatNum(num, fixed = 2) {
            num = num || 0
            return parseFloat(Math.round(num * 100) / 100).toFixed(fixed)
        },
        formatKm(num, fixed = 2) {
            num = num || 0
            return this.formatNum(num/1000, fixed);
        },
        formatCurrency(num, fixed = 2) {
            num = num || 0
            return parseFloat(Math.round(num * 100) / 100).toFixed(fixed).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
        },
        getOrderType (value, defaultValue = '') {
            return value || defaultValue
        },
        async imageExists(imgUrl) {
            if (imgUrl === '') {
                return false
            }
            return new Promise(res => {
                const image = new Image()
                image.onload = () => res(true)
                image.onerror = () => res(false)
                image.src = imgUrl
            })
        },
        notificationError(vm, message) {
            vm.$notify({
                type: 'error',
                title: message,
            })
        },
        notificationSuccess(vm, message) {
            vm.$notify({
                type: 'success',
                title: message,
            })
        },
        validEmail (email){
            let reg = /^[\w-\.]{1,50}@[a-z0-9]{2,}(\.[a-z0-9]{2,4}){1,2}$/
            return reg.test(email)
        },
        isValidBirthday (year, month, day) {
            //JavaScript counts months from 0 to 11.
            month = month -1;
            let d = new Date(year, month, day)
            let result =  d instanceof Date && !isNaN(d) && d.getMonth() == month;
            return result;
        },
        getPhoneWithoutPrefix(phone, prefix) {
            if (this.emptyVariable(prefix)) {
                return phone;
            }

            if (phone.indexOf(prefix) === 0) {
                return phone.slice(2);
            }

            return phone;
        },
        getTime(vm) {
          let result = ''
          if(vm.localisation.collectionTime && vm.localisation.collectionTime.pickedHours != vm.$t('common.asap')){
            let now = moment()
            let tomorrow = moment().add(1, 'days')
            if(vm.localisation.collectionTime.pickedDay == now.format('YYYY-MM-DD')){
              result = vm.$t('common.today') + ' ' + vm.localisation.collectionTime.pickedHours
            }else if(vm.localisation.collectionTime.pickedDay == tomorrow.format('YYYY-MM-DD')){
              result = vm.$t('common.tomorrow') + ' ' + vm.localisation.collectionTime.pickedHours
            }else {
              result = vm.localisation.collectionTime.pickedDay + ' ' + vm.localisation.collectionTime.pickedHours
            }
          }else{
            result = vm.$t('common.asap')
          }

          if (result.trim() === '') {
              result = vm.$t('common.asap')
          }
          return result
        },
        formatCollectionTime (orderTime) {
            if (orderTime) {
              let datatime = moment(orderTime, 'YYYY-MM-DD HH:mm');
              if (datatime.isValid()) {
                return datatime.format('YYYY-MM-DD HH:mm:ss')
              }
            }
            return orderTime;
        },
        convertDateTimeToUTCOrCurrent(datetime) {
            if (!datetime) {
                return moment().utc().format('YYYY-MM-DD HH:mm:ss');
            }

            let checkDate = moment(datetime);
            if (!checkDate.isValid()) {
                return moment().utc().format('YYYY-MM-DD HH:mm:ss')
            }
            return checkDate.utc().format('YYYY-MM-DD HH:mm:ss')
        },
        convertDateTimeFromTimezoneToUTC(timezone, datetime) {
            if (!timezone) {
                return this.convertDateTimeToUTCOrCurrent(datetime);
            }
            if (!datetime) {
                return moment.tz(timezone).utc().format('YYYY-MM-DD HH:mm:ss');
            }

            let checkDate = moment.tz(datetime, timezone);

            if (!checkDate.isValid()) {
                return moment.tz(timezone).utc().format('YYYY-MM-DD HH:mm:ss');
            }
            return checkDate.utc().format('YYYY-MM-DD HH:mm:ss');
        },
        getOpeningTime (day, hourTime, timezone) {
            if (day && hourTime && timezone) {
                let hour = hourTime;
                if (hourTime == '24:00:00'){
                    hour = '23:59:59';
                }
              let datatime =  moment(day + ' ' + hour, 'ddd HH:mm:ss');
              if (datatime.isValid()) {
                  let value = hourTime == '24:00:00'? '24:00 PM' : datatime.format('hh:mm A');
                return {
                    name: datatime.format('dddd'),
                    value: value
                };
              }
            }
            return false;
        },
        checkClosedStore(businessHours = []) {

            let storeClosedDay = 0;
            for (let op of businessHours){
                let stack = op.stack;

                if (this.emptyVariable(stack)) {
                    storeClosedDay++;
                    continue;
                }
            }
            return storeClosedDay === businessHours.length;

        },
        checkOpeningTime(openingTime, type, channel) {
          let today = new Date().toLocaleDateString('en-US', { weekday: 'short' });
          today = today.toLocaleLowerCase()
          const checkToday = openingTime[today];
    
          if (checkToday) {
            const openTime = checkToday.open.find(time => time.type === type && time.channel === channel);
            const closeTime = checkToday.close.find(time => time.type === type && time.channel === channel);
            
            if (openTime && closeTime) {
              const currentTime = new Date();
              const open = new Date(currentTime.toDateString() + ' ' + openTime.value);
              const close = new Date(currentTime.toDateString() + ' ' + closeTime.value);
              
              if (currentTime >= open && currentTime <= close) {
                return true;
              } else {
                return false;
              }
            }
          }
          
          return false;
        },
        // setEventHeaderData () {
        //     this.eventClear()
        //     const obj = {}
        //     if (this.orderLocation.code) {
        //         obj.localized = 'yes'
        //         obj.hut_id = this.orderLocation.code
        //     }
        //     if (this.orderLocation.orderType) {
        //         obj.disposition = this.orderLocation.orderType === Constant.ORDERTYPE.DELIVERY ? 'delivery' : 'collection'
        //     }
        //     if (this.isAuth && this.authUser.info) {
        //         obj.user_id = this.authUser.info.uuid
        //         obj.loyalty_member = 'y'
        //         obj.customer_type = 'existing'
        //     }
        //     obj.screen_name = this.$route.name
        //     obj.content_group = 'Home'
        //     this.$set(this, 'eventHeaderData', obj)
        // },
        // eventProductClick (productObj) {
        //     this.setEventHeaderData()
        //     gtag('event', 'select_item', {
        //         ...this.eventHeaderData,
        //         'items': [{
        //             'item_name': productObj.name,
        //             'item_id': productObj.id,
        //             'price': productObj.sub_price || productObj.price,
        //             'item_category': productObj.category
        //         }]
        //     })
        // },
        // eventProductView (products, position = '') {
        //     let items = [];
        //     for (let i in products) {
        //         let item = products[i];
        //         let variant = [];
        //         let price = item.sub_price || item.price;
        //         if (item.option_groups && item.option_groups.length > 0) {
        //             let optionGroup = item.option_groups[0];
        //             if (optionGroup.options && optionGroup.options.length > 0) {
        //                 variant.push(optionGroup.options[0].uuid);
        //                 price += optionGroup.options[0].sub_price;
        //             }
        //             price += optionGroup.sub_price;
        //             variant.push(optionGroup.uuid);
        //         }
        //         items.push({
        //             'item_name': item.name,
        //             'item_id': item.uuid,
        //             'price': price,
        //             'item_brand': 'PHD',
        //             'list': position,
        //             'item_category': item.category && item.category.name ? item.category.name : '',
        //             'item_variant': variant.join('-'),
        //             'position': (i*1) + 1
        //         })
        //     }
        //     this.setEventHeaderData()
        //     gtag('event', 'view_item_list', {
        //         ...this.eventHeaderData,
        //         'items': items
        //     })
        // },
        // eventAddToCart (productObj) {
        //     this.setEventHeaderData()
        //     gtag('event', 'add_to_cart', {
        //         ...this.eventHeaderData,
        //         'items': [{
        //             'item_name': productObj.name,
        //             'item_id': productObj.id,
        //             'price': productObj.sub_price || productObj.price,
        //             'item_category': productObj.category,
        //             'item_variant': productObj.variant,
        //             'quantity': 1,
        //             'currency': 'IDR'
        //         }]
        //     })
        // },
        // eventRemoveItem (productObj) {
        //     this.setEventHeaderData()
        //     gtag('event', 'remove_from_cart', {
        //         ...this.eventHeaderData,
        //         'items': [{
        //             'item_name': productObj.name,
        //             'item_id': productObj.id,
        //             'price': productObj.sub_price || productObj.price,
        //             'item_category': productObj.category,
        //             'item_variant': productObj.variant,
        //             'quantity': productObj.quantity
        //         }]
        //     })
        // },
        // eventCheckout(cartObject, paymentObject) {
        //     let products = [];
        //     for (let menu of cartObject.products) {
        //         let item = {};
        //         let variant = [];
        //         if (menu.parent.uuid) {
        //             item = menu.parent;
        //         } else {
        //             item = menu.product.length > 0 ? menu.product[0] : {};
        //         }
        //         if (item.option && item.option.uuid) {
        //             variant.push(item.option.uuid)
        //         }
        //         if (item.option_group && item.option_group.uuid) {
        //             variant.push(item.option_group.uuid)
        //         }
        //         products.push({
        //             'item_name': item.name || '',
        //             'item_id': item.uuid || '',
        //             'price': menu.sub_price || menu.price || '',
        //             'item_category': item.category && item.category.name ? item.category.name : '',
        //             'item_variant': variant.join('-'),
        //             'quantity': menu.quantity
        //         })
        //     }
        //     this.setEventHeaderData()
        //     gtag('event', 'begin_checkout', {
        //         ...this.eventHeaderData,
        //         'items': products
        //     })
        // },
        // eventPurchase (cartObject) {
        //     let products = [];
        //     for (let menu of cartObject.details) {
        //         let item = {};
        //         let variant = [];
        //         item = menu.product;
        //         if (item.option_group && item.option_group.uuid) {
        //             if (item.option_group.option && item.option_group.option.uuid) {
        //                 variant.push(item.option_group.option.uuid)
        //             }
        //             variant.push(item.option_group.uuid)
        //         }
        //         products.push({
        //             'item_name': item.name || '',
        //             'item_id': item.uuid || '',
        //             'price': menu.sub_price || menu.price || '',
        //             'item_category': item.category && item.category.name ? item.category.name : '',
        //             'item_variant': variant.join('-'),
        //             'quantity': menu.quantity
        //         })
        //     }
        //     this.setEventHeaderData()
        //     gtag('event', 'purchase', {
        //         ...this.eventHeaderData,
        //         'transaction_id': cartObject.uuid,
        //         'value': cartObject.total,
        //         'tax': cartObject.tax_value,
        //         'shipping': cartObject.delivery_fee,
        //         'items': products,
        //     })
        // },
        // eventProductsImpression (prodtucts, type = '') {
        //     let dataProduct = [];
        //     for (let i in prodtucts) {
        //         dataProduct.push({
        //             'item_name': prodtucts[i].name,
        //             'item_id': prodtucts[i].uuid,
        //             'price': prodtucts[i].sub_price || prodtucts[i].price,
        //             'item_brand': 'PizzaHut',
        //             'item_category': prodtucts[i].category && prodtucts[i].category.name ? prodtucts[i].category.name : '',
        //             'item_variant': '',
        //             'item_list_id': type,
        //             'position': i * 1
        //         })
        //     }
        //     this.setEventHeaderData()
        //     gtag('event', 'view_item', {
        //         ...this.eventHeaderData,
        //         'items': dataProduct
        //     })
        // },
        // eventScreenLoad () {
        //     dataLayer.push({
        //         'event': 'default.pageload'
        //     });
        // },
        // eventPageView () {
        //     this.setEventHeaderData()
        //     gtag('event', 'page_view', { ...this.eventHeaderData })
        //     // var dataLayer = window.dataLayer = window.dataLayer || [];
        //     // dataLayer.push({
        //     // 'event': 'pageview',     
        //     //     'page_location': this.eventHeaderData.screen_name,
        //     //     'page_title': this.eventHeaderData.content_group
        //     // });
        // },
        // eventClear () {
        //     dataLayer.push({
        //         'ecommerce': null
        //     });
        // },
        eventProductClick (productObj) {
            this.eventClearData()
            dataLayer.push( {
              'event': 'productClick',
              'ecommerce': {
                  'click':  {
                      'products': [
                          {
                              'name': productObj.name,
                              'id': productObj.id,
                              'price': productObj.sub_price,
                              'category': productObj.category
                          }
                      ]
                  }
              },
              'eventCallback': function() {
                  // document.location = productObj.url
              }
           });
        },
        eventProductView (products, position = '') {
            this.eventClearData()
            let items = [];
            for (let i in products) {
                let item = products[i];
                let variant = [];
                let price = item.sub_price;
                if (item.option_groups && item.option_groups.length > 0) {
                    let optionGroup = item.option_groups[0];
                    if (optionGroup.options && optionGroup.options.length > 0) {
                        variant.push(optionGroup.options[0].uuid);
                        price += optionGroup.options[0].sub_price;
                    }
                    price += optionGroup.sub_price;
                    variant.push(optionGroup.uuid);
                }
                items.push({
                    'name': item.name,
                    'id': item.uuid,
                    'price': price,
                    'brand': 'PHD',
                    'list': position,
                    'category': item.category && item.category.name ? item.category.name : '',
                    'variant': variant.join('-'),
                    'position': (i*1) + 1
                })
            }
            dataLayer.push({ 
               'ecommerce': {
                   'detail': {
                       'actionField': {'list': 'Menus'},
                       'products': items
                   }
               }
            });
        },
        eventAddToCart (productObj) {
            this.eventClearData()
            dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': 'IDR',
                    'add': {                                // 'add' actionFieldObject measures.
                        'products': [{                        //  adding a product to a shopping cart.
                            'name': productObj.name,
                            'id': productObj.id,
                            'price': productObj.sub_price,
                            'category': productObj.category,
                            'variant': productObj.variant,
                            'quantity': 1
                        }]
                    }
                }
            });
        },
        eventRemoveItem (productObj) {
            this.eventClearData()
            dataLayer.push({
                'event': 'removeFromCart',
                'ecommerce': {
                    'remove': {                               // 'remove' actionFieldObject measures.
                        'products': [{                          //  removing a product to a shopping cart.
                            'name': productObj.name,
                            'id': productObj.id,
                            'price': productObj.sub_price,
                            'category': productObj.category,
                            'variant': productObj.variant,
                            'quantity': productObj.quantity
                        }]
                    }
                }
            });
        },
        eventCheckout(cartObject, paymentObject) {
            this.eventClearData()
            let products = [];
            for (let menu of cartObject.products) {
                let item = {};
                let variant = [];
                if (menu.parent.uuid) {
                    item = menu.parent;
                } else {
                    item = menu.product.length > 0 ? menu.product[0] : {};
                }
                if (item.option && item.option.uuid) {
                    variant.push(item.option.uuid)
                }
                if (item.option_group && item.option_group.uuid) {
                    variant.push(item.option_group.uuid)
                }
                products.push({
                    'name': item.name || '',
                    'id': item.uuid || '',
                    'price': menu.sub_price || '',
                    'category': item.category && item.category.name ? item.category.name : '',
                    'variant': variant.join('-'),
                    'quantity': menu.quantity
                })
            }
            dataLayer.push({
                'event': 'checkout',
                'ecommerce': {
                    'checkout': {
                        'actionField': {'step': 1, 'option': paymentObject.name || ''}, // cash/credit like this
                        'products': products
                    }

                }
            });
        },
        eventPurchase (cartObject) {
            this.eventClearData()
            let products = [];
            for (let menu of cartObject.details) {
                let item = {};
                let variant = [];
                item = menu.product;
                if (item.option_group && item.option_group.uuid) {
                    if (item.option_group.option && item.option_group.option.uuid) {
                        variant.push(item.option_group.option.uuid)
                    }
                    variant.push(item.option_group.uuid)
                }
                products.push({
                    'name': item.name || '',
                    'id': item.uuid || '',
                    'price': menu.sub_price || '',
                    'category': item.category && item.category.name ? item.category.name : '',
                    'variant': variant.join('-'),
                    'quantity': menu.quantity
                })
            }
              dataLayer.push({
                'event': 'productPurchase',
                'ecommerce': {
                  'currencyCode': 'IDR',
                  'purchase': {
                      'actionField': {
                        'id': cartObject.uuid,                         // Transaction ID. Required for purchases and refunds.
                        'revenue': cartObject.total,                     // Total transaction value (incl. tax and shipping)
                        'tax': cartObject.tax_value,
                        'shipping': cartObject.delivery_fee,
                        'coupon': ''
                    },
                    'products': products
                  }
                }
              });
        },
        eventProductsImpression (prodtucts, type = '') {
            this.eventClearData()
            let dataProduct = [];
            for (let i in prodtucts) {
                dataProduct.push({
                    'name': prodtucts[i].name,
                    'id': prodtucts[i].uuid,
                    'price': prodtucts[i].sub_price,
                    'brand': 'PizzaHut',
                    'category': prodtucts[i].category && prodtucts[i].category.name ? prodtucts[i].category.name : '',
                    'variant': '',
                    'list': type,
                    'position': i * 1
                })
            }
            dataLayer.push({
                'event': 'productView',
                'ecommerce': {
                    'currencyCode': 'IDR',
                    'impressions': dataProduct
                }
            });
        },
        eventScreenLoad () {
            dataLayer.push({
                'event': 'default.pageload'
            });
        },
        eventClearData () {
            dataLayer.push({ ecommerce:null })
        },
        setCookie(cname, cvalue, exdays) {
            let d = new Date();
            d.setTime(d.getTime() + (exdays*24*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
        },
        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

    }
}
