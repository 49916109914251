<template>
  <div class="inset-0 bg-white flex flex-col" :class="{'fixed': isMobile()}">
    <header-component />
    <div id="dinein-checkout" class="dinein-checkout-container overflow-auto w-full z-300 md:z-200 lg:shadow-left lg:border-l bg-white ml-auto flex-1 pb-5">
      <div class="font-open-sans-bold uppercase text-center relative py-15" :style="{'background-image': 'url(' + bgImage + ')'}">
        <h1 class="px-40">{{$t('common.btn.status')}}</h1>
        <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
      </div>
      <div class="bg-white">
        <div class="md:flex">
          <form id="checkForm" class="contain-form m-auto w-full" novalidate="novalidate" method="post" action="" @submit="checkForm" >
            <div class="block-section border-0 mb-0" style="border: none;">
              <div class="items-center h-full flex-row">
                <delivery-address-info />
              </div>
            </div>
            <div class="block-section border-0 mb-0">
              <div class="relative border-t border-grey-light mb-20">
                <div class="absolute top-0 left-0 w-full text-center font-bold" style="margin-top: -13px;">
                  <span class="bg-white p-5">Bill Summary</span>
                </div>
              </div>
              <template v-for="(item, key) in cartObject.products">
                <div class="flex flex-row items-center justify-between pb-10 border-b border-grey-light" :key="key" v-if="item">
                  <div class="flex flex-col items-start">
                    <div class="typography-7 text-center font-bold mb-5">{{item.quantity}}x <span v-if="!emptyVariable(item.product[0].option)">{{item.product[0].option.name}}</span> {{item.product[0].name}}</div>
                    <div class="font-open-sans-font typography-9 text-center text-white bg-grey-darker rounded-3 py-1 px-20" v-if="item.item_status && item.item_status == 'ORDERED'">{{item.item_status !== 'ORDERED' ? item.item_status : 'SELECTED'}}</div>
                    <div class="font-open-sans-font typography-9 text-center text-white bg-yellow rounded-3 py-1 px-20" v-if="item.item_status && item.item_status == 'COOKING'">{{item.item_status !== 'ORDERED' ? item.item_status : 'SELECTED'}}</div>
                    <div class="font-open-sans-font typography-9 text-center text-white bg-yellow rounded-3 py-1 px-20" v-if="item.item_status && item.item_status == 'COOKED'">{{item.item_status !== 'ORDERED' ? item.item_status : 'SELECTED'}}</div>
                    <div class="font-open-sans-font typography-9 text-center text-white bg-green rounded-3 py-1 px-20" v-if="item.item_status && item.item_status == 'SERVED'">{{item.item_status !== 'ORDERED' ? item.item_status : 'SELECTED'}}</div>
                  </div>
                  <div class="text-center font-bold">
                    <span class="flex-1" v-if="item.point > 0">
                      {{$t('common.format_point', {amount: formatCurrency(item.point * item.quantity, $const.SUFFIX_CURRENCY)})}}
                    </span>
                    <span class="flex-1"  v-else>
                      {{$t('common.format_currency', {amount: formatCurrency(item.sub_price * item.quantity, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </form>
          <div class="block-section border-0 mb-0 p-20 typography-9">
            <div class="flex flex-row items-center justify-between mb-5 text-grey">
              <div class="text-left font-bold">
                {{$t('orderConfirm.subtotal')}}
              </div>
              <div class="text-right flex-1 font-bold">
                <span>{{$t('common.format_currency', {amount: formatCurrency(cartObject.information ? cartObject.information.sub_total : 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
              </div>
            </div>
            <div class="flex flex-row items-center justify-between mb-5 text-grey">
              <div class="text-left font-bold">
                {{$t('orderConfirm.tax_fee')}}
              </div>
              <div class="text-right flex-1 font-bold">
                <span>{{$t('common.format_currency', {amount: formatCurrency(cartObject.information ? cartObject.information.tax : 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
              </div>
            </div>
            <div class="flex flex-row items-center justify-between mb-5">
              <div class="text-left font-bold">
                {{$t('orderConfirm.subtotal')}}
              </div>
              <div class="text-right flex-1 font-bold">
                <span>{{$t('common.format_currency', {amount: formatCurrency(cartObject.information ? cartObject.information.total : 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="dinein-checkout-footer bg-white lg:shadow-up-white ml-auto w-full mt-auto" :class="{'mobile-bottom': this.isMobile()}">
      <div class="flex">
        <div class="flex flex-row mb-5 px-15 pb-15 w-full" style="gap: 5px;">
          <button class="font-open-sans button button--red flex-1 rounded px-20 typography-7 font-bold" style="min-height: 42px;" type="submit" form="checkForm"> {{$t('common.btn.confirm_to_order')}}</button>
        </div>
      </div>
    </div> -->

    <div class="my-order-footer bg-white lg:shadow-up-white ml-auto w-full mt-auto" :class="{'mobile-bottom': this.isMobile()}">
      <div class="flex">
        <div class="flex flex-row mb-5 px-15 pt-15 pb-15 w-full" style="gap: 5px;">
          <button class="button button--white-red w-p50 typography-6" @click="addMore">
            {{$t('common.btn.add_more')}}
          </button>
          <router-link :to="{name: 'payment'}" class="button button--white-red w-p50 typography-6" :disabled="orderStatusCode !== $const.ORDERSTATUS.CHECKOUT.toUpperCase()">
            {{$t('common.btn.pay')}}
          </router-link>
        </div>
      </div>
    </div>
    <mobile-footer-component v-if="isMobile()"></mobile-footer-component>
  </div>
</template>
<script>
/**/
import HeaderComponent from '@/components/HeaderComponent';
import types from '@/store/mutationTypes'
import {  mapGetters, mapActions  } from 'vuex'
import CartApi from '@/services/cart'
import DeliveryAddressInfo from '@/components/shared/DeliveryAddressInfo'
import ModalOrderTime from "@/components/modal/ModalOrderTime";
import MobileFooterComponent from "@/components/MobileFooterComponent";
import manthan from '@/services/manthan';
var VueScrollTo = require('vue-scrollto');
import branch from '@/services/branch';
import bgImage from '@/assets/images/bg-app.jpg'


export default {
  name: "status",
  components: {
    HeaderComponent,
    DeliveryAddressInfo,
    MobileFooterComponent,
  },
  data() {
    return {
      bgImage: bgImage,
      contactLess: 1,
      cartTotal: 0,
      errors: {
        isValid: false,
        name: '',
        note: '',
        phone: '',
        company: '',
        receipt: 0,
        term: 0,
        email: '',
        building: '',
        unitNo: '',
        street: '',
        address: '',
        amountOfChange: '',
        paymentMethod: ''
      },
      payments: [],
      paymentMethod: {},
      paymentMethodType: '',
      deliveryInfo: {
        building: '',
        unitNo: '',
        street: '',
        state: '',
        address: ''
      },
      customer: {
        name: '',
        note: '',
        phone: '',
        company: '',
        receipt: 0,
        term: 0,
        email: ''
      },
      amountOfChange: '',
      pickedTime : ''
    }
  },
  computed: {
    ...mapGetters({
      storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
      storeName: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_NAME,
      location: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCATION,
      localisation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
      orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
      cartUuid: 'moduleCart/' + types.CART_GET_CART_UUID,
      cartObject: 'moduleCart/' + types.CART_DETAIL,
      grantTotal: 'moduleCart/' + types.CART_GRANT_TOTAL,
      cartCount: 'moduleCart/' + types.CART_COUNT,
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
      orderStatusCode: 'moduleOrder/' + types.ORDER_GET_ORDER_STATUS_CODE,
    })
  },
  destroyed () {
    this.$root.$off('collectionTimeUpdate');
  },
  mounted() {
    this.$root.$on('collectionTimeUpdate', () => {
      this.pickedTime = this.getTime(this)
    })
    if (!(this.cartCount > 0)) {
      this.$router.push({name: 'home'});
    }
    this.pickedTime = this.getTime(this)
    this.deliveryInfo.address = this.$t('common.combine_delivery',{
      buildingName: this.localisation.deliveryInfo ? this.localisation.deliveryInfo.buildingName : '', 
      streetName: this.localisation.deliveryInfo ? this.localisation.deliveryInfo.streetName : '', 
      postalCode: this.localisation.deliveryInfo ? this.localisation.deliveryInfo.postalCode : '', 
      state: this.localisation.deliveryInfo ? this.localisation.deliveryInfo.state : ''
    });
    this.deliveryInfo.unitNo = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.streetNumber : '';
    this.deliveryInfo.building = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.buildingName : '';
    this.deliveryInfo.street = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.streetName : '';
    this.deliveryInfo.state = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.state : '';
    this.deliveryInfo.postalCode = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.postalCode : '';
    this.getPayments();
    this.initCustomerData();
    this.getCartDetailByUuid();
    this.manthanCheckout();
    this.calculateTotal();
    if (this.orderType !== this.$const.ORDERTYPE.DELIVERY) {
      this.contactLess = 0;
    }
  },
  watch: {
    authUser: function (newValue) {
       if (newValue) {
         this.initCustomerData();
       }
    },
    cartUuid: function (newValue) {
       if (newValue) {
         this.getCartDetailByUuid();
       }
    },
    'amountOfChange': function (newValue, oldValue) {
      this.errors.amountOfChange = false;
      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.$set(this, 'amountOfChange', oldValue)
        return
      }

      if (!newValue) {
        this.errors.amountOfChange = this.$t('message.error.amount_of_change');
      }
    },
    'customer.phone': function (newValue, oldValue) {
      this.errors.phone = false;

      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.$set(this.customer, 'phone', oldValue)
        return
      }

      if (!newValue) {
        this.errors.phone = this.$t('message.error.phone');
      }else if (newValue.length < this.$const.PHONE.MIN || newValue.length > this.$const.PHONE.MAX) {
        this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX});
      }
    },
    'customer.email': function (newValue) {
      this.errors.email = false;
      if (!newValue) {
        this.errors.email = this.$t('message.error.email');
      }else if (!this.validEmail(newValue)) {
        this.errors.email = this.$t('message.error.invalid_email');
      }
    },
    'customer.name': function (newValue) {
      this.errors.name = false;
      if (!newValue) {
        this.errors.name = this.$t('message.error.name');
      }
    },
    'customer.company': function (newValue) {
      this.errors.company = false;
      if (!newValue) {
        this.errors.company = this.$t('message.error.company');
      }
    },
    'deliveryInfo.building': function (newValue) {
      this.errors.building = false;
      if (!newValue) {
        this.errors.building = '';
      }
    },
    'deliveryInfo.unitNo': function (newValue, oldValue) {
      this.errors.unitNo = false;
      if (!newValue) {
        this.errors.unitNo = '';
      }
    },
    'deliveryInfo.street': function (newValue) {
      this.errors.street = false;
      if (!newValue) {
        this.errors.street = '';
      }
    },
    'deliveryInfo.address': function (newValue) {
      this.errors.address = false;
      if (!newValue) {
        this.errors.address = this.$t('message.error.address');
      }
    },
    'customer.term': function (newValue) {
      this.errors.term = false;
      if (!newValue) {
        this.errors.term = this.$t('message.error.term');
      }
    },
    'paymentMethod': function (newValue) {
      this.errors.amountOfChange = false;
      this.errors.paymentMethod = false;
      if (!newValue) {
        this.errors.paymentMethod = this.$t('message.error.paymentMethod');
      }
    },
    'paymentMethodType': function (newValue) {
      this.errors.amountOfChange = false;
      this.paymentMethod = {};
    }
  },
  methods: {
    ...mapActions({
      restLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
      resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
      getCartDetail: 'moduleCart/' + types.CART_GET_CART_DETAIL
    }),
    getCartDetailByUuid() {
      if (this.localisation.cartUuid) {
        this.getCartDetail({payload: {uuid: this.localisation.cartUuid}});
      }
    },
    addMore() {
      if (this.localisation.cartUuid) {
        this.viewAllDeal()
      }
    },
    viewAllDeal(){
        if (this.storeUuid) {
            this.$router.push({name: 'category', params:{slug: this.defaultCategory}});
        } else {
            this.$router.push({name: 'home', query: {warning: '1', callback:'order/hot-promo'}});
            return false;
        }
    },
    initCustomerData () {
      if (this.isAuth) {
        this.customer.note = this.localisation.hasOwnProperty('note') ? this.localisation.note : '';
        this.customer.email = this.authUser.email;
        this.customer.name = this.authUser.name || (this.authUser.first_name + ' ' + this.authUser.last_name);
        this.customer.phone = this.getPhoneWithoutPrefix(this.authUser.phone, this.$const.PREFIX_PHONE);
      }
    },
    initErrorMessage () {
      this.errors = {
        isValid: false,
        name: false,
        note: false,
        phone: false,
        company: false,
        receipt: false,
        term: false,
        email: false,
        building: false,
        unitNo: false,
        street: false,
        address: false,
        amountOfChange: false,
        paymentMethod: false
      }
    },
    sortPayment(payments) {
      let tmpPayment = [];
      for (let i in payments) {
        let paymentType = {
          name: payments[i].name,
          code: payments[i].code,
          uuid: payments[i].uuid,
          items: [],
        };
        let itemOther = {};
        for (let j in payments[i].items) {
          if (payments[i].items[j].name !== 'Others') {
            paymentType.items.push(payments[i].items[j]);
          } else {
            itemOther = payments[i].items[j];
          }
        }
        if (!this.emptyVariable(itemOther)) {
          paymentType.items.push(itemOther);
        }
          if (!this.localisation.is_qr_code) {
            if((paymentType.code === 'ONLINE_PAYMENT' && this.grantTotal > 0) || paymentType.code !== 'ONLINE_PAYMENT'){
              tmpPayment.push(paymentType)
            }
          } else {
              if ((paymentType.code === 'ONLINE_PAYMENT' && this.grantTotal > 0) || paymentType.code === 'PAYMENT_IN_STORE') {
                  tmpPayment.push(paymentType)
              }
          }
      }
      this.payments = tmpPayment;
      for (let i in this.payments) {
        this.paymentMethodType = this.payments[i];
        break
      }
    },
    getPayments () {
      var vm = this;
      CartApi.getPayments({channel: 2, vm: this, type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)]},function (response) {
        vm.sortPayment(response.data);
      })
    },
    checkForm(e) {
      e.preventDefault();
      let isValidForm = true;
      this.initErrorMessage();
      if (this.orderType !== this.$const.ORDERTYPE.DINEIN) {
        if (!this.customer.name.trim()) {
          this.errors.name = this.$t('message.error.name');
          if (isValidForm) {
            this.$refs.name.focus();
          }
          isValidForm = false;
        }
        if (!this.customer.email) {
          this.errors.email = this.$t('message.error.email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        } else if (!this.validEmail(this.customer.email)) {
          this.errors.email = this.$t('message.error.invalid_email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        }

        if (!this.customer.phone) {
          this.errors.phone = this.$t('message.error.phone');
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        } else if (this.customer.phone.length < this.$const.PHONE.MIN || this.customer.phone.length > this.$const.PHONE.MAX) {
          this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX})
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        }
        if (this.orderType === this.$const.ORDERTYPE.DELIVERY) {
          if (this.paymentMethod.code == this.$const.PAYMENT_METHOD.CASH && this.contactLess == '1') {
            if (!this.amountOfChange) {
              this.errors.amountOfChange = this.$t('message.error.amount_of_change');
              if (isValidForm) {
                // this.$refs.amount_of_change.focus();
              }
              isValidForm = false;
            }
          }
        /* if (!this.deliveryInfo.building) {
            this.errors.building = this.$t('message.error.building');
            if (isValidForm) {
              this.$refs.building.focus();
            }
            isValidForm = false;
          }*/
          /*if (!this.deliveryInfo.unitNo) {
            this.errors.unitNo = this.$t('message.error.unitNo');
            if (isValidForm) {
              this.$refs.unitNo.focus();
            }
            isValidForm = false;
          }
          if (!this.deliveryInfo.street) {
            this.errors.street = this.$t('message.error.street');
            if (isValidForm) {
              this.$refs.street.focus();
            }
            isValidForm = false;
          }*/
          /*if (!this.deliveryInfo.address) {
            this.errors.address = this.$t('message.error.address');
            if (isValidForm) {
              this.$refs.address.focus();
            }
            isValidForm = false;
          }*/
        }

        if (this.customer.receipt && this.customer.term !== '0') {
          if (!this.customer.company) {
            this.errors.company = this.$t('message.error.company');
            if (isValidForm) {
              this.$refs.company.focus();
            }
            isValidForm = false;
          }
        }
        if (this.emptyVariable(this.paymentMethod)) {
          this.errors.paymentMethod = this.$t('message.error.paymentMethod');
          if (isValidForm) {
            VueScrollTo.scrollTo('.error-payment-block', 0.5)
          }
          isValidForm = false;
        }
        if (!this.customer.term || this.customer.term === '0') {
          this.errors.term = this.$t('message.error.term');
          if (isValidForm) {
            VueScrollTo.scrollTo('.error-term-block', 0.5)
          }
          isValidForm = false;
        }
      }
      
      if (isValidForm) {
        this.placeOrder();
      }
    },
    clearCart () {
      this.restLocalisation();
      this.resetCart();
    },
    async placeOrder(){
      var vm  = this;
      var payload = {
        "cart_uuid": this.cartUuid,
        "company": this.customer.company,
        "delivery": {
          "address_level_0": this.deliveryInfo.building,
          "address_level_1": this.deliveryInfo.unitNo,
          "address_level_2": this.deliveryInfo.street,
          "address_level_3": this.deliveryInfo.state,
          "full_address": this.deliveryInfo.address,
          "lat": this.localisation.lat,
          "long": this.localisation.long,
          "postal_code": this.deliveryInfo.postalCode
        },
        "email": this.isAuth ? this.authUser.email : this.customer.email,
        "name": this.customer.name,
        "note": this.customer.note,
        "contact_less": this.orderType === this.$const.ORDERTYPE.DELIVERY && this.contactLess == 1  ? '1' : '0',
        "payment": {
          "code": this.paymentMethod.code,
          "name": this.paymentMethod.name,
          "note": '',
          "uuid": this.paymentMethod.uuid
        },
        "phone": this.customer.phone,
        "receipt": this.customer.receipt ? 1 : 0,
        "is_qr_code": (this.localisation.is_qr_code) ? this.localisation.is_qr_code : 0,
        "vm": this
      }

      if (this.paymentMethod.code == this.$const.PAYMENT_METHOD.CASH && this.amountOfChange != '') {
        payload.payment_expected = this.amountOfChange * 1;
      }

    if (this.orderType === this.$const.ORDERTYPE.DINEIN) {
      payload.grantTotal = this.cartTotal
    } else {
      payload.grantTotal = vm.grantTotal
    }
    
    await CartApi.checkout(payload,
        function (response) {
          // check redirect
          vm.eventCheckout(vm.cartObject, payload.payment)
          if (vm.paymentMethod.code == vm.$const.PAYMENT_METHOD.BCA) {
            let key = 'payment-bca-'  + response.data.transaction_uuid;
            let paymentData = {
              total: vm.grantTotal,
              code: response.data.payment_data
            };
            localStorage.setItem(key, JSON.stringify(paymentData));
            vm.$router.push({name:'payment-status-bca', params: {uuid: response.data.transaction_uuid}});
          } else if (vm.paymentMethod.code == vm.$const.PAYMENT_METHOD.DOKU) {
            vm.submitDokuWallet(response.data);
          } else if (response.data.payment_url) {
            window.location = response.data.payment_url
          } else {
            vm._eventCheckout('eventTransactionComplete', payload, response.data)
            if (vm.orderType !== vm.$const.ORDERTYPE.DINEIN) {
                vm.clearCart();
            }
            vm.$router.push({name:'order-confirm', params: {uuid: response.data.uuid}})
          }
        }, function (data) {
          vm.notificationError(vm, data.message)
        }
        )

        vm.getCartDetail({payload: {uuid: this.cartUuid}})
    },
    submitDokuWallet(payload) {
      let form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("name", "formDoku");
      form.setAttribute("action", payload.payment_url);
      let formData = JSON.parse('{"' + payload.payment_data.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
      for (let key in formData) {
        let hiddenField = document.createElement("input");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", formData[key]);
        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    },
      showAsapPopup(){
          this.$modal.show(
              ModalOrderTime,null,
              {
                  classes:(this.isMobile()) ? 'modal-order-time' : ''
              }
          )
      },
    _eventCheckout(event, payload, data = {}) {
      //function for manthan
      let dataManthanCheckout = {}
      const cartProducts = [];
      const info = {
        firstName: this.authUser.first_name,
        lastName: this.authUser.last_name,
        line1: payload.delivery.address_level_1,
        line2: payload.delivery.address_level_2,
        city: payload.delivery.city,
        state: payload.delivery.address_level_3,
        zip: payload.delivery.postal_code,
        country: "Indonesia",
        emailAddress: payload.email,
        phoneNumber: payload.phone
      };
      let totalDiscount = 0;
      let couponCode = "";
      if (this.cartObject.products.length > 0) {
        for (const [index, item] of this.cartObject.products.entries()) {
          totalDiscount += item.discount
          for (const value of item.product) {
            cartProducts.push({
              productId: value.uuid,
              productPosition: index + 1,
              action: "Checkout",
              quantity: item.quantity,
              productBasePrice: (item.quantity > 0 ) ? item.sub_price * item.quantity : item.sub_price,
              tax:(item.quantity > 0 ) ? item.quantity * item.tax : item.tax,
              discount: item.discount,
              couponCode: item.promotion_code,
            });
          }
        }
      }

      if (this.cartObject.promotions.length > 0) {
        for (const [index, item] of this.cartObject.promotions.entries()) {
          if (index == 0) {
            couponCode = item.code;
          } else {
            couponCode += ',' + item.code;
          }
        }
      }

      if (event === 'eventCheckout') {
        dataManthanCheckout = {
          transactionId: '',
          referrerPageUrl: window.location.href,
          tenderType: payload.payment.name ? payload.payment.name : '',
          checkoutType: this.isAuth ? 'Logged in by ' + this.authUser.email : 'Guest',
          totalPrice: payload.grantTotal,
          shippingCost: this.cartObject.information.delivery_fee > 0 ? this.cartObject.information.delivery_fee : 0,
          discount: totalDiscount,
          couponCode: couponCode,
          cartProducts: cartProducts,
          billingInfo: info,
          shippingInfo: info,
          cartId: payload.cart_uuid,
        }
        branch.branchLog('INITIATE_PURCHASE', this.cartObject.products)
      }

      if (event ===  'eventTransactionComplete') {
        dataManthanCheckout = {
          transactionId: data.transaction_uuid,
          referrerPageUrl: window.location.href,
          orderId: data.code,
          tenderType: payload.payment.name ? payload.payment.name : '',
          checkoutType: this.isAuth ? 'Logged in by ' + this.authUser.email : 'Guest',
          totalPrice: payload.grantTotal,
          shippingCost: this.cartObject.information.delivery_fee > 0 ? this.cartObject.information.delivery_fee : 0,
          discount: totalDiscount,
          couponCode: couponCode,
          cartProducts: cartProducts,
          billingInfo: info,
          shippingInfo: info,
          cartId: payload.cart_uuid,
        }
        branch.branchLog('PURCHASE', this.cartObject.products, dataManthanCheckout)
      }
      // eslint-disable-next-line no-undef
      manthan.execute(dataManthanCheckout,event)
    },
    manthanCheckout(){
      var vm  = this;
      var payload = {
        "cart_uuid": this.cartUuid,
        "company": this.customer.company,
        "delivery": {
          "address_level_0": this.deliveryInfo.building,
          "address_level_1": this.deliveryInfo.unitNo,
          "address_level_2": this.deliveryInfo.street,
          "address_level_3": this.deliveryInfo.state,
          "full_address": this.deliveryInfo.address,
          "lat": this.localisation.lat,
          "long": this.localisation.long,
          "postal_code": this.deliveryInfo.postalCode,
          "city": this.localisation.deliveryInfo ? this.localisation.deliveryInfo.city : ''
        },
        "email": this.isAuth ? this.authUser.email : this.customer.email,
        "name": this.customer.name,
        "note": this.customer.note,
        "contact_less": this.orderType === this.$const.ORDERTYPE.DELIVERY && this.contactLess == 1  ? '1' : '0',
        "payment": {
          "code": this.paymentMethod.code,
          "name": this.paymentMethod.name,
          "note": '',
          "uuid": this.paymentMethod.uuid
        },
        "phone": this.customer.phone,
        "receipt": this.customer.receipt ? 1 : 0,
        "is_qr_code": (this.localisation.is_qr_code) ? this.localisation.is_qr_code : 0,
        "vm": this
      }

      if (this.paymentMethod.code == this.$const.PAYMENT_METHOD.CASH && this.amountOfChange != '') {
        payload.payment_expected = this.amountOfChange * 1;
      }

      if (this.orderType === this.$const.ORDERTYPE.DINEIN) {
        payload.grantTotal = this.cartTotal
      } else {
        payload.grantTotal = vm.grantTotal
      }

      vm._eventCheckout('eventCheckout', payload)
    },
    calculateTotal() {
      this.cartObject.products.forEach( e => {
        if (e.is_checkout == 1) {
          this.cartTotal += e.price
        }
      });
    },
  }
}
</script>
<style scoped>
  .login-account {
      background-color: #f6f7f9;
      padding: 20px;
  }
  .contact-less-icon::before {
      content: "";
      background-image: url(../assets/images/PHD_contactless_20200320.png);
      background-size: 35px;
      background-repeat: no-repeat;
      display: inline-block;
      height: 40px;
      width: 40px;
      margin-right: 5px;
  }
  label.contact-less  {
      justify-content: flex-start !important;
  }
  .mobile-bottom{margin-bottom: 60px}
  .dinein-checkout-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      max-height: 100vh
  }
</style>
