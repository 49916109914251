<template>
    <div class=" en-GB app-bg">
        <div class="container mt-20 mb-40">
            <div class="font-open-sans-bold uppercase text-center relative mb-20">
                <h1 class="px-40">{{$t('common.title_term_of_use')}}</h1>
                <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
            </div>
            <div class="p-20 bg-white">
                <div class="container p-10">
                    <div>
                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong><span style="font-size:14.0pt">KETENTUAN PENGGUNAAN APLIKASI DAN WEBSITE PIZZA HUT INDONESIA</span></strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Selamat datang di Aplikasi &amp; Website Pizza Hut Indonesia</strong></span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Syarat dan Ketentuan Umum</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>&nbsp;</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Syarat dan Ketentuan Umum ini adalah perjanjian antara pengguna (&ldquo;<strong>Anda</strong>&rdquo;) dengan PT Sarimelati Kencana Tbk (&ldquo;<strong>Kami</strong>&rdquo;), sebuah perusahaan terbuka yang didirikan dan diatur secara sah berdasarkan hukum Republik Indonesia dan berdomisili di Jakarta Selatan, Indonesia.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Syarat dan Ketentuan Umum ini mengatur ruang lingkup hak dan kewajiban berkenaan atas akses dan penggunaan Anda terhadap aplikasi Pizza Hut Indonesia dan website www.pizzahut.co.id</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Dengan menggunakan aplikasi dan mengunjungi website Kami, Anda menerima dan setuju untuk mengikatkan diri terhadap Syarat dan Ketentuan yang ditujukan untuk penggunaan aplikasi dan website Kami. Jika Anda tidak bersedia untuk memberikan persetujuan, maka Kami menyarankan Anda untuk tidak melanjutkan penggunaan aplikasi dan website Kami. Lebih lanjut, Anda juga menyetujui setiap bentuk perubahan, penambahan dan/atau pergantian terhadap Syarat dan Ketentuan Umum, yang merupakan satu kesatuan bagian yang tidak terpisahkan, sebagaimana diberlakukan dari waktu ke waktu oleh Kami.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Syarat dan Ketentuan Umum dapat diubah, ditambah dan/atau diperbaharui secara berkala, dan Kami akan menempatkan setiap perubahan, penambahan dan/atau pembaharuan di dalam aplikasi dan website kami. Syarat dan Ketentuan Umum yang diubah dan diperbarui akan berlaku setelah dipublikasikan di aplikasi dan website Kami. Kami menyarankan Anda untuk secara berkala memeriksa dan membaca kembali Syarat dan Ketentuan Umum, karena setiap perubahan, penambahan dan/atau pembaharuan akan dilakukan dengan tidak menyampaikan pemberitahuan terlebih dahulu. Dengan tetap mengakses dan menggunakan aplikasi maupun website Kami, maka Anda dengan ini menyatakan persetujuan untuk setiap perubahan, penambahan dan/atau pembaharuan terhadap Syarat dan Ketentuan Umum yang diberlakukan oleh Kami dari waktu ke waktu.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Jika Anda tidak menyetujui syarat dan ketentuan yang diatur disini, Kami mohon untuk tidak menggunakan layanan atau mengakses aplikasi dan website Kami. Jika Anda ternyata berusia di bawah 17 tahun atau berada di bawah pengampuan, maka Anda harus mendapatkan izin terlebih dahulu dari salah satu orang tua atau wali sah untuk membuat sebuah akun, dan orang tua atau wali yang sah tersebut harus menyetujui syarat dan ketentuan ini. Jika Anda belum memahami syarat dan ketentuan ini, baik secara sebagian maupun keseluruhan, Kami mohon tidak membuat akun sampai Anda telah meminta bantuan orang tua atau wali sah Anda. </span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Jika Anda adalah orang tua atau wali sah anak di bawah umur yang hendak membuat akun, maka Anda harus menyetujui syarat dan ketentuan ini atas nama anak di bawah umur tersebut, dan Anda akan bertanggung jawab atas seluruh penggunaan akun atau pemakaian akses pada aplikasi dan website Kami, termasuk setiap instruksi dan/atau transaksi yang dibuat oleh anak di bawah umur tersebut, terlepas dari apakah akun anak di bawah umur tersebut dibuka saat ini atau di kemudian hari, dan terlepas dari apakah anak di bawah umur tersebut berada atau tidak berada di bawah pengawasan Anda selama ia mempergunakan aplikasi dan website Kami.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda dengan ini menyatakan dan menjamin secara terang dan jelas bahwa:</span></span></p>

                        <div style="padding-left: 5%;">
                            <ol>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Anda adalah individu yang cakap untuk melakukan perbuatan hukum sesuai dengan peraturan perundang-undangan yang berlaku di Republik Indonesia, khususnya dalam rangka melakukan pendaftaran dan memberikan persetujuan terhadap ketentuan-ketentuan yang diatur berdasarkan Syarat dan Ketentuan ini; dan </span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Anda merupakan individu yang telah berusia 17 (tujuh belas) tahun atau lebih dan Anda tidak berada di dalam kondisi pengampuan.</span></span></li>
                            </ol>
                        </div>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Ruang Lingkup Privasi / Perlindungan Data Pribadi</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>&nbsp;</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami menyarankan Anda untuk membuat dan mendaftarkan diri Anda dengan membuat akun baru untuk mempergunakan aplikasi dan website kami. Dalam hal ini, Anda bertanggung jawab untuk menjaga kerahasiaan akun dan sandi untuk membatasi akses melalui perangkat pribadi Anda, termasuk untuk menerima tanggung jawab atas semua aktivitas yang terjadi dalam akun Anda. Kami menyarankan agar Anda untuk tidak memberitahukan sandi kepada pihak lain, serta untuk melakukan pergantian sandi untuk akun Anda dalam rentang waktu tertentu.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Dengan melakukan pendaftaran, akses dan penggunaan aplikasi dan website Kami, Anda memberikan data dan informasi pribadi secara mandiri dan sukarela kepada Kami. Anda selanjutnya menyatakan bahwa setiap data dan informasi yang disampaikan adalah benar dan sah, serta memberikan persetujuan kepada Kami untuk memperoleh, mengumpulkan, menyimpan, mengelola mengalihkan dan mempergunakan data dan informasi tersebut sesuai dengan Syarat dan Ketentuan ini.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami mengasumsikan bahwa semua data dan informasi yang diberikan, termasuk setiap perubahan di masa yang akan datang, adalah akurat dan benar. Apabila data, informasi dan perubahan-perubahan yang diberikan ternyata terbukti tidak benar, maka dengan ini Kami menyatakan tidak bertanggung jawab atas segala konsekuensi yang dapat terjadi sebagai akibat dari ketidakbenaran tersebut.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Penggunaan Data dan Informasi</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Aplikasi dan website Kami memungkinkan menyimpan informasi pribadi Anda baik yang Anda bagikan saat mendaftar dan atau melakukan pesanan di aplikasi dan website Kami maupun informasi pribadi yang Kami dapatkan secara otomatis saat Anda mengakses aplikasi dan website Kami. Anda Kami anggap memahami dan menyetujui jika data yang Kami terima tersebut dapat Kami gunakan di aplikasi dan website Kami atau dalam saluran lainnya atau pun pada media tertentu.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami akan melakukan upaya terbaik untuk selalu menjaga keamanan dan kerahasiaan data dan informasi yang Anda serahkan kepada Kami maupun yang Kami kumpulkan dari Anda, terutama yang menyangkut data pribadi Anda, yang diserahkan kepada Kami atau dikumpulkan oleh Kami untuk tujuan pendaftaran, peningkatan, pengkinian dan/atau pembaharuan data, penyampaian informasi, pengurusan klaim, analisa, pengembangan/peningkatan layanan, dan tujuan-tujuan lainnya sepanjang tidak bertentangan dengan peraturan perundang-undangan yang berlaku.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami akan menggunakan sebagian atau seluruh data dan informasi yang disampaikan Anda untuk hal-hal sebagai berikut:</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <div class="unordered" style="padding-left: 5%;">
                            <ol>
                                <li style="text-align:justify"><span style="font-size:12pt"><span> Memproses segala bentuk permintaan dan transaksi yang dilakukan melalui aplikasi dan website Kami.</span></span></li>

                                <li style="text-align:justify"><span style="font-size:12pt"><span>Mengembangkan layanan dan fitur yang disediakan melalui aplikasi dan website Kami sebagai bagian dari upaya Kami untuk meningkatkan keamanan dan kenyamanan Anda.</span></span></li>

                                <li style="text-align:justify"><span style="font-size:12pt"><span> Melakukan riset pasar yang terdiri dari analisis data, pengujian, penelitian, termasuk menganalisis kecenderungan aktivitas dan pemakaian pada aplikasi dan website Kami.</span></span></li>

                                <li style="text-align:justify"><span style="font-size:12pt"><span> Melakukan komunikasi dengan Anda untuk membantu, melayani, menyelesaikan transaksi, menawarkan program promosi dan/atau menyelesaikan kendala permasalahan yang dialami oleh Anda.</span></span></li>

                                <li style="text-align:justify"><span style="font-size:12pt"><span> Melakukan peninjauan dan pemeriksaan atas kejadian aktivitas yang bersifat mencurigakan, atau mengandung pelanggaran dalam penggunaan aplikasi dan website Kami.</span></span></li>
                            </ol>

                            <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>
                        </div>
                        
                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami akan menyimpan data dan informasi Anda selama akun Anda masih aktif dan akan melakukan penghapusan terhadap data dan informasi tersebut sesuai dengan ketentuan peraturan perundang &ndash; undangan yang berlaku.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Pengungkapan Data dan Informasi</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami menjamin tidak ada aktivitas penjualan, distribusi atau peminjaman data informasi Anda kepada pihak ketiga lainnya, kecuali dalam hal-hal yang dinyatakan berikut ini:</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <div class="unordered" style="padding-left: 5%;">
                            <ol>
                                <li style="text-align:justify"><span style="font-size:12pt"><span> Sebagai bagian dari penegakan hukum atau memenuhi kewajiban hukum, dengan memperhatikan ketentuan peraturan perundang-undangan yang berlaku.</span></span></li>

                                <li style="text-align:justify"><span style="font-size:12pt"><span> Pengungkapan kepada pihak terafiliasi atau pemberi waralaba, sebagai bagian dari kewajiban Kami berdasarkan kebijakan yang berlaku, dengan tujuan tertentu dalam rangka membantu program peningkatan layanan dan pengolahan data dan informasi yang ditujukan untuk kepentingan dan atas nama Kami.</span></span></li>

                                <li style="text-align:justify"><span style="font-size:12pt"><span> Keperluan komunikasi dengan mitra usaha Kami (seperti mitra penyedia layanan pembayaran dan mitra penyedia layanan pengiriman) sehubungan dengan transaksi pembelian Anda.</span></span></li>

                                <li style="text-align:justify"><span style="font-size:12pt"><span> Dalam rangka menyelesaikan keluhan atas transaksi, kerusakan pengiriman dan/atau perselisihan antara Anda dengan Kami.</span></span></li> 
                            </ol>
                        </div>

                        <p style="text-align:justify">&nbsp;</p>

                        <p>&nbsp;</p>

                        <p>&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Pemasaran dan Promo</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami dapat mengadakan kegiatan promosi (&ldquo;<strong>Promo</strong>&rdquo;) dengan syarat dan ketentuan khusus dari waktu ke waktu. Kami juga dapat membagikan voucher produk dengan nilai nominal tertentu (&ldquo;<strong>Voucher</strong>&rdquo;) kepada Anda dengan syarat dan ketentuan terpisah. Dalam hal ini, Kami menyarankan agar Anda membaca dan mempelajari dengan seksama untuk setiap syarat dan ketentuan yang diberlakukan oleh kami sesuai dengan Promo yang sedang berlangsung atau Voucher yang diberikan.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Rincian atas Promo dan Voucher akan ditentukan sesuai dengan kebijakan dan program pemasaran Kami. Kami memiliki hak dan kewenangan untuk menentukan rincian atas jenis, tata cara, prosedur, masa berlaku, ataupun ketentuan-ketentuan lain yang diterbitkan sehubungan dengan Promo dan Voucher, baik yang diberlakukan secara bersama-sama maupun terpisah.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Slices</strong></span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Ketentuan penerimaan Slices ini adalah Anda dipersyaratkan untuk terlebih dahulu bergabung menjadi member dari aplikasi dan website Pizza Hut Indonesia. Dalam setiap transaksi yang Anda lakukan dengan nominal Rp10.000,- (sepuluh ribu Rupiah) akan mendapatkan 1 Slices dan berlaku untuk setiap kelipatan. Kami memiliki kewenangan untuk merubah nilai nominal yang dipersyaratkan untuk penukaran Slices dari waktu ke waktu. Pemberian Slices akan diberikan secara otomatis untuk setiap proses pemesanan (<em>order</em>) transaksi yang dilakukan melalui aplikasi dan website Pizza Hut Indonesia telah terselesaikan.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Slices memiliki masa berlaku terbatas selama 1 (satu) tahun yang dihitung dari periode transaksi terakhir Anda. Setelah lampaunya jangka waktu 1 (satu) tahun tersebut, maka akan dilakukan penyesuaian terhadap jumlah Slices yang belum habis terpakai di dalam akhir periode triwulan (<strong><em>3-bulan</em></strong>) secara berkala (Maret, Juni, September dan Desember pada setiap tahunnya). Dengan demikian Kami menyarankan Anda untuk memperhatikan masa berlaku Slices dan segera mempergunakannya untuk konversi sesuai dengan ketersediaan yang berlaku pada saat itu.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Sebagai Contoh: Ade memiliki <strong>120 Slices</strong> yang diperhitungkan dari transaksi terakhir 1 Juli 2023. </span></span></p>

                        <div style="padding-left: 5%;">
                            <ol>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Dalam hal ini, Slices memiliki masa berlaku dan masih dapat dipergunakan sampai dengan tanggal <strong>30 September 2024</strong>. </span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Sepanjang keberlakukan dari Slices, Ade dapat mempergunakan Slices <strong>untuk ditukarkan dengan berbagai ragam hadiah dan promosi</strong> yang Kami tentukan dengan rincian jenis dan kebijakan konversi jumlah Slices yang Kami berlakukan dari waktu ke waktu.</span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Sisa dari Slices milik Ade yang belum habis terkonsumsi akan <strong>menjadi tidak berlaku (<em>expired</em>) </strong>dan tidak dapat dipergunakan lagi selambat-lambatnya pada tanggal 1 Oktober 2024. </span></span></li>
                            </ol>
                        </div>
                        
                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Voucher Tier </strong></span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Perhitungan Tier didasarkan pada akumulasi transaksi Anda sebagai member yang dilakukan baik melalui outlet, aplikasi dan website Pizza Hut Indonesia dalam rentang waktu 365 (tiga ratus enam puluh lima) hari kalender terakhir berturut-turut yang merupakan akumulasi dihitung selama 1 (satu) tahun. Metode penghitungan tidak didasarkan apda tahun buku berjalan atau dipengaruhi berdasarkan pergantian tahun. Tier atau tingkatan member Pizza Hut Indonesia dibagi menjadi 3 (tiga) tingkatan, sebagai berikut :</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <center>
                            <table class="tierlevel" cellspacing="0" style="border-collapse:collapse;">
                                <tbody>
                                    <tr>
                                        <td style="background-color:#f4b083; border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:1px solid black; vertical-align:top; width:170px">
                                        <p style="text-align:center"><span style="font-size:12pt"><span><strong><span style="color:black">Voucher Tier</span></strong></span></span></p>
                                        </td>
                                        <td style="background-color:#f4b083; border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:1px solid black; vertical-align:top; width:300px">
                                        <p style="text-align:center"><span style="font-size:12pt"><span><strong><span style="color:black">Level / Tingkatan</span></strong></span></span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; vertical-align:top; width:170px">
                                        <p style="text-align:center"><span style="font-size:12pt"><span><strong>Fan</strong></span></span></p>
                                        </td>
                                        <td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; vertical-align:top; width:300px">
                                        <p style="text-align:center"><span style="font-size:12pt"><span><strong>Rp0,- sampai Rp499.000,-</strong></span></span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; vertical-align:top; width:170px">
                                        <p style="text-align:center"><span style="font-size:12pt"><span><strong>Lover</strong></span></span></p>
                                        </td>
                                        <td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; vertical-align:top; width:300px">
                                        <p style="text-align:center"><span style="font-size:12pt"><span><strong>Rp500.000,- sampai Rp1.499.000,-</strong></span></span></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-bottom:1px solid black; border-left:1px solid black; border-right:1px solid black; border-top:none; vertical-align:top; width:170px">
                                        <p style="text-align:center"><span style="font-size:12pt"><span><strong>Mania</strong></span></span></p>
                                        </td>
                                        <td style="border-bottom:1px solid black; border-left:none; border-right:1px solid black; border-top:none; vertical-align:top; width:300px">
                                        <p style="text-align:center"><span style="font-size:12pt"><span><strong>&gt;=Rp1.500.000,-</strong></span></span></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </center>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Setiap Tier atau tingkatan akan menerima manfaat berupa voucher PHD atau PHR dengan jenis tertentu. Voucher akan diberikan secara otomatis ketika terjadi peningkatan Tier dan masing-masing Tier akan mendapatkan voucher yang berbeda-beda, semakin tinggi Tier maka semakin banyak keuntungan dan manfaat yang didapatkan oleh Anda.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Sebagai contoh:&nbsp; Ade adalah member Pizza Hut Indonesia dengan status <strong>Tier Mania</strong>. </span></span></p>

                        <div style="padding-left: 5%;">
                            <ol>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Apabila sekarang <strong>1 Desember 2023</strong>, maka akumulasi penghitungan transaksi Ade yang berlaku dihitung sejak tanggal <strong>1 Desember 2022</strong> untuk menentukan status tingkatan Tier. </span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Selama keberlakukan status <strong>Tier Mania</strong> dari Ade, Ade dapat menerima berbagai manfaat dan beragam bonus promosi yang diberikan sesuai dengan kebijakan yang diberlakukan oleh Kami dari waktu ke waktu</span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Status atas <strong>Tier Mania</strong> dari Ade dapat berubah seiring dengan jumlah transaksi pembelian yang dilakukan dan pergantian hari kalender berjalan.</span></span></li>
                            </ol>
                        </div>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Treasure Hut</strong></span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda memiliki kesempatan untuk memenangkan hadiah atau kejutan gratis dari Treasure Hut pada setiap melakukan pemesanan melalui channel online seperti aplikasi, website, BYOD atau QR Takeaway di Pizza Hut Indonesia. Hadiah yang akan Anda dapatkan berupa merchandise Pizza Hut Indonesia dan/atau Voucher produk PHD atau PHR. Apabila Anda menerima merchandise, Anda dapat menukarkan tanpa adanya transaksi dan tidak ada pemungutan biaya apapun untuk pengiriman merchandise ke alamat yang Anda kehendaki. Merchandise akan dikirimkan dengan perkiraan jangka waktu pengiriman 21 hari kerja sejak Anda melakukan klaim. Jika Anda ingin melakukan pengecekan atas resi pengiriman, Anda bisa menghubungi Layanan Pelanggan (<em>Customer Services</em>) kami. Apabila Anda menerima mendapatkan Voucher produk PHD atau PHR, Anda dapat menemukan voucher tersebut di bagian halaman voucher.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p>&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Pembayaran</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda memiliki pilihan berbagai metode pembayaran, yang menurut pendapat Anda merasa lebih nyaman dan mudah untuk dipergunakan, sebagaimana difasilitasi melalui aplikasi dan website Kami. Kami telah menjalin kerjasama dengan lembaga perbankan dan/atau penyedia jasa layanan pembayaran, yang masing &ndash; masing telah terdaftar pada Otoritas Jasa Keuangan, berkenaan atas berbagai metode dan tata cara pembayaran yang dapat dipergunakan oleh Anda pada aplikasi dan website Kami. Tidak ada pembayaran dalam bentuk lain yang dapat dipergunakan atau dimanfaatkan Anda selain sebagaimana telah disediakan pada aplikasi dan website Kami.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda berkewajiban untuk melakukan pembayaran secara penuh dan terselesaikan dalam jangka waktu yang telah ditentukan sesuai dengan transaksi pembelian yang dilakukan pada aplikasi dan website Kami. Apabila Anda belum menyelesaikan pembayaran dalam jangka waktu yang telah ditetapkan maka Kami berhak untuk menyatakan pembatalan terhadap transaksi pembelian yang Anda lakukan. Anda dapat melakukan klarifikasi dan konfirmasi atas transaksi pembelian yang telah dilaksanakan secara langsung sesuai dengan prosedur dan tata cara yang ditetapkan oleh masing-masing lembaga perbankan dan/atau penyedia jasa layanan pembayaran yang terkait sesuai dengan syarat dan ketentuan yang berlaku.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Pernyataan Penyangkalan</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda setuju bahwa dengan mempergunakan aplikasi dan website Kami maka segala resiko yang mungkin timbul akan menjadi resiko Anda sendiri. Sepanjang diperbolehkan oleh peraturan perundang-undangan yang berlaku, Kami, direksi, dewan komisaris, manajemen dan karyawan dapat menyangkal dari semua jaminan, baik yang dinyatakan secara tegas maupun tersirat, sehubungan dengan aplikasi dan website Kami, beserta tata cara penggunaannya. </span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami tidak memberikan jaminan atau pernyataan tentang kesesuaian, keakuratan atau kelengkapan atas muatan materi aplikasi dan website, dan Kami tidak bertanggung jawab atas : (i) kesalahan atau ketidakakuratan muatan materi (<em>konten</em>), (ii) kerusakan properti, hingga apapun bentuknya, yang dihasilkan sebagai akibat dari mengakses dan menggunakan aplikasi dan website Kami, (iii) setiap bentuk pemakaian akses yang tidak sah atau penggunaan server Kami yang tidak aman, termasuk namun tidak terbatas pada informasi pribadi atau data keuangan yang tersimpan di dalamnya, (iv) setiap bentuk gangguan atau penghentian transmisi ke atau dari aplikasi dan website Kami, (v) bug, virus, trojan horse, atau sejenisnya yang dapat ditularkan ke atau melalui aplikasi dan website Kami, baik secara langsung maupun tidak langsung, dengan pihak ketiga, dan/atau (vi) kesalahan atau kelalaian dalam materi muatan (konten) atau untuk setiap kerugian atau kerusakan apapun yang timbul sebagai akibat dari penggunaan aplikasi dan website Kami.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p>&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Pembatasan Tanggung Jawab</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Sepanjang diperbolehkan oleh peraturan perundang-undangan, tidak satupun dari Kami, direksi, dewan komisaris, manajemen dan karyawan, termasuk namun tidak terbatas pada pihak terafiliasi, pemberi waralaba, konsultan atau agen pihak ketiga yang ditunjuk oleh Kami, sehubungan dengan pembuatan, produksi, penyampaian atas materi muatan (<em>konten</em>) yang terdapat pada aplikasi dan website Kami yang diharuskan untuk bertanggung jawab kepada Anda, maupun setiap pihak ketiga lain yang mengajukan tuntutan melalui Anda, atas setiap bentuk kerugian (baik yang bersifat ekonomis maupun non-ekonomis), kehilangan keuntungan atau aset tidak berwujud (<em>goodwill</em>), reputasi serta potensi pendapatan, ketidakakuratan data dan informasi, ketidaksesuaian materi muatan (<em>konten</em>), kegagalan untuk memperoleh hasil, manfaat ataupun keuntungan yang diharapkan, maupun setiap bentuk kerugian lain yang berifat signifikan atau insidental, secara langsung maupun tidak langsung, yang disebabkan karena pemakaian akses pada aplikasi dan website Kami, ataupun yang dikarenakan kegagalan dalam melakukan pemakaian akses terhadap aplikasi dan website Kami, baik yang bersifat keseluruhan ataupun sebagian, sebagaimana dapat terjadi berdasarkan wanprestasi dalam hubungan kontraktual, jenis &ndash; jenis perbuatan melawan hukum, pertanggungjawaban atas produk makanan dan minuman, serta kemungkinan apakah Kami mengetahui atau setidaknya dapat memperkirakan kemungkinan terjadinya kerugian tersebut atau tidak kepada Anda ataupun kepada pihak lain yang terkait dengan Anda. Pembatasan tanggung jawab Kami akan tetap berlaku meskipun telah terjadi pengakhiran atas akun Anda pada aplikasi dan website Kami, ataupun setelah berakhirnya jangka waktu keberlakuan atas akun Anda.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami dapat melakukan pembatasan tertentu atas setiap atau seluruh layanan yang disediakan melalui aplikasi dan website Kami, sesuai dengan kebijakan Kami dan/atau peraturan perundang-undangan yang berlaku. Kami tidak memberikan jaminan atau pernyataan bahwa jenis dan lingkup transaksi, manfaat, fitur dan fungsi yang sama akan tersedia bagi pemakaian akses pada aplikasi dan website Kami. Kami dapat menerapkan aturan, pembatasan, larangan, atau langkah-langkah tambahan lain yang, berdasarkan direksi dan kebijakan Kami secara absolut, diperlukan atau sewajarnya sehubungan dengan pemakaian akses pada aplikasi dan website Kami.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda memahami dan menyepakati bahwa pemakaian akses terhadap aplikasi dan website Kami dilakukan atas resiko Anda sendiri. Pelayanan yang difasilitasi melalui aplikasi dan website Kami diberikan dengan sifat &ldquo;APA ADANYA&rdquo; dan &ldquo;SEBAGAIMANA TERSEDIA&rdquo;. Berdasarkan uraian dan penjelasan sebagaimana tersebut di atas, dengan ini Anda membebaskan Kami, direksi, dewan komisaris, manajemen, dan karyawan, dari setiap tuntutan, gugatan, ganti rugi, dan/atau klaim yang Anda ajukan sehubungan dengan berbagai bentuk penyalahgunaan dan/atau pemakaian akses yang tidak sah terhadap akun Anda sebagaimana telah terdaftar melalui aplikasi dan website Kami.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p>&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Koneksi Jaringan Internet</strong></span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda dengan ini memahami dan mengakui bahwa pengaksesan dan pemanfaatan aplikasi dan website Kami membutuhkan jaringan internet. Anda juga memahami bahwa gangguan pada jaringan internet, baik pada perangkat Anda atau aplikasi dan sistem Kami, dapat menyebabkan kegagalan, keterlambatan, dan pembatasan terhadap pengaksesan dan pemanfaatan aplikasi dan website Kami. Anda dengan ini memahami dan mengakui bahwa ketersediaan dan kelancaran jaringan internet merupakan suatu hal yang sifatnya diluar tanggung jawab Kami.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Hak Atas Kekayaan Intelektual</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Aplikasi dan website Kami memiliki merek dagang yang berharga yang dimiliki dan digunakan oleh Kami. Merek dagang ini mendapatkan hak perlindungan hukum dari reproduksi dan simulasi sesuai dengan peraturan perundang-undangan yang berlaku baik di bawah hukum nasional dan/atau konvensi internasional.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Muatan materi (konten), teks, rancangan, design, grafik, gambar, dan kode html, sebagaimana terdapat pada aplikasi dan website Kami merupakan hak ekslusif dari Kami, dan tidak dapat disalin, didistribusikan, ditampilkan, direproduksi atau ditransmisikan dalam bentuk apapun atau dengan cara apapun tanpa persetujuan tertulis terlebih dahulu dari Kami.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Keadaan Kahar</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&quot;Keadaan Kahar&quot; adalah keadaan, situasi atau kondisi yang terjadi di luar kemampuan pihak yang mengalaminya untuk mencegahnya dan tidak dapat dihindarkan oleh pihak yang mengalaminya, sehingga menyebabkan pihak yang mengalaminya tidak dapat melaksanakan sebagian dan/atau seluruh kegiatannya yang berpengaruh terhadap pelaksanaan hak dan kewajiban dari pihak yang mengalaminya. </span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Jenis-jenis kejadian yang termasuk dalam kategori Keadaan Kahar, yaitu; bencana alam, gunung meletus, gempa bumi, tsunami, kebakaran, banjir, pemadaman listrik, gangguan koneksi jaringan internet, kondisi perang, baik yang dinyatakan atau tidak, sabotase, pemogokan, demonstrasi, kerusuhan sosial, epidemi atau pandemi, diundangkannya suatu peraturan perundang-undangan, kerusuhan, demonstrasi, pemogokan atau kondisi atau kejadian lain yang secara wajar tidak dapat dicegah atau dihindari oleh pihak yang mengalaminya, yang menyebabkan pihak yang mengalaminya tidak dapat melaksanakan hak dan kewajiban yang timbul dari Syarat dan Ketentuan ini.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda dengan ini memahami dan mengakui bahwa kegagalan atau keterlambatan Kami dalam melaksanakan kewajiban-kewajiban dan/atau tanggung jawab Kami yang disebabkan oleh terjadinya suatu Keadaan Kahar tidak dapat dianggap sebagai suatu kejadian kelalaian atau wanprestasi.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda dengan ini sepakat untuk tidak mengajukan gugatan, tuntutan, klaim dan/atau meminta ganti kerugian, baik yang bersifat materiil maupun immateriil, atas keterlambatan atau kegagalan Kami dalam melaksanakan dan memenuhi kewajiban-kewajiban Kami yang disebabkan oleh suatu Keadaan Kahar.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Pilihan Hukum</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Syarat dan ketentuan ini diatur dan ditafsirkan berdasarkan Hukum Republik Indonesia. Segala perselisihan yang mungkin timbul antara Anda dengan Kami sehubungan dengan pemakaian aplikasi dan website Kami maupun pelaksanaan dalam bentuk lain yang terjadi berdasarkan syarat dan ketentuan ini akan diselesaikan secara ekslusif dalam wilayah yurisdiksi Pengadilan Negeri Jakarta Selatan.</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Tindakan Penyalahgunaan</strong></span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Dengan ini disampaikan kategori tindakan-tindakan penyalahgunaan yang terdiri dari sebagai berikut:</span></span></p>

                        <div style="padding-left: 5%;">
                            <ol>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Tindakan yang merupakan pelanggaran atas salah satu atau lebih ketentuan yang diatur dalam Syarat dan Ketentuan Umum ini.</span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Tindakan yang merupakan pelanggaran atas peraturan perundang-undangan yang berlaku, tindakan penipuan (fraud), penggelapan, pencucian uang, pendanaan kegiatan terorisme, pembayaran atas barang dan/atau jasa ilegal, kegiatan pornografi/pornoaksi, perjudian, atau tindakan-tindakan lainnya yang merupakan pelanggaran atas kepentingan pihak manapun.</span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Tindakan yang merugikan atau dapat menyebabkan kerugian kepada Kami, pihak terafiliasi Kami, dan/atau mitra usaha (antara lain, mitra penyedia layanan pembayaran dan mitra penyedia layanan pengiriman) yang bekerja sama dengan Kami.</span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Tindakan yang dilakukan untuk mendapatkan keuntungan pribadi, atau dilakukan secara abusif, palsu, atau fiktif, baik melalui aplikasi dan/atau website Kami, untuk mendapatkan keuntungan pribadi, termasuk namun tidak terbatas pada perbuatan-perbuatan yang menimbulkan dampak negatif terhadap reputasi Merek Dagang Pizza Hut dan/atau PHD beserta kualitas layanan terkait dengan produk-produk Kami.</span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Tindakan yang dimaksudkan untuk mendapatkan atau memperoleh Slices, Voucher Tier, dan Treasure Huts, dengan cara-cara curang, contoh: penyalahgunaan atau tindakan manipulatif untuk mengelabui dan memperdaya aplikasi atau website Kami atau suatu program promosi untuk mendapatkan Slices, Voucher Tier dan Treasure Huts.</span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Laporan yang disampaikan oleh mitra usaha yang berkerjasama dengan Kami dan/atau pihak ketiga lain yang disertai dengan bukti permulaan yang cukup bahwa akun Anda telah dipergunakan untuk melakukan tindakan-tindakan yang dapat merugikan Kami dan/atau mitra usaha Kami.</span></span></li>
                                <li style="text-align:justify"><span style="font-size:12pt"><span>Tindakan yang dilakukan dengan meretas, mengelabui, menipu, dan/atau memanipulasi aplikasi dan/atau website Kami atau sistem yang dimiliki dan/atau dikendalikan oleh pihak terafiliasi Kami, dan/atau mitra usaha yang bekerja sama dengan Kami.</span></span></li>
                            </ol>
                        </div>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp; </span></span></p>

                        <p>&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami memiliki kewenangan dan kebijakan untuk melakukan pemblokiran akun Anda, baik secara sementara dalam suatu jangka waktu tertentu atau secara permanen, apabila telah terjadi atau terdapat suatu indikasi tertentu yang termasuk dalam salah satu atau lebih dari tindakan-tindakan penyalahgunaan sebagaimana tersebut di atas. Pemblokiran akun akan menyebabkan akun Anda tidak dapat digunakan untuk mengakses dan menggunakan aplikasi dan/atau website Kami sampai dengan akun Anda telah diaktifkan kembali oleh Kami. </span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>Layanan Pengaduan Konsumen</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda dapat menghubungi perwakilan Kami dengan cara-cara sebagai berikut.</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Call Center :</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>1500-600</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Kami akan membalas email</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Anda sesegera mungkin</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>[csc@phd.co.id]</strong></span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span><strong>[customerservice@pizzahut.co.id]</strong></span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>&nbsp;</span></span></p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>Semua pemberitahuan atau permintaan informasi kepada atau tentang Kami akan diproses jika dibuat secara tertulis dan dikirimkan kepada: PT Sarimelati Kencana Tbk., <span style="background-color:white"><span style="color:#202124">Jl. Gatot Subroto Kav. 1.000, Menteng Dalam, Tebet, Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12870.</span></span></span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify"><span style="font-size:12pt"><span>SAYA TELAH MEMBACA DAN MEMAHAMI SYARAT DAN KETENTUAN INI, DAN MENYETUJUI SELURUH PENJELASAN SEBAGAIMANA TERCANTUM DI ATAS, BERIKUT SETIAP PERUBAHAN ATAU PEMBAHARUAN YANG DIBUAT SETELAHNYA. DENGAN MENGKLIK TOMBOL <strong>&quot;DAFTAR&quot;</strong> ATAU TINDAKAN LAIN YANG SERUPA SEBAGAIMANA DIATUR DI BAWAH INI</span></span></p>

                        <p style="text-align:justify">&nbsp;</p>

                        <p style="text-align:justify">&nbsp;</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  /* eslint-disable */
  export default {
    name: "content-content",
    components: {
    },
    data() {
      return {
      }
    },
    computed: {
    },
    mounted() {
    },
    methods: {}
  }
</script>

<style>
    table.tierlevel {
        border: 1px solid black;
    }

    div.unordered ol li {
        list-style-type: disc;
    }
</style>
