<template>
    <div class=" en-GB app-bg">
        <div class="container mt-20 mb-40">
            <div class="font-open-sans-bold uppercase text-center relative mb-20">
                <h1 class="px-40">GIFT VOUCHER</h1>
                <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
            </div>
            <div class="p-20 bg-white">
                <div class="gift-main">
                    <b style="color: #c8102e;">Beli dan hadiahkan Gift Voucher Pizza Hut kepada orang-orang</b><br>
                    <div class="md:flex md:justify-center  md:flex-wrap xs-full-width">
                        <div  class="p-10 xs-full-width" style="flex: 0 50%;">
                            <img src="../../assets/images/VCR-For-MKT-100k-602x280.jpg" alt="" >
                        </div>
                        <div class="p-10 xs-full-width" style="flex: 0 50%;">
                            <img src="../../assets/images/VCR-For-MKT-50k-602x280.jpg" alt="" >
                        </div>
                    </div>
                    <p>Tersedia dalam nilai nominal mulai Rp 25.000 dan kelipatannya, atau dengan nominal khusus sesuai pesanan.</p>
                    <p>Dengan jumlah pembelian tertentu, Gift Voucher Pizza Hut dapat dicetak dengan menampilkan pesan / logo / foto khusus sesuai pesanan Anda.</p>
                    <p>Dapatkan diskon atau free voucher dalam pembelian jumlah tertentu.</p>
                    <p>Bila ingin memesan dapat menghubungi ke 021.5096.6789 Bapak Alfian  ext. 230, Bapak Sidhik ext.752, atau melalui email ke alfian@pizzahut.ci.id atau ansorum@pizzahut.co.id</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GiftVoucher"
    }
</script>

<style scoped>
    .gift-main div {
        padding: 10px 0;
    }
</style>