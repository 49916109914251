<template>
  <!-- <div class=" en-GB app-bg"> -->
    <div class="main-container">
      <div class="main-container p-20 bg-white">
        <div class="container">

          <!-- TOP BANNER -->
          <div class="top-banner">
            <img src="../assets/images/top-banner.jpg">
            <a type="button">
              <svg viewBox="0 0 31.89 76.86" xmlns="http://www.w3.org/2000/svg"><path d="m2.5 76.86a2.5 2.5 0 0 1 -2-4l25.77-34.43-25.77-34.43a2.5 2.5 0 1 1 4-3l26.89 35.93a2.49 2.49 0 0 1 0 3l-26.89 35.93a2.5 2.5 0 0 1 -2 1z" fill="#ed1c24"></path></svg>
            </a>
          </div>

          <Modal v-show="isModal" @close="isModal = false" :title="'Lorem Ipsum'" class="modal">
            <img slot="header" :src="currentModal.image" alt=""/>
            <h2 slot="title">{{ currentModal.title }}</h2>
            <p slot="body">{{ currentModal.body }}</p>
          </Modal>

            <slick
              ref="slick"
              :options="slickOptions"
              @afterChange="handleAfterChange"
              @beforeChange="handleBeforeChange"
              @breakpoint="handleBreakpoint"
              @destroy="handleDestroy"
              @edge="handleEdge"
              @init="handleInit"
              @reInit="handleReInit"
              @setPosition="handleSetPosition"
              @swipe="handleSwipe"
              @lazyLoaded="handleLazyLoaded"
              @lazyLoadError="handleLazyLoadError">
              <template v-for="(sl, key) in slideContent">
                <a :key="key" @click="showModal(key)">
                  <img :src="sl.image" alt=""/>
                  <div class="text-container">
                    <h2 slot="header">{{ sl.title }}</h2>
                    <p slot="body">{{ sl.body }}</p>
                  </div>
                </a>
              </template>
              <!-- <a @click="showModal(true)">
                <img src="../assets/images/promo-2.jpg" alt=""/>
                <div class="text-container">
                  <h2 slot="header">Lorem ipsum dolor, sit amet consectetur. </h2>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit at rerum nulla beatae, natus quisquam blanditiis voluptatem commodi debitis molestiae, ipsam atque in tenetur accusantium nobis quos veniam! Voluptatem, nostrum!</p>
                </div>
              </a>
              <a @click="showModal(true)">
                <img src="../assets/images/promo-3.jpg" alt=""/>
                <div class="text-container">
                  <h2 slot="header">Lorem ipsum dolor, sit amet consectetur. </h2>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Impedit at rerum nulla beatae, natus quisquam blanditiis voluptatem commodi debitis molestiae, ipsam atque in tenetur accusantium nobis quos veniam! Voluptatem, nostrum!</p>
                </div>
              </a> -->
            </slick>

          <!-- MAPS -->
          <div class="maps-container">
            <div id="geolocation-confirm" class="">
              <Maps :stores="stores" v-if="isMap === true" />
              <div class="container" v-else="isMap == false">
                <div class="row">
                  <div class="col">
                    <div class="geolocation-notification">
                      <div class="content" >
                        <h3>
                          <svg viewBox="0 0 22.81 22.81" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.41" cy="11.41" fill="#fdbc3b" r="11.41"></circle>
                            <g fill="#fff">
                              <path d="m10.07 6.72a1.94 1.94 0 0 0 .6.41 1.77 1.77 0 0 0 .73.15 1.79 1.79 0 0 0 .72-.15 2 2 0 0 0 .58-.41 2 2 0 0 0 .38-.58 1.83 1.83 0 0 0 .15-.73 1.79 1.79 0 0 0 -.15-.72 2.06 2.06 0 0 0 -.39-.57 1.81 1.81 0 0 0 -2.6 0 1.61 1.61 0 0 0 -.39.57 1.61 1.61 0 0 0 -.16.72 1.8 1.8 0 0 0 .15.73 2.33 2.33 0 0 0 .38.58z"></path>
                              <path d="m12.88 16.38v-8.14h-4.42v2.87h1.41v5.27h-1.39v2.86h5.87v-2.86z"></path>
                            </g>
                          </svg>
                          Enable location?
                        </h3>
                        <p>By enabling location access, we can help find the best Pizza Hut offerings available in your area and
                          provide a better experience.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <a id="check-geolocation" class="btn btn-lg d-block btn-primary" @click="showMap(true)"> Ok. Open settings </a>
                  </div>
                  <div class="col-md-4">
                    <a id="cancel-geolocation" class="btn btn-lg d-block btn-secondary" @click="showMap(true)"> Not now </a>
                  </div>
                  <div class="col-md-4">
                    <a href="/stores" target="_blank" class="btn btn-lg d-block btn-secondary"> Search
                      all outlet </a>
                  </div>
                  <!-- <div class="col-md-4">
                    <button type="button" class="slick-next slick-arrow" style>Next</button>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <!-- BOTTOM BANNER -->
          <div id="bottom-banner">
            <div class="row">
              <div class="col-md-6">
                <div class="banner">
                  <div class="image-container">
                    <img src="../assets/images/dine_in/bottom-banner/banner-1.jpg" alt="">
                  </div>
                  <a href="/order" class="btn btn-primary btn-lg d-block">Order Online</a>
                </div>
              </div>
              <div class="col-md-6">
                <div class="banner kanan"  >
                  <div class="image-container">
                    <img src="../assets/images/dine_in/bottom-banner/banner-2.jpg" alt="">
                  </div>
                  <a href="/digital-menu" class="btn btn-primary btn-lg d-block">Dine-in Menu</a>
                </div>
              </div>
            </div>
          </div>

          <!-- FOOTER -->        

          <!-- GMAPS Coba-coba -->
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
  import Maps from '../components/partials/maps.vue'
  import Modal from '../components/modal/Modal.vue'
  import Slick from 'vue-slick'
  import 'slick-carousel/slick/slick.css'
  import localizationAPI from '@/services/localization';

  export default {
    data() {
      return {
        isModal: false,
        isMap: false,
        slickOptions: {
          arrows: true,
          centerMode: true,
          centerPadding: '180px',
          slidesToShow: 1,
          nextArrow: '<button type="button" class="slick-next slick-arrow"><svg viewBox="0 0 31.89 76.86" xmlns="http://www.w3.org/2000/svg"><path d="m2.5 76.86a2.5 2.5 0 0 1 -2-4l25.77-34.43-25.77-34.43a2.5 2.5 0 1 1 4-3l26.89 35.93a2.49 2.49 0 0 1 0 3l-26.89 35.93a2.5 2.5 0 0 1 -2 1z" fill="#ed1c24"></path></svg></button>',
          prevArrow: '<button type="button" class="slick-prev slick-arrow"><svg viewBox="0 0 31.89 76.86" xmlns="http://www.w3.org/2000/svg"><path d="m29.39 0a2.5 2.5 0 0 1 2 4l-25.77 34.43 25.77 34.43a2.5 2.5 0 0 1 -4 3l-26.89-35.93a2.49 2.49 0 0 1 0-3l26.89-35.93a2.5 2.5 0 0 1 2-1z" fill="#ed1c24"></path></svg></button>',
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                centerMode: true,
                centerPadding: '180px',
              }
            },
            {
              breakpoint: 577,
              settings: {
                arrows: false,
                dots: true,
                centerPadding: '30px',
              }
            }
          ]
        },
        slideContent: [
          {
            image: require('@/assets/images/dine_in/promo/promo-1.jpg'),
            title: 'Pilihan menu hemat di Pizza Hut, Bisa Makan Mewah harga murah',
            body: 'Rasain sensasi nongkrong gak bikin kantong bolong dengan pesan menu Sensasi Hebat! Dengan pesan Sensasi Hebat kantong bisa tetap irit dengan 35rb per orang Langsung aja cobain Sensasi makan dan minum di Pizza Hut Restoran hanya 35rb!',
          },
          {
            image: require('@/assets/images/dine_in/promo/promo-2.jpg'),
            title: 'Wajib coba Pizza baru, MeatMax Pizza Spesial',
            body: 'MeatMax. Pizza spesial pakai telur dengan paduan Jamur, Baked Beans, Beef Rashers, Telur, Saus Tomat, Cream Mayo & Sosis Ayam. Buruan jadi yang pertama cobain MeatMax Pizza spesial Pakai telur Pizza Hut & PHD Untuk harimu yang lebih maximal. Pesan MeatMax dari Pizza Hut',
          },
          {
            image: require('@/assets/images/dine_in/promo/promo-3.jpg'),
            title: 'Pizza Bentuk Lapangan Bola? Baru. The Game Pizza',
            body: 'Nonton bola bola #LebihNendang sambil nikmatin The Game Pizza. Pesan The Game Pizza dahulu, Nonton bola kemudian. Menu wajib untuk supporter bola supaya #LebihSemangat nontonnya.',
          },
        ],
        currentModal: {
          image: '',
          title: '',
          body: '',
        },
      }
    },
    components: {
      Maps,
      Slick,
      Modal
    },
    methods: {
      async showMap(isMap) {
        await this.getStores()
        this.isMap = isMap
      },
      showModal(idx) {
        this.currentModal = Object.assign({}, this.slideContent[idx])
        this.isModal = true
      },
      async getStores() {
        var vm = this;
        await localizationAPI.stores({vm: this}, (response) => {
          const stores = response.data.map(row => {
            if (row.name.toLowerCase().indexOf('test') !== -1){
              return {}
            }
            const originName = row.name
            row.name = row.name.replace('PHD', '')
            row.name = row.name.replace('Pizza Hut', '')
            row.name = row.name.trim()
            return {
              lat: parseFloat(row.lat),
              lng: parseFloat(row.long),
              name: originName,
              location: row.location,
              phone: row.phone,
              uuid: row.uuid,
              slug: row.slug
            }
          })
          this.stores = stores.filter(element => {
            if (Object.keys(element).length !== 0) {
              return true;
            }
            return false;
          });
        })
      },
      // getLocation() {
      //   if (navigator.geolocation) {
      //     navigator.geolocation.getCurrentPosition(showPosition);
      //     console.log('TRUE')
      //   } else { 
      //     x.innerHTML = "Geolocation is not supported by this browser."
      //     console.log('FALSE')
      //   }
      // },
      // showPosition(position) {
      //   console.log('LAT = ' + position.coords.latitude)
      //   console.log('LONG = ' + position.coords.longitude)
      // },
      next() {
          this.$refs.slick.next();
      },

      prev() {
          this.$refs.slick.prev();
      },

      reInit() {
          // Helpful if you have to deal with v-for to update dynamic lists
          this.$nextTick(() => {
              this.$refs.slick.reSlick();
          });
      },
    }
  };
</script>

<style>
  .banner{
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .banner .image-container{
    margin-bottom: 20px;
    
  }
  .banner .image-container img{
    width: 100%;
  }

  .btn.btn-primary {
    background-color: #da291c;
    color: #fff;
  }

  .btn.btn-secondary {
    background-color: #ebebeb;
    color: #da291c;
  }

  .btn.btn-lg{
    padding: 13px 20px;
    font-size: 20px;
    width: 100%;
  }
  .btn {
    font-weight: 600;
    border: none;
    transition: .2s all ease-in-out;
  }

  .col-2{
    width: 50%;
    float: left;
    display: inline;
  }
  
  .col-3{
    flex: 0 0 auto;
    width: 33.3%;
    float: left;
    display: inline;
    justify-content: space-around;
  }

  .container {
    max-width: 1140px;
  }

  .container a img {
    margin: auto;
  }

  .d-block {
    display: block!important;
  }

  h2 {
    font-size: 26px;
    font-weight: bold;
    color: #000;
    margin-bottom: 0.8rem;
  }

  .modal h2 {
    margin: 0;
  }

  h3 {
    font-size: 22px;
    font-weight: bold;
    color: #000;
    margin-bottom: 0.8rem;
  }
  @media (min-width: 768px) {
    .row {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;
      display: flex;
      flex-wrap: wrap;
      margin-top: calc(-1 * 0);
      margin-right: calc(-.5 * 1.5rem);
      margin-left: calc(-.5 * 1.5rem);
    }

    .row>* {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(1.5rem * .5);
      padding-left: calc(1.5rem * .5);
      margin-top: 0;
    }
    
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }

    .col-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }
  }

  /* clear content after float */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .main-container{
    align-items: center;
    justify-content: center;
  }

  .promo{
    margin-right: 40px;
    margin-left: 40px;
  }

  .geolocation-notification {
    background-color: #fef7e3;
    padding: 40px 20px;
    margin-bottom: 20px;
    border-radius: 8px;
  } 

  .geolocation-notification .content {
    position: relative;
    max-width: 500px;
    margin: auto;
  }
  
  .geolocation-notification h3 {
    position: relative;
  }

  .geolocation-notification h3 svg {
    height: 20px;
    position: absolute;
    left: -27px;
    top: 10px;
  }

  .btn.btn-primary:hover {
    background-color: #c3172d;
  }
  .btn.btn-secondary:hover {
    background-color: #dedede;
  }

  .slick-current img {
    cursor: pointer;
  }

  .slick-current a .text-container {
    visibility: visible;
  }

  .slick-slide .text-container {
    visibility: hidden;
  }

  .slick-slide.slick-active:hover img {
    transform: scale(1.02);
  }

  .slick-slide.slick-active:hover img {
    transition: .3s all ease-in-out;
  }

  .slick-slide .text-container {
    padding: 15px;
    margin-top: 20px;
  }

  .slick-arrow svg{
    position: absolute!important;
    color: #da291c;
    width: 20px;
  }

  .slick-arrow svg{
    width:20px;transition:.2s all ease-in-out
  }

  .slick-arrow.slick-prev:hover svg{
    transform:translateX(-3px)
  }

  .slick-arrow.slick-next:hover svg{
    transform:translateX(3px)
  }

  .slick-next svg {
    right: -5%;
    top: 40%;
  }
  
  .slick-prev svg {
    left: -5%;
    top: 40%;
  }

  @media (max-width: 767.98px) {
    .container {
      padding: 0px;
    }

    .slick-dots {
      display: flex;
      justify-content: center;
      list-style: none;
      margin: 20px 0;
      padding: 0;
    }

    .slick-dots li {
      height: 10px;
      position: relative;
      line-height: 10px;
    }

    [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
      cursor: pointer;
    }

    .slick-dots li.slick-active button {
      background: #da291c !important;
    }

    .slick-dots li button {
      border: none;
      font-size: 0;
      height: 4px;
      width: 30px;
      background: #ebebeb;
      margin: 0 5px;
    }

    .slick-slide img {
      padding: 0 5px;
    }

    .slick-slide .text-container h2 {
      margin-top: 20px;
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
    }

    #geolocation-confirm .btn {
      margin-bottom: 10px;
    }

    .banner {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .geolocation-notification {
      background-color: #fef7e3;
      padding: 40px 20px;
      margin-bottom: 20px;
      border-radius: 8px;
    }

    .maps-container .geolocation-notification .content {
      padding-left: 30px;
    }
  }
  

</style>