import Api from '@/services/api'
import types from "../store/mutationTypes";
const url = '/menu/v1'
export default {
    categories (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/categories', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getProducts (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/products-by-tag', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getProductDetail (uuid, data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/products' + (uuid ? `/${uuid}` : ''), {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getComboDetail (uuid, params, success, error ) {
        let mainApp = null;
        if (params.vm) {
            mainApp = params.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete params['vm']
        }
        return Api().get(url + '/combos/' + uuid, {params: params})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },

    getProductsByPoint (params, success, error ) {
        var mainApp = null;
        if (params.vm) {
            mainApp = params.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete params['vm']
        }
        params.v =  Date.now()
        return Api().get(  '/menu/v3/products-by-point', {params: params})
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
    getAllProductsByPoint (params, success, error ) {
        let mainApp = null;
        if (params.vm) {
            mainApp = params.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete params['vm']
        }
        params.v =  Date.now()
        return Api().get(  '/menu/v3/products-group-point', {params: params})
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    }
}