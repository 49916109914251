<template>
    <div class=" en-GB app-bg">
        <div class="container mt-20 mb-40">
            <div class="p-20 bg-white">
                <h1 style="color: #c8102e;"><b>CSR</b></h1>
                <div class="content">
                    <img src="../../assets/images/CSR_photo1.jpg">
                    <p>
                        <b style="color: #c8102e;">Berbagi itu menyenangkan!</b><br>
                        Pizza lebih enak kalau dinikmati bareng bareng! Nah prinsip ini juga dipakai untuk program sosial
                        kita. Kita pengen berbagi dan menyebarkan kebahagiaan ke semua orang. Karena dari berbagi, bukan
                        cuma mereka yang merasa bahagia, kami juga ngerasain hal yang sama.
                    </p>
                </div>
                <div class="content">
                    <img src="../../assets/images/CSR_photo2.jpg">
                    <p>
                        <b style="color: #c8102e;">Setetes Darah, Sejuta harapan</b><br>
                        Donor darah sudah seperti kegiatan wajib buat kami. Karena kami ngerti, setetes darah yang kami
                        berikan bisa jadi sangat berarti buat orang yang membutuhkan. Agar lebih tepat sasaran, kami
                        bekerjasama dengan Palang Merah Indonesia buat menyalurkannya.
                    </p>
                </div>
                <div class="content">
                    <img src="../../assets/images/CSR_photo3.jpg">
                    <p>
                        <b style="color: #c8102e;">Bantuan saat Bencana</b><br>
                        Ngga cuma ada di saat bahagia, Pizza Hut juga mau ada di saat musibah datang tanpa diduga! Dengan
                        menggalang bantuan dari karyawan dan pengunjung, Pizza Hut ingin berbuat sesuatu yang nyata untuk
                        meringankan beban mereka yang sedang kesusahan.
                    </p>
                </div>
                <div class="content">
                    <img src="../../assets/images/CSR_photo4.jpg">
                    <p>
                        <b style="color: #c8102e;">Petani sejahtera, Bumi terjaga</b><br>
                        Pizza Hut sangat peduli dengan kualitas sayuran yang digunakan dan kesejahteraan petani yang
                        menanamnya. Karena kalau mereka bahagia, tentu hasil panennya pun jadi lebih baik untuk kita.
                        Ngga cuma petani yang kita perhatiin, tapi juga bumi! Beberapa caranya dengan pakai bahan yang bisa
                        di daur ulang, mengurangi limbah makanan sisa dan banyak hal lainnya.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CSR"
    }
</script>

<style scoped>
 .content {
     display:  inline-block;
     width: 100%;
     margin-bottom: 15px;
    }
 .content > img {
     float: left;
     width: 300px;
     margin-right: 20px;
 }
</style>