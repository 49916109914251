import Api from '@/services/api'
import types from "../store/mutationTypes";
const url = '/content/v1'
export default {
    sentContact (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/comments', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getContent (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/contents/' + data.slug)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getHomeBanner (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/banners/home-bottom', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getHomeTop (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/banners/home-top')
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getHomeLogo (data, success, error ) {
        let mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/banners/home-logo')
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
    getPersonalizeBanner (data, success, error) {
        let mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get('https://integration.richrelevance.com/rrserver/api/personalize', {params: data}).then(
            response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }
        ).catch(errorData => {
            if (mainApp) {
                mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    }
}