import types from '../../mutationTypes'
import cartAPI from '../../../services/cart'

export default {
    [types.CART_SAVE_ITEM]({commit}, payload) {
      return new Promise((resolve, reject) => {
          // call api
          if (payload.hasOwnProperty('vm')) {
              payload.payload.vm = payload.vm;
          }
          cartAPI.addCart(payload.payload, function (response) {
              payload.vm.$notify({
                  type: 'success',
                  title: 'Penambahan item ke keranjang telah sukses',
              })

              const obj = {
                total: 0,
                sub_total: 0,
                before_tax: 0
              }
              response.data.products.forEach( e => {
                if (e.is_checkout == 0) {
                  obj.total += e.price           
                  obj.sub_total += e.sub_price
                  obj.before_tax += e.tax
                }
              })

              response.data.all_total = obj
              commit(types.CART_SET_DATA, response.data)
              resolve(response.data); // Mengembalikan respons sukses
          }, function (error) {
              payload.vm.$notify({
                  type: 'error',
                  title: error.message,
              })
              reject(error); // Mengembalikan kesalahan
          })
      });
    },
    [types.CART_GET_CART_DETAIL]({commit}, payload) {
        // call api
        cartAPI.getDetail(payload.payload, function (response) {
          const obj = {
            total: 0,
            sub_total: 0,
            before_tax: 0
          }
          response.data.products.forEach( e => {
            if (e.is_checkout == 0) {
              obj.total += e.price           
              obj.sub_total += e.sub_price
              obj.before_tax += e.tax
            }
          })
          response.data.all_total = obj

            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
        })
    },
    [types.CART_UPDATE_QUANTITY]({commit}, payload) {
        // call api
        if (payload.hasOwnProperty('vm')) {
            payload.payload.vm = payload.vm;
        }
        cartAPI.updateQuantity(payload.payload, function (response) {
            if(response.data.products.length == 0){
                commit(types.CART_SET_SUGGEST, [])
                response.data.all_total = {
                  before_tax: 0,
                  total: 0,
                  sub_total: 0,
                }
            } else {
              const obj = {
                total: 0,
                sub_total: 0,
                before_tax: 0
              }

              response.data.products.forEach( e => {
                if (e.is_checkout == 0) {
                  obj.total += (e.price * e.quantity)
                  obj.sub_total += (e.sub_price * e.quantity)
                  obj.before_tax += (e.tax * e.quantity)
                }
              })

              response.data.all_total = obj
            }
            payload.vm.$notify({
                type: 'success',
                title: payload.vm.$t('cart.update_quantity_success'),
            })
            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
            payload.vm.$notify({
                type: 'error',
                title: error.message,
            })
        })
    },
    [types.CART_REMOVE_COUPON]({commit}, payload) {
        // call api
        if (payload.hasOwnProperty('vm')) {
            payload.payload.vm = payload.vm;
        }
        cartAPI.removeCoupon(payload.payload, function (response) {
            payload.vm.$notify({
                type: 'success',
                title: payload.vm.$t('cart.remove_coupon_success'),
            })
            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
            payload.vm.$notify({
                type: 'error',
                title: error.message,
            })
        })
    },
    [types.CART_UPDATE_CART]({commit}, payload) {
        // call api
        if (payload.hasOwnProperty('vm')) {
            payload.payload.vm = payload.vm;
        }
        cartAPI.update(payload.payload, function (response) {
            commit(types.CART_SET_DATA, response.data)
        }, function (error) {
        })
    },
    [types.CART_SET_DATA]({commit}, payload) {
        commit(types.CART_SET_DATA, payload.payload)
    },
    [types.CART_RESET_DATA]({commit}) {
        commit(types.CART_SET_DATA, {
            all_total: {},
            information: {},
            products: [],
            promotions: [],
        })
    },

    [types.CART_SET_SUGGEST]({commit}, payload) {
        commit(types.CART_SET_SUGGEST, payload.payload)
    },

}