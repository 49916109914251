<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header"  v-if="title || !(hideClose === true)" >
              <h5 class="modal-title">{{title}}</h5>
              <button type="button" aria-label="Close" class="close" @click="$emit('close')" v-if="!(hideClose === true)">×</button>
          </div>

          <div class="modal-body">
              <div>{{contain }}</div>
          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-secondary " @click="$emit('close')" v-if="!(hideClose === true)">{{buttonCancel || $t('common.btn.cancel')}}</button>
              <button type="button" class="btn button--red " v-if="!(hideOK === true)">{{buttonOK || $t('common.btn.ok')}}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalNotification',
  props: {
    'hideClose': {
      type: Boolean,
      default: false,
    },
    'hideOK': {
      type: Boolean,
      default: false,
    },
    'title': {
      type: String,
      default: '',
    },
    'contain': {
      type: String,
      default: '',
    },
    'buttonOK': {
      type: String,
    },
    'buttonCancel': {
      type: String,
    }
  },
  data() {
    return {

    }
  },
  watch: {},
  mounted() {

  },
  methods: {}
}
</script>
<style scoped>
    .button--red {
        background-color: #da291c;
        color: #fff;
    }
</style>

