import Api from '@/services/api'
import types from "../store/mutationTypes";
const url = '/customer/v1'

/* eslint-disable */
export default {
    register (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/customer/register', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    verifyRegister (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/customer/register/verify-by-otp', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    verifyProfileOtp (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/customer/profile/verify-by-otp', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    login (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/customer/login', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    loginOTP (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/customer/login/verify-by-otp', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    loginSocial (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/customer/login-social', data)
          .then(response => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    logout (success, error ) {
        var mainApp = null;
        // if (data.vm) {
        //     mainApp = data.vm;
        //     mainApp.$store.dispatch(types.IN_CREATE_LOADING);
        //     delete data['vm']
        // }
        return Api().get(url + '/customer/logout')
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    profile (success, error ) {
        var mainApp = null;
        // if (data.vm) {
        //     mainApp = data.vm;
        //     mainApp.$store.dispatch(types.IN_CREATE_LOADING);
        //     delete data['vm']
        // }
        return Api().get(url + '/customer/profile')
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getLocations (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/customer/saved-information', {params: data})
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    verifyProfileByEmail (key, data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/customer/profile/verify-by-email/' + key)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    sendOtpViaEmail (email, data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/customer/email/' + email)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    getOtpByEmail (uuid, data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/customer/otp-by-email/' + uuid)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    updateProfile (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().put(url + '/customer/profile', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    resetPassword(data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/customer/reset-password-by-otp', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    resetPasswordByEmail(data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/customer/reset-password-by-email', data)
          .then(response => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
            if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(errorData)
            }
        })
    },
    deleteLocation(data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().delete(url + '/customer/saved-information/' + data.uuid )
          .then(response => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (success != undefined) {
                  success(response)
              }
          }).catch(errorData => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (error != undefined) {
                  error(errorData)
              }
          })
    },
    sendOTP(data, success, error){
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().post(url + '/customer/send-otp', data)
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
    resendOTP (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/customer/resend-otp/'+ data.key, {params: data.type})
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
    pointTransactions(data, success, error ) {
        let mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/customer/point-transactions')
            .then(response => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (success != undefined) {
                    success(response)
                }
            }).catch(errorData => {
                if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING);
                }
                if (error != undefined) {
                    error(errorData)
                }
            })
    },
}
