<template>
  <div class=" en-GB app-bg">
    <div class="container mt-20 mb-40">
        <div class="font-open-sans-bold uppercase text-center relative mb-20">
            <h1 class="px-40">{{$t('customer.title_verify_email')}}</h1>
            <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
        </div>
        <div class="p-20 bg-white">
            <div class="text-red text-center t17" v-if="!flag">{{$t('customer.waiting_for_verify_email')}}</div>
            <div class="text-green text-center t17" v-else>{{$t('message.success.verify_email')}}</div>
        </div>
    </div>
  </div>
</template>
<script>
  import CustomerApi from "../services/customer";
  import {mapActions, mapGetters} from "vuex";
  import types from "../store/mutationTypes";

  export default {
    name: "verify-email",
    components: {
    },
    data() {
      return {
        flag: false
      }
    },
    computed: {
      ...mapGetters({
        isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      }),
    },
    mounted() {
      this.verifyProfileByEmail(this.$route.params.token);
    },
    watch: {
    },
    methods: {
      ...mapActions ({
        logout: 'moduleAuth/' + types.CUSTOMER_LOGOUT,
      }),
      verifyProfileByEmail (token) {
        var vm = this;
        CustomerApi.verifyProfileByEmail(token, {vm: this},
          function (response) {
            if (response) {
              // eslint-disable-next-line no-console
              console.log('success')
            }
            vm.flag = true;
            if (vm.isAuth) {
              vm.logout();
            }
            vm.notificationSuccess(vm, vm.$t('message.success.verify_email'));
            setTimeout(function () {
              vm.$router.push({name: 'login'});
            }, 1500)
          }, function (data) {
            vm.notificationError(vm, data.message)
          }
        )
      }
    }
  }
</script>
