var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"en-GB app-bg"},[_c('div',{staticClass:"container mt-20 mb-40"},[_c('div',{staticClass:"p-20 bg-white"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"photo"},[(!_vm.isMobile())?_c('ul',[_vm._m(2),_vm._m(3),_vm._m(4)]):_c('ul',{staticStyle:{"flex-direction":"column"}},[_vm._m(5),_vm._m(6),_vm._m(7)])]),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticStyle:{"color":"#c8102e"}},[_c('b',[_vm._v("Brand Story")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticStyle:{"color":"#c8102e"}},[_c('b',[_vm._v("Kenalin, kami Pizza Hut")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/brand_story1.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/brand_story2.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/BrandStory_photo_3.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/brand_story1.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/brand_story2.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("../../assets/images/BrandStory_photo_3.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_c('br')]),_c('span',[_vm._v("Cerita kami dimulai di tahun 1958, di kota Wichita. Saat itu dua orang bersaudara bernama Dan serta Frank Carney berinisiatif membuat sebuah kedai Pizza sederhana. Ternyata, Pizza yang mereka bikin disukai banyak orang hingga akhirnya menyebar ke seluruh Dunia, termasuk ke Indonesia.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Di Indonesia, Restoran pertama kami dibuka di gedung Djakarta Theatre pada tahun 1984. Dalam waktu cukup singkat, Pizza Hut menyebar ke seluruh Indonesia, sampai ada lebih dari 500 outlet kami dari ujung barat ke ujung timur negri. Keren kan? Nah resep rahasia di balik semua itu adalah service terbaik dan inovasi!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v(" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("Pizza Hut Indonesia terdiri dari Pizza Hut Restaurant dan PHD dengan layanan “30 menit pasti tiba”. Karena kami mengerti, kesibukan dan terbatasnya waktu bikin kamu ingin semuanya serba cepat, jadi pesanan kamu tetap hangat dan nikmat saat tiba di tujuan.")])])
}]

export { render, staticRenderFns }