import axios from 'axios'
import types from '../store/mutationTypes'
import constant from '../constant'
import store from '../store'


if (!window.mobileCheck) {
    window.mobileCheck = function() {
        return ( window.innerWidth <= 800 );
    };
}


export default() => {
    /*
    * X-DEVICE-TYPE': 'PC' or 'MOBILE',
    'X-PLATFORM': 'Chrome',  -> user agent
    * */
    // eslint-disable-next-line no-useless-escape
    let locale = window.location.pathname.replace(/^\/([^\/]+).*/i,'$1');
    // let tmp = document.cookie.split('; ').find(row => row.startsWith('x_axis_main=')).split('=')
    // let sessionID = ''
    // if (tmp !== undefined && tmp.length > 0) {
    //     let cookie = tmp[1]
    //     let regex = new RegExp('(ses_id:)(.*)%', 'g')
    //     let valueExec = regex.exec(cookie)
    //     sessionID = valueExec.length > 0 ? valueExec[2] : ''
    // }
    
    locale = (locale.trim().length && locale != "/" && ['en', 'id'].indexOf(locale.trim()) >=0) ? locale : 'en'
    const httpClient = axios.create({
        baseURL: process.env.VUE_APP_BASE_API,
        withCredentials: false,
        headers: {
            'X-CLIENT-ID': 'b39773b0-435b-4f41-80e9-163eef20e0ab',
            'X-LANG': locale,
            'X-DEVICE-TYPE': 'PC',
            'x-platform': window.mobileCheck() ? 'WEBMOBILE' : 'WEBDESKTOP',
            'X-DEVICE-ID': 'web',
            // 'X-DEVICE-ID': sessionID,
            'X-CHANNEL': 2, //1: POS, 2: ONLINE
            'accept': 'application/json',
        }
    })
    // Add a request interceptor
    httpClient.interceptors.request.use(function (config) {
        // Do something before request is sent
        // set aAuthorization
        let localData = JSON.parse(localStorage.getItem('vuex'));
        if (localData.moduleAuth && !!localData.moduleAuth.user.token) {
            config.headers['X-CUSTOMER-TOKEN'] = localData.moduleAuth.user.token;
        }
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    // Add a response interceptor
    httpClient.interceptors.response.use(function (response) {

        return response.data || response
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        switch(error && error.response.status) {
            case 400:
                // eslint-disable-next-line no-console
              if (error.response.data.code === constant.ERROR_CODE.CART_NOT_FOUND) {
                 // store.dispatch('moduleCart/' +  types.CART_RESET_DATA);
              }
              break;

            case 401: // authentication error, logout the user
                if (error.response.data.code === 401) {
                    store.dispatch('moduleAuth/' +  types.CUSTOMER_LOGOUT);
                    store.dispatch('moduleCart/' +  types.CART_RESET_DATA);
                }
                break;
            default:
                // eslint-disable-next-line no-console
                console.error(error.response.status, error.message);
            // notify.error('Server Error');

        }
        let dataResponse = error.response.data || error
        return Promise.reject(dataResponse);
    });

    return httpClient
}
