import Vue from 'vue'
import Vuex from 'vuex'
import state from './store/state'
import actions from './store/actions'
import mutations from './store/mutations'
import getters from './store/getters'
import moduleLocalisation from './store/modules/localisation/index'
import moduleAuth from './store/modules/authen/index'
import moduleCart from './store/modules/cart/index'
import moduleOrder from './store/modules/order/index'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules:{
    moduleLocalisation: moduleLocalisation,
    moduleCart: moduleCart,
    moduleAuth: moduleAuth,
    moduleOrder: moduleOrder,
  },
  plugins: [createPersistedState()]
})
