<template>
  <transition>
    <div class="modal-mask">
      <div class="modal-wrapper" @click="$emit('close')">
        <div class="modal-container" style="width: 487px; border-radius: 12px" @click.stop="">
          <div class="modal-body text-center">
            <div class="container mt-20 mb-40">
              <div class="p-20 bg-white">
                <div class="font-open-sans-bold text-center relative mb-20">
                  <h1 v-if="currentStep === 'LOGIN'" class="px-40">{{ $t('common.login') }}</h1>
                  <h1 v-if="currentStep === 'REGISTER'" class="px-40">{{ $t('common.sign_up') }}</h1>
                  <h4 v-if="currentStep === 'REGISTER'" class="text-grey">Join us and enjoy free pizza today</h4>
                  <h2 v-if="currentStep === 'PRE_OTP'" class="px-40">{{ $t('common.verify_otp') }}</h2>
                </div>
                <div v-if="currentStep === 'LOGIN'" class="md:flex">
                  <form class="contain-form m-auto w-full" novalidate="novalidate" method="post"
                        @submit="checkFormLogin">
                    <div class="form-field">
                      <input id="email"
                             v-model="email"
                             class="input xs-mb-10"
                             ref="email"
                             name="email"
                             placeholder="Email"
                             maxlength="255"
                             v-bind:class="{'has-error': errors.email, 'has-success': errors.email === false}"
                             type="text">
                      <span class="float-left text-red" v-if="errors.email">{{ errors.email }}</span>
                      <div class="form-field">
                        <input id="password"
                               v-model="password"
                               class="input xs-mb-10"
                               ref="password"
                               name="password"
                               placeholder="Password"
                               maxlength="255"
                               v-bind:class="{'has-error': errors.password, 'has-success': errors.password === false}"
                               type="password">
                        <span class="float-left text-red" v-if="errors.password">{{ errors.password }}</span>
                      </div>
                      <div class="text-right p-10">
                        <router-link :to="{name: 'forgot-password'}">
                          <span class="text-red t15 cursor-pointer" @click="$emit('close')">{{ $t('common.forgot_password') }}</span>
                        </router-link>
                      </div>
                      <button class="button button--red flex-1 rounded px-20"
                              style="background-color: #C8102E; min-height: 42px;">
                        {{ $t('common.btn.sign_in') }}
                      </button>
                      <div class="text-center title-line line-header">{{ $t('common.or_sign_in') }}</div>
                      <google-login-btn @handleUserInfo="signInGoogleV2"/>
                    </div>
                    <div><span class="text-red t15 cursor-pointer"
                               @click="currentStep='REGISTER'">Register Now </span>and
                      enjoy free Pizza today
                    </div>
                  </form>
                </div>
                <div v-if="currentStep === 'REGISTER'" class="md:flex">
                  <form class="contain-form m-auto w-full" novalidate="novalidate" method="post"
                        @submit="checkFormRegister">
                    <div>
                      <div class="form-field">
                        <input id="first_name"
                               v-model="customer.first_name"
                               class="input xs-mb-10"
                               ref="first_name"
                               name="first_name"
                               placeholder="First Name"
                               maxlength="255"
                               v-bind:class="{'has-error': errors.first_name, 'has-success': errors.first_name === false}"
                               type="text">
                        <span class="float-left text-red" v-if="errors.first_name">{{ errors.first_name }}</span>
                      </div>
                      <div class="form-field">
                        <input id="last_name"
                               v-model="customer.last_name"
                               class="input xs-mb-10"
                               ref="last_name"
                               name="last_name"
                               placeholder="Last Name"
                               maxlength="255"
                               v-bind:class="{'has-error': errors.last_name, 'has-success': errors.last_name === false}"
                               type="text">
                        <span class="float-left text-red" v-if="errors.last_name">{{ errors.last_name }}</span>
                      </div>
                      <div class="form-field">
                        <div class="input-group">
                          <div class="input-group-append"><span
                              class="input-group-text">{{ CONSTANT.PREFIX_PHONE }}</span></div>
                          <input id="phone"
                                 v-model="customer.phone"
                                 class="input xs-mb-10 form-control"
                                 ref="phone"
                                 name="phone"
                                 type="text"
                                 v-bind:class="{'has-error': errors.phone, 'has-success': errors.phone === false}"
                                 :maxlength="CONSTANT.PHONE.MAX">
                        </div>
                        <span class="float-left text-red" v-if="errors.phone">{{ errors.phone }}</span>
                      </div>
                      <div class="form-field">
                        <input id="email"
                               v-model="customer.email"
                               class="input xs-mb-10"
                               ref="email"
                               name="email"
                               placeholder="Email"
                               maxlength="255"
                               v-bind:class="{'has-error': errors.email, 'has-success': errors.email === false}"
                               type="text">
                        <span class="float-left text-red" v-if="errors.email">{{ errors.email }}</span>
                      </div>
                      <div class="form-field">
                        <div class="input-group">
                          <input id="password"
                                 class="input form-control border-right-top-bottom-none xs-mb-10"
                                 v-model="customer.password"
                                 ref="password"
                                 name="password"
                                 placeholder="Password"
                                 maxlength="255"
                                 :type="showPass ? 'text' : 'password'"
                                 v-bind:class="{'has-error': errors.password, 'has-success': errors.password === false}"
                          >
                          <div class="input-group-append">
                            <span class="input-group-text border-left-top-bottom-none cursor-pointer"
                                  @click="showPass = !showPass">
                              <i class="fa" aria-hidden="true"
                                 v-bind:class="{'fa-eye-slash': !showPass, 'fa-eye': showPass}"> </i>
                            </span>
                          </div>
                        </div>
                        <span class="float-left text-red" v-if="errors.password">{{ errors.password }}</span>
                      </div>
                      <div class="form-field">
                        <div class="input-group">
                          <input id="password_confirm"
                                 v-model="customer.passwordConfirm"
                                 class="input form-control border-right-top-bottom-none xs-mb-10"
                                 ref="password_confirm"
                                 name="passwordConfirm"
                                 placeholder="Confirm Password"
                                 maxlength="255"
                                 :type="showPass ? 'text' : 'password'"
                                 v-bind:class="{'has-error': errors.passwordConfirm, 'has-success': errors.passwordConfirm === false}"
                          >
                          <div class="input-group-append">
                            <span class="input-group-text border-left-top-bottom-none cursor-pointer"
                                  @click="showPass = !showPass">
                              <i class="fa" aria-hidden="true"
                                 v-bind:class="{'fa-eye-slash': !showPass, 'fa-eye': showPass}"> </i>
                            </span>
                          </div>
                        </div>
                        <span class="float-left text-red" v-if="errors.passwordConfirm">{{
                            errors.passwordConfirm
                          }}</span>
                      </div>
                      <div class="form-field text-left">
                        <input id="birthday"
                               v-model="birthdayData"
                               class="input xs-mb-10"
                               ref="birthday"
                               name="birthday"
                               placeholder="Select Your Birthday"
                               maxlength="255"
                               :type="showTextBirthday ? 'date' : 'text'"
                               @click="() => {!showTextBirthday ? showTextBirthday = true: ''}">
                        <div v-if="errors.birthday"><span class="float-left text-red" >{{ errors.birthday }}</span><br></div>
                        <span class="text-grey">Enjoy free birthday pizza when level up. Once registered it cannot be updated.</span>
                      </div>
                      <div class="form-field text-left">
                        <div class="xs:flex xs:flex-center mb-5">
                          <label class="label mb-0" for="genders">{{ $t('customer.gender') }}
                          </label>
                        </div>
                        <div class="input-group">
                          <div class="input-tem">
                            <input id="gender_male"
                                   v-model="customer.gender.code"
                                   class="form-control"
                                   name="gender"
                                   type="radio"
                                   value="1"
                            >
                            <label class="text-grey" for="gender_male">Male</label>
                          </div>
                          <div class="input-tem">
                            <input id="gender_female"
                                   v-model="customer.gender.code"
                                   class="form-control"
                                   name="gender"
                                   type="radio"
                                   value="2">
                            <label class="text-grey" for="gender_female">Female</label>
                          </div>
                          <div class="input-tem">
                            <input id="gender_na"
                                   v-model="customer.gender.code"
                                   class="form-control"
                                   name="gender"
                                   type="radio"
                                   value="0">
                            <label class="text-grey" for="gender_na">NA</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button class="button button--red flex-1 rounded px-20"
                            style="background-color: #C8102E; min-height: 42px;">
                      {{ $t('common.btn.register') }}
                    </button>
                    <div>Already Registered? <span class="text-red t15 cursor-pointer"
                                                   @click="currentStep='LOGIN'">Login</span>
                    </div>
                  </form>
                </div>
                <div v-if="currentStep === 'PRE_OTP'" class="md:flex">
                  <div class="contain-form m-auto w-full">
                    <span class="text-red">How do you want to get the OTP code?</span>
                    <button type="button" class="button button--white-red" style="margin-bottom: 10px; margin-top: 5px"
                            @click="sendOTP(1)">
                      Use Whatsapp
                    </button>
                    <button class="button button--red" @click="sendOTP(0)">Use SMS</button>
                  </div>
                </div>
                <div v-if="currentStep === 'OTP'" class="md:flex">
                  <div class="contain-form m-auto w-full">
                    <form class="contain-form m-auto w-full" novalidate="novalidate" method="post"
                          @submit="checkFormOTP">
                      <div class="block-section">
                        <div class="md:flex lg:flex ">
                          <div class="form-field flex-1 ml-5 mr-5 ">
                            <div class="xs:flex xs:flex-center mb-5">
                              <label class="label mb-0" for="otp">{{ $t('customer.otp') }}
                                <span class="text-red">{{ $t('customer.required') }}</span>
                                <span class="float-right text-red" v-if="message">{{ message }}</span>
                              </label>
                            </div>
                            <input id="otp"
                                   class="input xs-mb-10"
                                   ref="otp"
                                   name="otp"
                                   v-model="otp"
                                   maxlength="255"
                                   type="text">
                            <span class="float-left text-red" v-if="otpMessage">{{otpMessage}}</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button v-if="countDown" type="button" class="button button--red flex-1" disabled>Resend OTP
                          ({{ countDown }})
                        </button>
                        <button v-else type="button" class="button button--red flex-1" @click="resendOTP()">Resend OTP
                        </button>
                      </div>
                      <div class="input-group" style="padding-top: 10px">
                        <button type="button" class="button button--white-red flex-1" @click="$emit('close')"
                                style="width: 20%">{{ $t('common.btn.cancel') }}
                        </button>
                        <button class="button button--red flex-1" style="width: 20%">{{ $t('common.btn.submit') }}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Constant from '@/constant'
import CustomerApi from '@/services/customer'
import types from "@/store/mutationTypes";
import {createNamespacedHelpers} from 'vuex'
import GoogleLoginBtn  from "@/components/modal/GoogleLoginBtn";

const {mapActions} = createNamespacedHelpers('moduleAuth')

export default {
  name: "ModalLogin",
  props: {
    manthanLogin: {
      type: Function
    },
    isRegister: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    GoogleLoginBtn,
  },
  data() {
    return {
      currentStep: 'LOGIN', // LOGIN/REGISTER->PRE_OTP->OTP
      CONSTANT: Constant,
      showPass: false,
      showTextBirthday: false,
      birthdayData: undefined,
      email: '',
      password: '',
      errors: {
        isValid: false,
        password: '',
        email: '',
        first_name: '',
        last_name: '',
        phone: '',
        birthday: '',
        birth_month: '',
        birth_year: '',
        birth_date: '',
        passwordConfirm: '',
      },
      keyToken: '',
      otp: '',
      message: '',
      countDown: 180,
      isLogin: true,
      customer: {
        first_name: '',
        last_name: '',
        phone: '',
        gender: {
          code: 0
        },
        birth_date: {},
        birth_month: {},
        birth_year: {},
        password: '',
        passwordConfirm: '',
        email: '',
        term: 0
      },
      otpMessage: '',
    }
  },
  created() {
    if (this.isRegister) {
      this.currentStep = 'REGISTER'
    }
  },
  watch: {
    'email': function (newValue) {
      this.errors.email = false;
      if (!newValue) {
        this.errors.email = this.$t('message.error.email');
      } else if (!this.validEmail(newValue)) {
        this.errors.email = this.$t('message.error.invalid_email');
      }
    },
    'password': function (newValue) {
      this.errors.password = false;
      if (!newValue) {
        this.errors.password = this.$t('message.error.password');
      }
    },
    'customer.phone': function (newValue, oldValue) {
      this.errors.phone = false;

      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.$set(this.customer, 'phone', oldValue)
        return
      }

      if (!newValue) {
        this.errors.phone = this.$t('message.error.phone');
      } else if (newValue.length < this.$const.PHONE.MIN || newValue.length > this.$const.PHONE.MAX) {
        this.errors.phone = this.$t('message.error.invalid_phone', {
          min: this.$const.PHONE.MIN,
          max: this.$const.PHONE.MAX
        });
      }
    },
    'customer.email': function (newValue) {
      this.errors.email = false;
      if (!newValue) {
        this.errors.email = this.$t('message.error.email');
      } else if (!this.validEmail(newValue)) {
        this.errors.email = this.$t('message.error.invalid_email');
      }
    },
    'customer.first_name': function (newValue) {
      this.errors.first_name = false;
      if (!newValue) {
        this.errors.first_name = this.$t('message.error.first_name');
      }
    },
    'customer.last_name': function (newValue) {
      this.errors.last_name = false;
      if (!newValue) {
        this.errors.last_name = this.$t('message.error.last_name');
      }
    },
    'customer.password': function (newValue) {
      this.errors.password = false;
      if (!newValue) {
        this.errors.password = this.$t('message.error.password');
      }
    },
    'customer.passwordConfirm': function (newValue) {
      this.errors.passwordConfirm = false;
      if (!newValue) {
        this.errors.passwordConfirm = this.$t('message.error.password_confirm');
      }
    },
  },
  methods: {
    ...mapActions({
      setToken: types.CUSTOMER_SET_TOKEN,
      getProfileByToken: types.CUSTOMER_GET_INFO_BY_TOKEN,
    }),
    initErrorMessage() {
      this.errors = {
        isValid: false,
        first_name: false,
        last_name: false,
        phone: false,
        birthday: '',
        birth_date: '',
        birth_month: '',
        birth_year: '',
        password: false,
        passwordConfirm: false,
        email: false
      }
    },
    checkFormLogin(e) {
      e.preventDefault();
      let isValidForm = true;
      this.initErrorMessage();

      if (!this.email) {
        this.errors.email = this.$t('message.error.email');
        if (isValidForm) {
          this.$refs.email.focus();
        }
        isValidForm = false;
      } else if (!this.validEmail(this.email)) {
        this.errors.email = this.$t('message.error.invalid_email');
        if (isValidForm) {
          this.$refs.email.focus();
        }
        isValidForm = false;
      }
      if (!this.password) {
        this.errors.password = this.$t('message.error.password');
        if (isValidForm) {
          this.$refs.password.focus();
        }
        isValidForm = false;
      }

      if (isValidForm) {
        this.login();
      }
    },
    checkFormRegister(e) {
      e.preventDefault();
      let isValidForm = true;
      this.initErrorMessage();
      if (!this.customer.first_name) {
        this.errors.first_name = this.$t('message.error.first_name');
        if (isValidForm) {
          this.$refs.first_name.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.last_name) {
        this.errors.last_name = this.$t('message.error.last_name');
        if (isValidForm) {
          this.$refs.last_name.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.phone) {
        this.errors.phone = this.$t('message.error.phone');
        if (isValidForm) {
          this.$refs.phone.focus();
        }
        isValidForm = false;
      } else if (this.customer.phone.length < this.$const.PHONE.MIN || this.customer.phone.length > this.$const.PHONE.MAX) {
        this.errors.phone = this.$t('message.error.invalid_phone', {
          min: this.$const.PHONE.MIN,
          max: this.$const.PHONE.MAX
        })
        if (isValidForm) {
          this.$refs.phone.focus();
        }
        isValidForm = false;
      }

      if (!this.customer.email) {
        this.errors.email = this.$t('message.error.email');
        if (isValidForm) {
          this.$refs.email.focus();
        }
        isValidForm = false;
      } else if (!this.validEmail(this.customer.email)) {
        this.errors.email = this.$t('message.error.invalid_email');
        if (isValidForm) {
          this.$refs.email.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.password) {
        this.errors.password = this.$t('message.error.password');
        if (isValidForm) {
          this.$refs.password.focus();
        }
        isValidForm = false;
      }
      if (!this.customer.passwordConfirm) {
        this.errors.passwordConfirm = this.$t('message.error.password_confirm');
        if (isValidForm) {
          this.$refs.password_confirm.focus();
        }
        isValidForm = false;
      } else if (this.customer.passwordConfirm != this.customer.password) {
        this.errors.passwordConfirm = this.$t('message.error.password_confirm_not_match');
        if (isValidForm) {
          this.$refs.password_confirm.focus();
        }
        isValidForm = false;
      }
      if (!this.birthdayData) {
        this.showTextBirthday = false;
      }

      if (isValidForm) {
        this.createNew();
      }
    },
    checkFormOTP(e) {
      e.preventDefault();
      this.message = '';
      if (!this.otp) {
        this.message = this.$t('message.error.otp');
        this.$refs.otp.focus();
      }
      if (this.message === '') {
        if (this.isLogin) {
          this.verifyTokenLogin()
        } else {
          this.verifyTokenRegister()
        }
      }
    },
    createNew() {
      const vm = this;
      let payload = {
        "email": this.customer.email,
        "first_name": this.customer.first_name,
        "gender": Number(this.customer.gender.code),
        "last_name": this.customer.last_name,
        "password": this.customer.password,
        "phone": this.customer.phone,
        "birthday": this.birthdayData,
        "vm": this
      }
      CustomerApi.register(payload,
          function (response) {
            vm.keyToken = response.data.key
            vm.currentStep = 'OTP'
            vm.countDownTimer()
            vm.isLogin = false
          }, function (data) {
            if (data.code == vm.$const.ERROR_CODE.REGISTER.EMAIL_EXIST) {
              vm.errors.email = data.message;
            }
            else if (data.code == vm.$const.ERROR_CODE.REGISTER.PASSWORD) {
              vm.errors.password = data.message;
            }
            else if (data.code == vm.$const.ERROR_CODE.REGISTER.PHONE) {
              vm.errors.phone = data.message;
            } else {
              vm.notificationError(vm, data.message)
            }
          }
      )
    },
    login() {
      const vm = this
      let payload = {
        "email": this.email,
        "password": this.password,
        "keep_otp": 1,
        "vm": this
      };
      CustomerApi.login(payload, function (response) {
        vm.keyToken = response.data.key;
        vm.currentStep = 'PRE_OTP'
      }, function (error) {
        if (error.code == vm.$const.ERROR_CODE.LOGIN.MAX_OTP) {
          vm.isLogin = true
          vm.keyToken = error.data.key;
          vm.currentStep = 'OTP'
          vm.otpMessage = error.message
          vm.countDownTimer()
        } else {
          vm.errors.email = 'Please input correct email or/and password';
          vm.errors.password = 'Please input correct email or/and password';
        }
      })
    },
    sendOTP(type) {
      const vm = this
      let payload = {
        "type": type,
        "key": this.keyToken,
        "vm": this
      }
      CustomerApi.sendOTP(payload, function (responseOTP) {
        if (responseOTP.code == '200') {
          vm.currentStep = 'OTP'
          vm.countDownTimer()
        }
      }, function (error) {
        if (error.code == this.$const.ERROR_CODE.LOGIN.MAX_OTP) {
          vm.otpMessage = error.message
          vm.currentStep = 'OTP'
          vm.countDownTimer()
        } else {
          vm.notificationError(vm, error.message);
        }
      });
    },
    resendOTP() {
      let vm = this;
      let payload = {
        "key": this.keyToken,
        "vm": vm,
        "type": {"type": this.otpType}
      }
      this.countDownTimer(true)
      CustomerApi.resendOTP(
          payload,
          // eslint-disable-next-line no-unused-vars
          function (response) {

          },
          function (error) {
            vm.notificationError(vm, error.message)
          }
      )
    },
    countDownTimer(isResend) {
      if (isResend) {
        this.countDown = 180;
      }
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    signInGoogle() {
      const vm = this
      this.$gAuth.signIn()
          .then(GoogleUser => {
            let dataCustomer = GoogleUser.getBasicProfile();
            let payload = {
              "first_name": dataCustomer.ET,
              "last_name": dataCustomer.GR,
              "email": dataCustomer.zt,
              "token": GoogleUser.getAuthResponse().id_token,
              "keep_otp": 1
            };
            this.loginSocial(payload);
          })
          .catch(error => {
            this.notificationError(vm, error.message);
          })
    },
    signInGoogleV2(data) {
      const vm = this
      data.then(payload => {
        this.loginSocial(payload)
      }).catch (error => {
        this.notificationError(vm, error.message)
      })
    },
    loginSocial(payload) {
      const vm = this;
      payload.vm = this;
      CustomerApi.loginSocial(payload, function (response) {
        vm.keyToken = response.data.key;
        vm.currentStep = 'PRE_OTP'
      }, function (error) {
        vm.keyToken = error.data.key;
        vm.currentStep = 'OTP'
        vm.countDownTimer()
      })
    },
    verifyTokenRegister() {
      const vm = this;
      let payload = {
        "key": this.keyToken,
        "otp": this.otp,
        "vm": this
      }
      CustomerApi.verifyRegister(payload,
          function (response) {
            vm.$emit('close');
            vm.callbackFunc(response.data['x-token']);
          }, function (data) {
            vm.notificationError(vm, data.message)
          }
      )
    },
    verifyTokenLogin() {
      const vm = this;
      let payload = {
        "key": this.keyToken,
        "otp": this.otp,
        "vm": this
      }
      CustomerApi.loginOTP(payload,
          function (response) {
            vm.$emit('close');
            vm.callbackFunc(response.data['x-token']);
          }, function (data) {
            vm.notificationError(vm, data.message)
          }
      )
    },
    callbackFunc(xToken) {
      if (xToken) {
        this.notificationSuccess(this, this.$t('message.success.login'));
        this.handleXToken(xToken)
        this.manthanLogin()
      }
    },
    handleXToken(xToken) {
      this.setToken(xToken);
      this.getProfileByToken();
      if (this.$route.query && this.$route.query.callback) {
        if (this.$route.query.callback === 'checkout') {
          this.$router.push({name: 'checkout'});
          return;
        }
        return this.redirectInternal(this, this.$route.query.callback);
      }
      this.$router.push({name: 'home'});
      return;
    }
  }
}
</script>

<style scoped>
.input-tem {
  display: flex;
  align-items: center;
  margin-right: 30px
}

.input-tem input {
  margin-right: 10px
}
</style>
