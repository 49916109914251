import types from '../../mutationTypes'

export default {
    [types.LOCALISATION_GET_STORE_UUID]: state => state.localisation.uuid,
    [types.LOCALISATION_GET_STORE_NAME]: state => state.localisation.name,
    [types.LOCALISATION_GET_ORDER_TYPE]: state => state.localisation.orderType,
    [types.LOCALISATION_GET_TABLE_NUMBER]: state => state.localisation.tableNumber,
    [types.LOCALISATION_GET_COLLECTION_TIME]: state => state.localisation.collection_time,
    [types.LOCALISATION_GET_STORE_TIMEZONE]: state => state.localisation.timezone,
    [types.LOCALISATION_GET_ORDER_NOW]: state => state.localisation.orderNow,
    [types.LOCALISATION_GET_LOCATION]: state => state.localisation.location,
    [types.LOCALISATION_GET_LOCALISATION]: state => state.localisation,
}
