<template>
  <div class="mx-10">
    <div class="flex-row flex flex-center px-10" v-if="list.length > 0">
      <h2 v-if="!isMobile()"><span>Redeem Now</span></h2>
      <div class="flex-1 font-open-sans-bold">
        <div v-if="isMobile()">Redeem Now</div>
      </div>
      <router-link :to="{name: 'all-redeem'}" class="text-red">see all</router-link>
    </div>
    <div class="scroll-list">
      <div class="list-container" :style="style">
        <point-singles v-for="(item, index) in list" :item="item" :key="index"/>
      </div>
    </div>
  </div>
</template>
<script>

import MenuApi from "@/services/menu";
import {mapGetters} from "vuex";
import mutationTypes from "@/store/mutationTypes";
import PointSingles from "@/components/rewards/PointSingles";
import types from "@/store/mutationTypes";

export default {
  name: "ListRedeem",
  components: {PointSingles},
  data() {
    return {
      list: [],
      isOpenModal: false,
      redeemData: {},
      style: {
        width: '0px',
      },
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      const vm = this;
      let payload = {
        type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.localisation.orderType, 0)],
        store_uuid: this.storeUuid || '',
        vm: this,
      }
      vm.list = [];
      MenuApi.getProductsByPoint(payload, function (response) {
        if (response.data.length > 0 && response.data[0].products) {
          vm.list = response.data[0].products;
          const width = response.data[0].products.length * 160
          vm.style.width = `${width}px`
        }
      })
    },
    handleRedeemPoint(item){
        this.redeemData = item;
        this.isOpenModal = true;
    },
    addItemIntoCart(){
      let plus = [];
      if (!this.emptyVariable(this.hasExtraCheese)) {
        plus.push({
          quantity: 1,
          uuid: this.extraCheese.uuid,
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      localisation: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
      storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
    })
  }
}
</script>
<style scoped>
  .scroll-list{
    overflow-x: auto;
    overflow-y: hidden;
  }
  .list-container {
    display: flex;
    white-space: nowrap;
    height: 100%;
    gap: 12px;
    margin: 0px 10px;
    padding: 8px 0px;
  }
  @media screen and (max-width: 800px) {
    .list-container {
      gap: 8px;
    }
  }
</style>
