<template>
  <div id="background-overlay" :class="`background-overlay ${!isOpen && 'overlay-fade'}`" @click="emitCloseModal">
    <div v-if="isVoucherEmpty" :class="`modal-wrapper ${!isOpen && 'modal-wrapper-close'}`">
      <div class="info">
        <div class="image-wrapper">
          <img src="@/assets/images/pizza16x9.png" alt="Voucher Image" />
        </div>
        <div>
          <h1>Free Pizza hut delivery</h1>
          <p>Get 40% off on order above 2000, and only if you are in fan or lover tier Delivery | Take away | Dine in </p>
        </div>
      </div>
      <div class="bottom">
        <h1>Ask for Restaurant ID</h1>
        <div class="buttons-wrapper">
          <button>Dine in</button>
          <button>Delivery</button>
          <button>Take away</button>
        </div>
        <p v-if="!isMobile()">Dine in only available in App</p>
      </div>
    </div>
    <div v-else :class="`modal-wrapper ${!isOpen && 'modal-wrapper-close'}`">
      <div v-if="isMobile()" class="top">
      </div>
      <div class="info">
        <div class="image-wrapper">
          <img :src="voucherData.thumbnail" alt="Voucher Image" />
        </div>
        <div>
          <h1>{{voucherData.name}}</h1>
          <div v-html=voucherData.content></div>
        </div>
      </div>
      <div v-if="!selectedMode" class="bottom">
        <h1>Select mode</h1>
        <div v-if="!isMobile()" class="buttons-wrapper">
          <button v-if="voucherData.promotion_types.includes(CONSTANT.PROMOTION_TYPES.DINEIN)" style="background-color: #C5C4C5">Dine in</button>
          <button v-if="voucherData.promotion_types.includes(CONSTANT.PROMOTION_TYPES.DELIVERY)" @click="setSelectedMode(CONSTANT.ORDERTYPE_MAPAPI.DELIVERY)">Delivery</button>
          <button v-if="voucherData.promotion_types.includes(CONSTANT.PROMOTION_TYPES.CARRYOUT)" @click="setSelectedMode(CONSTANT.ORDERTYPE_MAPAPI.CARRYOUT)">Take away</button>
        </div>
        <div v-else class="buttons-wrapper">
          <button v-if="voucherData.promotion_types.includes(CONSTANT.PROMOTION_TYPES.DINEIN)" style="background-color: #C5C4C5">Dine in</button>
          <button v-if="voucherData.promotion_types.includes(CONSTANT.PROMOTION_TYPES.DELIVERY)" @click="setSelectedMode(CONSTANT.ORDERTYPE_MAPAPI.DELIVERY)">Delivery</button>
          <button v-if="voucherData.promotion_types.includes(CONSTANT.PROMOTION_TYPES.CARRYOUT)" @click="setSelectedMode(CONSTANT.ORDERTYPE_MAPAPI.CARRYOUT)">Take away</button>
        </div>
        <p v-if="voucherData.promotion_types.includes(CONSTANT.PROMOTION_TYPES.DINEIN)">Dine in only available in App</p>
      </div>
      <div v-if="listMode.includes(selectedMode)" class="bottom">
        <div class="dine-in">
          <h1>Ask for Retro ID</h1>
          <h2 @click="setSelectedMode('')">Change mode</h2>
        </div>
        <input type="text" id="retro-id" class="retro-id" name="retro-id" placeholder="Enter Retro ID">
        <button class="retro-btn">Confirm</button>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  import constant from '@/constant'
  import {mapGetters} from "vuex";
  import mutationTypes from "@/store/mutationTypes";
  export default {
    name: "ModalVoucher",
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      voucherData: {
        type: Object,
        default: () => {},
      }
    },
    data() {
      return {
        CONSTANT: constant,
        selectedMode: '',
        listMode: Object.values(constant.ORDERTYPE_MAPAPI)
      }
    },
    created() {
    },
    methods: {
      emitCloseModal(event) {
        if (event.target.id === 'background-overlay') {
          this.selectedMode = ''
          this.$emit('close-modal')
        }
      },
      setSelectedMode(mode) {
        if (this.isMobile()) {
          this.selectedMode = mode
        }
        return this.$router.push({name: 'home', query: {warning: '1'}})
      },
    },
    computed: {
      ...mapGetters({
        storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
        cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
      }),
      isVoucherEmpty() {
        return _.isEmpty(this.voucherData)
      }
    },
  }
</script>

<style scoped>
  .background-overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    transition: width .5s ease, height .3s ease, border-radius .3s ease, top .3s ease, left .5s ease, opacity .5s ease;
  }
  .overlay-fade {
    top: 50%;
    left: 50%;
    width: 0px;
    height: 0px;
    border-radius: 9999px;
    opacity: 0;
    transition: none;
  }
  .modal-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 480px;
    min-height: 300px;
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 32px;
  }
  .modal-wrapper-close {
    display: none;
  }
  .modal-wrapper h1 {
    color: #020202;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
  .top {
    background-color: #C4C5C9;
    width: 32px;
    height: 4px;
    border-radius: 24px;
    margin: 0px auto 12px auto;
  }
  .info {
    display: flex;
    gap: 16px;
  }
  .image-wrapper {
    min-width: 100px;
    min-height: 100px;
  }
  .image-wrapper > img {
    width: 100px;
    height: 100px;
    border-radius: 4px;
  }
  .info p {
    color: #9C9FA7;
    font-size: 16px;
    line-height: 22px;
  }
  .bottom {
    margin-top: 16px;
  }
  .bottom p {
    color: #5D6063;
    font-weight: 400;
    font-size: 15px;
  }
  .dine-in {
    display: flex;
  }
  .dine-in h1 {
    flex: auto;
  }
  .dine-in h2 {
    font-weight: bold;
    font-size: 12px;
    color: #3680EF;
    cursor: pointer;
  }
  .retro-btn {
    background-color: #C8102E;
    width: 100%;
    height: 56px;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
  }
  .retro-id {
    width: 100%;
    height: 54px;
    padding: 0px 16px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #7D8084;
  }
  .buttons-wrapper {
    margin: 8px 0px;
    display: flex;
    gap: 8px;
  }
  .buttons-wrapper > button {
    flex: 1;
    height: 44px;
    border-radius: 4px;
    border: 1px solid #C4C5C9;
    color: #020202;
    font-size: 16px;
  }
  .buttons-wrapper > button:hover {
    background-color: #C5C4C5;
  }

  @media (max-width: 800px) {
    .background-overlay {
      transition: none;
    }
    .overlay-fade {
      border-radius: 0px;
      top: 100%;
      left: 0;
      width: 100%;
      height: 0px;
      opacity: 0;
    }
    .modal-wrapper {
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      transform: translate(0, -100%);
      width: 100%;
      min-height: 224px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 20px;
      transition: all .5s ease;
    }
    .modal-wrapper-close {
      display: block;
      transform: translate(0, 0%);
    }
    .modal-wrapper h1 {
      color: #020202;
      font-size: 14px;
      line-height: 20px;
    }
    .info {
      gap: 12px;
      max-height: 420px;
      overflow-y: scroll;
    }
    .info > img {
      width: 40px;
      height: 40px;
    }
    .info p {
      font-size: 12px;
      line-height: 16px;
    }
    .bottom h1 {
      font-size: 16px;
    }
    .buttons-wrapper {
      margin: 8px 0px;
      display: flex;
      gap: 8px;
    }
    .buttons-wrapper > button {
      flex: 1;
      height: 44px;
      border-radius: 4px;
      border: 1px solid #C4C5C9;
      color: #020202;
      font-size: 16px;
    }
    .buttons-wrapper > button:hover {
      background-color: #C5C4C5;
    }
  }

</style>