<template>
  <div>
      <header-component />
      <div class=" en-GB app-bg">
          <div class="container mt-20 mb-40">
              <div class="p-20 bg-white">
                  <div class="md:flex">
                          <div class="block-section">
                              <div class="form-field">
                                  <div class="xs:flex xs:flex-center mb-5">
                                      <QRScannerButton />
                                  </div>
                              </div>
                          </div>
                  </div>
              </div>
          </div>
      </div>
      <footer-component/>
  </div>
</template>

<script>
  import HeaderComponent from '../components/HeaderComponent';
  import FooterComponent from "../components/FooterComponent";
  import QRScannerButton from "../components/shared/QRScannerButton";

  export default {
    name: "order-tracker",
    components: {
      FooterComponent,
      HeaderComponent,
      QRScannerButton,
    },
  }
</script>
