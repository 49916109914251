<template>
    <div class="basket-wrapper lg:fixed lg:z-500 lg:right-0 lg:top-0 lg:h-full">
        <router-link class="fixed inset-0 z-200 modal-overlay lg:static lg:hidden" :to="'/order/'+ defaultCategory"></router-link>
        <div class="basket-transformer inset-0 lg:ml-auto flex flex-col">
            <div id="basket" class="basket-container overflow-auto w-full z-300 md:z-200 lg:shadow-left lg:border-l bg-white ml-auto flex-1 pb-5">
                <div class="font-open-sans-bold capitalize text-center relative py-15" :style="{'background-image': 'url(' + bgImage + ')'}" v-if="this.isMobile()">
                    <h1 class="px-40">{{$t('common.your_basket')}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="hideBasket"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <template v-else>
                  <button class="drawer__close text-left p-10 mr-10 lg:hidden  text-black opacity`-50 p-10" @click="hideBasket">
                      <span class="icon-caret-left bg-contain" style="height: 10px;"></span> <span>{{$t('common.back')}}</span>
                  </button>
                  <h2 class="typography-2 regular-400 text-center px-20 pt-10 lg:pt-40 pb-40"><span>{{$t('common.your_basket')}}</span></h2>
                </template>
                <div class="pt-10 pl-10"><delivery-address-info /></div>
                <div v-if="cartCount <= 0">
                    <p class="typo-l7 px-20 pt-10 pb-40" v-html="$t('common.suggest_menu', {url: '/order/'+ defaultCategory})">
                    </p>
                </div>
                <div class="cart-summer pt-10">
                    <div class="cart-item flex-1" v-for="(item, indext) in cartData.products" :key="indext">
                        <div class="w-full" v-if="!item.parent.uuid">
                            <div class="per-item-row" v-if="item.is_checkout == 0">
                                <div v-if="item.product.length > 0" class="basket-block-left">
                                    <div class="cart-item-name pl-5"><span v-if="!emptyVariable(item.product[0].option)">{{item.product[0].option.name}}</span> {{item.product[0].name}}</div>
                                </div>
                                <div class="cart-item-quantity" @click="modalUpdateQuantity(item)">
                                    x {{item.quantity}}
                                </div>
                                <div class="basket-block-right">
                                    <div class="cart-item-price flex ">
                                        <span class="flex-1" v-if="item.point > 0">
                                          {{$t('common.format_point', {amount: formatCurrency(item.point * item.quantity, $const.SUFFIX_CURRENCY)})}}
                                        </span>
                                        <span class="flex-1"  v-else>
                                          {{$t('common.format_currency', {amount: formatCurrency(item.sub_price * item.quantity, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                        </span>
                                        <img src="../../assets/images/icon-remove.svg" style="height: 15px; padding-left: 5px"  @click.prevent.stop="removeCart(item)">
                                    </div>
                                </div>
                            </div>
                            <div class="per-item-row" v-if="item.is_checkout == 0">
                                <div v-if="item.product.length > 0 " class="basket-block-left">
                                    <div class="cart-item-sub-name" v-if="!emptyVariable(item.product[0].option_group)">{{item.product[0].option_group.name}}</div>
                                </div>
                                <div class="basket-block-right">
                                </div>
                            </div>
                            <div v-if="item.product.length > 0 && item.product[0].addition_topping && item.product[0].addition_topping.length > 0" >
                                <div class="per-item-row" v-for="(additional, indexA) in item.product[0].addition_topping" :key="indexA">
                                    <div class="basket-block-left">
                                        <div class="cart-item-sub-name pl-10">{{$t('common.add')}} {{additional.name}}</div>
                                    </div>
                                    <div class="basket-block-right">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-full" v-else>
                            <div class="per-item-row">
                                <div class="basket-block-left">
                                    <div class="cart-item-name pl-5">{{item.parent.name}}</div>
                                </div>
                                <div class="cart-item-quantity" @click="modalUpdateQuantity(item)">
                                    x {{item.quantity}}
                                </div>
                                <div class="basket-block-right">
                                    <div class="cart-item-price flex ">
                                        <span class="flex-1" v-if="item.point > 0">
                                          {{$t('common.format_point', {amount: formatCurrency(item.point * item.quantity, $const.SUFFIX_CURRENCY)})}}
                                        </span>
                                        <span class="flex-1">
                                            {{$t('common.format_currency', {amount: formatCurrency(item.sub_price * item.quantity, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                        </span>
                                        <img src="../../assets/images/icon-remove.svg" style="height: 15px; padding-left: 5px"  @click.prevent.stop="removeCart(item)">
                                    </div>
                                </div>
                            </div>
                            <div v-for="(itCombo, indexCombodetail) in item.product" :key="indexCombodetail">
                                <div class="per-item-row">
                                    <div v-if="item.product.length > 0" class="basket-block-left">
                                        <div class="cart-item-name pl-10"><span v-if="!emptyVariable(itCombo.option)">{{itCombo.option.name}}</span> {{itCombo.name}}</div>
                                    </div>
                                </div>
                                <div class="per-item-row">
                                    <div v-if="item.product.length > 0 " class="basket-block-left">
                                        <div class="cart-item-sub-name pl-5" v-if="!emptyVariable(itCombo.option_group)">{{itCombo.option_group.name}}</div>
                                    </div>
                                </div>
                                <div v-for="(addTopping, indexTopping) in itCombo.addition_topping" :key="indexTopping">
                                    <div class="per-item-row">
                                        <div class="basket-block-left">
                                            <div class="cart-item-sub-name pl-15">{{addTopping.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <template v-for="(coupon, indexC) in cartData.promotions">
                      <div class="cart-item flex-1" :key="indexC" v-if="flagCouponRedeem">
                          <div class="w-full" v-if="coupon.type == 5">
                              <div class="per-item-row">
                                  <div class="basket-block-left">
                                      <div class="cart-item-name pl-5">
                                          {{coupon.name}} {{coupon.code}}
                                      </div>
                                  </div>
                                  <div class="basket-block-right">
                                      <div class="cart-item-price flex">
                                          <span class="flex-1">Rp 0</span>
                                          <img src="../../assets/images/icon-remove.svg" style="height: 15px; padding-left: 5px"  @click.prevent.stop="_removeCoupon(coupon)" class="cursor-pointer">
                                      </div>
                                  </div>
                              </div>
                              <div class="p-5 flex">
                                  <div v-if="coupon.image" class="basket-block-left">
                                      <img :src="coupon.image">
                                  </div>
                              </div>
                          </div>
                      </div>
                    </template>
                    <div class=" w-full border-top">
                        <div class=" p-5 flex">
                            <div class="basket-block-left bold">
                                {{$t('orderConfirm.subtotal')}}
                            </div>
                            <div class="basket-block-right bold">
                                <div class="cart-item-price text-right">
                                    <span v-if="this.orderType == $const.ORDERTYPE.DINEIN">{{$t('common.format_currency', {amount: formatCurrency(cartData.all_total ? cartData.all_total.sub_total : 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                    <span v-else>{{$t('common.format_currency', {amount: formatCurrency(cartData.information.sub_total || 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                </div>
                            </div>
                        </div>
                        <div class=" p-5 flex" v-if="cartData.information && cartData.information.discount >0">
                            <div class="basket-block-left bold">
                                {{$t('orderConfirm.discount')}}
                            </div>
                            <div class="basket-block-right bold">
                                <div class="cart-item-price text-right">
                                    <span>{{$t('common.format_currency', {amount: formatCurrency(cartData.information.discount || 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                </div>
                            </div>
                        </div>
                        <div class=" p-5 flex" v-if="cartData.information && cartData.information.sub_delivery_fee >0">
                            <div class="basket-block-left bold">
                                {{$t('orderConfirm.delivery_fee')}}
                            </div>
                            <div v-for="(itemPromo, indexPromo) in cartData.promotions" :key="indexPromo" class="basket-block-right">
                                <s class="cart-item-price text-right" v-if="itemPromo.type == 5 && itemPromo.origin_value > 0">
                                    {{$t('common.format_currency', {amount: formatCurrency(itemPromo.origin_value, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                                </s>
                            </div>
                            <div class="basket-block-right bold">
                                <div v-if="cartData.promotions"></div>
                                <div class="cart-item-price text-right">
                                    <span>{{$t('common.format_currency', {amount: formatCurrency(cartData.information.sub_delivery_fee || 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                </div>
                            </div>
                        </div>
                        <div class=" p-5 flex" v-if="cartData.information && cartData.information.tax >0">
                            <div class="basket-block-left bold">
                                {{$t('orderConfirm.tax_fee')}}
                            </div>
                            <div class="basket-block-right bold">
                                <div class="cart-item-price text-right">
                                    <span v-if="this.orderType == $const.ORDERTYPE.DINEIN">{{$t('common.format_currency', {amount: formatCurrency(cartData.all_total ? cartData.all_total.before_tax : 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                    <span v-else>{{$t('common.format_currency', {amount: formatCurrency(cartData.information.tax || 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                </div>
                            </div>
                        </div>
                        <div class=" p-5 flex">
                            <div class="basket-block-left bold">
                                {{$t('orderConfirm.total')}}
                            </div>
                            <div class="basket-block-right bold">
                                <div class="cart-item-price text-right cart-total-flag">
                                    <span v-if="this.orderType == $const.ORDERTYPE.DINEIN">{{$t('common.format_currency', {amount: formatCurrency(cartData.all_total ? cartData.all_total.total : 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                    <span v-else>{{$t('common.format_currency', {amount: formatCurrency(grantTotal || 0, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="px-20" v-if="cartSuggest.message && this.orderType === $const.ORDERTYPE.DELIVERY">
                    <button class="w-full my-10 block pt-10 pb-10 lg:py-5 flex items-center">
                        <p class="typography-6 underline" style="color: #6c757d; font-weight: 600"><span>{{cartSuggest.message}}</span></p>
                    </button>
                </div>
<!--                <div class="px-20">-->
<!--                    <button class="w-full my-10 block pt-10 pb-10 lg:py-5 flex items-center" @click="addVoucher"><span class="w-16 h-16  text-16 text-white flex justify-center items-center mr-10 bg-black opacity-25 rounded-full" style="width: 16px; height: 16px;">+</span>-->
<!--                        <p class="typography-6 underline opacity-50" style="color: #6c757d"><span>{{$t('cart.add_voucher_code')}}</span></p>-->
<!--                    </button>-->
<!--                </div>-->
                <div class=" p-5 flex" v-for="(coupon, indexC) in cartData.promotions" :key="indexC">
                    <div v-if="coupon.image && (!flagCouponRedeem || coupon.type != 5)" class="basket-block-left bold">
                        <img  :src="coupon.image">
                    </div>
                    <div class="basket-block-left bold" v-if="(!flagCouponRedeem || coupon.type != 5)">
                        {{coupon.name}} {{coupon.code}}
                    </div>
                    <div class="basket-block-right bold flex justify-end" v-if="(!flagCouponRedeem || coupon.type != 5)">
                        <img src="../../assets/images/icon-remove.svg" style="height: 15px; padding-left: 5px"  @click.prevent.stop="_removeCoupon(coupon)" class="cursor-pointer">
                    </div>
                </div>
            </div>
            <div class="basket__footer bg-white lg:shadow-up-white ml-auto w-full mt-auto" v-bind:class="{'mobile-cart': this.isMobile()}">
                <div class="p-15">
                    <div class="basket-checkout">
                        <div>
                            <div class="flex flex-center flex-row mb-5">
                              <button class="w-full font-bold" @click="showUpsell ? showUpsell = false : showUpsell = true">
                                {{$t('common.complete_meal')}}
                                <i class="fa" :class="showUpsell ? 'fa-angle-down' : 'fa-angle-up'"></i>
                              </button>
                            </div>
                            <div class="flex flex-start overflow-auto" v-show="showUpsell">
                              <upsell :key="key" :product="item" v-for="(item, key) in upsellProduct" />
                            </div>
                            <div class="flex flex-center flex-row mb-5">
                                <button @click="hideBasket" class="button button--white-red lg:hidden"><span class="absolute inset-0 flex-center"><span>{{$t('common.btn.add_more_food')}}</span></span></button>
                                <button v-if="!emptyVariable(productChilli)" @click="addChilli"  class="button button--white-red " ><span class="absolute inset-0 flex-center"><span>{{$t('common.extra_chilli')}}</span></span></button>
                            </div>
                            <div class="flex flex-center flex-row mb-5">
                                <button @click="addVoucher"  class="button button--white-red " ><span class="absolute inset-0 flex-center"><span>Vouchers &amp; Slices</span></span></button>
                            </div>
                            <div class="relative">
                                <router-link class="button button--red relative" :disabled="!(cartBeforeCheckoutCount && cartBeforeCheckoutCount > 0)" :to="{name: 'dine-in-checkout'}" v-if="orderType == $const.ORDERTYPE.DINEIN">
                                    <span class="shopping-items" :style="`background-image: url('${CDN_URL}/icons/icon-shopping-cart.png')`" >
                                        <span class="count-items" v-if="cartBeforeCheckoutCount > 0">{{cartBeforeCheckoutCount}}</span>
                                    </span>
                                    <span class="absolute inset-0 flex-center">
                                        <span>{{$t('cart.checkout')}}</span>
                                    </span>
                                </router-link>
                                <router-link class="button button--red relative" :disabled="!(cartCount && cartCount > 0)" :to="{name: 'checkout'}" v-else>
                                    <span class="shopping-items" :style="`background-image: url('${CDN_URL}/icons/icon-shopping-cart.png')`" >
                                        <span class="count-items" v-if="cartCount > 0">{{cartCount}}</span>
                                    </span>
                                    <span class="absolute inset-0 flex-center">
                                        <span>{{$t('cart.checkout')}}</span>
                                    </span>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mobile-footer-component v-if="isMobile()"></mobile-footer-component>
        </div>
    </div>
</template>

<script>
  import types from '../../store/mutationTypes'
  import {  mapGetters , mapActions } from 'vuex'
  import ModalUpdateQuantity from '../modal/ModalUpdateQuantity'
  import DeliveryAddressInfo from '../../components/shared/DeliveryAddressInfo'
  import menuAPI from '../../services/menu'
  import mutationTypes from "../../store/mutationTypes";
  import couponAPI from "../../services/coupon";
  import cartAPI from "../../services/cart";
  import MobileFooterComponent from "../MobileFooterComponent";
  import manthan from "../../services/manthan";
  import moment from "moment-timezone";
  import branch from '../../services/branch'
  import upsell from './upsell'
  import bgImage from '@/assets/images/bg-app.jpg'
  const CDN_URL = process.env.VUE_APP_CDN_URL

  export default {
    name: "cart",
    components: {
      MobileFooterComponent,
      DeliveryAddressInfo,
      upsell,
    },
    data(){
      return {
        CDN_URL: CDN_URL,
        productChilli: {},
        flagUseCoupon: true,
        flagUseSuggest: true,
        flagCouponRedeem: false,
        listRedeemType: [],
        upsellProduct: [],
        showUpsell: true,
        bgImage: bgImage,
      }
    },
    destroyed () {
      this.hideBasket();
    },
    mounted() {
      this.getMenuChilli();
      this._checkPromoType();
      this.fetchUpsell()
      if (this.isMobile()){
        if (this.$route.name == 'cart'){
          this._eventViewCart()
        }
      }
    },
      watch: {
          cartData: function () {
              this._getSuggestPromo()
              this.fetchUpsell()
          },
          cartPromo: function () {
              this._setSuggestMsg()
              this._checkPromoType()
          }
      },
    computed: {
      ...mapGetters({
        cartData: 'moduleCart/' + types.CART_DETAIL,
        grantTotal: 'moduleCart/' + types.CART_GRANT_TOTAL,
        cartUuid: 'moduleCart/' + types.CART_GET_CART_UUID,
        cartCount: 'moduleCart/' + types.CART_COUNT,
        cartBeforeCheckoutCount: 'moduleCart/' + types.CART_BEFORE_CHECKOUT_COUNT,
        cartTotal: 'moduleCart/' + mutationTypes.CART_GET_TOTAL,
        cartPromo: 'moduleCart/' + mutationTypes.CART_GET_PROMOTIONS,
        defaultCategory:  types.GET_DEFAULT_CATEGORY,
        storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
        orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
        timezone: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_TIMEZONE,
        orderNow: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_NOW,
        orderTime: 'moduleLocalisation/' + types.LOCALISATION_GET_COLLECTION_TIME,
        isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
        cartSuggest: 'moduleCart/' + mutationTypes.CART_GET_SUGGESTIONS,
        orderLocation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
      })
    },
    methods: {
      ...mapActions({
        updateQuantity: 'moduleCart/' + types.CART_UPDATE_QUANTITY,
        removeCoupon: 'moduleCart/' + types.CART_REMOVE_COUPON,
        getCartDetail: 'moduleCart/' + types.CART_GET_CART_DETAIL,
        addToCart: 'moduleCart/' + types.CART_SAVE_ITEM,
        setCartSuggest: 'moduleCart/' + mutationTypes.CART_SET_SUGGEST,
      }),
      fetchUpsell () {
        const params = {
          cart_uuid: this.cartUuid || "",
          store_type_id: this.orderLocation.storeType || "",
          disposition: this.$const.ORDER_TYPE_MAPPING[this.orderType],
          store_uuid: this.storeUuid || "",

        }
        if (this.isAuth) {
          cartAPI.fetchUpsell(params, (response) => {
            this.$set(this, 'upsellProduct', response.data)
          });
        } else {
          cartAPI.fetchUpsellGuest(params, (response) => {
            this.$set(this, 'upsellProduct', response.data)
          });
        }
      },
      getMenuChilli () {
        let params = {
          type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
          store_uuid: this.storeUuid,
          additional_type_id: this.$const.ADDITIONAL_TYPE.CHILI
        }
        var vm = this;
        menuAPI.getProducts(params, function (response) {
          if (response.data.length > 0 && !vm.emptyVariable(response.data[0].products) && response.data[0].products.length > 0) {
            vm.productChilli = response.data[0].products[0];
          }
        })
      },
      addChilli () {
        if (!this.storeUuid) {
          this.$notify({
            type: 'warning',
            title: this.$t('message.error.choose_localisation_first')
          });
          let path = this.$route.fullPath.indexOf('/') !== 0 ? this.$route.fullPath : this.$route.fullPath.substr(1);
          this.$router.push({name: 'home', query: {warning: '1', callback: path}});
          return false;
        }
        // check point
        if (this.productChilli.point > 0 && !this.isAuth) {
          this.$notify({
            type: 'warning',
            title: this.$t('message.error.login_to_redeem')
          });
          this.$router.push({name: 'login'});
          return false;
        }

        let dataProduct = {
          "cart_uuid": this.cartUuid || '',
          "disposition": this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
          "items": [
            {
              "combo_uuid": "",
              "is_point": this.productChilli.point > 0 ? 1 : 0,
              "products": [
                {
                  "category_uuid": this.productChilli.category.uuid,
                  "combo_group_uuid": "",
                  "option_group_uuid": '',
                  "option_uuid": '',
                  "product_uuid": this.productChilli.uuid,
                  "quantity": 1,
                  "toppings": {
                    "minus": [],
                    "plus": []
                  }
                }
              ],
              "quantity": 1,
              "type": "product",
              "voucher_code": ""
            }
          ],
          "now_order": this.orderNow,
          "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
          "store_uuid": this.storeUuid
        }

        this.addToCart({vm: this, payload: dataProduct}).then(() => {
          this._eventAddCart(this.productChilli);
        });
      },
      hideBasket () {
        document.body.classList.remove('basket-open')
      },
      removeCart (menu) {
        let payload = {
          "cart_uuid": this.cartUuid,
          "key": menu.key,
          "quantity": 0
        }
        this.handleEventRemoveItem(menu)
        this.updateQuantity({vm: this, payload: payload}).then(() => {
          this._eventCartItemEdit(menu, 0)
        });

      },
      _removeCoupon (coupon) {
        let payload = {
          "cart_uuid": this.cartUuid,
          "code": coupon.code
        }
        this.flagUseCoupon = false
        this.flagUseSuggest = false
        this.removeCoupon({vm: this, payload: payload});
        this.setCartSuggest({payload: []})
      },
      handleEventRemoveItem (product, quantity) {
        let variant = [];
        let menu = {};
        if (product.parent.uuid) {
          menu = product.parent;
        } else {
          menu = product.product.length > 0 ? product.product[0] : {};
        }
        if (menu.option && menu.option.uuid) {
          variant.push(menu.option.uuid)
        }
        if (menu.option_group && menu.option_group.uuid) {
          variant.push(menu.option_group.uuid)
        }

        let dataPro = {
          'name': menu.name || '',
          'id': menu.uuid || '',
          'price': product.sub_price || 0,
          'variant': variant.join('-'),
          'quantity': quantity || (product.quantity || 1),
          'category': menu && menu.category && menu.category.name ? menu.category.name : '',
        };
        this.eventRemoveItem(dataPro);
      },
      modalUpdateQuantity(item) {
        if (item.promotion_code) {
          return;
        }
        var vm = this;
        this.$modal.show(ModalUpdateQuantity, {
            min: 1,
            max: 50,
            step: 1,
            amount: item.quantity,
            save: function (amount) {
             let payload = {
                "cart_uuid": vm.cartUuid,
                "key": item.key,
                "quantity": amount
              }
              if (amount < item.quantity) {
                vm.handleEventRemoveItem(item, (item.quantity - amount));
              }
              vm.updateQuantity({vm: vm, payload: payload}).then(() => {
                vm._eventCartItemEdit(item, amount)
              });
            }
          },
          {
            'class': 'modal-update-quantity-product'
          },
          {
            'before-open': function () {
            },
            'before-close': function () {
            }
          })
      },
        _getSuggestPromo() {
          if (this.orderType != this.$const.ORDERTYPE.CARRYOUT){
              var vm = this;
              let params = {
                  store_type: (this.orderLocation.storeType) ? this.orderLocation.storeType : this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
                  store_uuid: vm.storeUuid,
                  type_id:1,
                  total: vm.cartTotal,
                  vm: vm
              }
              if(vm.cartData.products.length == 0){
                  vm.setCartSuggest({payload: []})
                  vm.flagUseCoupon = true
                  vm.flagUseSuggest = true
              }else if((vm.cartPromo.length == 0 || (vm.cartPromo[0].type != 5)) && this.flagUseCoupon){
                  couponAPI.checkSuggestPromo(params, function (response) {
                      if (response.data.is_valid_code){
                          vm.couponCode = response.data.code;
                          vm._redeemCoupon(vm.cartUuid);
                      }
                  })
              }
          }
        },
        _redeemCoupon ( uuidCart = '') {
            let params = {
                "cart_uuid": uuidCart,
                "code": this.couponCode || '',
                "vm": this
            }
            let vm = this;
            cartAPI.redeem(params, function (response) {
                if (response.data.status === 0) {
                    let proUuid = ''
                    let prodRequired = ''
                    let isCombo = false;
                    if (response.data.products && response.data.products.length > 0) {
                        proUuid = response.data.products[0].uuid;
                        if (response.data.products[0].is_combo == 1) {
                            isCombo = true;
                        }
                    } else {
                        prodRequired = response.data.required_product_uuids.join();
                        return vm.$router.push({name: 'redeem', params: {code: vm.couponCode, listRequired: prodRequired}});
                    }
                    if (isCombo) {
                        return  vm.$router.push({name: 'combo-detail', params: {uuid: proUuid, code: vm.couponCode}})
                    }
                    return vm.$router.push({name: 'redeem', params: {code: vm.couponCode, uuid: proUuid}});
                }
                vm.getCartDetail({payload: {uuid: uuidCart}});
            }, function (error) {
                vm.notificationError(vm, error.message);
            });
        },
        _setSuggestMsg(){
            if (this.orderType != this.$const.ORDERTYPE.CARRYOUT) {
                let vm = this
                let paramsPromo = {
                    store_type: (this.orderLocation.storeType) ? this.orderLocation.storeType : this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
                    store_uuid: vm.storeUuid,
                    type_id: 1,
                    total: vm.cartTotal,
                    vm: vm
                }
                if (vm.cartData.products.length == 0) {
                    vm.setCartSuggest({payload: []})
                } else if (this.flagUseSuggest) {
                    couponAPI.checkSuggestPromo(paramsPromo, function (response) {
                        if (response.data.is_valid_code) {
                            let total = vm.cartPromo.reduce((total, item) => {
                                return total += item.origin_value
                            }, 0);
                            if (total > 0) {
                                let newMsg = response.data.message.replace(new RegExp('%s', "g"), total);
                                response.data.message = newMsg;
                            }
                        }
                        vm.setCartSuggest({payload: response.data});
                    })
                }
            }
        },
        _checkPromoType(){
            this.cartPromo.forEach(i => {
                this.listRedeemType.push(i.type)
            });
            if (this.listRedeemType.indexOf(5) !== -1){
                this.flagCouponRedeem = true
            }

        },
        removeSuggest(){
          this.flagUseSuggest = false;
            this.setCartSuggest({payload: []})
        },
        addVoucher() {
        this.hideBasket();
        if (this.isAuth) {
          this.$root.$router.push({name: 'category', params: {slug: this.$const.COUPON_CAT.slug}});
          return;
        }
        this.$root.$router.push({name: 'login', query: { callback: 'order/' + this.$const.COUPON_CAT.slug }});
        return;

    },
    _eventViewCart() {
      //function for manthan
      let cartItem = [];
      let totalDiscount = 0
      let couponCode = "";

      if (this.cartData.products.length > 0) {
        for (const [index, item] of this.cartData.products.entries()) {
          totalDiscount += item.discount
          for (const value of item.product) {
            const productId = value.uuid;
            const childProductId = value.option_group ? value.option_group.uuid : '';

            cartItem.push({
              productId: productId,
              childProductId: childProductId,
              actualProductId: childProductId != '' ? childProductId : productId,
              productPosition: index + 1,
              action: "View Cart",
              quantity: item.quantity,
              productBasePrice: item.sub_price,
              tax: item.tax,
              discount: item.discount,
              couponCode: item.promotion_code,
            });
          }
        }
      }

      if (this.cartData.promotions.length > 0) {
        for (const [index, item] of this.cartData.promotions.entries()) {
          if (index == 0) {
            couponCode = item.code;
          } else {
            couponCode += ',' + item.code;
          }
        }
      }

      const dataManthanCart = {
        totalPrice: this.cartData.information.total,
        shippingCost: this.cartData.information.delivery_fee > 0 ? this.cartData.information.sub_delivery_fee : 0,
        discount: totalDiscount,
        couponCode: couponCode,
        cartProducts: (cartItem) ? cartItem: null,
        createdDate: moment.utc(this.cartData.information.created_at).local().format('YYYY-MM-DD HH:mm:ss'),
        subTotalPrice: this.cartData.information.sub_total,
        tax: this.cartData.information.tax,
        cartId: this.cartUuid || '',
        totalQty: this.cartCount,
        itemCount: this.cartData.products.length || 0,
      };
      // eslint-disable-next-line no-undef
      manthan.execute(dataManthanCart,"eventCartView");
      branch.branchLog('VIEW_CART', this.cartData.products)
    },
    _eventCartItemEdit(menu, quantity) {
      // function for manthan
      const productPosition = (this.cartData.products.findIndex(value => value.key == menu.key)) + 1;
      const cartProducts = {
        productId: menu.key,
        productPosition: productPosition,
        action: "Edit Cart",
        quantity: quantity,
        productBasePrice: (quantity > 0) ? menu.sub_price * quantity : menu.sub_price,
        tax: (quantity > 0) ? menu.tax * quantity : menu.tax,
        discount: menu.discount,
        couponCode: menu.promotion_code
      }
      const dataManthanCart = {
        cartProducts: [cartProducts],
        cartId: this.cartUuid || '',
      };
      // eslint-disable-next-line no-undef
      manthan.execute(dataManthanCart,"eventCartItemEdit");
    },
    _eventAddCart(product) {
      //function for manthan
      const dataManthanCart = {
        cartId: this.cartUuid || '',
        productId: product.uuid,
        categoryId: product.category && product.category.uuid ? product.category.uuid : '',
        brandId: this.orderLocation.storeType === this.$const.TYPE_HUT.PHD ? 'PHD' : 'PHR',
        price: product.sub_price,
        quantity: 1,
        totalQuantity: this.cartCount,
        shippingCost: this.cartData.information.delivery_fee > 0 ? this.cartData.information.delivery_fee : 0,
        tax: product.tax
      };
      // eslint-disable-next-line no-undef
      manthan.execute(dataManthanCart,"eventAddToCart")
      branch.branchLog('ADD_TO_CART',[product])
    }
  }
}
</script>

<style scoped>
.mobile-cart{margin-bottom: 60px}
</style>

<style>
@media (max-width:800px) {
  .basket-container::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
  }
  .basket-container::-webkit-scrollbar-thumb {
    background-color: #6c757d;
  }
  .basket-container::-webkit-scrollbar-track {
    background-color: #e9ebee;
}
}
</style>
