<template>
  <div v-if="isFloating || !isMobile()" :class="isFloating ? 'floating-wrapper' : 'normal-wrapper'">
    <div
        :class="`center-group cursor-pointer ${isFloating && 'floating-center-group'}`"
        @click="goToMyRewardPage(isFloating)"
    >
      <div v-if="isAuth" class="left">
        <div class="image-wrapper cursor-pointer" @click="goToMyRewardPage(isFloating)">
          <img v-if="levelMaster.image" :src="levelMaster.image" alt="Pizza">
        </div>
        <h1>{{levelMaster.name}}</h1>
        <p>Lv {{levelMaster.level}} of 3</p>
      </div>

      <div v-else class="left">
        <div class="image-wrapper cursor-pointer" @click="goToMyRewardPage">
          <img src="@/assets/images/icon-piece-of-pizza.svg" alt="Pizza">
        </div>
        <h1>Fan</h1>
        <p>Lv 1 of 3</p>
      </div>

      <div class="seperate-line"></div>

      <div v-if="isAuth" class="level">
        <div class="progress-bar" :style="cssProps">
          <span class="inner-line"></span>
          <div class="point-wrapper">
            <div class="point">
              <img src="@/assets/images/icon-red-star.svg" alt="" />
            </div>
          </div>
        </div>
        <p style="font-weight: 600">{{levelMaster.message[0]}}</p>
        <div>
          <p style="font-weight: 600">{{levelMaster.message[1]}}</p>
          <img v-if="!isMobile()" @click.stop="$router.push({name: 'ph-reward-detail'})" src="@/assets/images/icon-info-2.svg" alt="" />
          <p v-if="!isMobile()" @click.stop="$router.push({name: 'ph-reward-detail'})" class="cursor-pointer">My reward system</p>
        </div>
      </div>

      <div v-else class="level">
        <h1>Welcome to HUT REWARDS</h1>
        <p class="font-bold">Join sekarang <br/>Dapat Gratis Pizza!</p>
        <button v-if="!isAuth" @click="showLogin">Sign up Now</button>
      </div>
    </div>
  </div>

  <div v-else class="normal-wrapper mobile-mode" @click="isMobile() && goToMyRewardPage(isFloating)">
    <div class="center-group flex-col" style="height: 330px;">
      <div v-if="isAuth" class="top">
        <div class="image-wrapper">
          <img v-if="levelMaster.image" :src="levelMaster.image" alt="Pizza">
        </div>
        <h1>{{levelMaster.name}}</h1>
        <p>Lv {{levelMaster.level}} of 3</p>
      </div>
      <div v-else class="top">
        <div class="image-wrapper">
          <img src="@/assets/images/icon-piece-of-pizza.svg" alt="Pizza">
        </div>
        <h1>Fan</h1>
        <p>Lv 1 of 3</p>
      </div>

      <div class="seperate-line-horizontal"></div>

      <div v-if="isAuth" class="level middle">
        <div class="progress-bar" :style="cssProps">
          <span class="inner-line"></span>
          <div class="point-wrapper">
            <div class="point">
              <img src="@/assets/images/icon-red-star.svg" alt="" />
            </div>
          </div>
        </div>
        <h3>{{levelMaster.message[0]}}</h3>
        <p class="font-bold">{{levelMaster.message[1]}}</p>
      </div>
      <div v-else class="level middle">
        <h1>Sign up as a member to Become a Fan<br>and enjoy goodies</h1>
        <p>25slices for snack and 100 slices for pizza. Keep it up!</p>
      </div>

<!--      <div class="bottom">-->
<!--        <p @click="$router.push({name: 'ph-reward-detail'});">More about benefits by level ></p>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
  import modalLoginRegister from "@/components/modal/ModalLoginRegister";
  import manthan from "@/services/manthan";

  export default {
    name: "RewardLevelSection",
    props: {
      isAuth: {
        type: Boolean,
        default: false,
      },
      authUser: {
        type: Object,
        default: () => {},
      },
      isFloating: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        progressBarPercent: 0,
        levelMaster: {},
      }
    },
    created() {
      if (this.isAuth) {
        this.calcProgressBarPercent()
      }
    },
    methods: {
      calcProgressBarPercent() {
        const level = this.authUser.level
        const levelMaster = this.authUser.level_master[level - 1]
        if (level < 3) {
          if (!this.authUser.total_amount) {
            this.progressBarPercent = 0
          } else {
            const max = levelMaster.max - levelMaster.min
            const currAmount = this.authUser.total_amount - levelMaster.min
            this.progressBarPercent = currAmount / max * 100
          }
        } else {
          this.progressBarPercent = 100
        }
        this.levelMaster = levelMaster
      },
      getProgressBarPercent() {
        if (this.progressBarPercent > 100) return "100%"
        if (this.progressBarPercent <= 0) return "0.5%"
        return this.progressBarPercent + "%"
      },
      goToMyRewardPage(isVoucherPage = false) {
        if (!this.isAuth) {
          this.showLogin()
          return
        }
        if (isVoucherPage) {
          this.$router.push({name: 'vouchers'})
        } else {
          this.$router.push({name: 'ph-reward-detail'})
        }
      },
      showLogin() {
        if (this.isMobile()) {
          this.$router.push('login')
          return
        }
        const vm = this
        this.$modal.show(
            modalLoginRegister,
            {
              resizable: true,
              clickToClose: true,
              manthanLogin: function () {
                return  vm.callManthan
              }
            }
        )
      },
      callManthan(){
        manthan.execute({}, "eventLogin")
        return true
      },
    },
    computed: {
      cssProps() {
        return {
          '--progress-bar-percent': this.getProgressBarPercent(),
        }
      }
    },
  }
</script>

<style scoped>
  .floating-wrapper {
    height: calc(275px * 0.7);
  }
  .normal-wrapper {
    background-color: #C8102E;
    cursor: auto;
  }
  .center-group {
    display: flex;
    width: 80%;
    height: 275px;
    max-width: 1280px;
    background-color: #C8102E;
    border-radius: 8px;
    margin: 0 auto;
    padding: 30px;
  }
  .floating-center-group {
    transform: translateY(-30%);
  }
  .left {
    width: 134px;
    height: 100%;
  }
  .image-wrapper {
    width: 134px;
    height: 134px;
    max-width: 134px;
    background-color: #FFFFFF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-wrapper > img {
    width: 60px;
    height: 60px;
  }
  .left > h1 {
    color: #F5F5F6;
    font-size: 24px;
    text-align: center;
  }
  .left > p {
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    padding: 0 16px;
  }
  .top {
    width: 100%;
    height: calc(50% - 2px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .top .image-wrapper {
    width: 96px;
    height: 72px;
  }
  .top .image-wrapper > img {
    width: 50px;
    height: 45px;
  }
  .top > h1 {
    color: #F5F5F6;
    font-size: 14px;
    text-align: center;
  }
  .top > p {
    color: #FFFFFF;
    text-align: center;
    font-weight: bold;
    font-size: 8px;
  }
  .seperate-line {
    transform: translateY(40%);
    border-left: 2px dashed #FD4747;
    height: 60%;
    margin: 0 72px;
  }
  .seperate-line-horizontal {
    border-top: 2px dashed #FD4747;
    width: 90%;
    margin: 0 auto;
  }
  .level {
    flex: auto;
  }
  .level h1 {
    color: #FFFFFF;
    max-width: 82%;
    font-size: 32px;
    margin-top: 32px;
  }
  .level h3 {
    color: #F5F5F6;
    max-width: 82%;
    font-size: 20px;
    margin-top: 24px;
  }
  .level p {
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 16px;
  }
  .level > button {
    background-color: #FFFFFF;
    color: #C8102E;
    position: fixed;
    width: 183px;
    height: 64px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 22px;
    text-align: center;
    right: 88px;
    bottom: 72px;
  }
  .level > div {
    display: flex;
    width: 95%;
  }
  .level > div > p {
    margin-top: 0px;
  }
  .level > div > p:first-child {
    flex: auto;
  }
  .level > div > img {
    margin-right: 8px;
  }
  .middle {
    flex: none;
    width: 95%;
    margin: 0 auto;
    text-align: center;
    padding-top: 16px;
  }
  .middle .progress-bar {
    width: 72%;
    margin: 0 auto;
  }
  .bottom {
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
  }
  .bottom > p {
    color: #FFFFFF;
    font-weight: 600;
    font-size: 12px;
  }
  .progress-bar {
    display: flex;
    width: 95%;
    height: 10px;
    background-color: #020202;
    border-radius: 12px;
    margin-top: 45px;
  }
  .progress-bar .inner-line {
    width: var(--progress-bar-percent);
  }
  .progress-bar > span {
    display: block;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 12px;
  }
  .progress-bar .point-wrapper {
    width: 0;
  }
  .progress-bar .point-wrapper .point {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
    border-radius: 16px;
    transform: translate(-90%, -27%);
  }
  .progress-bar .point-wrapper .point > img {
    width: 12px;
    height: 12px;
  }

  @media screen and (max-width: 1024px) {
    .floating-wrapper {
      height: 108px;
      margin: 20px;
    }
    .center-group {
      width: 100%;
      height: 110px;
      min-width: 350px;
      transform: translateY(0);
      padding: 12px;
    }
    .left {
      width: 55px;
    }
    .left .image-wrapper {
      width: 55px;
      height: 40px;
      max-width: 55px;
      border-radius: 8px;
    }
    .left .image-wrapper > img {
      width: 24px;
      height: 24px;
      max-width: 55px;
      border-radius: 8px;
    }
    .left > h1 {
      font-size: 14px;
    }
    .left > p {
      font-size: 8px;
      line-height: 8px;
      padding: 0;
    }
    .seperate-line {
      margin: 0 16px;
      height: 85%;
      transform: translateY(15%);
      border-left: 1px dashed #FD4747;
    }
    .level h1 {
      margin-top: 0px;
      font-size: 12px;
      line-height: 20px;
    }
    .level h3 {
      max-width: 100%;
      font-size: 12px;
      margin: 8px 0px 0px 0px;
    }
    .level p {
      font-size: 12px;
      margin-top: 8px;
    }
    .level > button {
      display: none;
    }
    .middle h1 {
      /* margin-top: 16px; */
      max-width: 100%;
    }
    .middle h3 {
      max-width: 100%;
    }
    .middle p {
      margin: 0px;
    }
    .progress-bar {
      height: 5px;
      margin-top: 36px;
    }
    .progress-bar .point-wrapper .point {
      width: 15px;
      height: 14px;
      transform: translate(-90%, -30%);
    }
    .progress-bar .point-wrapper .point > img {
      width: 7px;
      height: 7px;
    }
  }

</style>
