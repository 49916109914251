import Api from '@/services/api'
import types from "../store/mutationTypes";
const url = '/promotion/v2'
export default {
    randomReward (data, success, error ) {
      let mainApp = null;
      if (data.vm) {
        mainApp = data.vm;
        mainApp.$store.dispatch(types.IN_CREATE_LOADING)
        delete data['vm']
      }
      return Api().post(url + '/promotion-treasure', data)
        .then(response => {
          if (mainApp) {
            mainApp.$store.dispatch(types.DE_CREATE_LOADING)
          }
          if (success !== undefined) {
              success(response)
          }
      }).catch(errorData => {
        if (mainApp) {
          mainApp.$store.dispatch(types.DE_CREATE_LOADING)
        }
        if (error !== undefined) {
            error(errorData)
        }
      })
    },
    updateReceipt (data, success, error ) {
      let mainApp = null;
      if (data.vm) {
          mainApp = data.vm;
          mainApp.$store.dispatch(types.IN_CREATE_LOADING)
          delete data['vm']
      }
      return Api().post(url + '/promotion-treasure/update-receipt', data)
        .then(response => {
          if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING)
          }
          if (success !== undefined) {
              success(response)
          }
        }).catch(errorData => {
          if (mainApp) {
              mainApp.$store.dispatch(types.DE_CREATE_LOADING)
          }
          if (error !== undefined) {
              error(errorData)
          }
      })
    },
    claimReward(uuid, data, success, error ) {
      let mainApp = null;
      if (data.vm) {
        mainApp = data.vm;
        mainApp.$store.dispatch(types.IN_CREATE_LOADING)
        delete data['vm']
      }
      return Api().get(url + '/promotion-treasure/claim/' + uuid)
        .then(response => {
          if (mainApp) {
            mainApp.$store.dispatch(types.DE_CREATE_LOADING)
          }
          if (success !== undefined) {
            success(response)
          }
        }).catch(errorData => {
          if (mainApp) {
            mainApp.$store.dispatch(types.DE_CREATE_LOADING)
          }
          if (error !== undefined) {
            error(errorData)
          }
        })
    },
  getRewardDetail(orderCode, data, success, error ) {
    let mainApp = null;
    if (data.vm) {
      mainApp = data.vm;
      mainApp.$store.dispatch(types.IN_CREATE_LOADING)
      delete data['vm']
    }
    return Api().get(url + '/promotion-treasure/' + orderCode)
                .then(response => {
                  if (mainApp) {
                    mainApp.$store.dispatch(types.DE_CREATE_LOADING)
                  }
                  if (success !== undefined) {
                    success(response)
                  }
                }).catch(errorData => {
        if (mainApp) {
          mainApp.$store.dispatch(types.DE_CREATE_LOADING)
        }
        if (error !== undefined) {
          error(errorData)
        }
      })
  },
}
