<template>
    <div class="ReactModalPortal order-datetime-modal">
        <div class="ReactModal__Overlay ReactModal__Overlay--after-open " class-open="ReactModal__Overlay--after-open"
             style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; background-color: rgba(255, 255, 255, 0.75);"
             aria-modal="true">
            <div
                    class="ReactModal__Content overlay xs-full-height xs-full-width xs-flex xs-items-center ReactModal__Content--after-open"
                    class-open="ReactModal__Content--after-open" tabindex="-1" aria-label="DelayedOrderModal">
                <div class="" style="width: 100%; height: 100%;">
                    <div class="xs-pt-60 xs-px-40 xs-text-white pb-20">
                        <p class="xs-mb-20 text-center t24">{{$t('common.what_time_would_you_like_your_order')}} </p>
                    </div>
                    <button type="button"
                            class="icon-close--white xs-p-20 xs-absolute xs-r-20 xs-t-10 close-order-datetime"
                            @click="$emit('close')"></button>
                    <div class="text-center" style="margin:0 auto;width:50%">
                        <div class="w-p50 float-left">
                            <i class="fa fa-calendar xs-mr-5 fa-o-before-20" style="width: 20px;height: 20px"> </i>
                            <select class="select-popup-date-time t22" v-model="choiceDay">
                                <option class="dropdown-item" v-for="(item, index) in openingDateTime" :value="index" :key="index">{{ item.label }}
                                </option>
                            </select>
                        </div>

                        <div class="w-p50 float-left">
                            <i class="fa fa-clock-o xs-mr-5 fa-o-before-20" style="width: 20px;height: 20px"></i>
                            <select class="select-popup-date-time t22" v-model="choiceHour">
                                <option class="dropdown-item" v-for="(hour, indexH) in hours" :value="hour" :key="indexH">{{hour}}</option>
                            </select>
                        </div>
                        <div class=" pt-100 action-save-date-time">
                            <button style="width: 90%;margin: auto;" type="button"
                                    class="button button--red xs-text-center save-date-time" @click="setTime()">
                                {{$t('common.btn.set_order_time')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import types from '../../store/mutationTypes'
    import LocalizationApi from '../../services/localization';
    import ModalNotifyBlack from '../modal/ModalNotifyBlack'


    import '@/assets/css/plugins/font-awesome.min.css';
    import _ from 'lodash'
    import moment from 'moment-timezone'
    export default {
        name: "ModalASAP",
        props: ['businessHoursCustom', 'triggerCheckLocalisationData', 'timezoneHut'],
        data: function() {
            return {
                orderType: '',
                timezone: '',
                choiceDay : '',
                choiceHour : '',
                businessHours : [
                    // {
                    //     date: "2020-04-29",
                    //     day: "web",
                    //     start_at: "2020-04-29 10:00:00",
                    //     end_at: "2020-04-30 02:00:00",
                    //     waiting_time: 40,
                    //     duration: 30
                    // },
                    // {
                    //     date: "2020-04-30",
                    //     day: "web",
                    //     start_at: "2020-04-30 10:00:00",
                    //     end_at: "2020-04-31 02:00:00",
                    //     waiting_time: 40,
                    //     duration: 30
                    // }
                ],
                openingDateTime : {},
                hours : []
            }
        },
        mounted() {
            this.orderType = this.localisation.orderType;
            if (this.businessHoursCustom && this.businessHoursCustom.length > 0) {
              this.businessHours = this.businessHoursCustom;
              this.timezone = this.timezoneHut;
              this.getTimeSlot();
            } else {
              this.getAdvanceTime();
            }

        },
        computed: {
            ...mapGetters({
              localisation: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
              cartUuid: 'moduleCart/' + types.CART_GET_CART_UUID,
            }),
        },
        watch: {
            choiceDay: function (newValue, oldValue) {
                this.hours = typeof this.openingDateTime[newValue] !== 'undefined' ? this.openingDateTime[newValue].data : []

                let exist = this.hours.includes(this.choiceHour)
                if(!exist){
                    this.choiceHour = this.hours[0]
                }
                if (oldValue == '') {
                    let h = this.localisation.collectionTime
                    if (h !== undefined && h != '' && this.localisation.orderNow != 1) {
                        if (_.indexOf(this.hours, h.pickedHours) >= 0) {
                            this.choiceHour = h.pickedHours
                        }
                    }
                }
            }
        },
        methods : {
            ...mapActions({
                restLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
                resetCart: 'moduleCart/' +  types.CART_RESET_DATA,
                saveLocalisation:  'moduleLocalisation/' + types.LOCALISATION_SAVE_STORE,
                updateCart: 'moduleCart/' + types.CART_UPDATE_CART,
            }),
            getAdvanceTime () {
              var vm = this;
              let params = {
                store_uuid: this.localisation.uuid ,
                store_type_id: this.$const.TYPE_HUT.PHD,
                disposition: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)]
              }
              LocalizationApi.storeAdvanceTime(params, function (response) {
                vm.businessHours = response.data.business_hours;
                vm.timezone = response.data.timezone;
                vm.getTimeSlot();
              }, function (error) {
                vm.notificationError(vm, error.message);
                vm.$emit('close');
              })
            },
            getTimeSlot() {
              var vm = this;
                let now = moment();
                let tomorrow = moment().add(1, 'days');
                // let hourNextDate = []
                let defaultDate = this.localisation.collectionTime || ''
              let storeClosedDay = 0;
               for (let op of this.businessHours){
                 let stack = op.stack;
                 if (this.emptyVariable(stack)) {
                   storeClosedDay++;
                   continue;
                 }
                 let startTimeOrg = moment(op.start_at, 'YYYY-MM-DD HH:mm:ss').add(op.waiting_time, 'minutes');
                 let oldDay = '';
                 let tmrDay = '';
                 let obTime = [];
                 let obTimeTmr = [];
                 for (let itemS of stack) {
                   let date = moment(itemS, 'YYYY-MM-DD HH:mm:ss');
                   let newDay = date.format('YYYY-MM-DD');
                   if (oldDay == '') {
                     oldDay = newDay;
                   }
                   // if (oldDay != newDay) {
                   //   this.setDateTime(oldDay,
                   //     obTime,
                   //     now.format('YYYY-MM-DD'),
                   //     tomorrow.format('YYYY-MM-DD'),
                   //     startTimeOrg)
                   // }
                     if (oldDay == newDay) {
                         obTime.push(date.format('HH:mm'));
                     }else{
                         tmrDay = newDay;
                         obTimeTmr.push(date.format('HH:mm'));
                     }

                 }
                 if (oldDay != '' && obTime.length > 0) {
                   this.setDateTime(oldDay,
                     obTime,
                     now.format('YYYY-MM-DD'),
                     tomorrow.format('YYYY-MM-DD'),
                     startTimeOrg)
                 }
                 if (tmrDay != '' && obTimeTmr.length > 0){
                   this.setDateTime(tmrDay,
                       obTimeTmr,
                       now.format('YYYY-MM-DD'),
                       tomorrow.format('YYYY-MM-DD'),
                       startTimeOrg)
                 }
               }

               if (storeClosedDay && storeClosedDay == this.businessHours.length) {
                 this.$emit('close');
                 this.$modal.show(ModalNotifyBlack, {hideOK: true, contain: this.$t('message.error.store_closed')},
                   {resizable: false, clickToClose: true,
                     classes: 'modal-choose-delivery-collection-custom back-ground-black-transparent'},
                   {
                     'opened': function () {
                     },
                     'before-close': function () {
                       if (vm.emptyVariable(vm.businessHoursCustom)) {
                         vm.restLocalisation();
                         vm.resetCart();
                         vm.$router.push({name: 'home', query: { warning: '1'}});
                       }
                     }
                   });

                 return false;
               }


              /* for(let op of this.businessHours){
                   let obTime = []
                   let date = moment(op.start_at)
                   if (op.start_at && op.end_at) {
                       if(hourNextDate.length > 0) {
                           obTime = _.cloneDeep(hourNextDate)
                           hourNextDate = []
                       }
                       let info_interval = 5
                       let totalWaiting = op.waiting_time + op.duration
                       let dateFormat = moment(op.start_at).format('YYYY-MM-DD')
                       let startTime = moment(op.start_at).add(op.waiting_time, 'minutes')
                       let endTime = moment(op.end_at)
                       if (endTime.format('HH:mm:ss') == '00:00:00') {
                           endTime.set('hour', 23)
                           endTime.set('minute', 55)
                           endTime.set('second', 59)
                       }

                       let startTimeOrg = _.cloneDeep(startTime)

                       let nextMinute = null
                       if (now.format('YYYY-MM-DD') == date.format('YYYY-MM-DD')) {
                           if (now >= startTime) {
                               startTime = moment().add(totalWaiting, 'minutes')
                           }
                       }

                       if (now.format('YYYY-MM-DD') == date.format('YYYY-MM-DD')) {
                           nextMinute = (info_interval * (Math.floor(startTime.get('minute') / info_interval)))
                           while (nextMinute < startTime.get('minute') && info_interval > 0) {
                               nextMinute += info_interval
                           }
                           startTime.minute(nextMinute)
                       }


                       //subtract interval at start time
                       startTime.subtract(info_interval,'minutes')

                       let check_start = _.cloneDeep(startTime)
                       let check_end = _.cloneDeep(endTime)
                       let isSameDate = check_start.format('YYYY-MM-DD') == check_end.format('YYYY-MM-DD') ? true : false
                       let addTotal = 0
                       while (startTime.isBefore(endTime)) {
                           let interval = info_interval
                           let isAdd = true;

                           let nextTime = this._.cloneDeep(startTime)
                           nextTime.add(interval, 'minutes')
                           if (nextTime < now) {
                               isAdd = false
                           }
                           startTime = startTime.add(interval, 'minutes')
                           let currentFormat = startTime.format('HH:mm');

                           if (endTime.format('YYYY-MM-DD') > startTime.format('YYYY-MM-DD') && isAdd) {
                               obTime.push(currentFormat)
                           } else if(isAdd) {
                               if(isSameDate)
                                   obTime.push(currentFormat)
                               else{
                                   if(endTime.format('YYYY-MM-DD') == now.format('YYYY-MM-DD')){
                                       if(addTotal === 0){
                                           startTime = startTime.add(totalWaiting, 'minutes')
                                           addTotal++
                                       }
                                       hourNextDate.push(startTime.format('HH:mm'))
                                   }
                                   else{
                                       hourNextDate.push(currentFormat)
                                   }
                               }
                           }
                       }
                       if ((obTime.length > 1 || obTime[0] != this.$t('common.asap'))) {
                           this.setDateTime(dateFormat,
                               obTime,
                               now.format('YYYY-MM-DD'),
                               tomorrow.format('YYYY-MM-DD'),
                               startTimeOrg)
                       }
                   }
               }*/
                this.setDefaultChosen(defaultDate)
            },
            setDateTime(obDate, obTime, today, tomorrow, startTimeOrigin) {
                let label = ''
                if(obDate == today){
                    label = this.$t('common.today')
                }else if(obDate == tomorrow){
                    label = this.$t('common.tomorrow')
                }else {
                    label = moment(obDate).format('DD/MM/YYYY')
                }
                if (!this.openingDateTime[obDate]) {
                    this.openingDateTime[obDate] = {'label': label, 'data': obTime}
                } else {
                    let oldData = this.openingDateTime[obDate].data
                    let newData = obTime
                    this.openingDateTime[obDate].data = oldData.concat(newData)
                }
                if (this.openingDateTime[obDate].data.length <= 0) {
                    delete this.openingDateTime[obDate]
                } else {
                    if (obDate == today) {
                        // check hide asap when asap time not available to order
                        if (moment().isSameOrAfter(startTimeOrigin)) {
                            this.openingDateTime[obDate].data.unshift(this.$t('common.asap'))
                        }
                    }
                }
            },
            setDefaultChosen(defaultDate) {
                let flag = false
                if (this.localisation.orderNow != 1) {
                    for (let date in this.openingDateTime) {
                        if (date == defaultDate.pickedDay) {
                            this.choiceDay = defaultDate.pickedDay
                            this.hours = this.openingDateTime[date].data
                            if (_.indexOf(this.openingDateTime[date].data, defaultDate.pickedHours) >= 0) {
                                this.choiceHour = defaultDate.pickedHours
                            } else {
                                this.choiceHour = this.openingDateTime[date].data[0]
                            }
                            flag = true
                            break
                        }
                    }
                }

                if (!flag) {
                    for (let date in this.openingDateTime) {
                        this.choiceDay = date
                        this.days = this.openingDateTime[date].label
                        this.hours = this.openingDateTime[date].data
                        this.choiceHour = this.openingDateTime[date].data[0]
                        flag = true
                        break
                    }
                }
            },
            setTime: function () {
                let timepicker = {'pickedDay': this.choiceDay, 'pickedHours': this.choiceHour}

                this.localisation.collectionTime = timepicker;
                this.localisation.collection_time = this.choiceDay + ' ' + this.choiceHour;
                this.localisation.timezone = this.timezone;
                this.localisation.orderNow = 0;
                if (this.choiceHour == this.$t('common.asap')) {
                    this.localisation.orderNow = 1;
                }
                this.$root.$emit('collectionTimeUpdate');

                this.saveLocalisation(this.localisation);
                if( this.triggerCheckLocalisationData) {
                  this.$root.$emit('triggerCheckLocalisation', this.triggerCheckLocalisationData);
                }
                this.updateCartTime(this.localisation.orderNow, this.localisation.collection_time);
              this.$emit('close');
            },
            updateCartTime(orderNow, collectionTime) {
              if (!this.cartUuid){
                return;
              }
              let dataProduct = {
                "cart_uuid": this.cartUuid,
                "now_order": orderNow,
                "collection_time": this.convertDateTimeFromTimezoneToUTC(this.localisation.timezone, collectionTime)
              };

              this.updateCart({payload: dataProduct});
            }
        }
    }
</script>

<style scoped>
    select {
        border: none;
        background: transparent;
        /*background-color: blue;*/
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-top: 0px;
        background-size: 20px;
        color: #ffffff !important;
    }

    select.select-popup-date-time .dropdown-item {
        font-size: 15px !important;
    }

    .select-popup-date-time {
        border: 0;
        border-bottom: 1px solid #999;
        background: none;
        color: #fff !important;
        width: 79%;
    }

    .fa {
        color: #fff;
    }
</style>
