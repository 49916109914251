import Api from '@/services/api'
import types from '../store/mutationTypes'
const url = '/store/v1'
export default {
    storeDetail (data, success, error) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/store/' + data.uuid)
          .then(response => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (success != undefined) {
                  success(response)
              }
          }).catch(errorData => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (error != undefined) {
                  error(errorData)
              }
          })
    },
    storeAdvanceTime (data, success, error) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/advance-times', {params: data})
          .then(response => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (success != undefined) {
                  success(response)
              }
          }).catch(errorData => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (error != undefined) {
                  error(errorData)
              }
          })
    },
    stores(data, success, error) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/stores', {params: data})
          .then(response => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (success != undefined) {
                  success(response)
              }
          }).catch(errorData => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (error != undefined) {
                  error(errorData)
              }
          })
    },
    checkDelivery (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/stores/delivery-list', {params: data})
          .then(response => {
            if (mainApp) {
                mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(errorData => {
              if (mainApp) {
                  mainApp.$store.dispatch(types.DE_CREATE_LOADING);
              }
              if (error != undefined) {
                error(errorData)
            }
        })
    },
    getStoreCollection (data, success, error ) {
        var mainApp = null;
        if (data.vm) {
            mainApp = data.vm;
            mainApp.$store.dispatch(types.IN_CREATE_LOADING);
            delete data['vm']
        }
        return Api().get(url + '/stores/collection', {params: data}).then(response => {
            if (mainApp) {
                mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (success != undefined) {
                success(response)
            }
        }).catch(e => {
            if (mainApp) {
                mainApp.$store.dispatch(types.DE_CREATE_LOADING);
            }
            if (error != undefined) {
                error(e)
            }
        })
    },

}