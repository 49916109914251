<template>
  <div>
      <header-component />
      <div class=" en-GB app-bg">
          <div class="container mt-20 mb-40">
              <div class="font-open-sans-bold uppercase text-center relative mb-20">
                  <h1 class="px-40">{{$t('common.payment')}}</h1>
                  <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
              </div>
              <div class="p-20 bg-white">
                  <div class="md:flex">
                      <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" action="" @submit="checkForm" >
                          <div class="block-section">
                              <delivery-address-info />
                          </div>
                          <div class="block-section" v-if="!isAuth">
                              <div class="login-account mb-10" >
                                  <div><router-link class="text-blue underline" :to="{name: 'login', query: {callback: 'checkout'}}"> {{$t('common.sign_in')}}</router-link> {{$t('common.title_sign_in')}}</div>
                                  <div>{{$t('common.title_create_an_account')}} <router-link class="text-blue underline" :to="{name: 'register', query: {callback: 'checkout'}}"> {{$t('common.here')}}</router-link></div>
                              </div>
                          </div>
                          <div class="block-section">
                              <div class="text-center title-line line-header"><strong>{{$t('checkout.who_order')}}</strong></div>
                              <div class="form-field">
                                  <div class="xs:flex xs:flex-center mb-5">
                                      <label class="label mb-0" for="name">{{$t('checkout.name')}}
                                          <span class="text-red">{{ $t('checkout.required') }}</span>
                                          <span class="float-right text-red" v-if="errors.name">{{errors.name}}</span>
                                      </label>
                                  </div>
                                  <input id="name" class="input xs-mb-10" ref="name" v-bind:class="{'has-error': errors.name, 'has-success': errors.name === false}"
                                         name="name" v-model="customer.name"
                                         maxlength="50" type="text">
                              </div>
                              <div class="form-field">
                                  <div class="xs:flex xs:flex-center mb-5">
                                      <label class="label mb-0" for="phone">{{$t('checkout.phone')}}
                                          <span class="text-red">{{ $t('checkout.required') }}</span>
                                          <span class="float-right text-red" v-if="errors.phone">{{errors.phone}}</span>
                                      </label>
                                  </div>
                                  <div class="input-group">
                                      <div class="input-group-append" ><span class="input-group-text" >{{$const.PREFIX_PHONE}}</span></div>
                                      <input id="phone" class="input xs-mb-10 form-control" ref="phone" v-bind:class="{'has-error': errors.phone, 'has-success': errors.phone === false}"
                                             name="phone" v-model="customer.phone"
                                             :maxlength="$const.PHONE.MAX" type="text">
                                  </div>
                              </div>
                              <div class="form-field">
                                  <div class="xs:flex xs:flex-center mb-5">
                                      <label class="label mb-0" for="email">{{$t('checkout.email')}}
                                          <span class="text-red">{{ $t('checkout.required') }}</span>
                                          <span class="float-right text-red" v-if="errors.email">{{errors.email}}</span>
                                      </label>
                                  </div>
                                  <input id="email" class="input xs-mb-10" ref="email" v-bind:class="{'has-error': errors.email, 'has-success': errors.email === false}"
                                         name="email" v-model="customer.email"
                                         :readonly="isAuth"
                                         :disabled="isAuth"
                                         maxlength="255" type="text">
                              </div>
                          </div>
                          <div class="block-section">
                              <div class="text-center title-line line-header"><strong>{{$t('checkout.how_would_you_like_to_pay')}}</strong></div>
                              <div class="error-payment-block  pb-5 text-right" v-if="errors.paymentMethod">
                                  <label class="label text-red">{{errors.paymentMethod}}</label>
                              </div>
                              <select id="dropdown_64" class="custom-dropdown-select bg-grey-lightest p-10 w-full typography-6 bold mb-10" v-model="paymentMethodType">
                                  <option :value="itemPT"  v-for="(itemPT, indexPT) in payments" :key="indexPT" >{{itemPT.name }}</option>
                              </select>
                              <div class="payment-methods-contain" v-if="paymentMethodType && paymentMethodType.items.length > 0">
                                  <ul class=" flex flex-col" v-for="(itemP, indexP) in paymentMethodType.items" :key="indexP" >
                                      <li class="group-title bold t14">{{itemP.name}}</li>
                                      <li v-for="(item, index) in itemP.payments" :key="index" class="rounded payment-item p-20 mb-10 cursor-pointer  flex flex-col " :class="{'active': paymentMethod.uuid === item.uuid}">
                                          <label :for="'payment' + item.uuid" class=" flex flex-row">
                                              <input type="radio" v-bind:value="item" :ref="'payment' + item.uuid" v-model="paymentMethod" class="radio-custom" name="paymentMethod" :id="'payment' + item.uuid"/>
                                              <i></i>
                                              <span class="flex w-full">
                                                  <span> {{item.name}}</span>
                                                  <span class="text-right flex-1 bold" v-if="item.code == $const.PAYMENT_METHOD.CASH">{{$t('common.format_currency', {amount: formatCurrency(grantTotal, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}</span>
                                               </span>

                                          </label>

                                          <div class="form-field" v-if="item.code == $const.PAYMENT_METHOD.CASH">
                                              <div class="error-term-block  pb-5 text-right" v-if="errors.amountOfChange">
                                                  <label class="label text-red">{{errors.amountOfChange}}</label>
                                              </div>
                                              <input v-model="amountOfChange" name="amount_of_change" id="amount_of_change" ref="amount_of_change" class="input xs-mb-10"
                                                     v-bind:class="{'has-error': errors.amountOfChange, 'has-success': errors.amountOfChange === false}"
                                                     type="text" :placeholder="$t('common.amount_of_change')" />
                                          </div>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <div class="block-section" v-if="orderType === $const.ORDERTYPE.DELIVERY">
                              <label class="contact-less label flex mb-5 flex-row flex-center">
                                  <input v-model="contactLess" id="contact-less" class="checkbox" type="checkbox" ref="contactless" true-value="1" false-value="0">
                                  <span v-bind:class="{'active': contactLess == 1}"></span>
                                  <span class="contact-less-icon"></span>
                                  <span class="contact-less-text">{{$t('common.contact_less')}}</span>
                              </label>
                          </div>
                          <div class="block-section">
                              <div class="error-term-block  pb-5 text-right" v-if="errors.term">
                                  <label class="label text-red">{{errors.term}}</label>
                              </div>
                              <div class="flex mb-5 flex-row">
                                  <label for="term" class="label">
                                      <input v-model="customer.term" id="term" class="checkbox" type="checkbox" ref="term" true-value="1" false-value="0">
                                      <span v-bind:class="{'active': customer.term == 1}"></span>
                                  </label>
                                  <label class="label mb-0 flex-center " for="term">
                                      <div v-html="$t('checkout.term_condition', {term: '<a target=\'_blank\' href=\'/terms\'>'+$t('common.footer.term')+'</a>', privacy: '<a target=\'_blank\' href=\'/privacy\'>'+$t('common.footer.privacy')+'</a>'})"></div>
                                  </label>
                              </div>
                              <div>
                                  <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" > {{$t('common.btn.pay')}}</button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
/**/
import HeaderComponent from '../components/HeaderComponent';
import types from '../store/mutationTypes'
import {  mapGetters, mapActions  } from 'vuex'
import CartApi from '../services/cart'
import PaymentApi from '../services/payment'
import ModalASAP from '../components/modal/ModalASAP'
import DeliveryAddressInfo from '../components/shared/DeliveryAddressInfo'
import ModalOrderTime from "../components/modal/ModalOrderTime";
import manthan from '../services/manthan';
var VueScrollTo = require('vue-scrollto');
import branch from '../services/branch';


export default {
  name: "checkout",
  components: {
    HeaderComponent,
    DeliveryAddressInfo,
  },
  data() {
   return {
     contactLess: 1,
     errors: {
       isValid: false,
       name: '',
       note: '',
       phone: '',
       company: '',
       receipt: 0,
       term: 0,
       email: '',
       building: '',
       unitNo: '',
       street: '',
       address: '',
       amountOfChange: '',
       paymentMethod: ''
     },
     payments: [],
     paymentMethod: {},
     paymentMethodType: '',
     deliveryInfo: {
       building: '',
       unitNo: '',
       street: '',
       state: '',
       address: ''
     },
     customer: {
       name: '',
       note: '',
       phone: '',
       company: '',
       receipt: 0,
       term: 0,
       email: ''
     },
     amountOfChange: '',
     pickedTime : ''
   }
  },
  computed: {
    ...mapGetters({
      storeName: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_NAME,
      location: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCATION,
      localisation: 'moduleLocalisation/' + types.LOCALISATION_GET_LOCALISATION,
      orderType: 'moduleLocalisation/' + types.LOCALISATION_GET_ORDER_TYPE,
      cartUuid: 'moduleCart/' + types.CART_GET_CART_UUID,
      cartObject: 'moduleCart/' + types.CART_DETAIL,
      grantTotal: 'moduleCart/' + types.CART_GRANT_TOTAL,
      cartCount: 'moduleCart/' + types.CART_COUNT,
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
      orderUuid:  'moduleOrder/' + types.ORDER_GET_ORDER_UUID,
    })
  },
  destroyed () {
    this.$root.$off('collectionTimeUpdate');
  },
  mounted() {
    this.$root.$on('collectionTimeUpdate', () => {
      this.pickedTime = this.getTime(this)
    })
    if (!(this.cartCount > 0)) {
      this.$router.push({name: 'home'});
    }
    this.pickedTime = this.getTime(this)
    this.deliveryInfo.address = this.$t('common.combine_delivery',{
      buildingName: this.localisation.deliveryInfo ? this.localisation.deliveryInfo.buildingName : '', 
      streetName: this.localisation.deliveryInfo ? this.localisation.deliveryInfo.streetName : '', 
      postalCode: this.localisation.deliveryInfo ? this.localisation.deliveryInfo.postalCode : '', 
      state: this.localisation.deliveryInfo ? this.localisation.deliveryInfo.state : ''
    });
    this.deliveryInfo.unitNo = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.streetNumber : '';
    this.deliveryInfo.building = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.buildingName : '';
    this.deliveryInfo.street = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.streetName : '';
    this.deliveryInfo.state = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.state : '';
    this.deliveryInfo.postalCode = this.localisation.deliveryInfo ? this.localisation.deliveryInfo.postalCode : '';
    this.getPayments();
    this.initCustomerData();
    this.manthanCheckout();
    if (this.orderType !== this.$const.ORDERTYPE.DELIVERY) {
      this.contactLess = 0;
    }
  },
  watch: {
    authUser: function (newValue) {
       if (newValue) {
         this.initCustomerData();
       }
    },
    'amountOfChange': function (newValue, oldValue) {
      this.errors.amountOfChange = false;
      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.$set(this, 'amountOfChange', oldValue)
        return
      }

      if (!newValue) {
        this.errors.amountOfChange = this.$t('message.error.amount_of_change');
      }
    },
    'customer.phone': function (newValue, oldValue) {
      this.errors.phone = false;

      let numbers = /^[-+]?[0-9]+$/
      if (newValue && !newValue.match(numbers)) {
        this.$set(this.customer, 'phone', oldValue)
        return
      }

      if (!newValue) {
        this.errors.phone = this.$t('message.error.phone');
      }else if (newValue.length < this.$const.PHONE.MIN || newValue.length > this.$const.PHONE.MAX) {
        this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX});
      }
    },
    'customer.email': function (newValue) {
      this.errors.email = false;
      if (!newValue) {
        this.errors.email = this.$t('message.error.email');
      }else if (!this.validEmail(newValue)) {
        this.errors.email = this.$t('message.error.invalid_email');
      }
    },
    'customer.name': function (newValue) {
      this.errors.name = false;
      if (!newValue) {
        this.errors.name = this.$t('message.error.name');
      }
    },
    'customer.company': function (newValue) {
      this.errors.company = false;
      if (!newValue) {
        this.errors.company = this.$t('message.error.company');
      }
    },
    'deliveryInfo.building': function (newValue) {
      this.errors.building = false;
      if (!newValue) {
        this.errors.building = '';
      }
    },
    'deliveryInfo.unitNo': function (newValue, oldValue) {
      this.errors.unitNo = false;
      if (!newValue) {
        this.errors.unitNo = '';
      }
    },
    'deliveryInfo.street': function (newValue) {
      this.errors.street = false;
      if (!newValue) {
        this.errors.street = '';
      }
    },
    'deliveryInfo.address': function (newValue) {
      this.errors.address = false;
      if (!newValue) {
        this.errors.address = this.$t('message.error.address');
      }
    },
    'customer.term': function (newValue) {
      this.errors.term = false;
      if (!newValue) {
        this.errors.term = this.$t('message.error.term');
      }
    },
    'paymentMethod': function (newValue) {
      this.errors.amountOfChange = false;
      this.errors.paymentMethod = false;
      if (!newValue) {
        this.errors.paymentMethod = this.$t('message.error.paymentMethod');
      }
    },
    'paymentMethodType': function (newValue) {
      this.errors.amountOfChange = false;
      this.paymentMethod = {};
    }
  },
  methods: {
    ...mapActions({
      restLocalisation:  'moduleLocalisation/' + types.LOCALISATION_RESET_STORE,
      resetCart: 'moduleCart/' +  types.CART_RESET_DATA
    }),
    initCustomerData () {
      if (this.isAuth) {
        this.customer.note = this.localisation.hasOwnProperty('note') ? this.localisation.note : '';
        this.customer.email = this.authUser.email;
        this.customer.name = this.authUser.name || (this.authUser.first_name + ' ' + this.authUser.last_name);
        this.customer.phone = this.getPhoneWithoutPrefix(this.authUser.phone, this.$const.PREFIX_PHONE);
      }
    },
    initErrorMessage () {
      this.errors = {
        isValid: false,
        name: false,
        note: false,
        phone: false,
        company: false,
        receipt: false,
        term: false,
        email: false,
        building: false,
        unitNo: false,
        street: false,
        address: false,
        amountOfChange: false,
        paymentMethod: false
      }
    },
    sortPayment(payments) {
      let tmpPayment = [];
      for (let i in payments) {
        let paymentType = {
          name: payments[i].name,
          code: payments[i].code,
          uuid: payments[i].uuid,
          items: [],
        };
        let itemOther = {};
        for (let j in payments[i].items) {
          if (payments[i].items[j].name !== 'Others') {
            paymentType.items.push(payments[i].items[j]);
          } else {
            itemOther = payments[i].items[j];
          }
        }
        if (!this.emptyVariable(itemOther)) {
          paymentType.items.push(itemOther);
        }
          if (!this.localisation.is_qr_code) {
            if((paymentType.code === 'ONLINE_PAYMENT' && this.grantTotal > 0) || paymentType.code !== 'ONLINE_PAYMENT'){
              tmpPayment.push(paymentType)
            }
          } else {
              if ((paymentType.code === 'ONLINE_PAYMENT' && this.grantTotal > 0) || paymentType.code === 'PAYMENT_IN_STORE') {
                  tmpPayment.push(paymentType)
              }
          }
      }
      this.payments = tmpPayment;
      for (let i in this.payments) {
        this.paymentMethodType = this.payments[i];
        break
      }
    },
    getPayments () {
      var vm = this;
      CartApi.getPayments({channel: 2, vm: this, type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)]},function (response) {
        vm.sortPayment(response.data);
      })
    },
    checkForm(e) {
      e.preventDefault();
      let isValidForm = true;
      this.initErrorMessage();
      if (this.orderType !== this.$const.ORDERTYPE.DINEIN) {
        if (!this.customer.name.trim()) {
          this.errors.name = this.$t('message.error.name');
          if (isValidForm) {
            this.$refs.name.focus();
          }
          isValidForm = false;
        }
        if (!this.customer.email) {
          this.errors.email = this.$t('message.error.email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        } else if (!this.validEmail(this.customer.email)) {
          this.errors.email = this.$t('message.error.invalid_email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        }

        if (!this.customer.phone) {
          this.errors.phone = this.$t('message.error.phone');
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        } else if (this.customer.phone.length < this.$const.PHONE.MIN || this.customer.phone.length > this.$const.PHONE.MAX) {
          this.errors.phone = this.$t('message.error.invalid_phone', {min: this.$const.PHONE.MIN, max: this.$const.PHONE.MAX})
          if (isValidForm) {
            this.$refs.phone.focus();
          }
          isValidForm = false;
        }
        if (this.orderType === this.$const.ORDERTYPE.DELIVERY) {
          if (this.paymentMethod.code == this.$const.PAYMENT_METHOD.CASH && this.contactLess == '1') {
            if (!this.amountOfChange) {
              this.errors.amountOfChange = this.$t('message.error.amount_of_change');
              if (isValidForm) {
                // this.$refs.amount_of_change.focus();
              }
              isValidForm = false;
            }
          }
        /* if (!this.deliveryInfo.building) {
            this.errors.building = this.$t('message.error.building');
            if (isValidForm) {
              this.$refs.building.focus();
            }
            isValidForm = false;
          }*/
          /*if (!this.deliveryInfo.unitNo) {
            this.errors.unitNo = this.$t('message.error.unitNo');
            if (isValidForm) {
              this.$refs.unitNo.focus();
            }
            isValidForm = false;
          }
          if (!this.deliveryInfo.street) {
            this.errors.street = this.$t('message.error.street');
            if (isValidForm) {
              this.$refs.street.focus();
            }
            isValidForm = false;
          }*/
          /*if (!this.deliveryInfo.address) {
            this.errors.address = this.$t('message.error.address');
            if (isValidForm) {
              this.$refs.address.focus();
            }
            isValidForm = false;
          }*/
        }

        if (this.customer.receipt && this.customer.term !== '0') {
          if (!this.customer.company) {
            this.errors.company = this.$t('message.error.company');
            if (isValidForm) {
              this.$refs.company.focus();
            }
            isValidForm = false;
          }
        }
        if (this.emptyVariable(this.paymentMethod)) {
          this.errors.paymentMethod = this.$t('message.error.paymentMethod');
          if (isValidForm) {
            VueScrollTo.scrollTo('.error-payment-block', 0.5)
          }
          isValidForm = false;
        }
      }
      if (!this.customer.term || this.customer.term === '0') {
        this.errors.term = this.$t('message.error.term');
        if (isValidForm) {
          VueScrollTo.scrollTo('.error-term-block', 0.5)
        }
        isValidForm = false;
      }
      
      if (isValidForm) {
        this.placeOrder();
      }
    },
    clearCart () {
      this.restLocalisation();
      this.resetCart();
    },
    placeOrder(){
      var vm  = this;
      var payload = {
        "client_uuid": "b39773b0-435b-4f41-80e9-163eef20e0ab",
        "payment_method_code": vm.paymentMethod.code,
        "order_uuid": vm.orderUuid,
        "note": "",
      }

    payload.grantTotal = vm.grantTotal
    PaymentApi.paymentPos(payload,
        function (response) {
          // check redirect
          if (vm.paymentMethod.code == vm.$const.PAYMENT_METHOD.BCA) {
            let key = 'payment-bca-'  + response.data.transaction_uuid;
            let paymentData = {
              total: vm.grantTotal,
              code: response.data.payment_data
            };
            localStorage.setItem(key, JSON.stringify(paymentData));
            vm.$router.push({name:'payment-status-bca', params: {uuid: response.data.transaction_uuid}});
          } else if (vm.paymentMethod.code == vm.$const.PAYMENT_METHOD.DOKU) {
            vm.submitDokuWallet(response.data);
          } else if (response.data.payment_url) {
            window.location = response.data.payment_url
          } else {
            vm._eventCheckout('eventTransactionComplete', payload, response.data)
            if (this.orderType !== this.$const.ORDERTYPE.DINEIN) {
              vm.clearCart();
            }
            vm.$router.push({name:'order-confirm', params: {uuid: vm.orderUuid}})
          }
        }, function (data) {
          vm.notificationError(vm, data.message)
        }
      )
    },
    submitDokuWallet(payload) {
      let form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("name", "formDoku");
      form.setAttribute("action", payload.payment_url);
      let formData = JSON.parse('{"' + payload.payment_data.replace(/&/g, '","').replace(/=/g,'":"') + '"}', function(key, value) { return key===""?value:decodeURIComponent(value) })
      for (let key in formData) {
        let hiddenField = document.createElement("input");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", formData[key]);
        form.appendChild(hiddenField);
      }

      document.body.appendChild(form);
      form.submit();
    },
      showAsapPopup(){
          this.$modal.show(
              ModalOrderTime,null,
              {
                  classes:(this.isMobile()) ? 'modal-order-time' : ''
              }
          )
      },
    _eventCheckout(event, payload, data = {}) {
      //function for manthan
      let dataManthanCheckout = {}
      const cartProducts = [];
      const info = {
        firstName: this.authUser.first_name,
        lastName: this.authUser.last_name,
        line1: payload.delivery.address_level_1,
        line2: payload.delivery.address_level_2,
        city: payload.delivery.city,
        state: payload.delivery.address_level_3,
        zip: payload.delivery.postal_code,
        country: "Indonesia",
        emailAddress: payload.email,
        phoneNumber: payload.phone
      };
      let totalDiscount = 0;
      let couponCode = "";
      if (this.cartObject.products.length > 0) {
        for (const [index, item] of this.cartObject.products.entries()) {
          totalDiscount += item.discount
          for (const value of item.product) {
            cartProducts.push({
              productId: value.uuid,
              productPosition: index + 1,
              action: "Checkout",
              quantity: item.quantity,
              productBasePrice: (item.quantity > 0 ) ? item.sub_price * item.quantity : item.sub_price,
              tax:(item.quantity > 0 ) ? item.quantity * item.tax : item.tax,
              discount: item.discount,
              couponCode: item.promotion_code,
            });
          }
        }
      }

      if (this.cartObject.promotions.length > 0) {
        for (const [index, item] of this.cartObject.promotions.entries()) {
          if (index == 0) {
            couponCode = item.code;
          } else {
            couponCode += ',' + item.code;
          }
        }
      }

      if (event === 'eventCheckout') {
        dataManthanCheckout = {
          transactionId: '',
          referrerPageUrl: window.location.href,
          tenderType: payload.payment.name ? payload.payment.name : '',
          checkoutType: this.isAuth ? 'Logged in by ' + this.authUser.email : 'Guest',
          totalPrice: payload.grantTotal,
          shippingCost: this.cartObject.information.delivery_fee > 0 ? this.cartObject.information.delivery_fee : 0,
          discount: totalDiscount,
          couponCode: couponCode,
          cartProducts: cartProducts,
          billingInfo: info,
          shippingInfo: info,
          cartId: payload.cart_uuid,
        }
        branch.branchLog('INITIATE_PURCHASE', this.cartObject.products)
      }

      if (event ===  'eventTransactionComplete') {
        dataManthanCheckout = {
          transactionId: data.transaction_uuid,
          referrerPageUrl: window.location.href,
          orderId: data.code,
          tenderType: payload.payment.name ? payload.payment.name : '',
          checkoutType: this.isAuth ? 'Logged in by ' + this.authUser.email : 'Guest',
          totalPrice: payload.grantTotal,
          shippingCost: this.cartObject.information.delivery_fee > 0 ? this.cartObject.information.delivery_fee : 0,
          discount: totalDiscount,
          couponCode: couponCode,
          cartProducts: cartProducts,
          billingInfo: info,
          shippingInfo: info,
          cartId: payload.cart_uuid,
        }
        branch.branchLog('PURCHASE', this.cartObject.products, dataManthanCheckout)
      }
      // eslint-disable-next-line no-undef
      manthan.execute(dataManthanCheckout,event)
    },
    manthanCheckout(){
      var vm  = this;
      var payload = {
        "cart_uuid": this.cartUuid,
        "company": this.customer.company,
        "delivery": {
          "address_level_0": this.deliveryInfo.building,
          "address_level_1": this.deliveryInfo.unitNo,
          "address_level_2": this.deliveryInfo.street,
          "address_level_3": this.deliveryInfo.state,
          "full_address": this.deliveryInfo.address,
          "lat": this.localisation.lat,
          "long": this.localisation.long,
          "postal_code": this.deliveryInfo.postalCode,
          "city": this.localisation.deliveryInfo ? this.localisation.deliveryInfo.city : ''
        },
        "email": this.isAuth ? this.authUser.email : this.customer.email,
        "name": this.customer.name,
        "note": this.customer.note,
        "contact_less": this.orderType === this.$const.ORDERTYPE.DELIVERY && this.contactLess == 1  ? '1' : '0',
        "payment": {
          "code": this.paymentMethod.code,
          "name": this.paymentMethod.name,
          "note": '',
          "uuid": this.paymentMethod.uuid
        },
        "phone": this.customer.phone,
        "receipt": this.customer.receipt ? 1 : 0,
        "is_qr_code": (this.localisation.is_qr_code) ? this.localisation.is_qr_code : 0,
        "vm": this
      }

      if (this.paymentMethod.code == this.$const.PAYMENT_METHOD.CASH && this.amountOfChange != '') {
        payload.payment_expected = this.amountOfChange * 1;
      }
      payload.grantTotal = vm.grantTotal
      vm._eventCheckout('eventCheckout', payload)
    }
  }
}
</script>
<style scoped>
  .login-account {
      background-color: #f6f7f9;
      padding: 20px;
  }
  .contact-less-icon::before {
      content: "";
      background-image: url(../assets/images/PHD_contactless_20200320.png);
      background-size: 35px;
      background-repeat: no-repeat;
      display: inline-block;
      height: 40px;
      width: 40px;
      margin-right: 5px;
  }
  label.contact-less  {
      justify-content: flex-start !important;
  }
</style>
