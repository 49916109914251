<template>
    <div>
        <header-component />
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{$t('common.set_new_password')}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white">
                    <div class="md:flex">
                        <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" action="" @submit="checkFormInputPassword" v-if="tokenKey">
                            <div class="block-section">
                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="password">{{$t('customer.password')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.password">{{errors.password}}</span>
                                        </label>
                                    </div>
                                    <input id="password" class="input xs-mb-10" ref="password" v-bind:class="{'has-error': errors.password, 'has-success': errors.password === false}"
                                           name="password" v-model="password"
                                           maxlength="255" type="password">
                                </div>
                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="passwordConfirm">{{$t('customer.password_confirm')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.passwordConfirm">{{errors.passwordConfirm}}</span>
                                        </label>
                                    </div>
                                    <input id="passwordConfirm" class="input xs-mb-10" ref="password_confirm" v-bind:class="{'has-error': errors.passwordConfirm, 'has-success': errors.passwordConfirm === false}"
                                           name="passwordConfirm" v-model="passwordConfirm"
                                           maxlength="255" type="password">
                                </div>
                                <div>
                                    <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" > {{$t('common.btn.save')}}</button>
                                </div>
                            </div>
                        </form>
                        <div class="t15 text-red bold text-center w-full" v-else>
                            {{$t('common.please_contact_with_administrator')}}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
  import HeaderComponent from '../components/HeaderComponent';
  import CustomerApi from '../services/customer';
  import manthan from "../services/manthan";

  export default {
    name: "set-new-password",
    components: {
      HeaderComponent,
    },
    data() {
     return {
       tokenKey: '',
       password: '',
       passwordConfirm: '',
       errors: {
         isValid: false,
         password: '',
         passwordConfirm: '',
       }
     }
    },
    computed: {
    },
    mounted() {
        this.tokenKey = this.$route.params.token || '';
    },
    watch: {
      'password': function (newValue) {
        this.errors.password = false;
        if (!newValue) {
          this.errors.password = this.$t('message.error.password');
        }
      },
      'passwordConfirm': function (newValue) {
        this.errors.passwordConfirm = false;
        if (!newValue) {
          this.errors.passwordConfirm = this.$t('message.error.password_confirm');
        }else if (this.passwordConfirm !== this.password) {
          this.errors.passwordConfirm = this.$t('message.error.password_confirm_not_match');
        }
      }
    },
    methods: {
      initErrorMessage (mode) {
        if (mode && mode === 'inputPass') {
          this.errors = {
            isValid: false,
            password: '',
            passwordConfirm: '',
          }
        } else {
          this.errors = {
            isValid: false,
            password: false,
            passwordConfirm: false,
          }
        }
      },
      checkFormInputPassword(e) {
        e.preventDefault();
        let isValidForm = true;
        this.initErrorMessage();

        if (!this.password) {
          this.errors.password = this.$t('message.error.password');
          if (isValidForm) {
            this.$refs.password.focus();
          }
          isValidForm = false;
        }

        if (!this.passwordConfirm) {
          this.errors.passwordConfirm = this.$t('message.error.password_confirm');
          if (isValidForm) {
            this.$refs.password_confirm.focus();
          }
          isValidForm = false;
        }else if (this.passwordConfirm !== this.password){
          this.errors.passwordConfirm = this.$t('message.error.password_confirm_not_match');
          if (isValidForm) {
            this.$refs.password_confirm.focus();
          }
          isValidForm = false;
        }

        if (isValidForm) {
          this.resetPassword();
        }
      },
      resetPassword() {
        var vm = this;
        let payload = {
          "key": this.tokenKey,
          "password": this.password,
          "confirm_password": this.password,
          "vm": this
        };
        CustomerApi.resetPasswordByEmail(payload, function (response) {
          vm.notificationSuccess(vm, vm.$t('message.success.reset_password'));
          const dataManthan = {
            "forgotPwdPrimaryLink": vm.$route.path,
            "forgotPwdSecondaryLink": vm.$route.path,
            "newPwd": vm.password
          }
          // eslint-disable-next-line no-undef
          manthan.execute(dataManthan,"eventForgotPassword");
          vm.$router.push({name: 'login'})
        } ,function (error) {
          vm.notificationError(vm, error.message);
        })
      }
    }
  }
</script>
