<template>
  <div class="bg-white">
    <header-component v-if="!isMobile()"/>
    <div class="relative z-600" style="box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 5px 0px;">
      <div v-if="isMobile()" class="container flex flex-center justify-between relative h-full">
        <img style="width: 100%" src="../assets/images/ph_reward_nav.jpeg" alt="">
        <button @click="$router.push({name: 'ph-reward-detail'})" class="flex items-center text-grey absolute pr-20 top-0 bottom-0 right-0">
          <em class="ml-5 icon-info"></em>
        </button>
      </div>
    </div>
    <div v-if="isMobile()" class="container mt-20 mb-20">
      <div v-if="!isAuth" class="font-open-sans-bold text-left relative mb-20 ml-10">
        <span class="px-10" style="font-size: 16px;"><router-link :to="{name: 'login'}">Login/SignUp</router-link></span>
      </div>
      <div v-else class="container mt-20 mb-20">
        <div class="flex-row flex flex-center pl-20 pr-10">
          <div class="flex-1 font-open-sans-bold">
            <div style="font-style: normal; font-weight: 600; font-size: 10px; color: #7D8084">Welcome</div>
            <div>{{authUser.name}}</div>
          </div>
          <img src="../assets/images/hut_reward_circle.png" alt=""><div class="px-10">{{authUser.point}}</div>
        </div>
      </div>
    </div>
    <div class="mb-20">
      <div v-if="!isMobile()" class="relative">
        <img src="../assets/images/Banner_Benefit_Web.jpg" alt="" style="width: 100%">
      </div>
      <reward-level-section :is-auth="isAuth" :auth-user="authUser" />
      <div v-if="isMobile()" class="relative mobile-banner cursor-pointer" >
        <img src="../assets/images/Banner_Benefit_Web_mobile.png" alt="Banner_Benefit_Web_mobile" @click="$router.push({name: 'ph-reward-detail'})"
        >
      </div>
    </div >
    <div v-if="authUser.point > 20" :class="`container mt-20${isMobile() ? 'mb-0' : 'mb-40'}`">
      <list-redeem/>
    </div>
    <div class="container mt-20 mb-40">
      <my-voucher-component :is-auth="isAuth" :store-uuid="storeUuid"/>
    </div >
    <mobile-footer-component v-if="isMobile()"></mobile-footer-component>
  </div>
</template>

<script>
import MobileFooterComponent from '@/components/MobileFooterComponent'
import RewardLevelSection from '@/components/shared/RewardLevelSection'
import {mapGetters} from 'vuex'
import types from '@/store/mutationTypes'
import ListRedeem from "@/components/shared/ListRedeem";
import MyVoucherComponent from "@/components/shared/MyVoucherComponent";
import HeaderComponent from "@/components/HeaderComponent";
export default {
  name: "PhReward",
  components: {HeaderComponent, MyVoucherComponent, ListRedeem, MobileFooterComponent, RewardLevelSection},
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
      storeUuid: 'moduleLocalisation/' + types.LOCALISATION_GET_STORE_UUID,
    }),
  },
  methods: {
  }
}
</script>

<style scoped>
  .know-more {
    display: flex;
    position: absolute;
    top: 35%;
    left: 30%;
    width: 168px;
    height: 68px;
    background-color: #C8102E;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .know-more > p {
    font-size: 20px;
    font-weight: 600;
    color: #FFFFFF;
  }
  .mobile-banner {
    margin-top: 8px;
  }
  .mobile-banner > img {
    margin: 0 auto;
    width: 90%;
    min-width: 350px;
    height: auto;
    border-radius: 8px;
  }
  @media screen and (max-width: 800px) {
    .know-more {
      top: 55%;
      left: 11%;
      width: 90px;
      height: 36px;
    }
    .know-more > p {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 360px) {
    .know-more {
      top: 55%;
      left: 7.5%;
      width: 90px;
      height: 36px;
    }
    .know-more > p {
      font-size: 12px;
    }
  }
</style>
