<template>
    <input
        :class="className"
        :id="id" :placeholder="placeholder"
        ref="inputAutocomplete"
        type="text"
        v-model="autoCompleteSearch"
    />
</template>

<script>
    const ADDRESS_COMPONENTS_ALLOW = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        administrative_area_level_1: 'short_name',
        administrative_area_level_2: 'long_name',
        country: 'long_name',
        postal_code: 'short_name'
    };

    export default {
        name: 'GoogleAutocomplete',

        props: {
            id: {
                type: String,
                required: true
            },
            className: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: 'Input your address'
            },
            allowFields: {
                type: Array,
                default: null
            },
            country: {
                type: [String],
                default: null
            }
        },

        data() {
            return {
                addressAutoComplete: null,
                autoCompleteSearch: '',
            }
        },

        watch: {
            autoCompleteSearch: function (newVal, oldVal) {
                this.$emit('inputChangeAutoComplete', {newVal, oldVal}, this.id);
            }
        },

        mounted: function () {
            const options = {};
            if (this.allowFields) {
                options.fields = this.allowFields
            }
            if (this.country) {
                options.componentRestrictions = {
                    country: this.country
                };
            }

            this.addressAutoComplete = new google.maps.places.Autocomplete(
                document.getElementById(this.id),
                options
            );

            this.addressAutoComplete.addListener('place_changed', this.onPlaceChanged);
        },

        methods: {
            generateData(place) {
                let result = {
                    'latitude': place.geometry.location.lat(),
                    'longitude': place.geometry.location.lng()
                };
                place.address_components.map(e => {
                    let _types = e.types[0];
                    if (ADDRESS_COMPONENTS_ALLOW[_types]) {
                        let _val = e[ADDRESS_COMPONENTS_ALLOW[_types]];
                        result[_types] = _val;
                    }
                });
                return result
            },
            onPlaceChanged() {
                let place = this.addressAutoComplete.getPlace();
                if (!place.geometry) {
                    return;
                }
                if (place.address_components !== undefined) {
                    this.$emit('placechanged', this.generateData(place), place, this.id);
                    this.autoCompleteSearch = document.getElementById(this.id).value
                }
            },
            update(value) {
                this.autoCompleteSearch = value
            }
        }
    }
</script>
