import types from '../../mutationTypes'
import CustomerAPI from "../../../services/customer";
import manthan from "../../../services/manthan";
import moment from 'moment-timezone'

export default {
    [types.CUSTOMER_SET_TOKEN]({commit, state}, payload) {
        commit(types.CUSTOMER_SET_TOKEN, payload)
    },
    [types.CUSTOMER_SET_INFO]({commit, state}, payload) {
        commit(types.CUSTOMER_SET_INFO, payload)
    },
    [types.CUSTOMER_LOGOUT]({commit, state}) {
        // call api
        CustomerAPI.logout(function (response) {
            let now = new Date();
            let dateLogout = moment(now).format('YYYY-MM-DD HH:MM')
            const dataManthan = {
                "logout": dateLogout
            }
            manthan.execute(dataManthan,"eventLogout");
            commit(types.CUSTOMER_LOGOUT, {info: {}, token: ''})
        }, function (error) {
            commit(types.CUSTOMER_LOGOUT, {info: {}, token: ''})
        })
    },
    [types.CUSTOMER_GET_INFO_BY_TOKEN]({commit, state}, payload) {
        // call api
        CustomerAPI.profile(function (response) {
            commit(types.CUSTOMER_SET_INFO, response.data)
        }, function (error) {
            commit(types.CUSTOMER_SET_INFO, {})
        })
    }
}