export default  {
    execute (payload,eventName) {
        // if (window.targetOne) {
        //     window.targetOne.executeEventByData(payload,eventName);
        //     return true
        // }
    },
    setCustomer (payload) {
        // if (window.targetOne) {
        //     window.targetOne.setCustomer(payload);
        //     return true
        // }
    }
}