import types from "./mutationTypes";

export default {
  [types.SET_LANG]({commit}, payload) {
    commit(types.SET_LANG, payload)
  },
  [types.SET_CURRENT_CATEGORY]({commit}, payload) {
    commit(types.SET_CURRENT_CATEGORY, payload)
  },
  [types.SET_MENU_CATEGORY_LIST]({commit}, payload) {
    commit(types.SET_MENU_CATEGORY_LIST, payload)
  },
  [types.DE_CREATE_LOADING]({commit}, payload) {
    commit(types.DE_CREATE_LOADING, payload)
  },
  [types.IN_CREATE_LOADING]({commit}) {
    commit(types.IN_CREATE_LOADING)
  },
  [types.HIDE_LOADING]({commit}) {
    commit(types.HIDE_LOADING)
  },
  [types.SET_DEFAULT_CATEGORY]({commit}, payload) {
    commit(types.SET_DEFAULT_CATEGORY, payload)
  },
}