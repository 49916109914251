<template>
    <div class=" en-GB app-bg none-top-banner">
        <div class="container mb-10" v-if="!emptyVariable(menu)">
            <div class="p-20 bg-white" v-if="menu.slug == 'merdeka-2-pizza-regular-75-ribu'" >
                <img v-if="!isMobile()" src="../../assets/images/pizza16x9.png" :alt="menu.name" :title="menu.name" class="cursor-pointer" @click="handleClick">
                <img v-else src="../../assets/images/pizza-9x16.png" :alt="menu.name" :title="menu.name" class="cursor-pointer" @click="handleClick">
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'beef-rendang-pizza-cuma-69-ribu'" >
                <img v-if="!isMobile()" src="../../assets/images/Promo-Rendang-App-Desktop-Aesthetic-Page.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Promo-Rendang-App-Mobile-Aesthetic-Page.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'heboh_double_box_special_price'" >
                <img v-if="!isMobile()" src="../../assets/images/Doublebox-Heboh-Aesthetic-Page-desktop.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Doublebox-Heboh-App-Mobile-Aesthetic-Page.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'beli-1-gratis-1-pasta'" >
                <img v-if="!isMobile()" src="../../assets/images/BOGO-Pasta-28-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/BOGO-Pasta-28-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'terserdia-dalam-3-pilihan-topping-favorit'" >
                <img v-if="!isMobile()" src="../../assets/images/terserdia-desktop.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/terserdia-mobile.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'lucky-99-promo'" >
                <img v-if="!isMobile()" src="../../assets/images/Lucky-99-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Lucky-99-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'beli-pizza-diskon-50-untuk-pizza'" >
                <img v-if="!isMobile()" src="../../assets/images/Disc50-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Disc50-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'beli_1_dapet_2_pasta'" >
                <img v-if="!isMobile()" src="../../assets/images/beli_1_dapet_2_pasta_desktop.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/beli_1_dapet_2_pasta_mobile.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'beli_4_pizza_heboh_cuma_50_ribu'" >
                <img v-if="!isMobile()" src="../../assets/images/4Heboh-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/4Heboh-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'birthday_treat_menu_serba_13'" >
                <img v-if="!isMobile()" src="../../assets/images/13rb-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/13rb-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'wingstreet_tiap_jumat_cuma_5_ribu'" >
                <img v-if="!isMobile()" src="../../assets/images/Tjuan-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Tjuan-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'potongan_50_ribu_setiap_delivery'" >
                <img v-if="!isMobile()" src="../../assets/images/Potongan-50-Ribu-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Potongan-50-Ribu-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'cobain_krakatau_burst_pizza_dapetin_menu_serba_13rb'" >
                <img v-if="!isMobile()" src="../../assets/images/Krakatau-Burst-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Krakatau-Burst-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'makan-ayam-cuma-5-ribuan'" >
                <img v-if="!isMobile()" src="../../assets/images/Wings-Nov-Aesthethic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Wings-Nov-Aesthethic-Page-Mobile-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'potongan-50-ribu-setiap-delivery'" >
                <img v-if="!isMobile()" src="../../assets/images/disc50nov-aespg-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/disc50nov-aespg-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'super-murah-4-pizza-cuma-50-ribu'" >
                <img v-if="!isMobile()" src="../../assets/images/4pizza50k-nov-aesthetic-page-desktop-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/4pizza50k-nov-aesthetic-page-mobile-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'chicken-wings-5-ribu-aja'" >
                <img v-if="!isMobile()" src="../../assets/images/wings-16nov-aespg-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/wings-16nov-aespg-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'hemat-kenyang-4-pizza-cuma-50-ribu'" >
                <img v-if="!isMobile()" src="../../assets/images/4pizza50k-16nov-aespg-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/4pizza50k-16nov-aespg-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'beli-krakatau-burst-pizza-gratis-tuna-melt'" >
                <img v-if="!isMobile()" src="../../assets/images/BOGO-Krakatau-Burst-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/BOGO-Krakatau-Burst-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'super-hemat-4-pizza-heboh-cuma-50-ribu'" >
                <img v-if="!isMobile()" src="../../assets/images/1600x485-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/900x824-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'chicken-wingstreet-cuma-5-ribuan'" >
                <img v-if="!isMobile()" src="../../assets/images/Chicken1600x485-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Chicken900x824-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'pizza-terHEBOH-hemat-banget-loh'" >
                <img v-if="!isMobile()" src="../../assets/images/Pizza-Terheboh-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Pizza-Terheboh-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'gratis-tuna-melt-setiap-neli-big-box'" >
                <img v-if="!isMobile()" src="../../assets/images/bogo-30nov-aespg-Dnew.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/bogo-30nov-aespg-Mnew.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'krakatau-burst-pizza'" >
                <img v-if="!isMobile()" src="../../assets/images/Krakatau-Burst-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Krakatau-Burst-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'beli-big-box-gratis-tuna-melt'" >
                <img v-if="!isMobile()" src="../../assets/images/12.12-Tuna-Aespg-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/12.12-Tuna-Aespg-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'beli-2-dapat-3-pizza'" >
                <img v-if="!isMobile()" src="../../assets/images/Buy2Get3-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Buy2Get3-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'year-end-promo'" >
                <img v-if="!isMobile()" src="../../assets/images/Year-End-Promo-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Year-End-Promo-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'year-end-promo-makin-hemat-doublebox-kini-cuma'" >
                <img v-if="!isMobile()" src="../../assets/images/Doublebox-113rb-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Doublebox-113rb-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'payday-promo'" >
                <img v-if="!isMobile()" src="../../assets/images/1600x482-payday.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/900x825-payday.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'promo-khusus-delivery'" >
                <img v-if="!isMobile()" src="../../assets/images/1500x582-mybox-pasta.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/mybox-pasta-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'tersedia-pilihan-pasta-di-my-box'" >
                <img v-if="!isMobile()" src="../../assets/images/Mybox-Pasta-Aesthetic-Page-1600x485.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Mybox-Pasta-Mobile-Aesthetic-Page-900x824.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'promo-super-cuan-2-pizza-hanya-88-ribu'" >
                <img v-if="!isMobile()" src="../../assets/images/AestheDesk.jpeg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/AestheMob.jpeg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'kini-tersedia-3-paket-my-box'" >
                <img v-if="!isMobile()" src="../../assets/images/AestheDesk2.jpeg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/AestheMob2.jpeg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'double-box-classic-hanya-88-ribu'" >
                <img v-if="!isMobile()" src="../../assets/images/1600x482-88rb-pair.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/900x824-88rb-pair.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'upgrade-black-meat-monsta-dengan-cruncheeze'" >
                <img v-if="!isMobile()" src="../../assets/images/Free-Upgrade-Cruncheeze-AesPg-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/Free-Upgrade-Cruncheeze-AesPg-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'beli-1-my-box-gratis-1-pizza'" >
                <img v-if="!isMobile()" src="../../assets/images/5-Heboh-AesPg-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/5-Heboh-AesPg-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == '1-pizzanya-cuma-44-ribu-aja'" >
                <img v-if="!isMobile()" src="../../assets/images/DB-44rb-AesPg-D.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/DB-44rb-AesPg-M.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
            <div class="p-20 bg-white" v-if="menu.slug == 'dinein-cheeseisland'" >
                <img v-if="!isMobile()" src="../../assets/images/cheeseisland_1600x482.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer">
                <img v-else src="../../assets/images/cheeseisland_900x825.jpg" :alt="menu.name" :title="menu.name" class="cursor-pointer" >
            </div>
        </div>
        <div class="container pt-4">
            <div class="font-open-sans-bold uppercase text-center relative">
                <h1 class="px-40 t20">{{menu.name}}</h1>
                <h2 class=" t22 " v-if="menu.sub_name">{{menu.sub_name}}</h2>
            </div>
        </div>
        <localisation-box v-if="$route.params.slug !== 'dinein-cheeseisland'"></localisation-box>
        <div class="px-10 pb-30 container" v-else>
            <button class="button button--red-micro button--m mb-20" @click="viewAllOutlet()">
                <span class="button-ie-fix-56">
                    {{$t('common.btn.view_all_outlet')}}
                </span>
            </button>
        </div>
    </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import mutationTypes from "../../store/mutationTypes";
  import LocalisationBox from "../../components/shared/LocalisationBox";
  /* eslint-disable */
  export default {
    name: "offers",
    components: {
        LocalisationBox
    },
    data() {
      return {
        menu: {},
        menus: [
            {
                'name': 'PESAN SEKARANG',
                'slug': 'merdeka-2-pizza-regular-75-ribu',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'pizza-9x16.png',
                    'desktop': 'pizza16x9.png'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'beef-rendang-pizza-cuma-69-ribu',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Promo-Rendang-App-Desktop-Aesthetic-Page.jpg',
                    'desktop': 'Promo-Rendang-App-Mobile-Aesthetic-Page.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'heboh_double_box_special_price',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Doublebox-Heboh-App-Mobile-Aesthetic-Page.jpg',
                    'desktop': 'Doublebox-Heboh-Aesthetic-Page-desktop.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'beli-1-gratis-1-pasta',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'BOGO-Pasta-28-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'BOGO-Pasta-28-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'terserdia-dalam-3-pilihan-topping-favorit',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'terserdia-mobile.jpg',
                    'desktop': 'terserdia-desktop.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'lucky-99-promo',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Lucky-99-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'Lucky-99-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'beli-pizza-diskon-50-untuk-pizza',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Disc50-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'Disc50-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'beli_1_dapet_2_pasta',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'beli_1_dapet_2_pasta_mobile.jpg',
                    'desktop': 'beli_1_dapet_2_pasta_desktop.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'beli_4_pizza_heboh_cuma_50_ribu',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': '4Heboh-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': '4Heboh-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'birthday_treat_menu_serba_13',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': '13rb-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': '13rb-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'wingstreet_tiap_jumat_cuma_5_ribu',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Tjuan-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'Tjuan-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'potongan_50_ribu_setiap_delivery',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Potongan-50-Ribu-Aesthetic-Page-1600x485.jpg',
                    'desktop': 'Potongan-50-Ribu-Mobile-Aesthetic-Page-900x824.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'cobain_krakatau_burst_pizza_dapetin_menu_serba_13rb',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Krakatau-Burst-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'Krakatau-Burst-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'makan-ayam-cuma-5-ribuan',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Wings-Nov-Aesthethic-Page-Mobile-900x824.jpg',
                    'desktop': 'Wings-Nov-Aesthethic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'potongan-50-ribu-setiap-delivery',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'disc50nov-aespg-M.jpg',
                    'desktop': 'disc50nov-aespg-D.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'super-murah-4-pizza-cuma-50-ribu',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': '4pizza50k-nov-aesthetic-page-mobile-900x824.jpg',
                    'desktop': '4pizza50k-nov-aesthetic-page-desktop-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'chicken-wings-5-ribu-aja',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'wings-16nov-aespg-M.jpg',
                    'desktop': 'wings-16nov-aespg-D.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'hemat-kenyang-4-pizza-cuma-50-ribu',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': '4pizza50k-16nov-aespg-M.jpg',
                    'desktop': '4pizza50k-16nov-aespg-D.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'beli-krakatau-burst-pizza-gratis-tuna-melt',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'BOGO-Krakatau-Burst-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'BOGO-Krakatau-Burst-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'super-hemat-4-pizza-heboh-cuma-50-ribu',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': '900x824-M.jpg',
                    'desktop': '1600x485-D.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'chicken-wingstreet-cuma-5-ribuan',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Chicken900x824-M.jpg',
                    'desktop': 'Chicken1600x485-D.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'pizza-terHEBOH-hemat-banget-loh',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Pizza-Terheboh-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'Pizza-Terheboh-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'gratis-tuna-melt-setiap-neli-big-box',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'bogo-30nov-aespg-Mnew.jpg',
                    'desktop': 'bogo-30nov-aespg-Dnew.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'krakatau-burst-pizza',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Krakatau-Burst-M.jpg',
                    'desktop': 'Krakatau-Burst-D.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'beli-big-box-gratis-tuna-melt',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': '12.12-Tuna-Aespg-M.jpg',
                    'desktop': '12.12-Tuna-Aespg-D.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'beli-2-dapat-3-pizza',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Buy2Get3-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'Buy2Get3-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'year-end-promo',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Year-End-Promo-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'Year-End-Promo-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'year-end-promo-makin-hemat-doublebox-kini-cuma',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Doublebox-113rb-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'Doublebox-113rb-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'payday-promo',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': '900x825-payday.jpg',
                    'desktop': '1600x482-payday.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'promo-khusus-delivery',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'mybox-pasta-900x824.jpg',
                    'desktop': '1500x582-mybox-pasta.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'tersedia-pilihan-pasta-di-my-box',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Mybox-Pasta-Mobile-Aesthetic-Page-900x824.jpg',
                    'desktop': 'Mybox-Pasta-Aesthetic-Page-1600x485.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'promo-super-cuan-2-pizza-hanya-88-ribu',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'AestheMob.jpeg',
                    'desktop': 'AestheDesk.jpeg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'kini-tersedia-3-paket-my-box',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'AestheMob2.jpeg',
                    'desktop': 'AestheDesk2.jpeg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'double-box-classic-hanya-88-ribu',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': '900x824-88rb-pair.jpg',
                    'desktop': '1600x482-88rb-pair.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'upgrade-black-meat-monsta-dengan-cruncheeze',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Free-Upgrade-Cruncheeze-AesPg-M.jpg',
                    'desktop': 'Free-Upgrade-Cruncheeze-AesPg-D.jpg'
                }
            },
            {
                'name': 'PESAN SEKARANG',
                'slug': 'beli-1-my-box-gratis-1-pizza',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'Gratis1PizzaM20210225.jpg',
                    'desktop': 'Gratis1PizzaD20210225.jpg'
                }
            },{
                'name': 'PESAN SEKARANG',
                'slug': '1-pizzanya-cuma-44-ribu-aja',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'DB-44rb-AesPg-M.jpg',
                    'desktop': 'DB-44rb-AesPg-D.jpg'
                }
            },
            {
                'name': '',
                'slug': 'dinein-cheeseisland',
                'uuid': '',
                'path': '/order/hot-promo',
                'sub_name': '',
                'images': {
                    'mobile': 'cheeseisland_900x825.jpg',
                    'desktop': 'cheeseisland_1600x482.jpg'
                }
            }
        ]
      }
    },
    computed: {
      ...mapGetters ({
        storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      }),
    },
    mounted() {
      let slug = this.$route.params.slug;
      for (let i of this.menus) {
        if (i.slug.toLowerCase() == slug.toLowerCase()) {
          this.menu = i;
          break;
        }
      }
      if (this.emptyVariable(this.menu)) {
        this.menu = this.menus[0];
        return this.$router.push({name: 'offer', params: {slug: this.menus[0].slug}});
      }
    },
    methods: {
      handleClick (){
        if (!this.storeUuid) {
          this.$notify({
            type: 'warning',
            title: this.$t('message.error.choose_localisation_first')
          });
          let path = 'combo/' + this.menu.uuid;
          this.$router.push({name: 'home', query: {warning: '1', callback: path}});
          return false;
        }
        if (!this.menu.uuid && this.menu.path){
            return  this.$router.push({path: this.menu.path})
        }
        return this.$router.push({name: 'combo-detail', params: {uuid: this.menu.uuid}});
      },
      viewAllOutlet(){
        this.$router.push({name: 'stores'});
        return false;
      }
    },
  }
</script>
