<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
              <button type="button" aria-label="Close" class="close" @click="$emit('close')"><img src="../../assets/images/icon-close--white.svg" /></button>
          </div>

          <div class="modal-body t20 text-white ">
              <div class="text-center">{{contain }}</div>
          </div>

          <div class="modal-footer text-center">
              <button type="button" class="btn button--red f600 text-16 min-height-44 " @click="$emit('close');chooseCollection()" >{{$t('common.choose_collection')}}</button>
              <button type="button" class="btn button--red f600 text-16 min-height-44 " @click="$emit('close');chooseAnotherDelivery()" >{{$t('common.choose_another_delivery')}}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalDeliveryOrCollection',
  props: {
    'contain': {
      type: String,
      default: '',
    },
    chooseAnotherDelivery: {
      type: Function
    },
    chooseCollection: {
      type: Function
    }
  },
  data() {
    return {

    }
  },
  watch: {},
  mounted() {

  },
  methods: {}
}
</script>
<style scoped>
    .button--red {
        background-color: #da291c;
        color: #fff;
    }
</style>

