<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header"  >
              <h5 class="modal-title text-center  text-18 w-full">{{$t('common.title_update_quantity')}}</h5>
              <button type="button" aria-label="Close" class="close" @click="$emit('close')">×</button>
          </div>

          <div class="modal-body">
              <div>
                  <div class="block-inc-dec text-center">
                      <button class="decrement-button" :disabled="disabledDecrement" @click="decrementQuantity">−</button>
                      <input class="bold" name="quantity" type="number" :min="min" :max="max" :step="step" style="width: 80px; text-align: center; margin: 0 10px"
                             v-model.number="value"
                             @keydown.esc="validateAmount"
                             @blur="validateAmount"
                             @keydown.enter="validateAmount"
                      />
                      <button class="increment-button" :disabled="disabledIncrement" @click="incrementQuantity">+</button>
                  </div>
              </div>
          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-secondary flex-1" @click="$emit('close')">{{$t('common.btn.cancel')}}</button>
              <button type="button" class="btn  button--red flex-1"  style="color: white" @click="$emit('close');save(value)">{{$t('common.btn.save')}}</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalUpdateQuantity',
  props: {
    save: {
      type: Function
    },
    max: {
      type: Number,
      default: 50
    },
    min: {
      type: Number,
      default: 0
    },
    amount: {
      required: true
    },
    step: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      value: 0,
      disabledDecrement: false,
      disabledIncrement: false,
    }
  },
  watch: {},
  mounted() {
    this.value = this.amount ? this.amount : 0;
  },
  methods: {
    validateAmount(){
      if ( !Number.isInteger(this.value) || this.value < 0) {
        this.value = this.amount ? this.amount : 0;
      }
    },
    incrementQuantity() {
      if (this.incrementDisabled) {
        return
      }
      this.disabledDecrement = false;
      this.handleValue(this.value + (this.step * 1));
    },
    handleValue (value){
      const old = this.value;

      if (old === value) {
        return
      }
      if (value <= this.min) {
        value = this.min
        this.disabledDecrement = true
      }
      if (value >= this.max) {
        value = this.max
        this.incrementDisabled = true
      }
      this.value = value
    },
    decrementQuantity() {
      if (this.disabledDecrement) {
        return
      }
      this.incrementDisabled = false;
      this.handleValue(this.value + (this.step * (-1)))
    },
  }
}
</script>
<style scoped>
    .decrement-button, .increment-button {
        background: #da291c !important;
    }
    .button--red {
        background-color: #da291c !important;
    }
    .block-inc-dec button{
        background: #70a401;
        border-radius: 50%;
        color: #fff;
        font-size: 18px;
        width: 30px;
        height: 30px;
    }

</style>

