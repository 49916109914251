<template>
    <div class=" en-GB app-bg">
        <div class="container mt-20 mb-40">
            <div class="p-20 bg-white">
                <h1 style="color: #c8102e;"><b>Brand Story</b></h1>
                <p><span style="color: #c8102e;"><b>Kenalin, kami Pizza Hut</b></span></p>
                <div class="photo">
                    <ul v-if="!isMobile()">
                        <li>
                            <img src="../../assets/images/brand_story1.jpg">
                        </li>
                        <li>
                            <img src="../../assets/images/brand_story2.jpg">
                        </li>
                        <li>
                            <img src="../../assets/images/BrandStory_photo_3.jpg">
                        </li>
                    </ul>
                    <ul v-else style="flex-direction: column;">
                        <li>
                            <img src="../../assets/images/brand_story1.jpg">
                        </li>
                        <li>
                            <img src="../../assets/images/brand_story2.jpg">
                        </li>
                        <li>
                            <img src="../../assets/images/BrandStory_photo_3.jpg">
                        </li>
                    </ul>
                </div>
                <p><span><br></span><span>Cerita kami dimulai di tahun 1958, di kota Wichita. Saat itu dua orang bersaudara bernama Dan serta Frank Carney berinisiatif membuat sebuah kedai Pizza sederhana. Ternyata, Pizza yang mereka bikin disukai banyak orang hingga akhirnya menyebar ke seluruh Dunia, termasuk ke Indonesia.</span>
                </p>
                <p><span>&nbsp;</span></p>
                <p><span>Di Indonesia, Restoran pertama kami dibuka di gedung Djakarta Theatre pada tahun 1984. Dalam waktu cukup singkat, Pizza Hut menyebar ke seluruh Indonesia, sampai ada lebih dari 500 outlet kami dari ujung barat ke ujung timur negri. Keren kan? Nah resep rahasia di balik semua itu adalah service terbaik dan inovasi!</span>
                </p>
                <p><span>&nbsp;</span></p>
                <p><span>Pizza Hut Indonesia terdiri dari Pizza Hut Restaurant dan PHD dengan layanan “30 menit pasti tiba”. Karena kami mengerti, kesibukan dan terbatasnya waktu bikin kamu ingin semuanya serba cepat, jadi pesanan kamu tetap hangat dan nikmat saat tiba di tujuan.</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BrandStory"
    }
</script>

<style scoped>
 .photo > ul {
     display:flex;
     align-items:center;
     justify-content:space-around;
     padding-bottom: 10px;
     padding-top: 10px;
 }
 .photo > ul > li {
     width: 300px;
     height: auto;
     padding-bottom: 5px;
 }
</style>