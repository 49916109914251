<template>
    <div class="modal-map-carryout">
        <div class="">
            <button @click="$emit('close')" type="button" data-dismiss="modal" aria-label="Close" class="close" style="">
                <span aria-hidden="true">×</span>
            </button>
            <div>
                <div id="mapLocalisationCarryout"></div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    name: 'ModalMapCarryout',
    props: {
      'stores': {
        type: Array,
        default: [],
      },
      'lat': {
        type: Number,
        default: 0,
      },
      'lng': {
        type: Number,
        default: 0,
      },
      'chosenStore': {
        type: Function,
      }
    },
    data: () => {
      return {
        map: null,
        markers: []
      }
    },
    mounted () {
      var  vm = this;
      window.showInfoWindow = function (index) {
        vm.handleShowInfoWindow(index)
      }
      this.initMap();

    },
    methods: {
      initMap () {
        var vm = this;
        this.map = new google.maps.Map(document.getElementById('mapLocalisationCarryout'), {
          center: {lat: vm.lat || this.$const.LOCATION.LAT, lng: vm.lng || this.$const.LOCATION.LNG},
          zoom: 100,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          mapTypeControl: false
        });
        this.drawStoreOnMaps();
      },
      drawStoreOnMaps () {
        var vm = this
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null)
        }
        this.markers = []
        if (this.lat && this.lng) {
          let latlng = new google.maps.LatLng(this.lat, this.lng)
          let nMarket = new google.maps.Marker({
            position: latlng,
            map: this.map,
            draggable: false,
            icon: 'https://static.pizzahut.co.id/images/icons/pin.png'
          })
          this.markers.push(nMarket)
        }
        let bounds = new google.maps.LatLngBounds();
        if (this.markers.length > 0) {
          bounds.extend(new google.maps.LatLng(this.markers[0].getPosition().lat(), this.markers[0].getPosition().lng()))
        }
        for (let i = 0; i < this.stores.length; i++) {
          let latlng = new google.maps.LatLng(this.stores[i].lat, this.stores[i].long)
          let icon = {
            url: 'https://static.pizzahut.co.id/images/icons/gray-logo.png',
            scaledSize: new google.maps.Size(40, 40)
          }
          let contentString = '<div id="content">' +
            '<div >' +
            '</div>' +
            '<h1 > ' + this.stores[i].name + '</h1>' +
            '<div >' +
            '<p class="pt-10 pb-10">' + this.stores[i].location + '</p>' +
            '<p><button class="button button--red m-auto " style="padding: 8px 15px" onclick="showInfoWindow(' + i + ')">' + this.$t('common.select') + '</button></p>' +
            '</div>' +
            '</div>'

          let marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            draggable: false,
            outletIndex: i,
            outlet: this.stores[i],
            infowindow: new google.maps.InfoWindow({
              content: contentString
            }),
            title: this.stores[i].location,
            icon: icon,
            hoverIcon: 'https://static-dev.cpos.diqit.io/assets/icons/red-logo.png',
            originIcon: 'https://static-dev.cpos.diqit.io/assets/icons/gray-logo.png'
          })
          this.markers.push(marker)
          bounds.extend(new google.maps.LatLng(this.markers[i].getPosition().lat(), this.markers[i].getPosition().lng()))
          marker.addListener('click', function (e) {
            for (let i = 0; i < vm.markers.length; i++) {
              if (vm.markers[i].infowindow) {
                vm.markers[i].infowindow.close()
              }
            }
            vm.focusStore(this.outletIndex)
            this.infowindow.open(this.map, marker)
          })
        }
        this.map.fitBounds(bounds)
      },
      focusStore (index) {
        let flag = false
        if (this.lat && this.lng) {
          flag = true
          index += 1
        }
        for (let i in this.markers) {
          if (i == 0 && flag) continue
          let url = this.markers[i].originIcon
          if (i == index) {
            url = this.markers[i].hoverIcon
          }
          this.markers[i].setIcon({
            url: url,
            scaledSize: new google.maps.Size(40, 40)
          })
        }
        if (this.markers.length > 0) {
          this.map.panTo(this.markers[index].position)
        }
      },
      handleShowInfoWindow (index) {
        this.$emit('close');
        this.chosenStore(this.stores[index]);
      }
    }
  }

</script>
<style scoped>
    .modal-map-carryout button.close {
        background: #0000009c !important;

    }
    .modal-map-carryout  #mapLocalisationCarryout {
        width: 100%;
        height: 100vh;
    }
</style>
