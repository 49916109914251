<template>
  <div class="bg-white">
    <header-component v-if="!isMobile()" />
    <div v-if="isMobile()" class="text-center relative py-15">
      <h1 class="text-14">Redeem now</h1>
      <button class="flex items-center text-grey absolute pl-20 top-0 bottom-0 left-0" @click="$router.go(-1)">
        <em class="ml-5 icon-caret-left icon--small"></em>
      </button>
      <button @click="$router.push({name: 'ph-reward-detail'})" class="flex items-center text-grey absolute pr-20 top-0 bottom-0 right-0">
        <em class="ml-5 icon-info"></em>
      </button>
    </div>
    <div v-else class="container flex-row flex px-10">
      <h2 class="heading-left"><span>Redeem now</span></h2>
    </div>
    <div class="container">
      <div class="grid-container">
        <point-singles v-for="(item, index) in list" :item="item" :key="index"/>
      </div>
    </div>
    <modal-redeem-point :is-open="isOpenModal" :data="redeemData" @close-modal="isOpenModal = false"/>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import MenuApi from "@/services/menu";
import ModalRedeemPoint from "@/components/modal/ModalRedeemPoint";
import {mapGetters} from "vuex";
import mutationTypes from "@/store/mutationTypes";
import PointSingles from "@/components/rewards/PointSingles";
import types from "@/store/mutationTypes";
export default {
  name: "AllRedeem",
  components: {PointSingles, ModalRedeemPoint, HeaderComponent},
  data() {
    return {
      isOpenModal: false,
      redeemData: {},
      list: [],
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      const vm = this;
      let payload = {
        type_id: this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.localisation.orderType, 0)],
        store_uuid: this.storeUuid,
        vm: this,
      }
      vm.list = [];
      MenuApi.getAllProductsByPoint(payload, function (response) {
        if (response.data.length > 0 && response.data[0].products) {
          vm.list = response.data[0].products;
        }
      })
    },
    handleRedeemPoint(item){
      if(this.storeUuid != undefined) {
        this.$router.push({name: 'category', params: {'slug' : 'point'}})
      } else {
        this.redeemData = item;
        this.isOpenModal = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      localisation: 'moduleLocalisation/' +  types.LOCALISATION_GET_LOCALISATION,
      storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
    })
  }
}
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}
@media screen and (max-width: 800px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 16px;
    grid-gap: 12px 8px;
  }
}
</style>
