<template>
  <div id="background-overlay" class="background-overlay" @click="emitCloseModal">
    <div class="modal-wrapper">
      <div class="info">
        <div class="content" style="text-align: center; width: 100%">
          <img src="@/assets/images/treasure-claim.png"/>
          <h1>Successfully claimed!</h1>
          <p>You will receive your treasure soon</p>
        </div>
      </div>
      <div class="bottom">
        <div class="buttons-wrapper">
          <button class="button button--red" @click="goTreasurePage">Done</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalClaimReward",
  props: ['vm'],
  methods: {
    emitCloseModal() {
      if (event.target.id === 'background-overlay') {
        this.$emit('close')
      }
    },
    goTreasurePage() {
      if (this.vm) {
        this.vm.$router.push({name:'vouchers'})
      }
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
  .background-overlay {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    transition: width .5s ease, height .3s ease, border-radius .3s ease, top .3s ease, left .5s ease, opacity .5s ease;
  }
  .modal-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    width: 480px;
    min-height: 300px;
    background-color: #FFFFFF;
    border-radius: 12px;
    padding: 32px;
  }

  .info {
    display: flex;
    gap: 16px;
  }
  .info p {
    color: #9C9FA7;
    font-size: 16px;
    line-height: 22px;
  }
  .info img {
    height: 155px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .bottom {
    margin-top: 16px;
  }
  .bottom p {
    color: #5D6063;
    font-weight: 400;
    font-size: 15px;
  }
  .buttons-wrapper {
    margin: 8px 0px;
    display: flex;
    gap: 8px;
  }
  .buttons-wrapper > button {
    flex: 1;
    height: 44px;
    border-radius: 4px;
    font-size: 16px;
  }
  .buttons-wrapper > button:hover {
    background-color: #C5C4C5;
  }

  @media (max-width: 800px) {
    .background-overlay {
      transition: none;
    }

    .modal-wrapper {
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      transform: translate(0, -100%);
      width: 100%;
      min-height: 224px;
      border-radius: 16px 16px 0 0;
      padding: 20px;
      transition: all .5s ease;
    }

    .modal-wrapper h1 {
      color: #020202;
      font-size: 14px;
      line-height: 20px;
    }
    .info {
      gap: 12px;
      max-height: 420px;
      overflow-y: scroll;
    }
    .info p {
      font-size: 12px;
      line-height: 16px;
    }
    .bottom h1 {
      font-size: 16px;
    }
    .buttons-wrapper {
      margin: 8px 0px;
      display: flex;
      gap: 8px;
    }
  }
</style>