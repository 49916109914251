<template>
  <div class="item cursor-pointer" @click="handleRedeemPoint()">
    <div class="image-wrapper">
      <img v-if="item.image" :src="item.image" :alt="`Redeem Image`">
    </div>
    <div class="content font-open-sans-bold">
      <p>{{item.name}}</p>
      <div class="flex text-red">
        <img src="../../assets/images/hut_reward_circle.png" style="padding-right: 10px" alt="Pizza Icon">{{item.point}}
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import mutationTypes from "@/store/mutationTypes";
import _ from 'lodash'
import manthan from "@/services/manthan";
import branch from "@/services/branch";

export default {
  name: "PointSingles",
  props: {
    item: {
      type: Object,
      default: () => {},
    }
  },
  data () {
    return {
      amount: 0,
      listCrusts: [],
      listSizes: [],
      selectedCrustItem: {},
      selectedSizeItem: {},
      extraCheese: {},
      currentExtraCheese: {},
      hasExtraCheese: {},
      img_url:'',
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions({
      addToCart: 'moduleCart/' + mutationTypes.CART_SAVE_ITEM,
    }),
    initData(){
      // Crust
      let _listCrusts = this.item.option_groups || [];
      if (_listCrusts.length > 0) {
        for (let i in this.item.option_groups) {
          this.listCrusts.push({
            label: this.item.option_groups[i].name,
            item: this.item.option_groups[i]
          })
        }
        this.selectedCrustItem = this.listCrusts[0];
      }
      // Size
      if (this.selectedCrustItem && this.selectedCrustItem.item) {
        let dataListSizes = this.selectedCrustItem.item.options || [];
        if (dataListSizes.length) {
          this.listSizes = [];
          for (let i in dataListSizes) {
            this.listSizes.push({
              label: dataListSizes[i].name,
              item: dataListSizes[i]
            })
          }
          this.selectedSizeItem = this.listSizes[0];
        }
      }
      // Amount
      this.amount = this.item.sub_price;
      let _selectedCrustItem = this.selectedCrustItem ? _.cloneDeep(this.selectedCrustItem.item) : {};
      let _selectedSizeItem = this.selectedSizeItem ? _.cloneDeep(this.selectedSizeItem.item) : {};
      if (!this.emptyVariable(_selectedCrustItem)) {
        this.amount += _selectedCrustItem.sub_price;
      }
      if (!this.emptyVariable(_selectedSizeItem)) {
        this.amount += _selectedSizeItem.sub_price;
      }
      if (!this.emptyVariable(this.hasExtraCheese)) {
        this.amount += this.hasExtraCheese.sub_price;
      }
    },
    handleRedeemPoint(){
      if (this.storeUuid != undefined && this.storeUuid !== '') {
        if (this.item.is_combo) {
          return this.$router.push({name: 'combo-detail', params: {uuid: this.item.uuid, is_half_combo: this.item.is_combo_half}})
        }
        this.addSingleItemToCart()
      } else {
        this.$router.push({name: 'home', query: {warning: '1'}})
      }
    },
    addSingleItemToCart() {
      let _selectedCrustItem = this.selectedCrustItem ? _.cloneDeep(this.selectedCrustItem.item) : {};
      let _selectedSizeItem = this.selectedSizeItem ? _.cloneDeep(this.selectedSizeItem.item) : {};
      let dataProduct = {
        "cart_uuid": this.cartUuid || '',
        "disposition": this.$const.ORDER_TYPE_MAPPING[this.getOrderType(this.orderType, this.$const.ORDERTYPE.DELIVERY)],
        "items": [
          {
            "combo_uuid": "",
            "is_point": this.item.point > 0 ? 1 : 0,
            "promotion_code": this.codeRedeem || '',
            "products": [
              {
                "category_uuid": this.item.category.uuid,
                "combo_group_uuid": "",
                "option_group_uuid": _selectedCrustItem ? _selectedCrustItem.uuid : '',
                "option_uuid": _selectedSizeItem ? _selectedSizeItem.uuid : '',
                "product_uuid": this.item.uuid,
                "quantity": 1,
                "toppings": {
                  "removal": [],
                  "addition": []
                }
              }
            ],
            "quantity": 1,
            "type": "product",
            "voucher_code": ""
          }
        ],
        "now_order": this.orderNow,
        "collection_time": this.convertDateTimeFromTimezoneToUTC(this.timezone, this.orderTime),
        "store_uuid": this.storeUuid
      }

      // Send data layer product click
      let dataPro = {
        'name': this.item.name,
        'id': this.item.uuid,
        'price': this.item.sub_price,
        'category': this.item.category && this.item.category.name ? this.item.category.name : '',
        'url': window.location.href
      };
      this.eventProductClick(dataPro);

      // Send data layer add to cart
      let variant = [];
      if (this.selectedSizeItem) {
        variant.push(this.selectedSizeItem.uuid)
      }
      if (this.selectedCrustItem) {
        variant.push(this.selectedCrustItem.uuid)
      }
      dataPro.variant = variant.length > 0 ? variant.join('-') : '',
      this.eventAddToCart(dataPro);

      // Add to cart
      const vm = this
      this.addToCart({vm: this, payload: dataProduct}).then( () => {
        this._eventAddToCart();
        return this.$router.push({name: 'category', params: {'slug' : 'point'}})
      }).catch( error => {
        this.notificationError(vm, error.message)
      });
    },
    _eventAddToCart() {
      const dataManthanCart = {
        cartId: this.cartUuid || '',
        productId: this.item.uuid,
        categoryId: this.item.category && this.item.category.uuid ? this.item.category.uuid : '',
        brandId: this.localisation.storeType === this.$const.TYPE_HUT.PHD ? 'PHD' : 'PHR',
        price: this.item.sub_price,
        quantity: 1,
        totalQuantity: this.cartCount,
        shippingCost: this.cartData.information.delivery_fee > 0 ? this.cartData.information.delivery_fee : 0,
        tax: this.item.tax
      };
      manthan.execute(dataManthanCart,"eventAddToCart")
      branch.branchLog('ADD_TO_CART',[this.item])
    }
  },
  computed: {
    ...mapGetters({
      cartUuid: 'moduleCart/' + mutationTypes.CART_GET_CART_UUID,
      cartData: 'moduleCart/' + mutationTypes.CART_DETAIL,
      orderTime: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_COLLECTION_TIME,
      orderNow: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_NOW,
      storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
      localisation: 'moduleLocalisation/' +  mutationTypes.LOCALISATION_GET_LOCALISATION,
      orderType: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_ORDER_TYPE,
    })
  }
}
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 5px 0px;
}
.image-wrapper {
  aspect-ratio: 1/1;
  width: 100%;
}
.image-wrapper > img {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.content {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0px 12px 12px 12px;
}
.content > p {
  flex: auto;
  line-height: 20px;
  margin-bottom: 4px;
  white-space: break-spaces;
}
</style>