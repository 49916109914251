<template>
  <div id="background-overlay" class="background-overlay" @click="emitCloseModal">
    <div class="modal-wrapper">
      <div class="info">
        <div class="content" style="text-align: center; width: 100%">
          <img :src="data.promotion_thumbnail" class="pb-10"/>
          <h4>Yeah! you got {{data.promotion_master_name}} from Treasure Hut</h4>
        </div>
      </div>
      <div class="bottom">
        <div class="buttons-wrapper">
          <button v-if="data.is_expired" class="button" style="background-color: #C5C4C5" @click="$emit('close')">Expired</button>
          <button v-else-if="data.is_use" class="button" style="background-color: #C5C4C5" @click="$emit('close')">Used</button>
          <button v-else class="button button--red" @click="claimTreasure">Claim</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import constant from "@/constant";
import couponAPI from "@/services/coupon";
import ModalClaimReward from "@/components/modal/ModalClaimReward";

export default {
  name: "ModalTreasureHut",
  props:{
    data: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
    }
  },
  methods:{
    emitCloseModal() {
      if (event.target.id === 'background-overlay') {
        this.$emit('close')
      }
    },
    claimTreasure() {
      const vm = this;
      const params = {
        vm: vm
      }
      if (vm.data.promotion_type_id === constant.TREASURE_PRODUCT) {
        couponAPI.claimTreasure(vm.data.uuid, params, function (response) {
          if (response) {
            vm.$emit('close')
            vm.$modal.show(ModalClaimReward, {vm})
          }
        }, function (error) {
          vm.notificationError(vm, error.message);
        })
      } else if (vm.data.promotion_type_id === constant.TREASURE_GIFT) {
        vm.$emit('close')
        vm.$router.push({name:'detail-delivery-reward', params: {order_code: vm.data.order_code}})
      }

    }
  }
}
</script>

<style scoped>
.background-overlay {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  transition: width .5s ease, height .3s ease, border-radius .3s ease, top .3s ease, left .5s ease, opacity .5s ease;
}
.modal-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 480px;
  min-height: 300px;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 32px;
}
.info {
  display: flex;
  gap: 16px;
}
.info p {
  color: #9C9FA7;
  font-size: 16px;
  line-height: 22px;
}
.info img {
  height: 155px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.bottom {
  margin-top: 16px;
}
.bottom p {
  color: #5D6063;
  font-weight: 400;
  font-size: 15px;
}
.buttons-wrapper {
  margin: 8px 0px;
  display: flex;
  gap: 8px;
}
.buttons-wrapper > button {
  flex: 1;
  height: 44px;
  border-radius: 4px;
  font-size: 16px;
}
.buttons-wrapper > button:hover {
  background-color: #C5C4C5;
}

@media (max-width: 800px) {
  .background-overlay {
    transition: none;
  }

  .modal-wrapper {
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    transform: translate(0, -100%);
    width: 100%;
    min-height: 224px;
    border-radius: 16px 16px 0 0;
    padding: 20px;
    transition: all .5s ease;
  }

  .modal-wrapper h1 {
    color: #020202;
    font-size: 14px;
    line-height: 20px;
  }
  .info {
    gap: 12px;
    max-height: 420px;
    overflow-y: scroll;
  }
  .info p {
    font-size: 12px;
    line-height: 16px;
  }
  .bottom h1 {
    font-size: 16px;
  }
  .buttons-wrapper {
    margin: 8px 0px;
    display: flex;
    gap: 8px;
  }
}
</style>
