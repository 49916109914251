<script src="./js/lazysizes.min.js"></script>
<template>
    <div class="en-GB app-bg digital_menu">
        <header-component></header-component>
        <div class="md:flex md:flex-col lg:flex-row container lg:justify-start btn-top">
            <button class="button button--white-black" v-bind:class="{ active: this.slug == 'phr' }" @click="changeMenu('phr')">
                <img src="@/assets/images/logo_wide.png">
            </button>
            <button class="button button--white-black" v-bind:class="{ active: this.slug == 'phd' }" @click="changeMenu('phd')">
                <img src="@/assets/images/logo-phd2.png">
            </button>
        </div>

        <PHDContent v-if="this.slug == 'phd'"></PHDContent>
        <PHRContent v-else></PHRContent>
        <!-- <iframe ref="digitalMenuContent" class="digital-menu-embed" :src="baseURL + (this.slug == 'phd' ? '/mock/digital-menu/phd' : '/mock/digital-menu/phr')" frameborder="0" scrolling="no" @load="resizeIframe('digitalMenuContent')"></iframe> -->
        
        
        <div class="z-1" style="position: fixed;  bottom: 0; width: 100%">
            <div class="container flex flex-center justify-between relative h-full">
                <button class="button button--red flex-1  px-20 " style="text-align: center" @click="orderNow()">
                    <div class="flex w-full flex-col py-10"><b><span class=""><span>{{$t('common.order_now')}}</span></span></b>
                    </div>
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    import HeaderComponent from "@/components/HeaderComponent";
    import {mapGetters} from "vuex";
    import mutationTypes from "@/store/mutationTypes";
    import lazysizes from "lazysizes";
    import PHRContent from "@/components/digital_menu/phr";
    import PHDContent from "@/components/digital_menu/phd";
    const baseURL = process.env.VUE_APP_BASE_APP

    export default {
        name: "DigitalMenu",
        components: {HeaderComponent, PHRContent, PHDContent},
        data() {
            return {
                slug: 'phr',
                baseURL: baseURL
            }
        },
        watch: {
            $route (){
                this.slug = this.$route.params.slug
            }
        },
        methods: {
            changeMenu(slug){
                this.$router.push({name: 'digital-menu', params: {'slug' : slug}})
            },
            orderNow(){
                if(this.storeUuid != undefined) {
                    this.$router.push({name: 'category', params: {'slug' : 'hot-promo'}})
                } else {
                    this.$router.push({name: 'home', query: {warning: '1'}})
                }
            },
            resizeIframe(e) {
                const obj = this.$refs[e]
                obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px'
                let i = 0
                const checkHeight = setInterval(() => {
                    i++
                    obj.style.height = obj.contentWindow.document.querySelector('html').offsetHeight + 'px'
                    if (i > 9) {
                        clearInterval(checkHeight)
                    }
                }, 1000)
            }
        },
        computed: {
            ...mapGetters({
                storeUuid: 'moduleLocalisation/' + mutationTypes.LOCALISATION_GET_STORE_UUID,
            })
        }
    }
</script>

<style scoped>
.digital-menu-embed {
    width: 100%;
    min-height: calc(100vh - 200px);
    overflow: visible;
}
</style>