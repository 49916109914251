<template>
    <div @click="handleProductClick" class="list-item  cursor-pointer" :class="this.class_half">
        <div class="list-item__image " ref="itemImage">
            <div :class="this.class_image"  :style="css_img" ref="itemImage">
                <img :src="img_url+product.image" :alt="product.name"  v-if="!emptyVariable(product.image)">
            </div>
            <div v-if="this.is_half_combo">
                <p class="typography-4 list-item__name flex-1 ">{{product.name}}</p>
                <p class="typography-6 list-item__desc flex-1 ">{{product.description}}</p>
            </div>
        </div>
        <p class="typography-4 list-item__name flex-1 ">{{!this.is_half_combo ? product.name : ''}}</p>
        <p class="typography-6 list-item__desc flex-1 ">{{!this.is_half_combo ? product.description : ''}}</p>

        <div class="mx-10 py-10 flex items-baseline justify-end border-t pt-5">
            <span v-if="!this.is_half_combo" class="typography-4 list-item__price self-center pt-5 w-full">
                <button v-if="product.point > 0 " class="button button--md button--red flex-1 font-semibold t15 mt13" style="width: inherit">
                    <span>{{$t('common.add_to_redeem')}}</span> <span class="w-auto">{{$t('common.format_point', {amount: formatCurrency(product.point, $const.SUFFIX_CURRENCY)})}}</span>
                </button>
                <span v-else class="t15 mt13">
                    {{$t('common.format_currency', {amount: formatCurrency(product.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                </span>
            </span>
            <button v-if="this.is_half_combo" class="button button--md font-semibold mt-10 height-auto button--red t15 mt14"  @click="handleProductClick">
                <span>{{$t('common.btn.add')}}</span>
                <span class="t15 mt13">
                    {{$t('common.format_currency', {amount: formatCurrency(product.sub_price, $const.SUFFIX_CURRENCY), currency: $const.CURRENCY})}}
                </span>
            </button>
        </div>
    </div>
</template>

<script>

export default {
  props:["product", 'codeRedeem'],
    data() {
        return {
            img_url: '',
            class_half: '',
            class_image: '',
            css_img:'',
            is_half_combo: false
        }
    },
  created() {
    this.is_half_combo = this.product.is_combo && this.product.is_combo_half
    this.class_half = this.is_half_combo ? ' list-item--pizza' : (!this.product.point ? 'product-list--deals' : '')
    this.class_image = this.is_half_combo ? 'list-item__image flex-center flex' : ''
    this.css_img = this.is_half_combo ? 'height: 200px' : ''
  },
    methods: {
      handleProductClick () {
        let dataPro = {
          'name': this.product.name,
          'id': this.product.uuid,
          'price': this.product.sub_price,
          'category': this.product.category && this.product.category.name ? this.product.category.name : '',
          'url': window.location.href,
          'is_upsell': this.$route.query.is_upsell && this.$route.query.product === this.product.uuid ? 1 : 0
        };
        this.eventProductClick(dataPro);
        this.$router.push({name: 'combo-detail', params: {uuid: this.product.uuid, code: this.codeRedeem, is_half_combo: this.is_half_combo}})
      }
    },
    mounted() {
      const size = this.$refs.itemImage.clientWidth
        this.img_url = this.$const.LINK_STATIC_FLY + "width=" + size + "/"
    },
    computed: {
    }
}
</script>
