<template>
    <div>
        <header-component />
        <div class=" en-GB app-bg">
            <div class="container mt-20 mb-40">
                <div class="font-open-sans-bold uppercase text-center relative mb-20">
                    <h1 class="px-40">{{$t('common.profile')}}</h1>
                    <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
                </div>
                <div class="p-20 bg-white">
                    <div class="md:flex">
                        <form class="contain-form m-auto w-full" novalidate="novalidate" method="post" action="" @submit="checkForm">
                            <div class="block-section">
                                <div class="form-field">
                                    <div class="xs:flex xs:flex-center mb-5">
                                        <label class="label mb-0" for="email">{{$t('customer.email')}}
                                            <span class="text-red">{{ $t('customer.required') }}</span>
                                            <span class="float-right text-red" v-if="errors.email">{{errors.email}}</span>
                                        </label>
                                    </div>
                                    <input id="email" class="input email" ref="email" v-bind:class="{'has-error': errors.email, 'has-success': errors.email === false}"
                                           name="email" v-model="email" type="text">
                                </div>
                            </div>
                            <div class="block-section">
                                <div>
                                    <button class="button button--red flex-1 rounded px-20 " style="min-height: 42px;" > {{$t('common.btn.reset_password')}}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
  import HeaderComponent from '../components/HeaderComponent';
  import CustomerApi from '../services/customer';

  export default {
    name: "forgot-password",
    components: {
      HeaderComponent,
    },
    data() {
     return {
       email: '',
       passwordConfirm: '',
       errors: {
         isValid: false,
         email: '',
       }
     }
    },
    computed: {
    },
    mounted() {
    },
    watch: {
      'email': function (newValue) {
        this.errors.email = false;
        if (!newValue) {
          this.errors.email = this.$t('message.error.email');
        } else if (!this.validEmail(newValue)) {
          this.errors.email = this.$t('message.error.invalid_email');
        }
      }
    },
    methods: {
      initErrorMessage (mode) {
        if (mode && mode === 'inputPass') {
          this.errors = {
            isValid: false,
            email: false,
          }
        } else {
          this.errors = {
            isValid: false,
            email: false,
          }
        }
      },
      checkForm(e) {
        e.preventDefault();
        let isValidForm = true;
        this.initErrorMessage();

        if (!this.email) {
          this.errors.email = this.$t('message.error.email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        } else if (!this.validEmail(this.email)) {
          this.errors.email = this.$t('message.error.invalid_email');
          if (isValidForm) {
            this.$refs.email.focus();
          }
          isValidForm = false;
        }

        if (isValidForm) {
          this.getOtpByEmail(this.email);
        }
      },
      getOtpByEmail (email) {
        var vm = this;
        CustomerApi.sendOtpViaEmail(email, {vm: this},  function (response) {
          vm.notificationSuccess(vm, vm.$t('message.success.send_reset_password'));
        } ,function (error) {
          vm.notificationSuccess(vm, vm.$t('message.success.send_reset_password'));
        })
      },
    }
  }
</script>
