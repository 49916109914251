<template>
  <div>
    <header-component v-if="!isMobile()"></header-component>
    <PhRewardDetailDesktop></PhRewardDetailDesktop>
  </div>

</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import PhRewardDetailDesktop from "@/views/PhRewardDetailDesktop";
export default {
  name: "PhRewardDetail",
  components: {HeaderComponent, PhRewardDetailDesktop}
}
</script>
