import '@babel/polyfill'

import Vue from 'vue'
import App from './App.vue'
import VueGeolocation from 'vue-browser-geolocation';
import router from './router'
import store from './store'
import constant from './constant'
import axios from 'axios';
import VueAxios from 'vue-axios';
import Notifications from 'vue-notification'
import mixin from './mixins/index'
import translation from './translation'
import VModal from 'vue-js-modal'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VModal, {dynamic: true, dynamicDefaults: { clickToClose: false }, injectModalsContainer: true, dialog: true})
Vue.config.productionTip = false
Vue.use(VueGeolocation);
Vue.prototype.$const = constant
Vue.use(VueAxios, axios);
Vue.use(Notifications)
Vue.mixin(mixin)
Vue.use(VueLodash, { name: 'custom', lodash: lodash})
Vue.use(VueGoogleMaps,{
  load:{
    key:'AIzaSyC8Tu-J0g-TPXUOiT57EP5lvM9i5Pi9Ar4',
    libraries: "places"
  },
  installComponents: true
})
Vue.use(VueQrcodeReader)


const mainApp = new Vue({
  router,
  store,
  i18n: translation,
  created() {
  },
  mounted() {
  },
  render: h => h(App)
}).$mount('#app')
export default mainApp;