<template>
<div class="bg-white">
  <div class="container">
    <div class="grid-container">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="image-wrapper">
          <img v-if="item.promotion_thumbnail" :src="item.promotion_thumbnail">
        </div>
        <div class="content items-center font-open-sans-bold">
          <p>{{item.promotion_master_name}}</p>
          <div v-if="item.is_expired" class="flex text-grey">
            <span>Expired</span>
          </div>
          <div v-else-if="item.is_use" class="flex text-grey info-used">
            <span>Used</span><img src="@/assets/images/icon_tick_circle.png" style="height: 20px; padding-left: 5px" alt="Tick Icon">
          </div>
          <div v-else class="flex text-white info-used use cursor-pointer">
            <span @click="claimTreasure(item)">Claim</span> <img src="@/assets/images/icon_tick_circle_white.png" style="height: 20px; padding-left: 5px" alt="Tick Icon">
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal-claim-reward v-show="isClaimReward"  @close="isClaimReward = false"/>
</div>
</template>

<script>
import couponAPI from "@/services/coupon";
import {mapGetters} from "vuex";
import types from "@/store/mutationTypes";
import ModalClaimReward from "@/components/modal/ModalClaimReward";
import constant from "@/constant";

export default {
  name: "Treasures",
  components: {ModalClaimReward},
  data() {
    return {
      list: [],
      isClaimReward: false,
    }
  },
  created() {
    this.initData()
    this.claimTreasureGift()
  },
  methods: {
    claimTreasureGift() {
      if (this.$route.params.uuid) {
        this.claimTreasure({uuid: this.$route.params.uuid}, true)
        this.$route.params.uuid = ''
      }
    },
    initData(){
      const vm = this;
      let params = {
        store_uuid: this.storeUuid,
        vm: vm
      };
      vm.list = [];
      couponAPI.getTreasures(params, function (response) {
        vm.list = response.data;
      })
    },
    claimTreasure(item, claimGift = false) {
      const vm = this;
      const params = {
        vm: vm
      }
      if (item.promotion_type_id === constant.TREASURE_PRODUCT || claimGift) {
        couponAPI.claimTreasure(item.uuid, params, function (response) {
          if (response) {
            vm.isClaimReward = true
            vm.initData()
          }
        }, function (error) {
          vm.notificationError(vm, error.message);
        })
      } else if (item.promotion_type_id === constant.TREASURE_GIFT) {
        vm.$router.push({name:'detail-delivery-reward', params: {order_code: item.order_code}})
      }

    }
  },
  computed: {
    ...mapGetters({
      isAuth:  'moduleAuth/' + types.CUSTOMER_IS_AUTH,
      authUser:  'moduleAuth/' + types.CUSTOMER_GET_INFO,
    }),
  }
}
</script>

<style scoped>
  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
  }
  .item {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 5px 0px;
  }
  .image-wrapper {
    min-height: 130px;
    width: 100%;
  }
  .image-wrapper > img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .content {
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px 12px 12px 12px;
  }
  .content > p {
    flex: auto;
    line-height: 20px;
    margin-bottom: 4px;
  }
  .info-used {
    background: #D7D9DC;
    border-radius: 32px;
    justify-content: center;
    width: 70px;
    height: 25px;
    align-items: center;
  }
  .info-used.use {
    background: #29CA41;
  }
  @media screen and (max-width: 800px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
      padding: 0 16px;
      grid-gap: 12px 8px;
    }
  }
</style>