<template>
    <div class=" en-GB app-bg">
        <div class="container mt-20 mb-40">
            <div class="font-open-sans-bold uppercase text-center relative mb-20">
                <h1 class="px-40">About Us</h1>
                <button class="flex items-center text-grey absolute pl-15 top-0 bottom-0 left-0 opacity-50" @click="$router.go(-1)"><i class="ml-5 icon-caret-left"></i></button>
            </div>
            <div class="p-20 bg-white">
                    <c-s-r v-if="this.slug == 'csr'"> </c-s-r>
                    <brand-story v-else> </brand-story>
            </div>
        </div>
    </div>
</template>
<script>
  import BrandStory from "./BrandStory";
  import CSR from "./CSR";
  export default {
    name: "content-content",
    components: {
        CSR,
        BrandStory
    },
    data() {
      return {
        slug: this.$route.params.slug
      }
    },
      watch: {
          $route (){
              this.slug = this.$route.params.slug
          }
      }
  }
</script>
