<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header"  >
              <h5 class="modal-title text-center  text-18 w-full">{{$t('common.verify_otp')}}</h5>
              <button type="button" aria-label="Close" class="close" @click="$emit('close')">×</button>
          </div>
          <form novalidate="novalidate" method="post" action="" @submit="checkForm" >

            <div class="modal-body">
              <div class="md:flex">
                  <div class="contain-form m-auto w-full" >
                      <div class="block-section">
                          <div class="md:flex lg:flex ">
                              <div class="form-field flex-1 ml-5 mr-5 ">
                                  <div class="xs:flex xs:flex-center mb-5">
                                      <label class="label mb-0" for="otp">{{$t('customer.otp')}}
                                          <span class="text-red">{{ $t('customer.required') }}</span>
                                          <span class="float-right text-red" v-if="message">{{message}}</span>
                                      </label>
                                  </div>
                                  <input id="otp" class="input xs-mb-10" ref="otp"
                                         name="otp" v-model="otp"
                                         maxlength="255" type="text">
                                  <span class="float-left text-red" v-if="errorMessage">{{errorMessage}}</span>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                <button v-if="countDown" type="button" class="button button--red flex-1" disabled style="width: 50%; left: 25%;">Resend OTP ({{ countDown }})</button>
                <button v-else type="button" class="button button--red flex-1" @click="resendOTP()" style="width: 50%; left: 25%;">Resend OTP</button>
            </div>

            <div class="modal-footer">
              <button type="button" class="button btn-secondary flex-1" @click="$emit('close')">{{$t('common.btn.cancel')}}</button>
              <button class="button button--red flex-1">{{$t('common.btn.submit')}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import CustomerApi from '../../services/customer';

  export default {
  name: 'ModalVerifyToken',
  props: {
    mode: {
      type: Number,
      default: 0,
      required: true,
    },
    callbackFunc: {
      type: Function
    },
    keyToken: {
      type: String,
      default: '',
      required: true
    },
    errorMessage: {
          type: String,
          default: '',
      },
      otpType: {
          type: Number,
          default: 1,
          required: true,
      },
  },
  components: {
  },
  data() {
    return {
        otp: '',
        message: '',
        countDown : 180
    }
  },
  created() {
      this.countDownTimer()
   },
  methods: {
    countDownTimer(isResend) {
        if(isResend){
            this.countDown = 180;
        }
          if(this.countDown > 0) {
              setTimeout(() => {
                  this.countDown -= 1
                  this.countDownTimer()
              }, 1000)
          }
    },
    checkForm(e) {
      e.preventDefault();
      this.message = '';
      if (!this.otp) {
        this.message = this.$t('message.error.otp');
        this.$refs.otp.focus();
      }
      if (this.message === '') {
        this.verifyToken();
      }
    },
    verifyToken () {
      switch (this.mode) {
        case this.$const.VERIFY_TOKEN_METHOD.REGISTER:
          this.verifyTokenRegister()
          break;
        case this.$const.VERIFY_TOKEN_METHOD.PROFILE_PHONE:
          this.verifyTokenProfile()
          break;
        case this.$const.VERIFY_TOKEN_METHOD.LOGIN:
          this.verifyTokenLogin()
          break;
        default:
          break;
      }
    },
    verifyTokenRegister () {
      var vm  = this;
      let payload = {
        "key": this.keyToken,
        "otp": this.otp,
        "vm": this
      }
      CustomerApi.verifyRegister(payload,
        function (response) {
          vm.$emit('close');
          vm.callbackFunc(response.data['x-token']);
        }, function (data) {
          vm.notificationError(vm, data.message)
        }
      )
    },
    verifyTokenProfile () {
      var vm  = this;
      let payload = {
        "key": this.keyToken,
        "otp": this.otp,
        "vm": this
      }
      CustomerApi.verifyProfileOtp(payload,
          // eslint-disable-next-line no-unused-vars
        function (response) {
          vm.$emit('close');
          vm.callbackFunc(true);
        }, function (data) {
          vm.notificationError(vm, data.message)
        }
      )
    },
    verifyTokenLogin () {
      var vm  = this;
      let payload = {
        "key": this.keyToken,
        "otp": this.otp,
        "vm": this
      }
      CustomerApi.loginOTP(payload,
        function (response) {
          vm.$emit('close');
          vm.callbackFunc(response.data['x-token']);
        }, function (data) {
          vm.notificationError(vm, data.message)
        }
      )
    },
    resendOTP(){
        let vm = this;
        let payload = {
            "key" : this.keyToken,
            "vm" : vm,
            "type": {"type": this.otpType}
        }
        this.countDownTimer(true)
        CustomerApi.resendOTP(
            payload,
            // eslint-disable-next-line no-unused-vars
            function (response) {

            },
            function (error) {
               vm.notificationError(vm, error.message)
            }
        )
    }
  }
}
</script>
<style scoped>
</style>

